import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Checkbox, Switch } from '@progress/kendo-react-inputs';

import { DropDownList } from '@progress/kendo-react-dropdowns';
import {
  NotificationName,
  NotificationTypeResponse
} from '../../types/responses/notification-type-response';

export const notificationGroupMap = {
  Event: 'Events',
  Game: 'Game Templates',
  Assessment: 'Assessment Templates',
  Feedback: 'Feedback Templates',
  GlobalLibrary: 'Global Library'
};

export type groupedType = Record<
  keyof typeof notificationGroupMap,
  (NotificationTypeResponse & {
    checkedApp?: boolean;
    checkedEmail?: boolean;
  })[]
>;

interface UserSettingsProps {
  handleChange: (
    type: 'app' | 'email',
    notificationName: NotificationName,
    checked: boolean
  ) => void;
  isLoading: boolean;
  notificationTypes: groupedType[];
}

const UserSettings: React.FC<UserSettingsProps> = ({
  isLoading,
  notificationTypes,
  handleChange
}) => {
  const auth = useAuth();

  return (
    <div className={'flex-grow-1 pt-2 d-flex flex-column gap-2'}>
      <div className={'border-2 border-bottom'}>
        <div className={'text-success d-flex align-items-center pb-2 gap-2'}>
          <span className={'material-symbols-outlined'}>settings</span>
          <h2 className={'m-0 fw-bold'}>Settings</h2>
        </div>
      </div>

      <div
        className={'mt-2 border-2 border-bottom pb-3 d-flex flex-column gap-3'}>
        <Checkbox
          color="black"
          label="Sent a notification digest email if I don't visit the app"
        />
        <div className="d-flex align-items-center gap-2">
          <div>Frequency:</div>
          <DropDownList
            style={{
              backgroundColor: 'white',
              width: '150px'
            }}
            defaultValue="Daily"
            data={['Daily', 'Weekly', 'Monthly']}
            popupSettings={{
              animate: false
            }}
          />
        </div>
      </div>

      <div className={'mt-2 border-2 border-bottom'}>
        Notifications
        <div className="row">
          <div className="col-9 text-primary">
            <p>Select which notifications you want to receive and how.</p>
          </div>
          <div className="col-3 row">
            <div className="col-6 text-center">In-App</div>
            <div className="col-6 text-center">Email</div>
          </div>
        </div>
      </div>

      <div className={'mt-2 flex-grow-1'}>
        {isLoading ? (
          <div className="d-flex flex-grow-1 h-100 justify-content-center align-items-center">
            Loading ...
          </div>
        ) : (
          <>
            {notificationTypes.map((notification, idx) => {
              const [title] = Object.keys(notification) as [
                keyof typeof notificationGroupMap
              ];
              const [group] = Object.values(notification);

              return (
                <React.Fragment key={idx}>
                  <span className="text-black-50" style={{ fontSize: '14px' }}>
                    {notificationGroupMap[title]}
                  </span>

                  {group.map((row) => (
                    <div className="row" key={row.id}>
                      <div className="col-9">
                        <p>{row.displayName}</p>
                      </div>
                      <div className="col-3 row">
                        <div className="col-6 text-center">
                          <Switch
                            defaultChecked={row.checkedApp}
                            onChange={(e) => {
                              handleChange('app', row.name, e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-6 text-center ">
                          <Switch
                            defaultChecked={row.checkedEmail}
                            onChange={(e) => {
                              handleChange('email', row.name, e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default UserSettings;
