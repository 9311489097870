import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { Col, Container, Row } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../assets/cg-icon-globe.svg';
import { Stepper } from '../../components/stepper';
import { ValidateGameCodeAsync } from '../../services/games';

import { ProgressBar } from '@progress/kendo-react-progressbars';
import { GameDocumentContext } from '../../contexts/game-document';
import ImageLoad from '../../components/preload-assets/image';
import { Resource } from '../../types/game-document/entities/resource';
import VideoLoad from '../../components/preload-assets/video';
import lodash from 'lodash';
import { GameContext } from '../../contexts/game';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';

export const PreloadAssetPage = () => {
  const navigate = useNavigate();
  const [gameDocumentstate] = useContext(GameDocumentContext);
  const [gameState, setGameState] = useContext(GameContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const [assetImages, setAssetImages] = useState<Resource[]>([]);
  const [assetVideos, setAssetVideos] = useState<Resource[]>([]);
  const [assetImagePacks, setAssetImagePacks] = useState<Resource[]>([]);
  const [assetVideoPacks, setAssetVideoPacks] = useState<Resource[]>([]);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [assetNumber, setAssetNumber] = useState<number>(0);
  const resources = gameDocumentstate.gameDocument?.resources;
  const resourcesPacks = gameDocumentstate.gameDocument?.resourcePacks;

  // from resources
  const resourceImages = resources?.filter(
    (item) =>
      item.type === 'image' &&
      item.value !== '' &&
      item.value?.startsWith('https:')
  );
  const resourceVideos = resources?.filter(
    (item) =>
      item.type === 'video' &&
      item.value !== '' &&
      item.value?.startsWith('https:')
  );

  /**
   * @description handle next button after the preload assets is done
   * @returns 'Navigate to games/:gamecode
   */
  const handleNext = async () => {
    let gameCode = gameDocumentstate.gameCode;
    if (gameCode) {
      let codeIsValid = await ValidateGameCodeAsync(gameCode);
      if (codeIsValid) {
        navigate(`/games/${gameCode}`);
      }
    }
  };

  /**
   * @description get all images and videos and set into state for read on image/video components
   *
   */
  const resourceData = () => {
    // from resources packs
    resourcesPacks?.forEach((item) => {
      let resourceImagesPacks = item.resources?.filter(
        (item) =>
          item.type === 'image' &&
          item.value !== '' &&
          item.value?.startsWith('https:')
      );
      resourceImagesPacks = lodash.uniqBy(resourceImagesPacks, 'value');
      resourceImagesPacks && setAssetImagePacks(resourceImagesPacks);

      let resourceVideosPacks = item.resources?.filter(
        (item) =>
          item.type === 'video' &&
          item.value !== '' &&
          item.value?.startsWith('https:')
      );
      resourceVideosPacks = lodash.uniqBy(resourceVideosPacks, 'value');
      resourceVideosPacks && setAssetVideoPacks(resourceVideosPacks);
    });

    if (resourceImages && resourceImages.length > 0) {
      const newResourcesImages = lodash.uniqBy(resourceImages, 'value');
      setAssetImages(lodash.merge(newResourcesImages, assetImagePacks));
    }

    if (resourceVideos && resourceVideos.length > 0) {
      const newResourcesVideos = lodash.uniqBy(resourceVideos, 'value');
      setAssetVideos(lodash.merge(newResourcesVideos, assetVideoPacks));
    }
  };

  /**
   * @description when the assets is loaded, then counter the asset number state, for progress bar needs.
   */
  const onLoadedAssetData = () => {
    setAssetNumber((prev) => prev + 1);
  };
  const totalAssets = `${assetNumber}/${
    assetImages.length + assetVideos.length
  }`;

  useEffect(() => {
    if (assetImages && assetVideos) {
      const totalAssets = assetImages.length + assetVideos.length;
      setProgress((assetNumber / totalAssets) * 100);

      // set is done when assetNumber = totalAssets
      if (assetNumber > 0 && assetNumber === totalAssets) {
        setIsDone(true);

        //update Preload status and total assets.
        setGameState((prev) => ({
          ...prev,
          gameState: {
            ...prev.gameState!,
            isPreloadCompleted: true,
            totalAssets: totalAssets
          }
        }));
      }
    }
    //eslint-disable-next-line
  }, [assetNumber]);

  useEffect(() => {
    resourceData();
    //eslint-disable-next-line
  }, [gameDocumentstate]);

  return (
    <Container>
      <div className={'preload-assets'}>
        {assetImages &&
          assetImages.map((item, index) => (
            <ImageLoad
              assetUrl={item.value}
              index={index}
              key={index}
              onLoadedData={onLoadedAssetData}
            />
          ))}
        {assetVideos &&
          assetVideos.map((item, index) => (
            <VideoLoad
              assetUrl={item.value}
              index={index}
              key={index}
              onLoadedData={onLoadedAssetData}
            />
          ))}
      </div>
      <Row className={'align-items-center'}>
        <Col />
        <Col className={'text-center'}>
          <Logo style={{ width: 64 }} />
        </Col>
        <Col />
      </Row>
      <Row className={'gy-4 mt-4'}>
        <Col xs={12} className={'text-center'}>
          <h1>
            {generateTitleById(
              'f7c9251f-9bba-47c8-a554-7ddc95cd0dd4',
              gameDocumentstate,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Preload assets'}
          </h1>
        </Col>
        <Col xs={12}>
          <Stepper stepCount={4} currentStep={1} />
        </Col>
        <Col xs={12} className={'text-center'}>
          <strong>
            {generateTitleById(
              'd705595a-5864-4eb7-aa72-af7b2f2bb19e',
              gameDocumentstate,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'It will take a few seconds'}{' '}
          </strong>
        </Col>
        <Col xs={12}>
          <ProgressBar
            labelVisible={false}
            value={progress}
            progressStyle={{
              background: 'linear-gradient(127deg, #14bf8b 0%, #4698cb 100%)'
            }}
          />
          <p className={'text-center mb-0 fw-semibold'}>
            {(
              generateTitleById(
                '5d7d9dae-f5d4-45ba-993d-22fa61ca16e5',
                gameDocumentstate,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || '% Total assets'
            ).replace(
              '%',
              assetImages.length + assetVideos.length > 0 ? totalAssets : '%'
            )}
          </p>
        </Col>
        <Col xs={12}>
          <Button
            className={'k-button--gradient w-100'}
            themeColor={'primary'}
            onClick={handleNext}
            disabled={!isDone}>
            {generateTitleById(
              '079c039a-8207-4404-8eb6-558cf5d8477b',
              gameDocumentstate,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Next'}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
