import { isNetworkOnline } from '../indicator';
import {
  QueueCommandHandler,
  QueueCommandState
} from '../../contexts/queue-handler';
import {
  giveItem as teamGiveItem,
  giveScore as teamGiveScore,
  giveTitle as teamGiveTitle,
  navigateToWorldMap as teamNavigateToWorldMap,
  navigateToZone as teamNavigateToZone,
  showArea as teamShowArea,
  openTask as teamOpenTask,
  showTask as teamShowTask,
  pauseTimer as teamPauseTimer,
  startTimer as teamStartTimer,
  teamDistributeTaskAsync,
  randomTask
} from '../../services/teams';
import cloneDeep from 'lodash.clonedeep';

/**
 * Check if network is online, if not then save it to local storage
 * @param gameCode
 * @param command
 * @param parameters
 */
export const queueCommandHandler = (
  gameCode: string,
  command: string,
  parameters: any[]
) => {
  if (!isNetworkOnline()) {
    let queueCommandData: QueueCommandState =
      getQueueCommandLocalstorage(gameCode);

    let newQueueCommand = { ...queueCommandData };
    newQueueCommand.content.push({
      key: command,
      parameters: parameters
    });

    setQueueCommandLocalstorage(gameCode, newQueueCommand);
  }
};

/**
 * Get queue command local storage by game code
 * @param gameCode
 * @returns
 */
export const getQueueCommandLocalstorage = (
  gameCode: string
): QueueCommandState => {
  let queueCommand = localStorage.getItem(getQueueCommandKeyStorage(gameCode));
  if (!queueCommand) {
    queueCommand = JSON.stringify({ content: [] });
  }
  return JSON.parse(queueCommand);
};

/**
 * Get queue command local storage key
 * @param gameCode
 * @returns
 */
export const getQueueCommandKeyStorage = (gameCode: string): string => {
  return `${gameCode}-queue-command`;
};

/**
 * Insert data into queue command local storage
 * @param gameCode
 * @param state
 */
const setQueueCommandLocalstorage = (
  gameCode: string,
  state: QueueCommandState
) => {
  localStorage.setItem(
    getQueueCommandKeyStorage(gameCode),
    JSON.stringify(state)
  );
};

/**
 * Execute command on local storage to API
 * @param gameCode
 * @param state
 */
export const pushQueueCommandLocalstorage = (gameCode: string) => {
  let queueCommand: QueueCommandState = cloneDeep(
    getQueueCommandLocalstorage(gameCode)
  );

  if (queueCommand && queueCommand.content) {
    queueCommand.content.forEach(async (command, index) => {
      if (isNetworkOnline()) {
        try {
          await syncQueueCommandAsync(command);
        } catch (error) {
        } finally {
          let newCommands = { ...queueCommand };
          newCommands.content.splice(index, 1);
          localStorage.setItem(
            getQueueCommandKeyStorage(gameCode),
            JSON.stringify(newCommands)
          );
        }
      }
    });
  }
};

/**
 * Execute command in local storage
 * @param command
 */
const syncQueueCommandAsync = async (command: QueueCommandHandler) => {
  try {
    switch (command?.key) {
      case 'navigateToWorldMap':
        await teamNavigateToWorldMap(
          command!.parameters![0],
          command!.parameters![1]
        );
        break;
      case 'navigateToZone':
        await teamNavigateToZone(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2]
        );
        break;
      case 'openTask':
        await teamOpenTask(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2]
        );
        break;
      case 'showTask':
        await teamShowTask(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'hideTask':
        await teamShowTask(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'showArea':
        await teamShowArea(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'hideArea':
        await teamShowArea(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'startTimer':
        await teamStartTimer(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'stopTimer':
        await teamStartTimer(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'pauseTimer':
        await teamPauseTimer(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'resumeTimer':
        await teamPauseTimer(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'giveItem':
        await teamGiveItem(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3],
          command!.parameters![4]
        );
        break;
      case 'removeItem':
        await teamGiveItem(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3],
          command!.parameters![4]
        );
        break;
      case 'giveScore':
        await teamGiveScore(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'removeScore':
        await teamGiveScore(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'giveTitle':
        await teamGiveTitle(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'removeTitle':
        await teamGiveTitle(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2],
          command!.parameters![3]
        );
        break;
      case 'distributeTask':
        await teamDistributeTaskAsync(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2]
        );
        break;
      case 'randomTask':
        await randomTask(
          command!.parameters![0],
          command!.parameters![1],
          command!.parameters![2]
        );
        break;
      default:
        break;
    }
  } catch (error) {
    throw error;
  }
};
