export const formatTimer = (second: number): string => {
  let date = new Date(0);
  date.setSeconds(second);
  let timeString = date.toISOString().substring(11, 19);

  return timeString;
};

export const getHourMinuteFormat = (date: Date | null): string => {
  return `${date?.getHours().toString().padStart(2, '0')}:${date
    ?.getMinutes()
    .toString()
    .padStart(2, '0')}`;
};
