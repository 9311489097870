import merge from 'lodash.merge';
import { PlayerContextState } from '../../../contexts/player';
import { InventoryItem, PlayerStateFull } from '../../../types/state';

export const AddItemAsync = async (
  state: PlayerContextState,
  item: InventoryItem
) => {
  const { inventory = [] as InventoryItem[] } = state.playerState!;
  const itemIndex = inventory.findIndex((i) => i.id === item.id);
  if (itemIndex !== -1) {
    inventory[itemIndex].quantity += item.quantity;
  } else {
    inventory.push(item);
  }

  return MergeItem(state.playerState!, inventory);
};

export const AddItem = (state: PlayerContextState, item: InventoryItem) => {
  const { inventory = [] as InventoryItem[] } = state.playerState!;
  const itemIndex = inventory.findIndex((i) => i.id === item.id);
  if (itemIndex !== -1) {
    inventory[itemIndex].quantity += item.quantity;
  } else {
    inventory.push(item);
  }

  return MergeItem(state.playerState!, inventory);
};

export const RemoveItemAsync = async (
  state: PlayerContextState,
  item: InventoryItem
) => {
  const { inventory = [] as InventoryItem[] } = state.playerState!;
  const itemIndex = inventory.findIndex((i) => i.id === item.id);
  if (itemIndex !== -1) {
    inventory[itemIndex].quantity -= item.quantity;
    if (inventory[itemIndex].quantity === 0) {
      inventory.splice(itemIndex, 1);
    }
  }

  return MergeItem(state.playerState!, inventory);
};

export const RemoveItem = (state: PlayerContextState, item: InventoryItem) => {
  const { inventory = [] as InventoryItem[] } = state.playerState!;
  const itemIndex = inventory.findIndex((i) => i.id === item.id);
  if (itemIndex !== -1) {
    inventory[itemIndex].quantity -= item.quantity;
    if (inventory[itemIndex].quantity === 0) {
      inventory.splice(itemIndex, 1);
    }
  }

  return MergeItem(state.playerState!, inventory);
};

export const MergeItem = (player: PlayerStateFull, items: InventoryItem[]) => {
  return merge(player, { inventory: items });
};
