import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
  title: string;
  message: string;
  cancelText?: string;
  continueText?: string;
  icon?: string;
  widthDialog?: number;
  onCancelCallback?: () => void;
  onContinueCallback?: () => void;
};

export const ConfirmationDialog = ({
  title = 'Please confirm',
  message = 'Are you sure you want to continue?',
  cancelText = 'Cancel',
  continueText = 'Yes',
  icon,
  widthDialog = 400,
  onCancelCallback,
  onContinueCallback
}: Props) => {
  return (
    <Dialog title={title} onClose={onCancelCallback} width={widthDialog}>
      {icon && (
        <p className={'text-center'}>
          <span className={'material-symbols-outlined confirmation-icon '}>
            {icon}
          </span>
        </p>
      )}
      <p className={'m-4 text-center'}>{message}</p>
      <DialogActionsBar>
        {onCancelCallback && (
          <Button
            className={
              'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base w-50'
            }
            themeColor={'secondary'}
            onClick={onCancelCallback}>
            {cancelText}
          </Button>
        )}
        {onContinueCallback && (
          <Button
            className={
              'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
            }
            themeColor={'primary'}
            onClick={onContinueCallback}>
            {continueText}
          </Button>
        )}
      </DialogActionsBar>
    </Dialog>
  );
};
