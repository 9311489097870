import { userAgent } from './user-agent';

export const createCustomTaskPosition = ({
  id,
  name,
  iconUrl,
  isFacilitator
}: {
  id: string;
  name: string;
  iconUrl: string;
  isFacilitator: boolean;
}) => {
  const agent = userAgent();
  const taskContainer = document.createElement('div');
  taskContainer.classList.add('task-container', 'initial');
  !isFacilitator && taskContainer.classList.add('enable-click');
  taskContainer.id = `taskContainer-${id}`;

  const iconContainer = document.createElement('div');
  iconContainer.classList.add('icon-container');
  const iconCircle = document.createElement('div');
  iconCircle.classList.add('icon-circle');

  const icon = document.createElement('img');
  icon.id = `icon-${id}`;
  icon.src = iconUrl;
  icon.alt = 'icon';
  icon.classList.add('icon-img');
  iconCircle.append(icon);
  iconContainer.append(iconCircle);

  const detailTaskContainer = document.createElement('div');
  detailTaskContainer.classList.add('detail-task-container');
  detailTaskContainer.id = `detailTaskContainer-${id}`;

  const taskName = document.createElement('span');
  taskName.classList.add('fw-bold');
  taskName.id = `taskName-${id}`;
  taskName.innerHTML = name;

  const taskDistance = document.createElement('span');
  taskDistance.id = `taskDistance-${id}`;
  taskDistance.className = 'text-success d-none';
  taskDistance.innerHTML = 'Approx 0m';

  detailTaskContainer.append(taskName, taskDistance);

  detailTaskContainer.classList.add('hidden');
  if (agent !== 'mobile') {
    taskContainer.addEventListener('mouseenter', () => {
      if (!taskContainer.classList.contains('expanded')) {
        taskContainer.classList.add('expanded');
        taskContainer.classList.remove('initial');
        detailTaskContainer.classList.add('visible');
        detailTaskContainer.classList.remove('hidden');
      }
    });
    taskContainer.addEventListener('mouseleave', () => {
      if (taskContainer.classList.contains('expanded')) {
        taskContainer.classList.remove('expanded');
        taskContainer.classList.add('initial');
        detailTaskContainer.classList.remove('visible');
        detailTaskContainer.classList.add('hidden');
      }
    });
  }

  taskContainer.append(iconContainer, detailTaskContainer);
  return taskContainer;
};

export const createPlayerItem = ({
  id,
  avatarUrl,
  name
}: {
  id: string;
  avatarUrl: string;
  name: string;
}) => {
  const playerContainer = document.createElement('div');
  playerContainer.classList.add('player-container-item');
  playerContainer.id = `playerContainerItem-${id}`;

  const avatarContainer = document.createElement('div');
  avatarContainer.classList.add('avatar-container');
  const avatarCircle = document.createElement('div');
  avatarCircle.classList.add('avatar-circle');

  if (avatarUrl) {
    const avatar = document.createElement('img');
    avatar.id = `avatar-${id}`;
    avatar.src = avatarUrl;
    avatar.alt = 'avatar';
    avatar.classList.add('avatar-img');
    avatarCircle.append(avatar);
  }

  avatarContainer.append(avatarCircle);

  const profileContainer = document.createElement('div');
  profileContainer.classList.add('profile-container');

  const playerName = document.createElement('span');
  playerName.id = `playerName-${id}`;
  playerName.style.fontWeight = 'bold';
  playerName.innerHTML = name;

  const playerTeam = document.createElement('span');
  playerTeam.id = `playerTeam-${id}`;
  playerTeam.className = 'd-none';
  playerTeam.innerHTML = '';

  profileContainer.append(playerName, playerTeam);
  playerContainer.append(avatarContainer, profileContainer);

  return playerContainer;
};

export const createTaskPlayerContainer = (
  id: string,
  playerItems: HTMLDivElement[]
) => {
  const taskPlayerContainer = document.createElement('div');
  taskPlayerContainer.classList.add('task-player-container');
  taskPlayerContainer.id = `taskPlayerContainer-${id}`;

  const counter = document.createElement('span');
  const length = playerItems.length;
  counter.classList.add('cursor-pointer', 'w-100', 'text-center');
  counter.id = `taskPlayerCounter-${id}`;
  counter.innerHTML = `${length} ${length > 1 ? 'players' : 'player'}`;

  const playerList = document.createElement('div');
  playerList.classList.add(
    'd-none',
    'd-flex',
    'gap-1',
    'flex-column',
    'w-100',
    'h-100',
    'overflow-auto',
    'text-center'
  );
  playerList.id = `taskPlayerList-${id}`;

  const closeButton = document.createElement('div');
  closeButton.classList.add(
    'text-danger',
    'd-none',
    'd-flex',
    'w-100',
    'align-items-end',
    'justify-content-end',
    'cursor-pointer'
  );
  closeButton.innerHTML = 'Close';

  counter.addEventListener('click', () => {
    counter.classList.add('d-none');
    playerList.classList.remove('d-none');
    closeButton.classList.remove('d-none');
    taskPlayerContainer.classList.add('expanded');
  });

  closeButton.addEventListener('click', () => {
    counter.classList.remove('d-none');
    playerList.classList.add('d-none');
    closeButton.classList.add('d-none');
    taskPlayerContainer.classList.remove('expanded');
  });

  playerList.append(...playerItems);
  taskPlayerContainer.append(counter, playerList, closeButton);

  return taskPlayerContainer;
};
