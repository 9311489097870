import React, { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import * as serviceWorker from '../serviceWorker';
import { FeedbackDocumentState } from '../types/feedback-document';
import {
  getFeedbacksDocumentAsync,
  getFeedbacksGamesAsync
} from '../services/feedbacks';
import merge from 'lodash.merge';
import { useNavigate } from 'react-router-dom';
const FeedbackDocumentContext = React.createContext<
  [
    initialState: FeedbackDocumentState,
    setState: React.Dispatch<React.SetStateAction<FeedbackDocumentState>>
  ]
>([{ isLoaded: false, isDirty: false }, () => {}]);

interface FeedbackDocumentProviderProps {
  feedbackCode: string;
  children: React.ReactNode;
}

const FeedbackDocumentProvider = ({
  feedbackCode,
  ...props
}: FeedbackDocumentProviderProps) => {
  const navigate = useNavigate();
  const [state, setState] = useLocalStorage<FeedbackDocumentState>(
    `${feedbackCode}-feedback`,
    {
      feedbackCode: feedbackCode,
      isLoaded: false,
      isDirty: false,
      eventGames: [],
      selectedLanguageId: ''
    }
  );

  const loadServiceWorker = async () => {
    await new Promise((r) => {
      serviceWorker.register({ onSuccess: (e) => console.log(e) });
    });
  };

  const loadData = async () => {
    const feedbackResponse = await getFeedbacksDocumentAsync(
      state.feedbackCode!
    ).then((response) => response);

    const feedbackGamesResponse = await getFeedbacksGamesAsync(
      state.feedbackCode!
    ).then((response) => response);

    setState(() =>
      merge({
        ...state,
        isLoaded: true,
        feedbackDocument: feedbackResponse,
        eventGames: feedbackGamesResponse
      })
    );
  };

  useEffect(() => {
    loadServiceWorker();
    if (!state.isLoaded) {
      navigate(`/feedbacks/${feedbackCode}`);
      loadData();
    }
  }, []);

  return (
    <FeedbackDocumentContext.Provider value={[state, setState]}>
      {props.children}
    </FeedbackDocumentContext.Provider>
  );
};

export { FeedbackDocumentContext, FeedbackDocumentProvider };
