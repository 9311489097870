import React from 'react';
import { Button } from 'react-bootstrap';
import { useInternationalization } from '@progress/kendo-react-intl';

import { GetMySupportTickets } from '../../services/support-ticket';
import { SupportTicketResponse } from '../../types/responses/support-ticket';

interface UserSupportProps {
  setSelectedMenuItemName: React.Dispatch<React.SetStateAction<string>>;
}

const UserSupport: React.FC<UserSupportProps> = ({
  setSelectedMenuItemName
}) => {
  const intl = useInternationalization();
  const [isLoading, setIsloading] = React.useState<boolean>(false);
  const [supportTickets, setSupportTickets] = React.useState<
    SupportTicketResponse[]
  >([]);

  const fetchMySupportTickets = React.useCallback(async () => {
    try {
      setIsloading(true);
      const resp = await GetMySupportTickets();
      setSupportTickets(resp.data);
    } catch (error) {
      console.log(error, '>>>errors');
    } finally {
      setIsloading(false);
    }
  }, [setIsloading]);

  React.useEffect(() => {
    fetchMySupportTickets();
  }, []);

  return (
    <div className={'w-100 h-100 d-flex flex-column flex-grow-1'}>
      <div className={'d-flex flex-grow-1 pt-2 flex-column gap-2'}>
        <div className={'border-2 border-bottom'}>
          <div className={'text-success d-flex align-items-center pb-2 gap-2'}>
            <span className={'material-symbols-outlined'}>support</span>
            <h2 className={'m-0 fw-bold'}>Support</h2>
          </div>
        </div>

        <div className={'mt-3 pb-3 border-2 border-bottom'}>
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <Button
                variant="success"
                className="text-white w-100"
                onClick={() => setSelectedMenuItemName('SupportTicket')}>
                Open Support Ticket
              </Button>
            </div>
            <div className="col-6 d-flex flex-column justify-content-center">
              <h3 className={'fw-bold align-items-center'}>Contact support</h3>
              <span
                className={
                  'text-success text-decoration-underline cursor-pointer'
                }>
                support@catalyst.games
              </span>
            </div>
          </div>
        </div>

        <div className="my-4 px-2 d-flex flex-grow-1 flex-column gap-3">
          {isLoading ? (
            <div className="d-flex flex-grow-1 h-100 justify-content-center align-items-center">
              Loading ...
            </div>
          ) : (
            supportTickets.map((ticket, idx) => (
              <div key={idx} className="p-3 row border bg-light">
                <div
                  className="col-2 text-black-50"
                  style={{
                    fontSize: '14px'
                  }}>
                  {intl.formatDate(new Date(ticket.createdAt), 'HH:mm MM/y')}
                </div>
                <div className="col-9 d-flex align-items-center pl-0">
                  {ticket.title}
                </div>
                <div className="col-1 d-flex align-items-center justify-content-end text-success">
                  {ticket.status}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSupport;
