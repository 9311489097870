import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { AuthRequired } from '../../components/authentication';
import { GameStateProvider } from '../../contexts/game';
import { GameDocumentProvider } from '../../contexts/game-document';

/**
 * The layout for private pages.
 * @returns
 */
export const LeaderboardLayout = () => {
  const { gameCode } = useParams();
  return (
    <AuthRequired>
      <GameDocumentProvider gameCode={gameCode!}>
        <GameStateProvider gameCode={gameCode!}>
          <div className={'app-main'}>
            <Outlet />
          </div>
        </GameStateProvider>
      </GameDocumentProvider>
    </AuthRequired>
  );
};

export default LeaderboardLayout;
