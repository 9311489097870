import React from 'react';

interface TitleProps {
  title: string;
  imageUrl: string;
}

export default function FeedbackTitle({
  imageUrl = '',
  title = ''
}: TitleProps) {
  return (
    <div className={`feedback-header-pill`}>
      <div className={'feedback-header-pill__progress'}>
        <img
          className={'feedback-header-pill__image'}
          src={!imageUrl ? '/cg-icon-globe.svg' : imageUrl}
          alt={title}
        />
      </div>
      <div
        className={'d-flex flex-column justify-content-center overflow-hidden'}>
        <div className={'feedback-header-pill__title'}>{title}</div>
      </div>
    </div>
  );
}
