import React from 'react';
interface VideoLoadProps {
  assetUrl?: string;
  index?: number;
  onLoadedData?: () => void;
}

const VideoLoad = (props: VideoLoadProps) => {
  const { assetUrl, index, onLoadedData } = props;

  return (
    <video
      controls
      preload={'auto'}
      width={0}
      height={0}
      onCanPlayThrough={onLoadedData}
      key={index}>
      <source src={assetUrl} type={'video/mp4'} />
    </video>
  );
};

export default VideoLoad;
