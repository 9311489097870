export const loadInputFileToBlobString = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target?.result as string);
    };
    reader.readAsDataURL(file);
  });
};

export const loadImageToBlobUrl = (source: File | Blob): string =>
  URL.createObjectURL(source);

export const base64toBlob = (
  base64Data: string,
  contentType: string = 'image/jpeg',
  sliceSize: number = 512
): Blob => {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const dataURItoBlob = (imageDataUrl: string): Blob => {
  // convert base64 to raw binary data held in a string
  let byteString = atob(imageDataUrl.split(',')[1]);

  // separate out the mime component
  let mimeString = imageDataUrl.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  let ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  let blob = new Blob([ab], { type: mimeString });
  // set the blob name below if needed
  // blob.name = 'filename.png'
  return blob;
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  let b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

/**
 * Retrieve max size files based on its source
 * @param imageSource - Source file to get its related max size file
 * @returns
 */
export const GetMaxSizeBySource = (
  imageSource: string | undefined
): number | undefined => {
  switch (imageSource) {
    case '-':
      return 512000; // 500 KB
    default:
      return undefined;
  }
};

/**
 * Retrieve files extension(s) based on its source
 * @param imageSource - Source file to get its related file extension(s)
 * @returns
 */
export const GetFileExtensionsBySource = (
  imageSource: string | undefined
): string[] | undefined => {
  switch (imageSource) {
    case 'Assets - Task Content - Video Answer Type':
      return ['mp4', 'mov', 'wmv', 'avi', 'mkv', 'flv', 'avchd', 'swf', 'webm'];
    case 'Assets - Task Content - Image Answer Type':
      return ['png', 'jpg', 'jpeg', 'gif', 'heic'];
    default:
      return undefined;
  }
};

/**
 * Retrieve string conversion from byte formatting
 * @param bytes - Byte number
 * @param decimals - Decimal number
 * @returns
 */
export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
