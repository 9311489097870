import { Col, ColProps } from 'react-bootstrap';
import { Loader } from '@progress/kendo-react-indicators';
import React from 'react';

export interface ColLoaderProps extends ColProps {}

export const ColLoader = (props: ColLoaderProps) => {
  return (
    <Col
      xs={12}
      {...props}
      className={`col-loader ${props.className ?? ''}`.trim()}>
      <Loader size={'large'} />
    </Col>
  );
};

export default ColLoader;
