import { GetAllAsync } from './api';
import { CountryResponse } from '../types/responses/country-response';
import { CountryPhonecodeResponse } from '../types/responses/country-phonecode-response';

const CountryEndpoint = `countries`;
const LanguageEndpoint = `countries/languages`;
const CountryPhonecodeEndpoint = `countries/phonecodes`;
/**
 * Gets ALL countries.
 * @returns all countries objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetCountriesAsync = async () =>
  GetAllAsync<CountryResponse>(CountryEndpoint, true, true);

/**
 * Gets ALL languages.
 * @returns all languages objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetLanguagesAsync = async () =>
  GetAllAsync<CountryResponse>(LanguageEndpoint, true, true);

/**
 * Gets ALL country phonecodes.
 * @returns all country phonecodes objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetCountryPhonecodeAsync = async () =>
  GetAllAsync<CountryPhonecodeResponse>(CountryPhonecodeEndpoint, true, true);
