import { PlayerState } from '../../types/state';
import { GameDocument } from '../../types/game-document';
import { GetPreGameSetting } from '../game-document/settings';

/**
 * Set visibility of roles
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowRoles = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const GetPreGameRoles = GetPreGameSetting(gameDocument);
    return GetPreGameRoles.showPreGameRoles;
  }
  return true;
};
