import React, { useContext } from 'react';
import { GameDocumentContext } from '../../contexts/game-document';
import { ManualScoring } from '../../types/manual-scoring';
import { GetTaskById } from '../../utils/game-document/assets';

interface ListProps {
  listData?: ManualScoring[];
  selectedId: number;
  onClick?: (item: ManualScoring) => void;
}

export const FacilitatorScoringList = ({
  listData,
  selectedId,
  onClick = () => {}
}: ListProps) => {
  const [gameDocumentState] = useContext(GameDocumentContext);

  const dateFormat = React.useCallback((dateUTC: string) => {
    const date = new Date(dateUTC);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${formattedMonth}/${formattedDay}`;
  }, []);

  return (
    <div className="list-group w-100 ">
      {listData?.map((item) => (
        <div
          className={'cursor-pointer'}
          onClick={() => onClick(item)}
          style={{
            backgroundColor: item.id === selectedId ? '#f8fcfe' : 'white'
          }}>
          <div
            className={'row m-0'}
            style={{
              padding: '15px 20px'
            }}>
            <div className={'col-8 p-0'}>
              <div className={'d-flex flex-column'}>
                <small className={'text-dark'}>{item?.playerName}</small>
                <small className={'text-dark fw-bold'}>
                  {GetTaskById(gameDocumentState?.gameDocument, item?.taskId!)
                    ?.name ?? ''}
                </small>
              </div>
            </div>

            <div className={'col-4 p-0'}>
              <div
                className={
                  'd-flex w-100 h-100 align-items-center justify-content-end text-end text-secondary'
                }>
                <small>{dateFormat(item.SubmittedDateUtc as string)}</small>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
