import { useEffect, useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { HtmlEditor } from '../../components/editor/html-editor';
import { EditorChangeEvent } from '@progress/kendo-react-editor';
import { getTeamList, sendTeamsContent } from '../../services/teams';
import { GameContext } from '../../contexts/game';
import { TeamResponse } from '../../types/responses/team-response';
import { sendGlobalContent } from '../../services/games';

export const PushContentPage = () => {
  const [game] = useContext(GameContext);
  const [contentValue, setContentValue] = useState<string>('');
  const [teams, setTeams] = useState<TeamResponse[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<TeamResponse>();

  const handleChange = (e: DropDownListChangeEvent) => {
    setSelectedTeam(e.target.value);
  };

  const getAllTeams = async () => {
    let teamsResult = (await getTeamList(game?.gameCode!)) as TeamResponse[];
    teamsResult.unshift({ id: 0, name: 'Global (All Player)' });
    setTeams(teamsResult);
    if (teamsResult.length > 0) {
      setSelectedTeam(teamsResult[0]);
    }
  };

  const onHtmlChangeHandler = (event: EditorChangeEvent) => {
    setContentValue(event.html!);
  };

  const handleSubmit = async () => {
    if (game.gameCode && selectedTeam) {
      if (selectedTeam.id === 0) {
        await sendGlobalContent(game.gameCode, contentValue);
      } else {
        if (selectedTeam.teamCode)
          await sendTeamsContent(
            game.gameCode,
            selectedTeam.teamCode,
            contentValue
          );
      }
    }
  };

  useEffect(() => {
    getAllTeams();
  }, []);

  return (
    <Row>
      <Col md={12} className={'w-100 d-flex flex-wrap'}>
        <h1 className={'fw-bold text-dark mr-8'}>Recipient</h1>
        <DropDownList
          id={'select-team-content'}
          className={'w-20'}
          data={teams}
          textField={'name'}
          dataItemKey={'id'}
          value={
            selectedTeam
              ? teams.find((item) => item.id === selectedTeam.id)
              : null
          }
          onChange={handleChange}
        />
      </Col>
      <Col md={12} className={'my-4'}>
        <HtmlEditor
          value={contentValue}
          handleHtmlChange={onHtmlChangeHandler}
        />
      </Col>
      <Col className={'d-flex justify-content-end'}>
        <Button
          id={'submit-push-content'}
          themeColor={'success'}
          className={'w-10 h-3'}
          onClick={handleSubmit}>
          Submit
        </Button>
      </Col>
    </Row>
  );
};
