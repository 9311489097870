import { useContext, useMemo } from 'react';
import { GameDocumentContext } from '../../contexts/game-document';
import { PlayerContext } from '../../contexts/player';
import { GetResourceValue } from '../../utils/game-document/resources';

interface mediaItem {
  type: string;
  value: string;
}

interface GameMediaProps {
  className?: string;
}

/**
 * Renders the game name.
 * @remarks Relies on game document context.
 */
export const GameMedia = (props: GameMediaProps) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [player] = useContext(PlayerContext);
  const mediaResource = useMemo(() => {
    let medias = gameDocument.gameDocument!.overview?.medias!;
    if (medias.length !== undefined && medias.length > 0) {
      let mediaResources = medias.map((m: mediaItem, index) =>
        RenderGameMedia(
          props,
          {
            value:
              GetResourceValue(
                gameDocument.gameDocument!,
                m.value,
                player?.playerState?.language?.name! ?? ''
              ) ?? '',
            type: m.type
          },
          index
        )
      );
      return mediaResources;
    }
  }, [gameDocument.gameCode]);
  return <>{mediaResource}</>;
};

const RenderGameMedia = (
  props: GameMediaProps,
  item: mediaItem,
  index: number
) => {
  switch (item.type) {
    case 'video':
      return RenderGameMediaVideo(props, item, index);
    default:
      return RenderGameMediaImage(props, item, index);
  }
};

const RenderGameMediaImage = (
  props: GameMediaProps,
  item: mediaItem,
  index: number
) => (
  <div className={'col-12'} key={index}>
    <div key={index} className={`w-100 h-100 ${props.className}`}>
      <img key={index} src={item.value} className={'img-fluid img-thumbnail'} />
    </div>
  </div>
);

const RenderGameMediaVideo = (
  props: GameMediaProps,
  item: mediaItem,
  index: number
) => (
  <div className={'col-12'} key={index}>
    <div className={'media__main'} key={index}>
      <video
        key={index}
        controls
        {...props}
        className={'img-fluid img-thumbnail '}>
        <source src={item.value} type={'video/mp4'} />
      </video>
    </div>
  </div>
);
