import { GameDocument } from '../../types/game-document';

/**
 * Get pre-game settings from game document local storage.
 * @param gameDocument - The Game Document as datasource
 */
export const GetPreGameSetting = (gameDocument: GameDocument) => {
  return gameDocument?.settings?.preGame;
};

/**
 * Get in-game settings from game document local storage.
 * @param gameDocument - The Game Document as datasource
 */
export const GetInGameSetting = (gameDocument: GameDocument) => {
  return gameDocument?.settings?.inGame;
};

/**
 * Get pre-game settings from game document local storage for show or hide lobby tabs (team,inventory,roles,chat).
 * @param gameDocument - The Game Document as datasource
 */
export const GetPreGameLobby = (gameDocument: GameDocument) => {
  return gameDocument?.settings?.preGame.showPreGameLobby;
};

/**
 * Get pre-game settings from game document local storage for show or hide roles tab.
 * @param gameDocument - The Game Document as datasource
 */
export const GetPreGameRoles = (gameDocument: GameDocument) => {
  return gameDocument?.settings?.preGame.showPreGameRoles;
};

/**
 * Get pre-game settings from game document local storage to show or hide Game Teams.
 * @param gameDocument - The Game Document as datasource
 */
export const GetPreGameTeams = (gameDocument: GameDocument) => {
  return gameDocument?.settings?.preGame?.showPreGameTeams;
};

/**
 * Get pre-game settings from game document local storage for show or hide chat tab.
 * @param gameDocument - The Game Document as datasource
 */
export const GetPreGameChat = (gameDocument: GameDocument) => {
  return gameDocument?.settings?.preGame.showPreGameChat;
};

/**
 * Get pre-game settings from game document local storage for show or hide join leave team.
 * @param gameDocument - The Game Document as datasource
 */
export const GetPreGameJoinLeaveTeam = (gameDocument: GameDocument) => {
  return gameDocument?.settings?.preGame.showPreGameJoinLeaveTeams;
};

/**
 * Get pre-game settings from game document local storage to show or hide Game Inventory.
 * @param gameDocument - The Game Document as datasource
 */
export const GetPreGameInventory = (gameDocument: GameDocument) => {
  return gameDocument.settings.preGame.showPreGameInventory;
};
