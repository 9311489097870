import { GameState } from '../types/state/game/game-state';
import { PlayerState } from '../types/state/player/player-state';
import { API_URL } from '../constants';
import { getUrl } from './http-client';
import redaxios from 'redaxios';

export const gameState = (gameCode: string) => {
  const item = window.localStorage.getItem(`game-${gameCode}`);
  const gameState = parseJSON(item) as GameState;
  return gameState;
};

export const playerState = (gameCode: string) => {
  try {
    const item = window.localStorage.getItem(`player-${gameCode}`);
    const playerState = parseJSON(item) as PlayerState;
    return playerState;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

export const teamState = (gameCode: string) => {
  const item = window.localStorage.getItem(`player-${gameCode}`);
  const playerState = parseJSON(item) as PlayerState;
  return playerState;
};

/**
 *
 * @param gameCode
 * @returns endpointUrl
 * @returns accessToken
 */
export const stateHub = async (gameCode: string) => {
  try {
    const url = getUrl(`${API_URL.hubs} / ${gameCode}`);
    const response = await redaxios.post(url);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 *
 * @param gameCode
 * @returns endpointUrl
 * @returns accessToken
 * @returns method
 */
export const gameHub = async (gameCode: string) => {
  try {
    const url = getUrl(`${API_URL.hubs}/gamestate/${gameCode}`);
    const response = await redaxios.post(url);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 *
 * @param teamCode
 * @returns endpointUrl
 * @returns accessToken
 * @returns method
 */
export const teamHub = async (teamCode: string) => {
  try {
    const url = getUrl(`${API_URL.hubsV2}/teamstate/${teamCode}`);
    const response = await redaxios.post(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 *
 * @param hubName
 * @param methodHub
 * @param message
 * @returns endpointUrl
 * @returns accessToken
 * @returns method
 */
export const hubSendMessage = async (
  hubName: string,
  methodHub: string,
  message: string
) => {
  try {
    const url = getUrl(
      `${API_URL.hubs}/${hubName}/method/${methodHub}/send/${message}`
    );
    const response = await redaxios.post(url);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 *
 * @param playerId
 * @returns endpointUrl
 * @returns accessToken
 * @returns method
 */
export const playerHub = async (playerCode: string) => {
  try {
    const url = getUrl(`${API_URL.hubs}/playerstate/${playerCode}`);
    const response = await redaxios.post(url);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Publish Player State into BE
 * @param player code // Player Code
 */
export const postPlayerState = async (
  gameCode: string,
  code: string,
  playerState: string
) => {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${gameCode}/players/${code}/states`);
    const body = playerState;
    const headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const response = await redaxios.post(url, body, headers);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Publish Game State into BE
 * @param gameCode code // Game code
 */
export const postGameState = async (gameCode: string, gameState: string) => {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${gameCode}/states`);
    const body = JSON.stringify(gameState);
    const headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const response = await redaxios.post(url, body, headers);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

// TODO make this function into exportable one
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    console.log('parsing error on', { value });
    return undefined;
  }
}
