import { createContext, PropsWithChildren, useMemo } from 'react';
import { useToggle } from 'usehooks-ts';

export interface LayoutProviderState {
  navIsExpanded: boolean;
  toggleNavIsExpanded: () => void;
}

const LayoutContext = createContext<LayoutProviderState>({
  navIsExpanded: true,
  toggleNavIsExpanded: () => {}
});

/**
 * Creates a layout provider component that manages the state of the layout.
 * @param {PropsWithChildren} props - The properties passed to the component.
 * @returns {ReactElement} The rendered layout provider component.
 */
const LayoutProvider = (props: PropsWithChildren) => {
  const [navIsExpanded, toggleNavIsExpanded] = useToggle(true);
  const value = useMemo(
    () => ({
      navIsExpanded,
      toggleNavIsExpanded
    }),
    [navIsExpanded]
  );
  return (
    <LayoutContext.Provider value={value}>
      {props.children}
    </LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };
