import React, { useContext } from 'react';
import ProgressPill from '../components/progress-pill';
import { OverlayContext } from '../contexts/overlay';
import { formatTimer } from '../utils/timer';

export default function ProgressTimer() {
  const [overlay] = useContext(OverlayContext);

  return (
    <ProgressPill
      imageUrl={overlay.gameLogo}
      title={overlay.gameName}
      progress={overlay.progress}
      className={
        overlay.timerAssId !== undefined && overlay.timerAssId !== ''
          ? ''
          : 'progress-pil__game-title'
      }
      toggleTimerVisibility={
        overlay.timerAssId !== undefined && overlay.timerAssId !== ''
      }>
      {formatTimer(overlay.interval)}
    </ProgressPill>
  );
}
