import { useContext, useEffect, useState } from 'react';
import { QRCode } from '@progress/kendo-react-barcodes';
import PageToolbar from '../../components/toolbar/page-toolbar';
import { GameFeedback, getGameFeedback } from '../../services/games';
import { GameDocumentContext } from '../../contexts/game-document';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';

export const Feedback = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [feedbacks, setFeedbacks] = useState<GameFeedback[]>([]);
  const [, setNotification] = useContext(NotificationContext);

  const getFeedbackList = async () => {
    try {
      const result = await getGameFeedback(gameDocument?.gameCode || '');
      setFeedbacks(result || []); // Update feedbacks state with fetched data
    } catch (error) {
      console.error('Error fetching feedbacks:', error);
    }
  };

  const getFeedbackURL = (code: string) => {
    const baseUrl = `${window.location.origin}`;
    return `${baseUrl}/feedbacks/${code}`;
  };

  const notificationHandler = (message: string) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    setNotification((prevNotification) => ({
      ...prevNotification,
      content: [...prevNotification.content, responseTeamNotification]
    }));
  };

  useEffect(() => {
    getFeedbackList();
  }, [gameDocument]); // Fetch feedbacks whenever gameDocument changes

  return (
    <div>
      <PageToolbar title={''} />
      <div
        className={
          'd-flex flex-column justify-items-center align-items-center pr-0'
        }>
        {feedbacks.map((item, index) => (
          <div key={index} className={'mt-4'}>
            <QRCode
              size={'350'}
              value={getFeedbackURL(item?.code || '')}
              errorCorrection="M"
            />
            <div className={'input-group mb-3 mt-3'}>
              <input
                type={'text'}
                className={'form-control text-dark fw-bold'}
                disabled={true}
                value={getFeedbackURL(item?.code || '')}
              />
              <div className={'input-group-append'}>
                <button
                  className={'btn btn-success text-light'}
                  type={'button'}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      getFeedbackURL(item?.code || '')
                    );
                    notificationHandler('URL copied!');
                  }}>
                  <span className={'material-symbols-outlined'}>
                    content_copy
                  </span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
