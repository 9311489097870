import React, { PropsWithChildren, useState } from 'react';
import DrawerMobileContainer from '../features/in-game/overlay/drawer/mobile/container';
import { useMediaQuery } from 'usehooks-ts';
import { PopupProfile } from '../components/tabs';

type OverlayTimerStatus = 'start' | 'stop';

export interface OverlayState {
  drawerMobileIsOpen: boolean;
  drawerIsOpen: boolean;
  activeTab: string;
  progress: number;
  progressBar: number;
  interval: number;
  progressIsOpen: boolean;
  timerAssId?: string;
  gameName: string;
  gameLogo: string;
  progressChild: string;
  timerStatus: OverlayTimerStatus;
  profileDialogIsOpen: boolean;
}

const DefaultOverlayState: OverlayState = {
  drawerMobileIsOpen: false,
  drawerIsOpen: false,
  activeTab: 'info',
  progress: 0,
  progressBar: 0,
  interval: 0,
  progressIsOpen: false,
  timerAssId: '',
  gameName: '',
  gameLogo: '',
  progressChild: '',
  timerStatus: 'stop',
  profileDialogIsOpen: false
};

const OverlayContext = React.createContext<
  [
    initialState: OverlayState,
    setState: React.Dispatch<React.SetStateAction<OverlayState>>
  ]
>([DefaultOverlayState, () => {}]);

const OverlayProvider = (props: PropsWithChildren) => {
  const [state, setState] = useState<OverlayState>(DefaultOverlayState);
  const isSmallDevice = useMediaQuery('(max-width:767px)');

  const toggleProfileDialog = () =>
    setState((prev) => ({
      ...prev,
      profileDialogIsOpen: !prev.profileDialogIsOpen
    }));

  return (
    <OverlayContext.Provider value={[state, setState]}>
      {isSmallDevice ? (
        <DrawerMobileContainer>{props.children}</DrawerMobileContainer>
      ) : (
        props.children
      )}

      {state.profileDialogIsOpen && (
        <PopupProfile onToggleProfileWidget={toggleProfileDialog} />
      )}
    </OverlayContext.Provider>
  );
};

export { OverlayContext, OverlayProvider };
