import React from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';
import { Badge } from '@progress/kendo-react-indicators';

import { ChatRoomPlayer } from './chat-room';
import { TeamContext } from '../../contexts/team';
import { PlayerContext } from '../../contexts/player';
import { isExistUnreadChat } from '../../utils/game-engine/chat';
import { GameDocumentContext } from '../../contexts/game-document';
import { PlayerAvatar } from '../../features/in-game/player-avatar';
import { Avatar } from '@progress/kendo-react-layout';

interface MobileTabProps {
  activeTab: string;
  selectedPlayer: string;
  totalUnreadDirectChat: number;
  otherPlayers: ChatRoomPlayer[];

  handleSwitch: (
    chatType: string,
    unreadCode: string,
    withPlayerCode?: string
  ) => void;
  handleSwitchToDirectChat: () => void;
  chatTypeText: (type: string) => string;
  onChangePlayer: (code: string) => Promise<void>;
}

const MobileTab: React.FC<MobileTabProps> = ({
  activeTab,
  otherPlayers,
  selectedPlayer,
  totalUnreadDirectChat,
  chatTypeText,
  handleSwitch,
  onChangePlayer,
  handleSwitchToDirectChat
}) => {
  const [gameDocumentstate] = React.useContext(GameDocumentContext);
  const [playerState] = React.useContext(PlayerContext);
  const [teamState] = React.useContext(TeamContext);

  const isInLobby = playerState.playerState?.status === 'waiting';
  const preGameSetting = gameDocumentstate.gameDocument?.settings.preGame;
  const inGameSetting = gameDocumentstate.gameDocument?.settings.inGame;

  const showAllPlayerChat = isInLobby
    ? preGameSetting?.showPreGameAllPlayerChat
    : inGameSetting?.showInGameAllPlayerChat;
  const showFacilitatorChat = isInLobby
    ? preGameSetting?.showPreGameFacilitatorChat
    : inGameSetting?.showInGameFacilitatorChat;
  const showTeamChat = isInLobby
    ? preGameSetting?.showPreGameTeamChat
    : inGameSetting?.showInGameTeamChat;
  const showGroupChat = isInLobby
    ? preGameSetting?.showPreGameGroupChat
    : inGameSetting?.showInGameGroupChat;
  const showDirectChat = isInLobby
    ? preGameSetting?.showPreGameDirectChat
    : inGameSetting?.showInGameDirectChat;

  const chatTypeRef = React.useRef<any>();
  const [showChatType, setShowChatType] = React.useState(false);
  const playerDirectRef = React.useRef<any>();
  const [showPlayerDirect, setShowPlayerDirect] = React.useState(false);

  const toggleChatType = () => setShowChatType((prev) => !prev);
  const togglePlayerDirect = () => setShowPlayerDirect((prev) => !prev);

  return (
    <div
      className={`d-flex d-md-none align-items-center justify-content-${activeTab === 'Direct' ? 'between' : 'end'} my-3`}>
      {activeTab === 'Direct' && (
        <div ref={playerDirectRef}>
          <div
            className="d-flex align-items-center gap-1 cursor-pointer"
            onClick={togglePlayerDirect}>
            {otherPlayers
              .filter((player) => player.code === selectedPlayer)
              .map((player) => (
                <div
                  key={player.code}
                  className={'d-flex gap-2 align-items-center'}
                  style={{
                    wordBreak: 'break-word'
                  }}>
                  <Avatar type={'image'} themeColor={'light'}>
                    <img
                      src={player.avatarImage}
                      className={`h-3 w-3 rounded-circle`}
                      alt={'Player Avatar'}
                    />
                  </Avatar>
                  {player.name}
                </div>
              ))}
            <span className={'material-symbols-outlined'}>
              {showPlayerDirect ? 'expand_less' : 'expand_more'}
            </span>
          </div>

          <Popup
            anchor={playerDirectRef.current}
            show={showPlayerDirect}
            className={'mt-2'}
            anchorAlign={{
              horizontal: 'left',
              vertical: 'bottom'
            }}
            popupAlign={{
              horizontal: 'left',
              vertical: 'top'
            }}>
            <div
              className={
                'd-flex flex-column gap-1 p-2 direct-player-container'
              }>
              {otherPlayers.map((player) => (
                <div
                  key={player.code}
                  className={'d-flex gap-2 align-items-center cursor-pointer'}
                  style={{
                    wordBreak: 'break-word',
                    backgroundColor:
                      selectedPlayer === player.code ? '#003a64' : '',
                    color: selectedPlayer === player.code ? 'white' : 'black',
                    borderRadius: '0.25rem',
                    padding: '6px 12px'
                  }}
                  onClick={() => {
                    onChangePlayer(player.code!);
                    togglePlayerDirect();
                  }}>
                  <Avatar type={'image'} themeColor={'light'}>
                    <img
                      src={player.avatarImage}
                      className={` rounded-circle`}
                      alt={'Player Avatar'}
                    />
                  </Avatar>
                  {player.name}
                </div>
              ))}
            </div>
          </Popup>
        </div>
      )}

      <div ref={chatTypeRef}>
        <div
          className="d-flex align-items-center gap-1 cursor-pointer"
          onClick={toggleChatType}>
          <h3 className="m-0 fw-bold">{chatTypeText(activeTab)}</h3>
          <span className={'material-symbols-outlined'}>
            {showChatType ? 'expand_less' : 'expand_more'}
          </span>
        </div>

        <Popup
          anchor={chatTypeRef.current}
          show={showChatType}
          className={'mt-2'}
          anchorAlign={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
          popupAlign={{
            horizontal: 'right',
            vertical: 'top'
          }}>
          <div className={'d-flex flex-column gap-1 p-2'}>
            {showAllPlayerChat && (
              <Button
                className={'d-flex justify-content-start'}
                onClick={() => {
                  handleSwitch(
                    'All Player',
                    `GlobalChat_${gameDocumentstate?.gameCode}`
                  );
                  toggleChatType();
                }}
                themeColor={'dark'}
                fillMode={activeTab === 'All Player' ? 'solid' : 'flat'}>
                {chatTypeText('All Player')}
                {isExistUnreadChat(
                  gameDocumentstate?.gameCode!,
                  `GlobalChat_${gameDocumentstate?.gameCode}`
                ) && <span className={'k-icon k-i-circle text-danger'} />}
              </Button>
            )}

            {showTeamChat && (
              <Button
                className={'d-flex justify-content-start'}
                onClick={() => {
                  handleSwitch('Team', `TeamChat_${teamState.teamState?.code}`);
                  toggleChatType();
                }}
                themeColor={'dark'}
                fillMode={activeTab === 'Team' ? 'solid' : 'flat'}>
                {chatTypeText('Team')}
                {isExistUnreadChat(
                  gameDocumentstate?.gameCode!,
                  `TeamChat_${teamState.teamState?.code}`
                ) && <span className={'k-icon k-i-circle text-danger'} />}
              </Button>
            )}

            {showDirectChat && otherPlayers.length > 0 && (
              <Button
                className={'d-flex justify-content-start'}
                onClick={() => {
                  handleSwitchToDirectChat();
                  toggleChatType();
                }}
                themeColor={'dark'}
                fillMode={activeTab === 'Direct' ? 'solid' : 'flat'}>
                {chatTypeText('Direct')}
                {totalUnreadDirectChat > 0 && (
                  <Badge themeColor="info">{totalUnreadDirectChat}</Badge>
                )}
              </Button>
            )}

            {showGroupChat && (
              <Button
                className={'d-flex justify-content-start'}
                onClick={() => {
                  handleSwitch(
                    'Group',
                    `GroupChat_${teamState.teamState?.groupCode}`
                  );
                  toggleChatType();
                }}
                themeColor={'dark'}
                fillMode={activeTab === 'Group' ? 'solid' : 'flat'}>
                {chatTypeText('Group')}
                {isExistUnreadChat(
                  gameDocumentstate?.gameCode!,
                  `GroupChat_${teamState.teamState?.groupCode}`
                ) && <span className={'k-icon k-i-circle text-danger'} />}
              </Button>
            )}

            {showFacilitatorChat && (
              <Button
                className={'d-flex justify-content-start'}
                onClick={() => {
                  handleSwitch(
                    'Facilitators',
                    `PlayerChat_${playerState?.playerState?.code}_Facilitator`
                  );
                  toggleChatType();
                }}
                themeColor={'dark'}
                fillMode={activeTab === 'Facilitators' ? 'solid' : 'flat'}>
                {chatTypeText('Facilitators')}
                {isExistUnreadChat(
                  gameDocumentstate?.gameCode!,
                  `PlayerChat_${playerState?.playerState?.code}_Facilitator`
                ) && <span className={'k-icon k-i-circle text-danger'} />}
              </Button>
            )}
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default MobileTab;
