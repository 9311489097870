import React from 'react';
interface ImageLoadProps {
  assetUrl?: string;
  index?: number;
  onLoadedData?: () => void;
}

const ImageLoad = (props: ImageLoadProps) => {
  const { assetUrl, index, onLoadedData } = props;
  return (
    <img
      src={assetUrl}
      key={index}
      style={{ width: 0, height: 0, opacity: 0, visibility: 'hidden' }}
      onLoad={onLoadedData}
    />
  );
};

export default ImageLoad;
