import React from 'react';

import { Menu } from '../../../types/facilitator/map-menu';
import { GameContext } from '../../../contexts/game';
import { useGameHighlight } from '../../../hooks/use-game-highlight';

interface MapMenuProps {
  menu: Menu;
  selectedMenu?: Menu;
  showLocation?: boolean;
  onChange?: (menu: Menu | undefined) => void;
}
export const MapMenu = ({
  menu,
  selectedMenu,
  showLocation = true,
  onChange
}: MapMenuProps) => {
  const [game] = React.useContext(GameContext);
  const { removeHighlightClass, highlightPlayer, highlightTeam } =
    useGameHighlight();

  const getPlayerCodeList = React.useCallback(
    (type: 'team' | 'group', code: string) => {
      if (game.gameState) {
        if (type === 'team') {
          return game.gameState.players.filter(
            (player) => player.teamCode === code
          );
        } else {
          const teams = game.gameState.teams.filter(
            (team) => team.code === code
          );
          return game.gameState.players.filter((player) =>
            teams.some((team) => team.code === player.teamCode)
          );
        }
      }

      return [];
    },
    [game.gameState]
  );

  const handleHighlight = React.useCallback(
    (type: 'player' | 'team' | 'group', code: string) => {
      if (type === 'player') {
        highlightPlayer(menu.code);
      } else {
        const players = getPlayerCodeList(type, code);
        const codes = players.map((player) => player.code!);
        highlightTeam(codes);
      }
    },
    [highlightPlayer, getPlayerCodeList, highlightTeam]
  );

  const handleClick = React.useCallback(() => {
    if (onChange && game.gameState) {
      if (!selectedMenu || selectedMenu.code !== menu.code) {
        onChange(menu);
        handleHighlight(menu.type, menu.code);
      } else {
        onChange(undefined);
        removeHighlightClass();
      }
    }
  }, [
    selectedMenu,
    menu,
    game.gameState,
    onChange,
    removeHighlightClass,
    handleHighlight
  ]);

  return (
    <div
      className={`list-group-item cursor-pointer ${
        menu.code === selectedMenu?.code ? 'selected-list' : ''
      }`}
      onClick={handleClick}>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex justify-content-between'}>
          <div className={'d-flex align-items-center'}>
            <span
              style={
                {
                  '--status-color': menu!.statusColor
                } as React.CSSProperties
              }
              className={`material-symbols-outlined cursor-pointer player-status text-dark mr-2`}>
              {menu?.type === 'group'
                ? 'group_work'
                : menu?.type === 'team'
                ? 'group'
                : 'person'}
            </span>
            <small
              className={`${
                menu?.code === selectedMenu?.code
                  ? 'text-dark fw-bold'
                  : 'text-dark'
              }`}>
              {menu?.name}
            </small>
          </div>
          <div>
            <span
              className={`${
                menu?.code === selectedMenu?.code
                  ? 'text-dark fw-bold'
                  : 'text-dark'
              }`}>
              {menu?.score}
            </span>
          </div>
        </div>
        {showLocation && menu!.type === 'player' && (
          <div>
            <small className={'text-dark'}>Location : {menu?.location}</small>
          </div>
        )}
      </div>
    </div>
  );
};
