import { Col, Container, Row } from 'react-bootstrap';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import { getTeamList, joinTeam } from '../../services/teams';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { TeamResponse } from '../../types/responses/team-response';
import { joinAssessmentPlayer } from '../../services/players';
import { PlayerContext } from '../../contexts/player';
import { UpdatePlayerAssessmentContext } from '../../utils/player-state';
import { AssessmentLanguage } from '../../types/game-document/entities/assessment';
import { GetAssessmentLanguage } from '../../services/assessments';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { Spinner } from '../spinner';
import {
  generateTitleById,
  GetDisplayLanguageJsonAsync
} from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { AssessmentResourcePackEntity } from '../../types/state/assessment/assessment';

const defaultLanguage: AssessmentLanguage = {
  id: '00000000-0000-0000-0000-000000000000',
  name: 'Default',
  displayLanguageUrl: '',
  displayLanguage: ''
};

export const RegisterAssessmentPage = () => {
  const navigate = useNavigate();
  const { playerState: assessmentPlayerState } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [displayLanguageContext, setDisplayLanguagesContext] = useContext(
    DisplayLanguageContext
  );
  let { gameCode, teamId } = useParams();
  const [name, setName] = useState<string>('');
  const [selectedTeamCode, setSelectedTeamCode] = useState<string>(teamId!);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [teams, setTeams] = useState<TeamResponse[]>([]);
  const [isValidName, setIsValidName] = useState<boolean>(true);
  const [languageId, setLanguageId] = useState<string>(
    '00000000-0000-0000-0000-000000000000'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [languages, setLanguages] = useState<AssessmentLanguage[]>([]);
  const [selectedLanguage, setSelectedLanguage] =
    useState<AssessmentLanguage>(defaultLanguage);

  const getTeamsAsync = async () => {
    if (gameCode) {
      const result = await getTeamList(gameCode!);
      if (result) {
        setTeams((result as TeamResponse[]) ?? []);
      }
    }
  };

  const checkValidInput = () => {
    let isValid = true;

    if (!name || name === '') {
      setIsValidName(false);
      isValid = false;
    }

    return isValid;
  };

  const handleNext = async () => {
    try {
      setIsLoading(true);
      if (!checkValidInput()) {
        setIsLoading(false);
        return;
      }
      const result = await joinAssessmentPlayer(
        gameCode,
        isAnonymous ? 'Anonymous' : name,
        '',
        selectedTeamCode ?? ''
      );

      if (result) {
        if (selectedTeamCode) {
          await joinTeam(selectedTeamCode, result.code!, gameCode!);
        }

        setPlayerState((prev) =>
          UpdatePlayerAssessmentContext(prev, {
            ...prev.assessmentState!,
            code: result.code,
            name: result.name,
            languageId: languageId ?? '',
            displayLanguage: selectedLanguage.displayLanguage,
            displayLanguageUrl: selectedLanguage.displayLanguageUrl,
            teamCode: selectedTeamCode ?? ''
          })
        );
        navigate(`../../games/${gameCode}/assessment/1`);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const checkExistingPlayer = () => {
    if (playerState.assessmentState?.code) {
      navigate(`../../games/${gameCode}/assessment/1`);
    }
  };

  const handleOnChange = (event: DropDownListChangeEvent) => {
    const languageSelected = { ...event.target.value };
    setSelectedLanguage({ ...event.target.value });

    let url =
      event.target.value.displayLanguageUrl ||
      assessmentPlayerState.assessmentDocument?.displayLanguageUrl;

    //set selected display language
    GetDisplayLanguageJsonAsync(url).then((response) => {
      setDisplayLanguagesContext((prev) => ({
        ...prev,
        displayLanguageSelected: response
      }));
    });
  };

  useEffect(() => {
    checkExistingPlayer();
    getTeamsAsync();
  }, []);

  useEffect(() => {
    if (
      assessmentPlayerState.assessmentDocument?.resourcePacks &&
      assessmentPlayerState.assessmentDocument.resourcePacks.length > 0
    ) {
      defaultLanguage.name =
        assessmentPlayerState.assessmentDocument?.language ?? 'Default';
      const defaultLanguageState: AssessmentLanguage = {
        ...defaultLanguage,
        displayLanguage:
          assessmentPlayerState.assessmentDocument?.displayLanguage,
        displayLanguageUrl:
          assessmentPlayerState.assessmentDocument?.displayLanguageUrl
      };
      const availableLanguage: AssessmentLanguage[] = [
        defaultLanguageState
      ].concat(
        assessmentPlayerState.assessmentDocument?.resourcePacks?.map<AssessmentLanguage>(
          (item: AssessmentResourcePackEntity) => ({
            id: item.id,
            name: item.name,
            displayLanguageUrl: item.displayLanguageUrl,
            displayLanguage: item.displayLanguage
          })
        )
      );
      setLanguages([...availableLanguage]);
      if (playerState.isLoaded && !playerState.assessmentState) {
        setPlayerState((prev) =>
          UpdatePlayerAssessmentContext(prev, {
            ...prev.assessmentState!,
            languageId: languageId ?? '',
            displayLanguage: selectedLanguage.displayLanguage,
            displayLanguageUrl: selectedLanguage.displayLanguageUrl
          })
        );
      }
    }
  }, [assessmentPlayerState.assessmentDocument?.displayLanguage]);

  return (
    <Container className={'rounded shadow'}>
      {isLoading && <Spinner />}
      <Container className={'rounded shadow'}>
        <Row className={'align-items-center mt-3'}>
          <Col className={'d-flex justify-content-center'}>
            <img
              width={100}
              src={
                playerState
                  ? assessmentPlayerState?.assessmentDocument?.logoUrl
                  : ''
              }
              className={'position-relative w-10 mt-3'}
            />
          </Col>
        </Row>
        <Row className={'gy-4 mt-4'}>
          <Col xs={12}>
            <label>
              {generateTitleById(
                'fe3c349a-a3a0-4ac9-8396-8b4a2d487e41',
                assessmentPlayerState,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || 'Player name'}
            </label>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.value! ?? '');
                setIsValidName(true);
              }}
              placeholder={
                generateTitleById(
                  '31c384a2-7830-4a41-ad03-8306a14b7335',
                  assessmentPlayerState,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'assessment'
                ) || 'Name'
              }
              className={'w-100'}
              aria-describedby={'name'}
              disabled={isAnonymous}
            />
            {!isValidName && (
              <small id={'register-name'} className={'text-danger'}>
                {generateTitleById(
                  '31c384a2-7830-4a41-ad03-8306a14b7335',
                  assessmentPlayerState,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'assessment'
                ) || 'Name'}{' '}
                is invalid.
              </small>
            )}
          </Col>
          <Col xs={12} className={'d-flex flex-column'}>
            <label>
              {generateTitleById(
                '89572b6f-f14d-4cc4-bac0-c42b0b3617ff',
                assessmentPlayerState,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || 'Team'}
            </label>
            <DropDownList
              data={teams}
              textField={'name'}
              value={teams?.find((x) => x.teamCode === selectedTeamCode)}
              onChange={(team) => {
                setSelectedTeamCode(team?.value?.teamCode);
              }}
            />
          </Col>
          <Col xs={12}>
            <label>
              {generateTitleById(
                '793cc29d-e14c-4248-af9a-57e809631210',
                assessmentPlayerState,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || 'Language'}
            </label>
            <DropDownList
              data={languages}
              textField={'name'}
              value={selectedLanguage}
              onChange={handleOnChange}
            />
          </Col>
          <Col>
            <Checkbox
              id={'anonymous-name-feedback'}
              className={'mr-2'}
              name={'anonymous'}
              value={isAnonymous}
              onChange={(e) => {
                setIsAnonymous(!isAnonymous);

                if (e.value) {
                  setName('Anonymous');
                } else {
                  setName('');
                }
              }}
            />
            <label htmlFor={'anonymous-name-feedback'}>
              {generateTitleById(
                '89bdf773-24c6-4d57-87ef-1df6e42dd064',
                assessmentPlayerState,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || 'Anonymous'}
            </label>
          </Col>
          <Col xs={12}>
            <Button
              className={'k-button--gradient w-100 mb-3'}
              themeColor={'primary'}
              onClick={handleNext}>
              {generateTitleById(
                '43507c9f-5dc4-4ef7-997c-f6bb2202bfb5',
                assessmentPlayerState,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || 'Next'}
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
