import React from 'react';
import { Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat, toLonLat, transform } from 'ol/proj';

import { GameContext } from '../contexts/game';
import { TeamContext } from '../contexts/team';
import { PlayerContext } from '../contexts/player';
import { MapContext } from '../components/map/map-context';
import { useGameTaskPosition } from './use-game-task-position';
import { GameDocumentContext } from '../contexts/game-document';
import { getPlayerLocationStatus } from '../utils/game-document/location';
import { createCustomPlayerPosition } from '../utils/game-engine/player-position';
import { MapIllustrationContext } from '../components/map/map-illustration-context';

export const useGamePlayerPosition = ({ mapType }: { mapType: string }) => {
  const map = React.useContext(MapContext);
  const mapIllustration = React.useContext(MapIllustrationContext);
  const [game] = React.useContext(GameContext);
  const [gameDocument] = React.useContext(GameDocumentContext);
  const [player] = React.useContext(PlayerContext);
  const [team] = React.useContext(TeamContext);

  const { addTaskPlayerListOverlay } = useGameTaskPosition({
    mapType
  });

  const mapContext = React.useMemo(
    () => (mapType === 'illustration' ? mapIllustration : map),
    [mapType, map, mapIllustration]
  );

  //#region Add player element and its overlay
  const addPlayerOverlay = React.useCallback(
    (
      id: string,
      name: string,
      avatarUrl: string,
      isFacilitator: boolean = false
    ) => {
      if (mapContext) {
        const playerExist = mapContext.getOverlayById(id);
        // prevent create player overlay more than once
        if (playerExist) return;

        const playerElement = createCustomPlayerPosition({
          id,
          name,
          score: 0,
          avatarUrl,
          inGameSettings: gameDocument.gameDocument?.settings.inGame,
          isFacilitator
        });

        const overlay = new Overlay({
          id,
          // initial position 0,0
          position: fromLonLat([0, 0]),
          positioning: 'center-center',
          element: playerElement
        });

        mapContext.addOverlay(overlay);
      }
    },
    [mapContext, gameDocument.gameDocument?.settings.inGame]
  );

  //#region Update player overlay by its id
  const updatePlayerOverlayPosition = React.useCallback(
    (id: string, newPosition: Coordinate | [number, number]) => {
      const player = mapContext.getOverlayById(id);
      if (player) {
        player.setPosition(fromLonLat(newPosition));
      }
    },
    [mapContext]
  );

  const updatePlayerOverlay = React.useCallback(
    (
      code: string,
      avatar: string,
      name: string,
      score: number,
      playerStatus: 'offline' | 'available' | 'away',
      team?: string
    ) => {
      const playerAvatar = document.getElementById(
        `avatar-${code}`
      ) as HTMLImageElement;
      if (playerAvatar) {
        playerAvatar.src = avatar;
      }

      const playerName = document.getElementById(
        `playerName-${code}`
      ) as HTMLSpanElement;
      if (playerName) {
        playerName.innerHTML = name;

        const container = playerName.parentElement?.parentElement;
        container && container.classList.add(playerStatus);
      }

      const playerTeam = document.getElementById(
        `playerTeam-${code}`
      ) as HTMLSpanElement;
      if (playerTeam) {
        if (team) {
          playerTeam.className = '';
          playerTeam.innerHTML = team;
        } else {
          playerTeam.className = 'd-none';
        }
      }

      const playerScore = document.getElementById(
        `playerScore-${code}`
      ) as HTMLSpanElement;
      if (playerScore) {
        playerScore.innerHTML = score.toString();
      }
    },
    []
  );

  //#region Add me to map
  const addMine = React.useCallback(() => {
    if (mapContext && player.playerState) {
      const { code, name, avatarImage } = player.playerState;
      if (code && name && avatarImage) {
        addPlayerOverlay(code, name, avatarImage);
      }
    }
  }, [mapContext, player.playerState, addPlayerOverlay]);

  //#region Add and update other players to map
  const addAllPlayer = React.useCallback(
    (isFacilitator = false) => {
      if (game.gameState?.players.length) {
        game.gameState.players
          .filter((e) => e.code !== player.playerState?.code)
          .forEach((player) => {
            const {
              code,
              name,
              avatarImage,
              score,
              teamCode,
              coordinates,
              lastUpdated
            } = player;
            if (code && name && avatarImage) {
              addPlayerOverlay(code, name, avatarImage, isFacilitator);
            }

            if (code) {
              if (coordinates?.longitude && coordinates.latitude) {
                const playerCoordinates = transform(
                  [coordinates.longitude, coordinates.latitude],
                  'EPSG:4326',
                  'EPSG:3857'
                );
                updatePlayerOverlayPosition(code, toLonLat(playerCoordinates));
              }

              if (
                code !== undefined &&
                avatarImage !== undefined &&
                name !== undefined &&
                score !== undefined
              ) {
                let teamName: string | undefined = '';
                if (teamCode) {
                  teamName = game.gameState?.teams.find(
                    (team) => team.code === teamCode
                  )?.name;
                }
                const playerStatus = getPlayerLocationStatus(lastUpdated);
                updatePlayerOverlay(
                  code,
                  avatarImage,
                  name,
                  score,
                  playerStatus,
                  teamName
                );
              }
            }
          });
      }
    },
    [
      game.gameState?.teams,
      game.gameState?.players,
      player.playerState?.code,
      addPlayerOverlay,
      updatePlayerOverlay,
      updatePlayerOverlayPosition
    ]
  );

  // update my position to latest completed task if proximity of
  React.useEffect(() => {
    if (
      mapType === 'illustration' &&
      player.playerState &&
      player.playerState?.latestCompletedTaskId &&
      !gameDocument.gameDocument?.settings.inGame.tasksRequireProximity
    ) {
      const taskId = player.playerState.latestCompletedTaskId;
      const { code, avatarImage } = player.playerState;

      addTaskPlayerListOverlay(taskId, [
        {
          id: code,
          avatarUrl: avatarImage,
          name: 'You',
          team: team.teamState?.name || ''
        }
      ]);
    }
  }, [
    mapType,
    player.playerState,
    team.teamState?.name,
    !gameDocument.gameDocument?.settings.inGame.tasksRequireProximity,
    addTaskPlayerListOverlay
  ]);

  return {
    addMine,
    addAllPlayer,
    addPlayerOverlay,
    updatePlayerOverlay,
    updatePlayerOverlayPosition
  };
};
