import React, { useContext } from 'react';
import { GameDocumentContext } from '../../contexts/game-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { generateTitleById } from '../../utils/game-document/display-languages';

type Props = {
  isCorrectAnswer?: boolean;
  isManualScoring?: boolean;
};

const AnswerIncorrect = ({
  isCorrectAnswer,
  isManualScoring,
  ...props
}: Props) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  return isCorrectAnswer === undefined ? (
    <></>
  ) : (
    <div
      className={`text-end text-${
        isManualScoring ? '' : isCorrectAnswer ? 'success' : 'danger'
      } px-2`}>
      <span className={'fw-bold'}>
        {isManualScoring
          ? generateTitleById(
              '326560e1-3c4b-4e0b-a31e-8fecfc533fd7',
              gameDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Answer submitted'
          : isCorrectAnswer
          ? generateTitleById(
              '80884474-f011-402e-b4ea-6aa791457ed9',
              gameDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Correct'
          : generateTitleById(
              '3a0d67d6-8374-43ae-a050-2c256a3bece4',
              gameDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Incorrect'}
      </span>
    </div>
  );
};

export default AnswerIncorrect;
