const avatars = [
  {
    id: 1,
    src: 'dummy_avatars/avataaars1.png'
  },
  {
    id: 2,
    src: 'dummy_avatars/avataaars2.png'
  },
  {
    id: 3,
    src: 'dummy_avatars/avataaars3.png'
  },
  {
    id: 4,
    src: 'dummy_avatars/avataaars4.png'
  },
  {
    id: 5,
    src: 'dummy_avatars/avataaars5.png'
  },
  {
    id: 6,
    src: 'dummy_avatars/avataaars6.png'
  },
  {
    id: 7,
    src: 'dummy_avatars/avataaars7.png'
  },
  {
    id: 8,
    src: 'dummy_avatars/avataaars8.png'
  },
  {
    id: 9,
    src: 'dummy_avatars/avataaars9.png'
  },
  {
    id: 10,
    src: 'dummy_avatars/avataaars10.png'
  },
  {
    id: 11,
    src: 'dummy_avatars/avataaars11.png'
  },
  {
    id: 12,
    src: 'dummy_avatars/avataaars12.png'
  },
  {
    id: 13,
    src: 'dummy_avatars/avataaars13.png'
  },
  {
    id: 14,
    src: 'dummy_avatars/avataaars14.png'
  },
  {
    id: 15,
    src: 'dummy_avatars/avataaars15.png'
  },
  {
    id: 16,
    src: 'dummy_avatars/avataaars16.png'
  },
  {
    id: 17,
    src: 'dummy_avatars/avataaars17.png'
  },
  {
    id: 18,
    src: 'dummy_avatars/avataaars18.png'
  },
  {
    id: 19,
    src: 'dummy_avatars/avataaars19.png'
  },
  {
    id: 20,
    src: 'dummy_avatars/avataaars20.png'
  }
];

export default avatars;
