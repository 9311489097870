import { GameDocument } from '../../../types/game-document';
/**
 * Get all timers from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTimers = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.timers ?? [];
};

/**
 * Get Timers detail by ID from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @param timerId - The Game Document as datasource
 * @constructor
 */
export const GetTimerById = (
  gameDocument: GameDocument | undefined,
  timerId: string
) => {
  return (
    gameDocument?.assets?.timers?.filter((x) => timerId.indexOf(x.id) !== -1) ??
    []
  );
};

/**
 * Get Timer object detail by timerId
 * @param gameDocument - The Game Document as datasource
 * @param timerId - The Game Document as datasource
 * @returns
 */
export const GetTimerObjectById = (
  gameDocument: GameDocument,
  timerId: string
) => gameDocument.assets.timers?.find((x) => x.id === timerId);
