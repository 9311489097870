import { DetailPlayer } from '../../components/facilitator/detail-player';
import { useContext, useEffect, useRef, useState } from 'react';
import { FacilitatorScoringList } from '../../components/facilitator/facilitator-scoring-list';
import { ManualScoring } from '../../types/manual-scoring';
import {
  GetManualScoringAnswers,
  submitScoringAsync
} from '../../services/players';
import { GameDocumentContext } from '../../contexts/game-document';
import { Col, Row } from 'react-bootstrap';
import { GameContext } from '../../contexts/game';

export const TaskScoringPage = () => {
  const [, setGameContext] = useContext(GameContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedManualScoring, setSelectedManualScoring] = useState<
    ManualScoring | undefined
  >();
  const [manualScoringList, setManualScoringList] = useState<ManualScoring[]>(
    []
  );
  const [gameDocumentState] = useContext(GameDocumentContext);
  const handleChange = (manualScoring: ManualScoring) => {
    setSelectedManualScoring(manualScoring);
  };

  const getManualScoringlist = async () => {
    setIsLoading(true);
    let response = await GetManualScoringAnswers(gameDocumentState?.gameCode!);
    response.sort(
      (a, b) =>
        new Date(b.SubmittedDateUtc as string).valueOf() -
        new Date(a.SubmittedDateUtc as string).valueOf()
    );
    setManualScoringList(response as any);
    setIsLoading(false);
  };

  const submitScoring = async (scoring: ManualScoring) => {
    try {
      await submitScoringAsync(scoring);
    } catch (error) {
      console.error('Failed submit scoring');
    }
  };

  const onSubmitScoreHandler = async (scoring: ManualScoring) => {
    submitScoring(scoring);
    let scoringIndex = manualScoringList?.findIndex(
      (x) =>
        x.playerCode === scoring?.playerCode &&
        x.taskId === scoring?.taskId &&
        x.formId === scoring?.formId
    );

    let newScoring: ManualScoring[] = [...manualScoringList];
    newScoring.splice(scoringIndex, 1);
    setManualScoringList(newScoring);
    if (newScoring.length > 0) {
      setSelectedManualScoring(newScoring[0]);
    } else {
      setGameContext((prev) => ({
        ...prev,
        gameState: {
          ...prev.gameState!,
          hasNewSubmittedAnswer: false
        }
      }));
      setSelectedManualScoring(undefined);
    }
  };

  useEffect(() => {
    getManualScoringlist();
  }, []);

  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={'h-100 mh-100 w-100'} ref={containerRef}>
      {isLoading && (
        <div
          className={
            'd-flex flex-column align-items-center justify-content-center h-100 w-100 gap-2'
          }>
          <span className={'loader'}></span>
          <label className={'text-gray'}>Loading...</label>
        </div>
      )}
      {manualScoringList.length === 0 ? (
        <h3>No scoring found</h3>
      ) : (
        <Row className={'h-100 mh-100 m-0'}>
          <Col
            md={3}
            className={'p-0 border border-secondary'}
            style={{
              height: containerRef?.current?.clientHeight,
              overflowY: 'auto'
            }}>
            <FacilitatorScoringList
              listData={manualScoringList}
              onClick={handleChange}
              selectedId={selectedManualScoring?.id as number}
            />
          </Col>
          <Col
            md={9}
            className={'border border-secondary'}
            style={{
              padding: '20px 30px'
            }}>
            {selectedManualScoring !== undefined && (
              <DetailPlayer
                dataPlayer={selectedManualScoring}
                onSubmitScore={onSubmitScoreHandler}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};
