import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { FeedbackDocumentProvider } from '../../contexts/feedback-document';
import '../../styles/layouts/_layout--feedback.scss';
import { DisplayLanguageFeedbackProvider } from '../../contexts/display-languages-feedback';
/**
 * The layout for private pages.
 * @returns
 */
const FeedbackLayout = () => {
  const { feedbackCode } = useParams();
  return (
    <FeedbackDocumentProvider feedbackCode={feedbackCode!}>
      <DisplayLanguageFeedbackProvider>
        <div className={'layout--feedback'}>
          <div className={'app-main'}>
            <Outlet />
          </div>
        </div>
      </DisplayLanguageFeedbackProvider>
    </FeedbackDocumentProvider>
  );
};

export default FeedbackLayout;
