import React from 'react';
import { useAuth } from 'react-oidc-context';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import { GameContext } from './game';
import { GetGlobalChatHub } from '../services/games';
import { HubResponse } from '../types/responses/hub-response';
import { TeamResponse } from '../types/responses/team-response';
import { ChatState } from '../types/state/websocket/chat-state';
import { PlayerResponse } from '../types/responses/player-response';
import { sendPushNotification } from '../utils/game-engine/send-push-notification';

export interface FacilitatorPlayerChatContextProps {
  newChat: ChatState[] | [];
  setNewChat: React.Dispatch<React.SetStateAction<ChatState[] | []>>;
  players: PlayerResponse[];
  setPlayers: React.Dispatch<React.SetStateAction<PlayerResponse[]>>;
  teams: TeamResponse[];
  setTeams: React.Dispatch<React.SetStateAction<TeamResponse[]>>;
  createNewChatConnection: (
    type: 'global' | 'player' | 'team' | 'group',
    code: string
  ) => void;
}

const FacilitatorPlayerChatContext =
  React.createContext<FacilitatorPlayerChatContextProps | null>(null);

const FacilitatorPlayerChatProvider = ({
  gameCode,
  children
}: {
  gameCode: string;
  children: React.ReactNode;
}) => {
  const auth = useAuth();
  const facilitatorId = auth.user?.profile.sub;
  const [game] = React.useContext(GameContext);
  const [teams, setTeams] = React.useState<TeamResponse[]>([]);
  const [players, setPlayers] = React.useState<PlayerResponse[]>([]);
  const [newChat, setNewChat] = React.useState<ChatState[] | []>([]);

  const [chatHubResponse, setChatHubResponse] = React.useState<HubResponse>();
  const [chatHubConnections, setChatHubConnections] = React.useState<
    {
      method: string;
      connection?: HubConnection;
    }[]
  >([]);

  const createChatHub = React.useCallback(async (gameCode: string) => {
    try {
      // create chat hub to get hub's endpointUrl and hub's accestoken
      const hub = await GetGlobalChatHub(gameCode);
      setChatHubResponse(hub);
    } catch (error) {
      console.log('Error when create chat hub: ', error);
    }
  }, []);

  const createChatHubConnection = React.useCallback(
    async (method: string) => {
      if (chatHubResponse?.endpointUrl && chatHubResponse.accessToken) {
        // store method and connection
        // method stored for checker if player/team/group's connection was already created
        // connection stored for trigger stop connection, in case facilitator kick or remove player/team/group
        setChatHubConnections((prev) => [
          ...prev,
          {
            method,
            connection
          }
        ]);

        const connection = new HubConnectionBuilder()
          .withUrl(chatHubResponse.endpointUrl, {
            accessTokenFactory: () => chatHubResponse.accessToken ?? ''
          })
          .withAutomaticReconnect()
          .build();

        connection
          .start()
          .then(() => {
            connection.on(
              method,
              (playerCode, playerName, message, avatarUrl, date) => {
                const chat = {
                  author: {
                    id: playerCode,
                    name: playerName,
                    avatarUrl: avatarUrl
                  },
                  timestamp: new Date(date),
                  group: method,
                  text: message
                };
                setNewChat((prev) => [...prev, chat]);

                if (
                  facilitatorId &&
                  // check if code is same as facilitator id, notification will not send
                  facilitatorId !== playerCode &&
                  'serviceWorker' in navigator &&
                  'PushManager' in window
                ) {
                  sendPushNotification({
                    message,
                    avatar: avatarUrl || '/favicon.ico',
                    url: `${window.location.origin}/games/${gameCode}/facilitator`,
                    playerName
                  });
                }
              }
            );
          })
          .catch((error) => {
            console.log(`Error when starting connection ${method}:`, error);
          });
      }
    },
    [
      chatHubResponse?.endpointUrl,
      chatHubResponse?.accessToken,
      facilitatorId,
      gameCode
    ]
  );

  const createNewChatConnection = React.useCallback(
    (type: 'global' | 'player' | 'team' | 'group', code: string) => {
      const methodType = {
        global: 'GlobalChat_',
        player: 'PlayerChat_',
        team: 'TeamChat_',
        group: 'GroupChat_'
      };
      // find connection by method first, is it already created or not
      const connectionExist = chatHubConnections.find(
        (connection) => connection.method === `${methodType[type]}${code}`
      );
      // if not found nor created, create connection
      if (!connectionExist)
        createChatHubConnection(`${methodType[type]}${code}`);
    },
    [chatHubConnections, createChatHubConnection]
  );

  React.useEffect(() => {
    if (!gameCode) return;
    if (game.activityState && !game.activityState.hasGame) return;
    if (chatHubResponse?.accessToken) return;
    createChatHub(gameCode);
  }, [
    gameCode,
    chatHubResponse?.accessToken,
    game.activityState,
    createChatHub
  ]);

  // create connection for global chat
  React.useEffect(() => {
    if (chatHubResponse?.method) {
      createNewChatConnection(
        'global',
        chatHubResponse.method.replace('GlobalChat_', '')
      );
    }
  }, [chatHubResponse?.method, chatHubConnections, createNewChatConnection]);

  // create connection for players chat
  // this will need game started first to sync with game state's player(s)
  React.useEffect(() => {
    if (game.gameState?.players) {
      game.gameState.players.forEach((player) => {
        if (player.code) {
          createNewChatConnection('player', player.code);
        }
      });
    }
  }, [game.gameState?.players, createNewChatConnection]);

  // stop all connection when unmount
  React.useEffect(() => {
    return () => {
      chatHubConnections.forEach(({ connection }) => {
        if (connection) {
          connection.stop();
        }
      });
    };
  }, [chatHubConnections]);

  return (
    <FacilitatorPlayerChatContext.Provider
      value={{
        newChat,
        setNewChat,
        players,
        setPlayers,
        teams,
        setTeams,
        createNewChatConnection
      }}>
      {children}
    </FacilitatorPlayerChatContext.Provider>
  );
};

export { FacilitatorPlayerChatContext, FacilitatorPlayerChatProvider };
