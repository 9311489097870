import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface GameDialogProps {
  title: string;
  subTitle: string;
  showAssessment?: boolean;
  icon?: 'pause' | 'play_arrow';
}

const GameDialog = ({
  title,
  subTitle,
  icon,
  showAssessment
}: GameDialogProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={
        'modal in-game-modal d-flex flex-column align-items-center justify-content-center'
      }>
      {icon !== undefined && (
        <span className={'material-symbols-outlined text-size-100'}>
          {icon}
        </span>
      )}
      <h1 className={'fw-bold'}>{title}</h1>
      <h3>{subTitle}</h3>
      {showAssessment && (
        <Button
          themeColor={'success'}
          className={'mt-4'}
          onClick={() => {
            navigate('../assessment');
          }}>
          Join assessment
        </Button>
      )}
    </div>
  );
};

export default GameDialog;
