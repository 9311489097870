import { ApiBaseUrl, BearerHeader, JsonHeaders } from '../services/api';

/**
 * Fetches data from an API endpoint.
 *
 * @param {string} url - The URL of the API endpoint to fetch from.
 * @returns {Promise} - A Promise that resolves to the JSON response from the API.
 */
export const apiFetcher = (url: string) =>
  fetch(`${ApiBaseUrl}/${url}`).then((res) => res.json());

/**
 * Function that fetches data from an API with authentication.
 *
 * @param {string} url - The URL to fetch the data from.
 * @returns {Promise} A promise that resolves to the fetched data.
 */
export const apiFetcherWithAuth = (url: string) =>
  fetch(`${ApiBaseUrl}/${url}`, { headers: { ...BearerHeader } }).then((res) =>
    res.json()
  );

/**
 * Fetches data from a local URL.
 *
 * @param {string} url - The URL to fetch data from.
 * @returns {Promise} A promise that resolves to the parsed JSON response.
 */
export const localFetcher = (url: string) =>
  fetch(`/${url}`).then((res) => res.json());

/**
 * Fetches data from a remote URL.
 *
 * @param {string} url - The URL to fetch data from.
 * @returns {Promise} A promise that resolves to the parsed JSON response.
 */
export const remoteFetcher = (url: string) =>
  fetch(url, { mode: 'no-cors', headers: { ...JsonHeaders } }).then((res) =>
    res.json()
  );
