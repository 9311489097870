import Map from 'ol/Map';
import React from 'react';
// openlayers
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import { Zoom } from 'ol/control';

export const MapContext = React.createContext<Map>(
  new Map({
    layers: [
      new TileLayer({
        source: new OSM()
      })
    ],
    controls: [new Zoom()],
    view: new View({
      center: fromLonLat([0, 0]),
      zoom: 7
    })
  })
);
