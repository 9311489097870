import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { GameDocumentContext } from '../../../../../contexts/game-document';
import { PlayerContext } from '../../../../../contexts/player';
import { TeamContext } from '../../../../../contexts/team';
import { GetAsset } from '../../../../../utils/game-document/assets';
import { GetResourceValue } from '../../../../../utils/game-document/resources';
import { Card } from '../../../../../components/card';
import { Item } from '../../../../../types/game-document/entities/item';
import { CardList } from '../../../../../components/card-list';
import { Button } from '@progress/kendo-react-buttons';
import AchievementDialog from '../../../../../components/dialog/achievement-dialog';
import { GameDocument } from '../../../../../types/game-document';
import { PlayerStateFull, TeamStateFull } from '../../../../../types/state';
import SearchInput from '../../../../../components/input/search-input';

import { ShowInventory } from '../../../../../utils/game-engine/inventory';
import { generateTitleById } from '../../../../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../../../../contexts/display-languages';

export interface ItemAchievement extends Item {
  titleRes: string;
  imageRes: string;
  summaryRes: string;
  quantity: number;
}

/**
 * Loads the Item's resources and combines the result.
 * @param gameDocument
 * @param language
 * @param itemId
 * @param quantity
 */
export const GetItemAchievement = (
  gameDocument: GameDocument,
  language: string,
  itemId: string,
  quantity: number
) => {
  let inventoryItem = {
    ...GetAsset<Item>(gameDocument.assets.items!, itemId),
    quantity
  } as ItemAchievement;
  inventoryItem.titleRes = GetResourceValue(
    gameDocument,
    inventoryItem.titleResId!,
    language
  );
  inventoryItem.imageRes = GetResourceValue(
    gameDocument,
    inventoryItem.imageResId!,
    language
  );
  inventoryItem.summaryRes = GetResourceValue(
    gameDocument,
    inventoryItem.summaryResId!,
    language
  );
  return inventoryItem;
};

/**
 * Converts the Player's Inventory Items into InventoryItemAchievements
 * @param gameDocument
 * @param playerState
 */
const CastPlayerInventory = (
  gameDocument: GameDocument,
  playerState: PlayerStateFull
) =>
  playerState.inventory!.map((item, index) =>
    GetItemAchievement(
      gameDocument,
      playerState.language?.name! ?? '',
      item.id,
      item.quantity
    )
  ) ?? [];

/**
 * Converts the Team's Inventory Items into InventoryItemAchievements
 * @param gameDocument
 * @param teamState
 */
const CastTeamInventory = (
  gameDocument: GameDocument,
  teamState: TeamStateFull
) =>
  teamState.inventory!.map((item, index) =>
    GetItemAchievement(gameDocument, '', item.id, item.quantity)
  ) ?? [];

/**
 * Search the list of InventoryItemAchievements for the provided search term.
 * @param items
 * @param searchTerm
 */
const SearchItemAchievement = (items: ItemAchievement[], searchTerm: string) =>
  items.filter((i) =>
    i.titleRes.toLowerCase().includes(searchTerm.toLowerCase())
  );

export const Inventory = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [teamState, setTeamState] = useContext(TeamContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const [itemCard, setItemCard] = useState<ItemAchievement | undefined>();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [playerInventory, setPlayerInventory] = useState<ItemAchievement[]>([]);
  const [teamInventory, setTeamInventory] = useState<ItemAchievement[]>([]);
  const [showInventory, setShowInventory] = useState<boolean>(true);

  // When the player's inventory or search term changes. Load inventory.
  useEffect(() => {
    setPlayerInventory(
      SearchItemAchievement(
        CastPlayerInventory(
          gameDocument.gameDocument!,
          playerState.playerState!
        ),
        searchTerm
      )
    );
  }, [playerState.playerState?.inventory, searchTerm]);

  // When the Team's inventory or search term changes. Load inventory.
  useEffect(() => {
    setTeamInventory(
      SearchItemAchievement(
        CastTeamInventory(gameDocument.gameDocument!, teamState.teamState!),
        searchTerm
      )
    );
  }, [teamState.teamState?.inventory, searchTerm]);

  useEffect(() => {
    const result = ShowInventory(
      playerState?.playerState!,
      gameDocument?.gameDocument!
    );
    setShowInventory(result!);
  }, [playerState?.playerState?.status]);

  return (
    <>
      <Container className={'drawer__body'} fluid>
        <Row className={'mt-3 mb-4 align-items-center border-bottom pb-3'}>
          <Col>
            <h1 className={'m-0'}>
              {generateTitleById(
                'f7a7c135-91e2-433f-a7ed-0b62ff39aba0',
                gameDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Inventory'}
            </h1>
          </Col>
          <Col xs={'auto'}>
            <SearchInput
              placeholder={'Search inventory'}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            {showInventory && (
              <>
                <Col xs={12} className={'p-4'}>
                  <div>
                    {generateTitleById(
                      '0d09f801-d473-4918-adb3-04752d47664b',
                      gameDocument,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Players items'}
                    :
                  </div>
                </Col>
                <Col xs={12}>
                  <CardList>
                    {playerInventory &&
                      playerInventory
                        .filter((item) => !item.hideInGame)
                        .map((item, index) => {
                          return (
                            <Card
                              key={`pi-${index}`}
                              imageUrl={item.imageRes}
                              title={`${item.titleRes ?? ''} (${item.quantity})`}
                              subTitle={''}
                              onClick={() => setItemCard(item)}></Card>
                          );
                        })}
                  </CardList>
                </Col>
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            {showInventory && (
              <>
                <Col xs={12} className={'p-4'}>
                  <div>
                    {generateTitleById(
                      'c22c93e0-8474-479c-b734-53312614d3c5',
                      gameDocument,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Team items'}
                    :
                  </div>
                </Col>
                <Col xs={12}>
                  <CardList>
                    {teamInventory &&
                      teamInventory
                        .filter((item) => !item.hideInGame)
                        .map((item, index) => {
                          return (
                            <Card
                              key={`pi-${index}`}
                              imageUrl={item.imageRes}
                              title={`${item.titleRes ?? ''} (${item.quantity})`}
                              subTitle={''}
                              onClick={() => setItemCard(item)}></Card>
                          );
                        })}
                  </CardList>
                </Col>
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            {showInventory && (
              <>
                <Col xs={12} className={'p-4'}>
                  <div>
                    {generateTitleById(
                      '2f276a31-f0d1-48f7-9ce3-0f51488b35c9',
                      gameDocument,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Available items'}
                    :
                  </div>
                </Col>
                <Col xs={12}>
                  <CardList>
                    {gameDocument.gameDocument?.assets.items!.map(
                      (item, index) => {
                        let inventoryItem = GetItemAchievement(
                          gameDocument.gameDocument!,
                          playerState?.playerState?.language?.name! ?? '',
                          item.id,
                          0
                        );
                        return (
                          <Card
                            key={index}
                            imageUrl={inventoryItem.imageRes}
                            title={inventoryItem.titleRes ?? ''}
                            subTitle={''}
                            onClick={() => setItemCard(inventoryItem)}></Card>
                        );
                      }
                    )}
                  </CardList>
                </Col>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Container className={'drawer__footer p-0 text-center pb-2'} />
      {itemCard && (
        <AchievementDialog
          imageUrl={itemCard.imageRes}
          onMaskClick={() => setItemCard(undefined)}>
          <h1>{itemCard.quantity}</h1>
          <h2>{itemCard.titleRes}</h2>
          <p>{itemCard.summaryRes}</p>
          <div>
            <Button
              fillMode={'outline'}
              themeColor={'light'}
              onClick={() => setItemCard(undefined)}>
              {generateTitleById(
                '48bc820b-40e8-4624-8d46-b68e16013be6',
                gameDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Close'}
            </Button>
          </div>
        </AchievementDialog>
      )}
    </>
  );
};
