import React, { useState } from 'react';
import { AssessmentState } from '../types/state/assessment/assessment-players-answer-state';

const PlayerAssessmentContext = React.createContext<
  [
    initialState: AssessmentState,
    setState: React.Dispatch<React.SetStateAction<AssessmentState>>
  ]
>([{}, () => {}]);

const initialAssessmentState: AssessmentState = {};

const PlayerAssessmentProvider = (props: any) => {
  const [state, setState] = useState<AssessmentState>({
    ...initialAssessmentState
  });

  return (
    <PlayerAssessmentContext.Provider value={[state, setState]}>
      {props.children}
    </PlayerAssessmentContext.Provider>
  );
};

export { PlayerAssessmentContext, PlayerAssessmentProvider };
