import React from 'react';

import {
  FacilitatorPlayerChatContext,
  FacilitatorPlayerChatContextProps
} from '../../contexts/facilitator-player-chat';
import { TeamResponse } from '../../types/responses/team-response';
import { GroupResponse } from '../../types/responses/group-response';
import { PlayerResponse } from '../../types/responses/player-response';
import { PlayerContactResponse } from '../../types/responses/player-contacts-reponse';
import cloneDeep from 'lodash.clonedeep';
import { useAuth } from 'react-oidc-context';

interface ListProps {
  dataItem?: TeamResponse | PlayerResponse | PlayerContactResponse;
  listData?:
    | TeamResponse[]
    | PlayerResponse[]
    | GroupResponse[]
    | PlayerContactResponse[];
  onClick?: (item: TeamResponse | PlayerResponse | any) => void;
  listType?: '' | 'group' | 'playerchat';
}

export const FacilitatorList = ({
  listType,
  dataItem,
  listData,
  onClick = () => {}
}: ListProps) => {
  const auth = useAuth();
  const facilitatorId = auth.user?.profile.sub;
  const [list, setList] = React.useState<typeof listData>([]);
  const { newChat } = React.useContext(
    FacilitatorPlayerChatContext
  ) as FacilitatorPlayerChatContextProps;

  const isHighlighted = (
    currentItem: TeamResponse | PlayerResponse | PlayerContactResponse
  ) => {
    if (listType === 'playerchat') {
      let newCurrentItem = currentItem as PlayerContactResponse;
      let newDataItem = dataItem as PlayerContactResponse;

      if (
        newCurrentItem.playerCode1 === newDataItem.playerCode1 &&
        newCurrentItem.playerCode2 === newDataItem.playerCode2
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (currentItem?.name?.toLowerCase() === 'all teams') {
        return (
          dataItem?.name?.toLowerCase() === currentItem?.name?.toLowerCase()
        );
      } else {
        return dataItem?.id === currentItem?.id;
      }
    }
  };

  const showIsNewChat = React.useCallback(
    (code: string, isNewChat: boolean) => {
      setList((prev) => {
        if (prev) {
          const listTemp: typeof prev = cloneDeep(prev);
          const isNewChatIndex = prev.findIndex(
            (data: any) => data.code === code || data.teamCode === code
          );

          if (isNewChatIndex > -1) {
            listTemp[isNewChatIndex].isNewChat = isNewChat;
          }

          const result: any = listTemp.map((temp) => ({
            ...temp,
            isNewChat:
              typeof temp.isNewChat !== 'undefined' ? temp.isNewChat : false
          }));

          return [
            ...result.filter((res: any) => res.isNewChat),
            ...result.filter((res: any) => !res.isNewChat)
          ];
        }
      });
    },
    []
  );

  React.useEffect(() => {
    if (listData?.length) {
      setList(listData);
    }
  }, [listData]);

  React.useEffect(() => {
    if (listData && newChat.length > 0) {
      newChat.forEach((chat) => {
        const code = chat.group?.split('_')[1];
        if (code && chat.author.id !== facilitatorId) {
          showIsNewChat(code, true);
        }
      });
    }
  }, [newChat, listData, facilitatorId, showIsNewChat]);

  return (
    <div className="list-group w-100">
      {list?.map((item, index) => (
        <div
          key={index}
          className={`list-group-item cursor-pointer ${
            isHighlighted(item) ? 'selected-list' : ''
          }`}
          onClick={() => {
            onClick(item as any);
          }}>
          <div className={'d-flex justify-content-between'}>
            <small
              className={`${
                isHighlighted(item) || item.isNewChat
                  ? 'text-dark fw-bold'
                  : 'text-dark'
              }`}>
              {listType === 'group' && (item as TeamResponse).group?.name ? (
                <small className={'text-dark'}>{`${
                  (item as TeamResponse).group?.name
                } - ${item.name}`}</small>
              ) : (
                item?.name
              )}
            </small>
            {item.isNewChat && <span className="badge bg-info">New</span>}
          </div>
        </div>
      ))}
    </div>
  );
};
