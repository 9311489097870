import { ComponentPropsWithRef } from 'react';
import { CardImage } from './card-image';

export interface CardProps extends ComponentPropsWithRef<'div'> {
  imageUrl: string;
  title: string;
  subTitle: string;
}

export const Card = ({
  imageUrl,
  title,
  subTitle,
  children,
  ...props
}: CardProps) => {
  const stopOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };
  return (
    <div
      {...props}
      className={`card-list__card ${props.className ?? ''}`.trim()}>
      <div className={'card__inner'}>
        <CardImage imageUrl={imageUrl} />
        <div className={'card__title'}>{title}</div>
        <div className={'card__sub-title'}>{subTitle}</div>
      </div>
      {children && (
        <div className={'card__body'} onClick={stopOnClick}>
          {children}
        </div>
      )}
    </div>
  );
};
