import React, { useEffect, useState } from 'react';
import { toastStore } from '../stores/toast-store';

export type ToastStyle = 'none' | 'success' | 'error' | 'warning' | 'info';

export interface ToastProps {
  show?: boolean;
  title?: string;
  children?: React.ReactNode;
  style: ToastStyle;
  timeout?: number;
}

export function Toast(props: ToastProps) {
  useEffect(() => {
    if (props.show === true) {
      setTimeout(() => toastStore.hide(), toastStore?.getTimeout() ?? 5000);
    }
  }, [props.show]);

  const getTitleStyle = () => {
    switch (props.style) {
      case 'none':
        return undefined;
      case 'error':
        return 'text-danger';
      case 'info':
        return 'text-info';
      case 'success':
        return 'text-success';
      case 'warning':
        return 'text-warning';
      default:
        return undefined;
    }
  };
  const onClose = () => {
    toastStore.hide();
  };
  return (
    <div
      className="position-fixed bottom-0 end-0 p-3"
      style={{ zIndex: '30000' }}>
      <div
        id="liveToast"
        className={['toast', props.show ? 'show' : 'hide'].join(' ')}
        role="alert"
        aria-live="assertive"
        aria-atomic="true">
        {props.title && (
          <div className="toast-header">
            <strong
              className={[
                'me-auto',
                getTitleStyle() ? getTitleStyle() : ''
              ].join(' ')}>
              {props.title}
            </strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={onClose}></button>
          </div>
        )}

        <div className="toast-body">{props.children}</div>
      </div>
    </div>
  );
}
