import { PlayerState } from '../../types/state';
import { GameDocument } from '../../types/game-document';
import { GetPreGameSetting } from '../game-document/settings';

/**
 * Set visibility of inventory
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowInventory = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const GetPreGameInventory = GetPreGameSetting(gameDocument);
    return GetPreGameInventory.showPreGameInventory;
  }
  return true;
};
