import { Button } from '@progress/kendo-react-buttons';
import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

type Props = {
  additionalContainerClass?: string;
  isShowTitle?: boolean;
  wizardTitle?: string;
  additionalTitleClass?: string;
  isShowButton?: boolean;
  nextButtonText?: string;
  additionalButtonContainerClass?: string;
  additionalButtonNextClass?: string;
  handleNextButtonClicked?: (() => Promise<void>) | (() => void);
  children?: ReactNode | undefined;
};

const WizardContent = ({
  additionalContainerClass = '',
  isShowTitle = true,
  wizardTitle = '',
  additionalTitleClass = '',
  isShowButton = true,
  nextButtonText = '',
  additionalButtonContainerClass = '',
  additionalButtonNextClass = '',
  handleNextButtonClicked = () => Promise.resolve(),
  ...props
}: Props) => {
  return (
    <Row className={'gy-4 mt-4'}>
      {isShowTitle && (
        <Col xs={12} className={'text-center'}>
          <strong>{wizardTitle}</strong>
        </Col>
      )}
      <Col xs={12}>{props.children}</Col>
      {isShowButton && (
        <Col xs={12}>
          <Button
            className={'k-button--gradient w-100'}
            themeColor={'primary'}
            onClick={handleNextButtonClicked}>
            {nextButtonText}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default WizardContent;
