import { FallbackProps } from 'react-error-boundary';
import { Col, Row } from 'react-bootstrap';
import React from 'react';

export const AssessmentControlError = (props: FallbackProps) => (
  <Row className={'align-items-center border-bottom py-3'}>
    <Col className={'fst-italic text-danger'}>
      There was an error loading the assessment for this event:{' '}
      {props.error.toString()}.
    </Col>
  </Row>
);
