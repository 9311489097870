import { useContext, useState } from 'react';
import SimpleEntity from '../tabs/simple-entity';
import { addNewGroups, joinGroup } from '../../services/groups';
import { GameContext } from '../../contexts/game';
import { Button } from '@progress/kendo-react-buttons';
import { addNewTeams } from '../../services/teams';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';

interface CreateGroupAndTeamProps {
  groupCodeOfTeam?: string;
  onSubmitClick?: () => void;
}

export const CreateGroupAndTeam = ({
  groupCodeOfTeam,
  onSubmitClick = () => {}
}: CreateGroupAndTeamProps) => {
  const [game] = useContext(GameContext);
  const [teamDialogIsVisible, setTeamDialogIsVisible] =
    useState<boolean>(false);
  const [groupDialogIsVisible, setGroupDialogIsVisible] =
    useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [notification, setNotification] = useContext(NotificationContext);
  const toggleGroupsDialogHandler = () => {
    setGroupDialogIsVisible((prevState) => !prevState);
  };

  const toggleTeamsDialogHandler = () => {
    if (teamDialogIsVisible) {
      setError('');
    }
    setTeamDialogIsVisible((prevState) => !prevState);
  };

  const responseGroupTeamNotification = (message: string) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(responseTeamNotification);
    setNotification({ ...notification, content });
  };

  const onSubmitGroupHandler = (name?: string) => {
    let errorMessage = '';

    if (name === '') {
      errorMessage = 'Group name is required';
    } else if (name && (name.length < 2 || name.length > 128)) {
      errorMessage = 'Please enter a group name that is 2 - 128 characters';
    }

    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    addNewGroups(game.gameCode!, name!)
      .then((response) => {
        if (response) {
          responseGroupTeamNotification('Group successfully added');
          onSubmitClick();
        }

        toggleGroupsDialogHandler();
      })
      .catch(() => {
        setError('Group name already exists');
      });
  };

  const onSubmitTeamHandler = (name?: string, avatar?: string) => {
    let errorMessage = '';
    if (name === '') {
      errorMessage = 'Team name is required';
    } else if (name && (name.length < 2 || name.length > 128)) {
      errorMessage = 'Please enter a team name that is 2 - 128 characters';
    }

    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    addNewTeams(game.gameCode!, name!, avatar)
      .then((response) => {
        if (response) {
          onSubmitClick();
          responseGroupTeamNotification(
            'Team successfully added, please wait for team synchronization'
          );
        }
        toggleTeamsDialogHandler();
      })
      .catch(() => {
        setError('Team name already exists');
      });
  };

  return (
    <>
      <div className={'d-flex justify-content-end w-full'}>
        <Button
          className={'facilitator-button-light ml-5'}
          onClick={() => toggleGroupsDialogHandler()}>
          Create group
        </Button>
        <Button
          themeColor={'success'}
          className={'ml-3'}
          onClick={() => toggleTeamsDialogHandler()}>
          Create team
        </Button>
      </div>
      {groupDialogIsVisible && (
        <SimpleEntity
          inputTitle={'Group name'}
          errorMessage={error}
          onToggleProfileWidget={() => {
            toggleGroupsDialogHandler();
            setError('');
          }}
          onSubmit={onSubmitGroupHandler}
          isShowAvatar={false}
        />
      )}
      {teamDialogIsVisible && (
        <SimpleEntity
          inputTitle={'Team name'}
          errorMessage={error}
          onToggleProfileWidget={() => {
            toggleTeamsDialogHandler();
            setError('');
          }}
          onSubmit={onSubmitTeamHandler}
        />
      )}
    </>
  );
};
