import { Avatar } from '@progress/kendo-react-layout';
import React, { ComponentPropsWithRef, useContext } from 'react';
import { PlayerContext } from '../../contexts/player';

interface PlayerAvatarProps extends ComponentPropsWithRef<'div'> {}

/**
 * Renders the player avatar based on the player state.
 * @remarks Relies on player context.
 */
export const PlayerAvatar = (props: PlayerAvatarProps) => {
  const [playerState] = useContext(PlayerContext);
  return (
    <div {...props}>
      <Avatar
        type={'image'}
        size={'large'}
        className={'border border-3 border-white cursor-pointer'}>
        <img
          src={playerState.playerState?.avatarImage}
          className={'h-3 w-3 rounded-circle'}
          alt={playerState.playerState?.name}
        />
      </Avatar>
    </div>
  );
};
