import { GameDocument } from '../../types/game-document';

/**
 * Get resource pack's resource by name.
 * @param gameDocument - The Game Document to modify
 * @param name - Name of resource pack
 * @param resourceId - Resource Id you want to search
 * @return The Resource pack's resource
 */
export const GetResourcePackResourceValue = (
  gameDocument: GameDocument,
  name: string,
  resourceId: string
): string => {
  const selectedResourcePack = gameDocument?.resourcePacks?.find(
    (x) => x.name === name
  );

  return (
    selectedResourcePack?.resources?.find((x) => x.id === resourceId)?.value ??
    ''
  );
};
