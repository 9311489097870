import { KendoGridResult } from '../types/KendoGridResult';
import { User } from 'oidc-client-ts';
import { parseDate } from '@progress/kendo-intl';

/**
 * The V2 API Endpoint Url for Catalyst Games Portal API.
 * @remarks DOES NOT End with a trailing slash /
 */
export const ApiBaseUrl = `${
  window?._env_?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL
}/api/v2`;
export const PortalApiBaseUrl = `${
  window?._env_?.REACT_APP_PORTAL_API_BASE_URL ||
  process.env.REACT_APP_PORTAL_API_BASE_URL
}/api/v2`;

export const GetUser = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${
      window?._env_?.REACT_APP_AUTHORITY || process.env.REACT_APP_AUTHORITY
    }:${window?._env_?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID}`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
};

/**
 * Accept and Content-Type headers for PUT and PATCH methods.
 * @internal
 */
export const JsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

/**
 * Gets the bearer/access token from the current user.
 */
export const BearerHeader = {
  authorization: `Bearer ${GetUser()?.access_token ?? ''}`
};

/**
 * Horrible way of converting all date properties of an object from strings to dates...
 * @param intl - Kendo IntlService / locale for conversion
 * @param o - Object to parse
 * @remarks
 * Not resilient to case or naming convention changes.
 * Dates must end with DateUtc.
 */
export const ConvertObjectDates = (o: any) => {
  Object.keys(o).forEach((k: string) => {
    if (k.endsWith('DateUtc') && o[k]) {
      o[k] = parseDate(o[k]);
    }
  });
  return o;
};

/**
 * Gets ALL entities encapsulated in a paged payload.
 * @param endpoint - the API v2 Endpoint for entity GET
 * @param requiresAuth - the endpoint requires the bearer token
 * @returns all entity objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export async function GetAllAsync<ResponseType>(
  endpoint: string,
  requiresAuth: boolean = false,
  isPortal: boolean = false
): Promise<KendoGridResult<ResponseType>> {
  let headers = requiresAuth
    ? {
        ...JsonHeaders,
        ...BearerHeader
      }
    : { ...JsonHeaders };
  return fetch(`${isPortal ? PortalApiBaseUrl : ApiBaseUrl}/${endpoint}`, {
    method: 'GET',
    headers
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response
      .json()
      .then((data) => data as KendoGridResult<ResponseType>);
  });
}

/**
 * adds new entity.
 * @param endpoint - the API v2 Endpoint for entity POST
 * @param entity - the entity to add [POST]
 * @param requiresAuth - the endpoint requires the bearer token
 * @returns the added entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export async function PostAsync<ResponseType>(
  endpoint: string,
  entity: ResponseType,
  requiresAuth: boolean = false,
  isPortal: boolean = false
): Promise<ResponseType> {
  let headers = requiresAuth
    ? {
        ...JsonHeaders,
        ...BearerHeader
      }
    : { ...JsonHeaders };
  return fetch(`${isPortal ? PortalApiBaseUrl : ApiBaseUrl}/${endpoint}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(entity)
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.text().then((data) => {
      if (data) {
        // Data is present in the response
        return JSON.parse(data);
      } else {
        // No data in the response
        return {} as ResponseType;
      }
    });
  });
}

/**
 * Gets the entity by id.
 * @param endpoint - the API v2 Endpoint for entity GET [by id]
 * @param requiresAuth - the endpoint requires the bearer token
 * @returns the entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export async function GetAsync<ResponseType>(
  endpoint: string,
  requiresAuth: boolean = false,
  isPortal: boolean = false
): Promise<ResponseType> {
  let headers = requiresAuth
    ? {
        ...JsonHeaders,
        ...BearerHeader
      }
    : { ...JsonHeaders };
  return fetch(`${isPortal ? PortalApiBaseUrl : ApiBaseUrl}/${endpoint}`, {
    method: 'GET',
    headers
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json().then((data) => data as ResponseType);
  });
}

/**
 * Updates the entity by completely replacing the object.
 * @param endpoint - the API v2 Endpoint for entity PUT
 * @param entity - the entity to update [PUT]
 * @param requiresAuth - the endpoint requires the bearer token
 * @returns the updated entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export async function PutAsync<ResponseType>(
  endpoint: string,
  entity: ResponseType,
  requiresAuth: boolean = false,
  isPortal: boolean = false
): Promise<ResponseType> {
  let headers = requiresAuth
    ? {
        ...JsonHeaders,
        ...BearerHeader
      }
    : { ...JsonHeaders };
  return fetch(`${isPortal ? PortalApiBaseUrl : ApiBaseUrl}/${endpoint}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(entity)
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json().then((data) => data as ResponseType);
  });
}

/**
 * Applies a PATCH to the entity. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param endpoint - the API v2 Endpoint for entity PATCH
 * @param entity - the entity or partial entity to update [PATCH]
 * @param requiresAuth - the endpoint requires the bearer token
 * @returns the updated entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export async function PatchAsync<ResponseType>(
  endpoint: string,
  entity: ResponseType | any,
  requiresAuth: boolean = false,
  isPortal: boolean = false
): Promise<ResponseType> {
  let headers = requiresAuth
    ? {
        ...JsonHeaders,
        ...BearerHeader
      }
    : { ...JsonHeaders };
  return fetch(`${isPortal ? PortalApiBaseUrl : ApiBaseUrl}/${endpoint}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(entity)
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json().then((data) => data as ResponseType);
  });
}

/**
 * Deletes an Entity <ResponseType> based on the entityId.
 * @param endpoint - the API V2 Endpoint for entity DELETE
 * @param requiresAuth - the endpoint requires the bearer token
 * @returns the deleted entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export async function DeleteAsync<ResponseType>(
  endpoint: string,
  requiresAuth: boolean = false,
  isPortal: boolean = false
): Promise<ResponseType> {
  let headers = requiresAuth
    ? {
        ...JsonHeaders,
        ...BearerHeader
      }
    : { ...JsonHeaders };
  return fetch(`${isPortal ? PortalApiBaseUrl : ApiBaseUrl}/${endpoint}`, {
    method: 'DELETE',
    headers
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json().then((data) => data as ResponseType);
  });
}

/**
 * Applied a Upload file to the media
 * @param endpoint - the API V2 Endpoint for upload file
 * @param file - file upload
 * @returns the added entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export async function PostFileAsync<ResponseType>(
  endpoint: string,
  file: File | Blob
): Promise<ResponseType> {
  let formData = new FormData();
  formData.append('file', file as Blob);
  return fetch(`${PortalApiBaseUrl}/${endpoint}`, {
    method: 'POST',
    headers: {
      ...BearerHeader
    },
    body: formData
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response
        .json()
        .then((data) => ConvertObjectDates(data) as ResponseType);
    })
    .catch((error) => {
      console.log(error);
      throw new Error(error);
    });
}
