import SelectionItem from '../types/selection-item';

export const Operations: SelectionItem[] = [
  {
    id: '1',
    name: 'navigateToWorldMap',
    description: 'navigate to the world map',
    colorClass: 'secondary'
  },
  {
    id: '2',
    name: 'navigateToZone',
    description: 'navigate to the zone',
    colorClass: 'secondary',
    list: 'zone'
  },
  {
    id: '3',
    name: 'openTask',
    description: 'open the task',
    colorClass: 'secondary',
    list: 'task'
  },
  {
    id: '4',
    name: 'showTask',
    description: 'show the task',
    colorClass: 'secondary',
    list: 'task'
  },
  {
    id: '5',
    name: 'hideTask',
    description: 'hide the task',
    colorClass: 'secondary',
    list: 'task'
  },
  {
    id: '6',
    name: 'showArea',
    description: 'show the area',
    colorClass: 'secondary',
    list: 'area'
  },
  {
    id: '7',
    name: 'hideArea',
    description: 'hide the area',
    colorClass: 'secondary',
    list: 'area'
  },
  {
    id: '8',
    name: 'startTimer',
    description: 'start the timer',
    colorClass: 'secondary',
    list: 'timer'
  },
  {
    id: '9',
    name: 'stopTimer',
    description: 'stop the timer',
    colorClass: 'secondary',
    list: 'timer'
  },
  {
    id: '10',
    name: 'pauseTimer',
    description: 'pause the timer',
    colorClass: 'secondary',
    list: 'timer'
  },
  {
    id: '11',
    name: 'resumeTimer',
    description: 'resume the timer',
    colorClass: 'secondary',
    list: 'timer'
  },
  {
    id: '12',
    name: 'giveItem',
    description: 'give the item',
    colorClass: 'secondary',
    list: 'item',
    showQuantity: true
  },
  {
    id: '13',
    name: 'removeItem',
    description: 'remove the item',
    colorClass: 'secondary',
    list: 'item',
    showQuantity: true
  },
  {
    id: '14',
    name: 'giveTitle',
    description: 'give the title',
    colorClass: 'secondary',
    list: 'title'
  },
  {
    id: '15',
    name: 'removeTitle',
    description: 'remove the title',
    colorClass: 'secondary',
    list: 'title'
  },
  {
    id: '16',
    name: 'giveScore',
    description: 'give score',
    colorClass: 'secondary',
    showQuantity: true
  },
  {
    id: '17',
    name: 'removeScore',
    description: 'remove score',
    colorClass: 'secondary',
    showQuantity: true
  }
];

export default Operations;
