import { Outlet, useParams } from 'react-router-dom';
import { GameDocumentProvider } from '../contexts/game-document';
import React, { Suspense } from 'react';
import { PlayerStateProvider } from '../contexts/player';
import { TeamStateProvider } from '../contexts/team';
import { GameStateProvider } from '../contexts/game';
import { ChatProvider } from '../contexts/chat';
import { NotificationProvider } from '../contexts/notification';
import { Overlay } from '../features/in-game/overlay';
import { OverlayProvider } from '../contexts/overlay';
import { NotificationAlert } from '../components/notification';
import { AchievementProvider } from '../contexts/achievement';
import NotificationDialog from '../components/dialog/notification-dialog';
import Theme from '../components/theme';
import { QueueCommandProvider } from '../contexts/queue-handler';
import { PlayerAssessmentProvider } from '../contexts/player-assessment';
import { DisplayLanguageProvider } from '../contexts/display-languages';
import { Spinner } from '../components/spinner';

/**
 * The layout for in-game screens.
 */
export const InGameLayout = () => {
  const { gameCode } = useParams();
  return (
    <Suspense fallback={<Spinner />}>
      <QueueCommandProvider gameCode={gameCode!}>
        <GameDocumentProvider gameCode={gameCode!}>
          <GameStateProvider gameCode={gameCode!}>
            <TeamStateProvider gameCode={gameCode!}>
              <PlayerStateProvider gameCode={gameCode!}>
                <ChatProvider>
                  <NotificationProvider>
                    <AchievementProvider>
                      <PlayerAssessmentProvider>
                        <DisplayLanguageProvider>
                          <Theme>
                            <div className={'layout layout--in-game'}>
                              <div className={'layout__container'}>
                                <OverlayProvider>
                                  <div className={'game__canvas'}>
                                    <Outlet />
                                  </div>
                                  <Overlay />
                                </OverlayProvider>
                                <NotificationAlert />
                                <NotificationDialog />
                              </div>
                            </div>
                          </Theme>
                        </DisplayLanguageProvider>
                      </PlayerAssessmentProvider>
                    </AchievementProvider>
                  </NotificationProvider>
                </ChatProvider>
              </PlayerStateProvider>
            </TeamStateProvider>
          </GameStateProvider>
        </GameDocumentProvider>
      </QueueCommandProvider>
    </Suspense>
  );
};

export default InGameLayout;
