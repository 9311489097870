import { TeamContextState } from '../../contexts/team';
import { TeamStateFull } from '../../types/state';
import cloneDeep from 'lodash.clonedeep';

export const UpdateTeamContext = (
  state: TeamContextState,
  updated: TeamStateFull,
  isDirty: boolean = true
) => {
  return cloneDeep({
    ...state,
    isDirty,
    teamState: cloneDeep({ ...updated })
  });
};
