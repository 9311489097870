import React, { useContext, useEffect, useRef, useState } from 'react';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import { BingMaps, OSM } from 'ol/source';
import { useInterval, useTimeout } from 'usehooks-ts';
import { OverlayContext } from '../../contexts/overlay';
import { fromLonLat, Projection } from 'ol/proj';
import { GameDocumentContext } from '../../contexts/game-document';
import { View } from 'ol';
import { getCenter } from 'ol/extent';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { GetResourceValue } from '../../utils/game-document/resources';
import {
  GetGameLogo,
  GetGameName,
  UpdateGameStateAsync
} from '../../utils/game-engine/base';
import { PlayerContext } from '../../contexts/player';
import { GameContext } from '../../contexts/game';
import { GameStatus } from '../../types/state';
import cloneDeep from 'lodash.clonedeep';
import { BingMapsKey } from '../../constants/bing-key';
import {
  playerHub,
  postPlayerState,
  teamHub
} from '../../services/syncronize-state';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { isNetworkOnline } from '../../utils/indicator';
import ListenerManager from '../../utils/listener-manager';
import { UpdatePlayerContext } from '../../utils/player-state';
import { TeamContext } from '../../contexts/team';
import { UpdateTeamContext } from '../../utils/team-state';
import GameStatusOverlay from '../../components/game-status-overlay';
import AchievementDialog from '../../components/dialog/achievement-dialog';
import { Button } from '@progress/kendo-react-buttons';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';

export const GameLobbyPage = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [gameState, setGameState] = useContext(GameContext);
  const [player, setPlayer] = useContext(PlayerContext);
  const [, setTeam] = useContext(TeamContext);
  const [playerHubConnection, setPlayerHubConnection] =
    useState<HubConnection>();
  const [teamHubConnection, setTeamHubConnection] = useState<HubConnection>();
  const [, setOverlay] = useContext(OverlayContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const [displayRequiredTeamModal, setDisplayRequiredTeamModal] =
    useState<boolean>(true);

  let worldMap = gameDocument.gameDocument?.assets?.maps?.find(
    (m) => m.id === gameDocument.gameDocument?.rules.worldMap.mapAssId
  );
  const [map] = useState<Map>(
    new Map({
      interactions: [],
      controls: [],
      layers: [
        new TileLayer({
          source:
            worldMap?.type === 'openStreetMap'
              ? new OSM()
              : new BingMaps({
                  key: BingMapsKey,
                  imagerySet: 'Aerial'
                })
        })
      ]
    })
  );

  const extent = [0, 0, 1200, 680];
  const projection = new Projection({
    code: 'xkcd-image',
    units: 'pixels',
    extent: extent
  });
  const [mapIllustration] = useState<Map>(
    new Map({
      layers: [],
      target: 'map',
      controls: [],
      view: new View({
        projection: projection,
        center: getCenter(extent),
        zoom: 1,
        maxZoom: 8,
        resolution: 1
      })
    })
  );

  const mapElement = useRef<HTMLDivElement>(null);

  const playerConnection = async () => {
    try {
      const hub = await playerHub(player.playerState?.code!);
      const connectionHub = new HubConnectionBuilder()
        .withUrl(`${hub.endpointUrl}`, {
          accessTokenFactory: () => hub.accessToken!
        })
        .withAutomaticReconnect()
        .build();
      if (connectionHub.state) {
        setPlayerHubConnection(connectionHub);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const teamConnection = async () => {
    try {
      const hub = await teamHub(player.playerState?.teamCode!);
      const connectionHub = new HubConnectionBuilder()
        .withUrl(`${hub.endpointUrl}`, {
          accessTokenFactory: () => hub.accessToken!
        })
        .withAutomaticReconnect()
        .build();

      setTeamHubConnection(connectionHub);
    } catch (err) {
      console.error(err);
    }
  };

  const syncPlayerState = async () => {
    try {
      await postPlayerState(
        gameState.gameCode!,
        player.playerState?.code!,
        JSON.stringify(player)
      );
    } catch (err) {
      console.error(err);
    }
  };

  useInterval(() => {
    // get the current game status
    let newGameState = { ...cloneDeep(gameState) };
    let currentGameStatus = newGameState.gameState?.status;
    // only process if current game status is not pre-game or staring
    if (currentGameStatus === 'PreGame' || currentGameStatus === 'Starting') {
      let now = new Date();
      let gameStartDate = new Date(gameDocument.gameDocument?.gameStartDate!);
      // if curent datetime has passed the game event start datetime
      if (now > gameStartDate) {
        let status: GameStatus = 'Running';
        setGameState((prev) =>
          UpdateGameStateAsync(prev, cloneDeep({ ...prev.gameState!, status }))
        );
      }
    }
  }, 60000);

  const PlayerAddRemoveTeamHandler = async (message: any) => {
    const playerResponseTeamCode = message.id?.replace(
      '00000000-0000-0000-0000-000000000000',
      ''
    );
    setPlayer((prev) =>
      UpdatePlayerContext(prev, {
        ...prev.playerState!,
        teamCode: playerResponseTeamCode
      })
    );
  };

  const TeamEnabledChatHandler = async (message: any) => {
    setTeam((prev) =>
      UpdateTeamContext(prev, {
        ...prev.teamState!,
        isChatDisabled: false
      })
    );
  };

  const TeamDisabledChatHandler = async (message: any) => {
    setTeam((prev) =>
      UpdateTeamContext(prev, {
        ...prev.teamState!,
        isChatDisabled: true
      })
    );
  };

  const playerConnectionListener = async (connection: HubConnection) => {
    connection.on(`${player.playerState?.code}`, async (message) => {
      const listener = new ListenerManager({
        PlayerAddToTeam: PlayerAddRemoveTeamHandler,
        PlayerRemoveFromTeam: PlayerAddRemoveTeamHandler
      });
      listener.listen(message);
    });
    connection.onclose((e) => {
      console.error('error');
    });

    connection.start();
  };

  const teamConnectionListener = async (connection: HubConnection) => {
    connection.on(`${player.playerState?.teamCode}`, async (message) => {
      const listener = new ListenerManager({
        TeamDisabledChat: TeamDisabledChatHandler,
        TeamEnabledChat: TeamEnabledChatHandler
      });
      listener.listen(message);
    });

    connection.onclose((e) => {
      console.error('error');
    });

    connection.start();
  };

  useEffect(() => {
    if (teamHubConnection) {
      teamConnectionListener(teamHubConnection);
    }
    return () => {
      teamHubConnection?.stop();
    };
  }, [teamHubConnection]);

  useEffect(() => {
    if (playerHubConnection) {
      playerConnectionListener(playerHubConnection);
    }
    return () => {
      playerHubConnection?.stop();
    };
  }, [playerHubConnection]);

  useEffect(() => {
    syncPlayerState();
    if (isNetworkOnline()) {
      playerConnection();
    }
  }, []);

  useEffect(() => {
    if (
      player.playerState?.teamCode &&
      player.playerState?.teamCode !== '' &&
      isNetworkOnline()
    ) {
      teamConnection();
    }
  }, [player.playerState?.teamCode]);

  useEffect(() => {
    // get the world map

    // check the world map type
    if (worldMap?.type === 'illustration') {
      const mapImage = GetResourceValue(
        gameDocument.gameDocument!,
        worldMap?.imageResId!,
        ''
      );

      //get resolution of the image
      const img = new Image();
      img.src = mapImage;
      img.onload = () => {
        //Map Illustration
        const extent = [0, 0, img?.width!, img?.height!];
        const projection = new Projection({
          code: 'xkcd-image',
          units: 'pixels',
          extent: extent
        });

        mapIllustration.setView(
          new View({
            projection: projection,
            center: getCenter(extent),
            extent,
            zoom: 1
          })
        );

        const imageLayer = new ImageLayer({
          source: new Static({
            attributions: '',
            url: mapImage,
            projection: projection,
            imageExtent: extent
          })
        });
        let layers = mapIllustration.getAllLayers();
        layers[0] = imageLayer;
        mapIllustration.setTarget(mapElement.current!);
        mapIllustration.setLayers(layers);
      };
    } else {
      if (map && worldMap) {
        map.setTarget(mapElement.current!);
        map.getView().setZoom(worldMap.zoomLevel ?? 17);
        map
          .getView()
          .setCenter(
            fromLonLat([
              worldMap.longitude ?? 152.9043310817896,
              worldMap.latitude ?? -27.681927396540104
            ])
          );
      }
    }
  }, [map, mapElement]);

  const openOverlay = () => {
    setOverlay((prevState) => ({
      ...prevState,
      drawerIsOpen: true,
      gameName: GetGameName(gameDocument, player),
      gameLogo: GetGameLogo(gameDocument, player)
    }));
  };

  useTimeout(openOverlay, 3000);

  const toggleDisplayTeamModal = () =>
    setDisplayRequiredTeamModal((prev) => !prev);

  return (
    <>
      <div className={'canvas__map'} ref={mapElement} />
      <GameStatusOverlay />
      {gameDocument.gameDocument?.settings.preGame.isPreGameTeamRequired &&
        !player.playerState?.teamCode &&
        displayRequiredTeamModal && (
          <AchievementDialog
            priorityQueueNumber={2}
            onMaskClick={toggleDisplayTeamModal}>
            <div className={'d-flex justify-content-center flex-column h-100'}>
              <span>
                {generateTitleById(
                  '0c38ed7b-a7b0-4091-bc8b-af7271652d2e',
                  gameDocument,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'game'
                ) ||
                  'This is a team game. You must be part of a team to join the game'}
                .{' '}
                <u
                  role="button"
                  onClick={() => {
                    setOverlay((prevState) => ({
                      ...prevState,
                      activeTab: 'teams',
                      drawerIsOpen: true
                    }));
                    toggleDisplayTeamModal();
                  }}>
                  {generateTitleById(
                    'fb5a1ca5-069e-4dda-aa26-a414100396da',
                    gameDocument,
                    displayLanguageContext.displayLanguageSelected.resources!,
                    'game'
                  ) || 'Select a team'}
                </u>
                .
              </span>
            </div>
            <div>
              <Button
                fillMode={'outline'}
                themeColor={'light'}
                onClick={toggleDisplayTeamModal}>
                {generateTitleById(
                  '48bc820b-40e8-4624-8d46-b68e16013be6',
                  gameDocument,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'game'
                ) || 'Close'}
              </Button>
            </div>
          </AchievementDialog>
        )}
    </>
  );
};
