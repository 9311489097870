import { AreaEntity } from '../../../types/entities';
import { merge } from 'lodash';
import { PlayerStateFull } from '../../../types/state/player';

/**
 * Get all areas from PlayerStateFull.
 * @param player - The player as PlayerStateFull
 * @constructor
 */
export const GetAreas = (player: PlayerStateFull | undefined) => {
  return player?.areas ?? [];
};

/**
 * Get area by areaid.
 * @param player - The player as PlayerStateFull
 * @param areaId - Id of the area
 * @constructor
 */
export const GetAreaById = (player: PlayerStateFull, areaId: string) => {
  let areas = GetAreas(player);
  return areas.find((z) => z.id === areaId);
};

export const AddAreaAsync = async (
  player: PlayerStateFull,
  area: AreaEntity
) => {
  let areas = player.areas ?? [];
  areas.push(area);
  return MergeArea(player, areas);
};

export const AddArea = (player: PlayerStateFull, area: AreaEntity) => {
  let areas = player.areas ?? [];
  areas.push(area);
  return MergeArea(player, areas);
};

export const MergeArea = (
  player: PlayerStateFull,
  areas: Array<AreaEntity>
) => {
  return merge(player, { areas });
};
