import { useContext, useEffect } from 'react';

import Map from '../../components/map/map';
import { PlayerContext } from '../../contexts/player';
import { OverlayContext } from '../../contexts/overlay';
import { GameDocumentContext } from '../../contexts/game-document';
import { GetGameLogo, GetGameName } from '../../utils/game-engine/base';

export const GameMapPage = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [player] = useContext(PlayerContext);
  const [, setOverlay] = useContext(OverlayContext);

  useEffect(() => {
    setOverlay((prevState) => ({
      ...prevState,
      drawerIsOpen: false,
      gameName: GetGameName(gameDocument, player),
      gameLogo: GetGameLogo(gameDocument, player)
    }));
  }, []);

  return <Map />;
};
