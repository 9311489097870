import React, { useEffect, useState } from 'react';
import { GalleryState } from './gallery';

export interface MediaPlayerProps {
  item: GalleryState;
  type: 'video' | 'audio';
  className?: string;
}

const MediaPlayer = ({ item, type, className = '' }: MediaPlayerProps) => {
  const [mediaUrl, setMediaUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getMediaFile = async () => {
      try {
        const fetchMedia = await fetch(item.url as string);
        const mediaBlob = await fetchMedia.blob();
        const myFile = new File([mediaBlob], item.name as string, {
          type: type === 'video' ? 'video/mp4' : 'audio/mp3'
        });
        const url = URL.createObjectURL(myFile);
        setMediaUrl(url);
      } catch (error) {
        console.log('There is an error when processing the media:', error);
      }
    };

    getMediaFile();
  }, [item.url, item.name, type]);

  if (!mediaUrl) {
    return null; // Return null while waiting for media URL
  }

  return type === 'video' ? (
    <video controls playsInline className={`${className} video h-100 w-100`}>
      <source src={mediaUrl} type={'video/mp4'} />
    </video>
  ) : (
    <audio src={mediaUrl} controls>
      <source src={mediaUrl} type={'audio/mp3'} />
    </audio>
  );
};

export default MediaPlayer;
