import React from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerSelectEvent
} from '@progress/kendo-react-layout';

import Item from './item';
import { PlayerContext } from '../../../../../contexts/player';
import { OverlayContext } from '../../../../../contexts/overlay';
import { GameDocumentContext } from '../../../../../contexts/game-document';
import { DisplayLanguageContext } from '../../../../../contexts/display-languages';
import { generateTitleById } from '../../../../../utils/game-document/display-languages';

const DrawerMobileContainer: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [overlay, setOverlay] = React.useContext(OverlayContext);
  const [player] = React.useContext(PlayerContext);
  const [state] = React.useContext(GameDocumentContext);
  const [displayLanguageContext] = React.useContext(DisplayLanguageContext);

  const toggleOverlay = () => {
    setOverlay((prevState) => ({
      ...prevState,
      drawerMobileIsOpen: !prevState.drawerMobileIsOpen
    }));
  };

  const isInLobby = player.playerState?.status === 'waiting';
  const preGameSetting = state.gameDocument?.settings.preGame;
  const inGameSetting = state.gameDocument?.settings.inGame;

  // tab setting
  const showInfoTab = isInLobby ? true : inGameSetting?.showInGameInfo;
  const showTeamsTab = isInLobby
    ? preGameSetting?.showPreGameTeams
    : inGameSetting?.showInGameTeams;
  const showInventoryTab = isInLobby
    ? preGameSetting?.showPreGameInventory
    : inGameSetting?.showInGameInventory;
  const showRolesTab = isInLobby
    ? preGameSetting?.showPreGameRoles
    : inGameSetting?.showInGameRoles;
  const showChatTab = isInLobby
    ? preGameSetting?.showPreGameChat
    : inGameSetting?.showInGameChat;
  const showScoreboardTab = isInLobby
    ? preGameSetting?.showPreGameScoreboard
    : inGameSetting?.showInGameScoreboard;

  const items = [
    {
      text: 'profile'
    },
    ...(showInfoTab
      ? [
          {
            text:
              generateTitleById(
                '670d9f08-d761-4c71-883c-4265648d6a73',
                state,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Info',
            icon: 'info',
            tab: 'info',
            selected: false
          }
        ]
      : []),
    ...(showTeamsTab
      ? [
          {
            text:
              generateTitleById(
                '4be208b6-39fc-434d-bae7-e39f6d1ed332',
                state,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Teams',
            icon: 'group',
            tab: 'teams',
            selected: false
          }
        ]
      : []),
    ...(showInventoryTab
      ? [
          {
            text:
              generateTitleById(
                'f7a7c135-91e2-433f-a7ed-0b62ff39aba0',
                state,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Inventory',
            icon: 'trip',
            tab: 'inventory',
            selected: false
          }
        ]
      : []),
    ...(showRolesTab
      ? [
          {
            text:
              generateTitleById(
                '832f9ead-d125-4418-aebb-ebdff00a3a8d',
                state,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Titles',
            icon: 'settings_accessibility',
            tab: 'roles',
            selected: false
          }
        ]
      : []),
    ...(showChatTab
      ? [
          {
            text:
              generateTitleById(
                '57f9b876-0dcf-4e59-b240-2dc88aa9390e',
                state,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Chat',
            icon: 'forum',
            tab: 'chat',
            selected: false
          }
        ]
      : []),
    ...(showScoreboardTab
      ? [
          {
            text:
              generateTitleById(
                'e698200c-73e4-41e3-b4b1-fa4b38f4638c',
                state,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Scoreboard',
            icon: 'leaderboard',
            tab: 'scoreboard',
            selected: false
          }
        ]
      : [])
  ];

  const [selectedItem, setSelectedItem] = React.useState(
    items.findIndex((x) => x.selected === true)
  );

  const onSelect = (e: DrawerSelectEvent) => {
    setSelectedItem(e.itemIndex);
    setOverlay((prevState) => ({
      ...prevState,
      drawerIsOpen: true,
      activeTab: e.itemTarget.props.tab
    }));
  };

  return (
    <Drawer
      width={300}
      item={Item}
      animation={{
        duration: 400
      }}
      mode={'overlay'}
      position={'end'}
      onSelect={onSelect}
      className={'bg-dark'}
      onOverlayClick={toggleOverlay}
      items={items.map((item, idx) => ({
        ...item,
        selected: idx === selectedItem
      }))}
      expanded={overlay.drawerMobileIsOpen}>
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  );
};

export default DrawerMobileContainer;
