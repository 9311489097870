import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { GameState } from '../types/state';
import { uuid } from '../types/common-helper';
import {
  GetGameStateHub,
  postBroadcastGameStateAsync
} from '../services/games';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { HubResponse } from '../types/responses/hub-response';
import { isEmpty, pick } from 'lodash';
import { UpdateGameStateAsync } from '../utils/game-engine/base';
import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';
import { PlayerContextState } from './player';

const GameContext = React.createContext<
  [
    initialState: GameContextState,
    setState: React.Dispatch<React.SetStateAction<GameContextState>>,
    HubConnection | undefined
  ]
>([{ isLoaded: false, isDirty: false }, () => {}, undefined]);

interface GameStateProviderProps {
  gameCode: string;
  children: React.ReactNode;
}

const GameStateProvider = ({ gameCode, ...props }: GameStateProviderProps) => {
  const [player] = useLocalStorage<PlayerContextState>(`${gameCode}-player`, {
    gameCode: gameCode,
    isLoaded: false,
    isDirty: false
  });
  const [state, setState] = useLocalStorage<GameContextState>(
    `${gameCode}-state`,
    {
      gameCode: gameCode,
      gameState: BuildNewGameState(gameCode),
      isLoaded: false,
      isDirty: false,
      activityState: {
        hasGame: true,
        hasAssessment: true,
        hasFeedback: false,
        eventName: ''
      }
    }
  );

  const [GameStateConnection, setGameStateConnection] =
    useState<HubConnection>();
  const [GameHubResponse, setGameHubResponse] = useState<HubResponse>();

  const gameStateConnection = async () => {
    try {
      const hub = await GetGameStateHub(gameCode, player.playerState?.code!);
      setGameHubResponse(hub);
      if (hub.activityState) {
        const hasGame: boolean = hub.activityState.hasGame;
        setState((prev) => ({
          ...prev,
          // set game state to undefined if does not has game
          ...(!hasGame && { gameState: undefined }),
          activityState: hub.activityState
        }));

        // create connection if has game
        if (hasGame) {
          const connectionHub = new HubConnectionBuilder()
            .withUrl(`${hub.endpointUrl}`, {
              accessTokenFactory: () => hub.accessToken!
            })
            .withAutomaticReconnect()
            .build();
          setGameStateConnection(connectionHub);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updatePlayerSubmittedStatus = (): void => {
    setState((prev) =>
      UpdateGameStateAsync(
        prev,
        cloneDeep({
          ...prev.gameState!,
          hasNewSubmittedAnswer: true
        })
      )
    );
  };

  const updateGameState = (gameState: GameState): void => {
    // when cloning the game state object from server, have to ignore 2 fields as it is only used locally
    const acceptFields = ['isPreloadCompleted', 'totalAssets'];

    setState((prev) => {
      // pick acceptFields with value, then merge into gamestate from server
      const newGameStatus = pick(prev.gameState, acceptFields);

      const updatedGameState: GameState = merge(
        { ...prev.gameState, ...gameState },
        newGameStatus
      );
      return UpdateGameStateAsync(prev, updatedGameState);
    });
  };

  const GameFacilitatorContentHandler = async (contentCode: string) => {
    setState((prev) => ({
      ...prev,
      contentId: contentCode
    }));
  };

  const GameConnectionListener = async (connection: HubConnection) => {
    connection.on(`StateUpdated`, (gameState: GameState) => {
      if (!isEmpty(gameState)) {
        try {
          updateGameState(gameState);
        } catch (error) {
          console.error(error);
        }
      }
    });

    connection.on(`FacilitatorContentAdded`, (contentCode: string) => {
      if (!isEmpty(contentCode)) {
        try {
          GameFacilitatorContentHandler(contentCode);
        } catch (error) {
          console.error(error);
        }
      }
    });

    connection.on(`AnswerSubmitted`, (playerCode: string) => {
      if (!isEmpty(playerCode)) {
        try {
          updatePlayerSubmittedStatus();
        } catch (error) {
          console.error(error);
        }
      }
    });

    // The connection was closed
    connection.onclose((e) => {
      //console.error(e);
    });

    await connection
      .start()
      .then(() => {
        postBroadcastGameStateAsync(gameCode);
      })
      .catch(() => {
        // do nothing
      });
  };

  useEffect(() => {
    if (!state.isLoaded) {
      setState((state) => ({
        ...state,
        isLoaded: true,
        gameState: BuildNewGameState(gameCode),
        activityState: {
          hasGame: true,
          hasAssessment: true,
          hasFeedback: false,
          eventName: state.activityState?.eventName || ''
        }
      }));
    }
  }, []);

  useEffect(() => {
    if (!gameCode) return;
    if (GameHubResponse) return;
    gameStateConnection();
  }, [gameCode, GameHubResponse]);

  useEffect(() => {
    if (GameStateConnection) {
      GameConnectionListener(GameStateConnection);
    }
    return () => {
      GameStateConnection?.stop();
    };
  }, [GameStateConnection]);

  return (
    <GameContext.Provider value={[state, setState, GameStateConnection]}>
      {props.children}
    </GameContext.Provider>
  );
};

interface activityState {
  hasGame: boolean;
  hasAssessment: boolean;
  hasFeedback: boolean;
  eventName: string;
}
export interface GameContextState {
  gameCode?: string;
  gameState?: GameState;
  activityState?: activityState;
  isLoaded?: boolean;
  isDirty: boolean;
  contentId?: string;
}

export const BuildNewGameState = (gameCode: string): GameState => {
  return {
    id: uuid(),
    name: '',
    code: gameCode,
    status: 'PreGame',
    isPreloadCompleted: false,
    hasNewSubmittedAnswer: false,
    totalAssets: 0,
    pausedStatus: undefined,
    elapsedTime: [],
    isResume: undefined,
    startedUtc: undefined,
    finishedUtc: undefined,
    teams: [],
    players: []
  };
};

export { GameContext, GameStateProvider };
