import { apiFetcher } from '../utils/fetchers';
import useSWRImmutable from 'swr/immutable';
import { useGame } from './use-game';

/**
 * Retrieves the assessment document for a given game code using SWRImmutable.
 *
 * @param {string} gameCode - The unique identifier of the game.
 * @returns {Object} - An object containing the assessment document data, loading flag, and error flag.
 *   - data: The assessment document data retrieved from the API.
 *   - isLoading: A boolean flag indicating whether the data is currently being loaded.
 *   - isError: A boolean flag indicating whether an error occurred while retrieving the data.
 */
export const useAssessmentDocument = (gameCode: string) => {
  // load the game so we can check that it actually has an assessment document.
  const game = useGame(gameCode);

  // load the game document. immutable as the document shouldn't change.
  const { data, isLoading, error } = useSWRImmutable(
    () =>
      game.data?.assessmentDocumentFileName
        ? `assessments/${gameCode}/document`
        : null,
    apiFetcher,
    { suspense: true }
  );

  if (error) console.log(error);

  return {
    data,
    isLoading,
    isError: error
  };
};
