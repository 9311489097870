/**
 * Check network status is online
 */
export const isNetworkOnline = () => {
  if (navigator.onLine) {
    return true;
  } else {
    return false;
  }
};
