import { useContext, useEffect, useState } from 'react';
import {
  FacilitatorChat,
  FasilitatorChatProps
} from '../../../components/facilitator/chat';
import { GameDocumentContext } from '../../../contexts/game-document';
import { ChatState } from '../../../types/state/websocket/chat-state';
import { useAuth } from 'react-oidc-context';
import { GetChatHistoryBetweeenPlayersAsync } from '../../../services/players';
import { PlayerContactResponse } from '../../../types/responses/player-contacts-reponse';

export const FacilitatorBetweenPlayerChat = ({
  onSendMessage = () => {},
  ...props
}: FasilitatorChatProps) => {
  const auth = useAuth();
  const [gameDocument] = useContext(GameDocumentContext);
  const [messages, setMessages] = useState<ChatState[]>([]);
  const [incomingMessage] = useState<ChatState>();

  const getChatHistoryAsync = () => {
    let newChat: ChatState[] = [];
    GetChatHistoryBetweeenPlayersAsync(
      gameDocument?.gameCode!,
      (props.item as PlayerContactResponse).playerCode1!,
      (props.item as PlayerContactResponse).playerCode2!
    ).then((response) => {
      response?.forEach((chat) => {
        newChat.push({
          author: {
            id:
              chat.playerCode === '00000000-0000-0000-0000-000000000000'
                ? auth?.user?.profile.sub!
                : chat.playerCode,
            name: chat.playerName,
            avatarUrl: chat.playerAvatar
          },
          timestamp: new Date(chat.sentDateUtc),
          group: '',
          text: chat.message
        });
      });
      setMessages(newChat);
    });
  };

  useEffect(() => {
    getChatHistoryAsync();
  }, [props.item]);

  useEffect(() => {
    let newMessages: ChatState[] = [...messages];
    if (incomingMessage) {
      newMessages.push(incomingMessage);
      setMessages(newMessages);
    }
  }, [incomingMessage]);

  return (
    <FacilitatorChat
      item={props.item}
      messages={messages}
      onSendMessage={onSendMessage}
    />
  );
};
