import { useContext, useEffect, useMemo, useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Col, Container, Row } from 'react-bootstrap';
import { AssessmentOutcome } from './assessment-outcome';
import { AssessmentEntity } from '../../types/state/assessment/assessment';
import { Action } from '../../types/state/assessment/assessment-players-answer-state';
import { PlayerAssessmentContext } from '../../contexts/player-assessment';
import { getOutcomeAverageScore } from '../../utils/assessment-state/player-answer-assessment';
import { Button } from '@progress/kendo-react-buttons';
import { PlayerContext } from '../../contexts/player';
import { DataChart, Outcome } from '../../types/assessment';
import { ChartContainer } from '../chart/radar-chart';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';

interface AssessmentActionProps {
  assessment?: AssessmentEntity;
  outcomes: Outcome[];
  actions?: Action[];
  showAddAction?: boolean;
  showAverageRate?: boolean;
  showDescription?: boolean;
  placeholder?: string;
  className?: string;
  showPageTitle?: boolean;
  pageTitle?: string;
  pageDescription?: string;
  holdActionFromParticipant?: boolean;
  onAddAction?: (outcomeId: string, action: string) => void;
  setIsShouldShowDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AssessmentAction = ({
  setIsShouldShowDialog,
  ...props
}: AssessmentActionProps) => {
  const [action, setAction] = useState<string>('');
  const [dataIndex, setIndex] = useState<number>(0);
  const [playerAssessment] = useContext(PlayerAssessmentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const { playerState } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [player] = useContext(PlayerContext);
  const handleKeyPress = (
    outcomeId: string,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      if (props.onAddAction) {
        props.onAddAction(outcomeId, action);
        setAction('');
      }
    }
  };

  const renderChart = () => {
    const dataChart: DataChart[] = props?.outcomes.map((item) => {
      const scores = item.responses
        .map((item) => item.score)
        .reduce(
          (accumulator: number, currentValue: number) =>
            accumulator + currentValue,
          0
        );
      const resultScore = scores / item.responses.length;
      const resultData: DataChart = {
        name: item.title,
        score: resultScore ? Math.round(resultScore) : 0
      };
      return resultData;
    });

    return (
      <div
        className={'py-4'}
        style={{
          width: '100%',
          height: '400px',
          overflowX: 'auto'
        }}>
        <ChartContainer data={dataChart} />
      </div>
    );
  };

  const orderedOutcomes = useMemo(() => {
    const averagedOutcome = props?.assessment?.assessmentOutcomes?.map(
      (outcome) => {
        const averageScore =
          playerAssessment?.assessmentStates &&
          playerAssessment?.assessmentStates?.length > 0
            ? getOutcomeAverageScore(
                playerAssessment?.assessmentStates![0]!,
                outcome.id
              )
            : 0;
        return { ...outcome, average: averageScore };
      }
    );

    const order = playerState.assessmentDocument
      ? playerState.assessmentDocument?.outcomeOrder
      : 'ascending';

    return averagedOutcome?.sort((a, b) =>
      order === 'ascending' ? a.average - b.average : b.average - a.average
    );
  }, [
    props?.assessment?.assessmentOutcomes,
    playerState.assessmentDocument,
    playerAssessment?.assessmentStates
  ]);

  useEffect(() => {
    const assessmentState = playerAssessment.assessmentStates;
    const myCode = player.assessmentState?.code;
    if (assessmentState && setIsShouldShowDialog) {
      const filled = assessmentState[0].actions.filter(
        (action) => action.proposedBy === myCode
      ).length;
      setIsShouldShowDialog(!Boolean(filled));
    }
  }, [
    playerAssessment.assessmentStates,
    player.assessmentState?.code,
    setIsShouldShowDialog
  ]);

  return (
    <Container className={'d-flex flex-column assessment-content'}>
      {props.showPageTitle && (
        <Row className={'mb-3'}>
          <Col className={'d-flex flex-column align-items-center text-center'}>
            {renderChart()}
            <span className={'fw-bold'}>{props?.pageDescription}</span>
          </Col>
        </Row>
      )}

      {orderedOutcomes?.map((item, index) => (
        <AssessmentOutcome
          key={item.id}
          showAverageRate={props.showAverageRate}
          showDescription={props.showDescription}
          assessmentOutcome={item}
          assessment={props.assessment!}
          averageRate={item.average}>
          {props.showAddAction && (
            <Row>
              <Col className={'d-flex flex-wrap'}>
                <div className={'d-flex w-100'}>
                  <Input
                    onFocus={(v) => {
                      setIndex(index);
                      setAction('');
                    }}
                    value={index === dataIndex ? action : ''}
                    placeholder={props.placeholder}
                    className={'mr-1 flex-grow-1'}
                    onChange={(e) =>
                      setAction(e.target.value?.toString() ?? '')
                    }
                    onKeyDown={(e) => handleKeyPress(item.id, e)}
                  />
                  <Button
                    themeColor={'primary'}
                    onClick={() => {
                      if (props.onAddAction) {
                        props.onAddAction(item.id, action);
                        setAction('');
                      }
                    }}>
                    {generateTitleById(
                      '68fe4076-4770-4624-8b14-fda0e1797cda',
                      playerState,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'assessment'
                    ) || 'Add'}
                  </Button>
                </div>
              </Col>
            </Row>
          )}

          <Row className={'mt-1'}>
            <Col>
              {props?.actions
                ?.filter(
                  (x) =>
                    x.outcomeId === item.id &&
                    (props?.holdActionFromParticipant
                      ? player?.assessmentState?.code === x.proposedBy!
                      : true)
                )
                ?.map((action) => {
                  return (
                    <div
                      className="card mt-2 success_bg-opacity-20"
                      key={action.id}>
                      <div className="card-body text-dark fw-bold">
                        {action?.name}
                      </div>
                    </div>
                  );
                })}
            </Col>
          </Row>
        </AssessmentOutcome>
      ))}
    </Container>
  );
};
