import redaxios from 'redaxios';
import { API_URL } from '../constants';
import { GroupResponse } from '../types/responses/group-response';
import { GetAllAsync, GetAsync, PostAsync } from './api';
import { HttpStatus, getUrl } from './http-client';
import { HubResponse } from '../types/responses/hub-response';
import { AlghoritmTask } from '../types/alghoritm-task';
import { KendoGridResult } from '../types/KendoGridResult';

interface Response {
  isError: boolean;
  statusCode: HttpStatus;
  message: string;
  result: string;
}

export interface GroupPostChat {
  playerCode: string;
  message: string;
  sentDateUtc: string;
}

export interface GroupChatHistory {
  id: number;
  teamId: string;
  playerCode: string;
  playerName: string;
  playerAvatar: string;
  sentDateUtc: string;
  message: string;
}

export const addNewGroups = async (gameCode: string, name: string) => {
  try {
    return await PostAsync<any>(`games/${gameCode}/groups`, {
      name: name
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export async function getGroupList(code: string) {
  try {
    const response = await GetAllAsync<GroupResponse>(`games/${code}/groups`);
    return response?.data;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const err = error as any;
    const errorRes: Response = {
      isError: true,
      statusCode: err?.status,
      message: err?.data,
      result: err?.data
    };
    return errorRes;
  }
}

export async function getGroupSelectedAsync(
  gameCode: string,
  groupCode: string
) {
  let response = await GetAsync<GroupResponse>(
    `games/${gameCode}/groups/${groupCode}`
  );
  return response;
}

/**
 * @description Handle team join group
 * @param groupCode
 * @param teamCode
 * @param gameCode
 *
 */

export async function joinGroup(
  groupCode: string,
  teamCode: string,
  gameCode: string
) {
  try {
    const response = PostAsync<any>(
      `games/${gameCode}/groups/${groupCode}/teams/${teamCode}`,
      null
    );
    return response;
  } catch (error) {
    throw error;
  }
}

/**
 * @description Handle team to leave group
 * @param groupCode
 * @param teamCode
 * @param gameCode
 *
 */

export async function leaveGroup(
  groupCode: string,
  teamCode: string,
  gameCode: string
) {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/groups/${groupCode}/teams/${teamCode}`
    );
    const response = await redaxios.delete<any>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
}

/**
 * @description Handle team to remove group
 * @param groupCode
 * @param gameCode
 *
 */

export async function removeGroup(groupCode: string, gameCode: string) {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${gameCode}/groups/${groupCode}`);
    const response = await redaxios.delete<any>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
}

/**
 *
 * @param groupCode // groupCode you want to join chat
 */
export const GetGroupChatHub = async (groupCode: string) => {
  try {
    const url = getUrl(`${API_URL.hubsV2}/groupchat/${groupCode}`);
    const response = await redaxios.post<HubResponse>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Get group chat history
 * @param groupCode // Group Code
 */
export const GetGroupChatHistory = async (
  groupCode: string,
  gameCode: string
) => {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/groups/${groupCode}/chats`
    );
    const response = await redaxios.get<KendoGridResult<GroupChatHistory>>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Send Group Chat
 * @param gameCode
 * @param teamCode
 * @param userId
 * @param name
 * @param message
 * @returns
 */
export function sendFacilitatorGroupChat(
  gameCode?: string,
  groupCode?: string,
  userId?: number,
  name?: string,
  message?: string
) {
  const response = PostAsync(
    `games/${gameCode}/groups/${groupCode}/facilitator/chats`,
    {
      facilitatorUserProfileId: userId,
      facilitatorName: name,
      message: message
    }
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

/**
 *
 * @param groupCode // groupCode you want to join chat
 */
export const PostGroupChat = async (
  groupCode: string,
  gameCode: string,
  message: GroupPostChat
) => {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/groups/${groupCode}/chats`
    );
    const response = await redaxios.post(url, message);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

export function navigateToWorldMap(gameCode: string, groupCode: string) {
  try {
    const response = PostAsync(
      `games/${gameCode}/groups/${groupCode}/locate?zoneId=world-map`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function navigateToZone(
  gameCode: string,
  groupCode: string,
  zoneId: string
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/groups/${groupCode}/locate?zoneId=${zoneId}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function openTask(gameCode: string, groupCode: string, taskId: string) {
  try {
    const response = PostAsync(
      `games/${gameCode}/groups/${groupCode}/tasks/${taskId}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function showTask(
  gameCode: string,
  groupCode: string,
  taskId: string,
  visible: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/groups/${groupCode}/tasks/${taskId}/?visible=${visible}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function randomTask(
  gameCode: string,
  groupCode: string,
  tasks: AlghoritmTask
) {
  try {
    let response = await PostAsync<string[]>(
      `games/${gameCode}/groups/${groupCode}/tasks/random`,
      tasks as never
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function showArea(
  gameCode: string,
  groupCode: string,
  areaId: string,
  visible: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/groups/${groupCode}/areas/${areaId}/?visible=${visible}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function startTimer(
  gameCode: string,
  groupCode: string,
  timerId: string,
  start: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/groups/${groupCode}/timers/${timerId}/start/?start=${start}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function pauseTimer(
  gameCode: string,
  groupCode: string,
  timerId: string,
  pause: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/groups/${groupCode}/timers/${timerId}/pause/?pause=${pause}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function giveItem(
  gameCode: string,
  groupCode: string,
  itemId: string,
  quantity: number,
  add: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/groups/${groupCode}/items/${itemId}/?quantity=${quantity}&add=${add}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function giveScore(
  gameCode: string,
  groupCode: string,
  score: number,
  add: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/groups/${groupCode}/score/?score=${score}&add=${add}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function giveTitle(
  gameCode: string,
  groupCode: string,
  titleId: string,
  add: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/groups/${groupCode}/titles/${titleId}/?add=${add}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}
