import { Button } from '@progress/kendo-react-buttons';
import {
  Window,
  WindowActionsBar,
  WindowActionsEvent
} from '@progress/kendo-react-dialogs';
import { Error } from '@progress/kendo-react-labels';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from '@progress/kendo-react-layout';
import {
  ExternalDropZone,
  Upload,
  UploadOnAddEvent
} from '@progress/kendo-react-upload';
import React, { ComponentType, useContext, useEffect, useState } from 'react';
import { Resource as ResourceEntity } from '../../types/game-document/entities/resource';
import { Media } from '../../types/media';

interface Resource {
  id?: string;
  name?: string;
  type?: string;
  value?: any;
  thumbnailValue?: any;
  size?: number;
}

interface UploadedImage {
  blobUrl: string;
  mimeType: string;
  fileName: string;
  resource?: Resource;
  size: number;
}

interface UploadImageProps {
  title?: string;
  onSubmit?: (uploadedImage: UploadedImage) => void;
  onClose?: (event: WindowActionsEvent) => void;
  onSubmitMultipleFiles?: (files: UploadedImage[]) => void;
  toggleDialog: Function;
  acceptedExtension?: string;
  imageUrl?: string;
  isMultipleFiles?: boolean;
  hideUploadTab?: boolean;
  hideUrlTab?: boolean;
  hideResourcesTab?: boolean;
  imageSource?: string;
}

const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const ResourceWindow: ComponentType<UploadImageProps> = ({
  title = 'Upload image',
  toggleDialog,
  onSubmit = () => {},
  onClose = () => {},
  acceptedExtension = 'image/*',
  imageUrl,
  hideUploadTab = false,
  hideUrlTab = false,
  hideResourcesTab = false,
  isMultipleFiles = false,
  onSubmitMultipleFiles = () => {},
  ...props
}: UploadImageProps) => {
  const uploadRef = React.createRef<Upload>();
  const [files, setFiles] = useState<Media[]>([]);
  const [resources, setResources] = useState<ResourceEntity[]>([]);
  const [lastImageUrl, setLastImageUrl] = useState<string>('');
  const [showDefaultPhoto, setShowDefaultPhoto] = useState<boolean>(true);
  const [selected, setSelected] = useState<number>(0);
  const [lastImageId, setLastImageId] = useState<string>('');
  const [isError, setIsError] = useState<string>('');
  const [isLargeImageSize, setIsLargeImageSize] = useState<boolean>(false);
  const [maxImageSize, setMaxImageSize] = useState<string>('');
  const [isValidFileExtension, setIsValidFileExtension] =
    useState<boolean>(false);
  const [isImageValid, setIsImageValid] = useState<boolean>(false);
  const [isFileEmpty, setIsFileEmpty] = useState<boolean>(true);
  const [lastUploadedImage, setLastUploadedImage] = useState<UploadedImage>(
    {} as UploadedImage
  );

  const onUploadClick = () => {
    if (uploadRef && uploadRef.current && uploadRef.current.actionElement) {
      uploadRef.current.actionElement.click();
    }
  };

  const onTabSelect = (event: TabStripSelectEventArguments) => {
    setSelected(event.selected);
  };

  // including restrictions check (such as max size, extension, if applied - depends where the source is from)
  const onDropFiles = (event: UploadOnAddEvent) => {
    let uploadedFiles: Media[] = [];

    event.newState.forEach((file) => {
      if (file && file.getRawFile) {
        // remove URL and resource if exists
        setLastImageUrl('');
        setLastImageId('');
        setLastUploadedImage({} as UploadedImage);

        // check max size
        let maxImageSizeInByte = 512000;

        if (maxImageSizeInByte && file.size && file.size > maxImageSizeInByte) {
          setIsLargeImageSize(true);
          setMaxImageSize(formatBytes(maxImageSizeInByte, 0));
        } else {
          if (isLargeImageSize) {
            setIsLargeImageSize(false);
          }
        }

        // check file extension(s), set isValidFileExtension to false if file extension is matching with the related extension list
        let imageExtensionList = ['.png', '.jpg', '.jpeg', '.gif', '.mp4'];

        if (imageExtensionList) {
          let extensionFound = imageExtensionList.find(
            (validFileExtension) =>
              validFileExtension === file.extension?.toLowerCase()
          );
          setIsValidFileExtension(extensionFound ? false : true);
        }

        setIsFileEmpty(false);
        uploadedFiles.push({ file: file.getRawFile() });
      }
    });

    setFiles(uploadedFiles);
  };

  const loadFile = () => {
    if (files.length > 0) {
      const blobUrl = URL.createObjectURL(files[files.length - 1].file);
      const fileName = files[files.length - 1].file.name;
      const mimeType = files[files.length - 1].file.type;
      const size = files[files.length - 1].file.size;

      setLastImageUrl(blobUrl);
      setIsImageValid(true);
      setLastUploadedImage({
        ...lastUploadedImage,
        blobUrl,
        mimeType,
        fileName,
        size
      });
    }
  };

  const onMediaChange = (url: string) => {
    if (url === '') {
      setLastUploadedImage({} as UploadedImage);
    }

    if (url) {
      const blobUrl = url;

      fetch(url, {
        method: 'HEAD'
      })
        .then((res) => {
          const fileName = url.split('/').pop()!;
          const mimeType = res.headers.get('Content-Type')!;

          setLastUploadedImage({
            ...lastUploadedImage,
            blobUrl,
            mimeType,
            fileName
          });
          setIsFileEmpty(false);

          // remove upload and resource if exists
          setFiles([]);
          setLastImageId('');
        })
        .catch((err) => {
          setLastImageUrl('');
          setIsError('Image has been blocked by CORS policy');
          setIsImageValid(false);
        });
    }
    setLastImageUrl(url);
    setIsImageValid(true);
  };

  const convertFilesToMedia = (): UploadedImage[] => {
    let allFiles: UploadedImage[] = [];
    files.forEach((file) => {
      const blobUrl = URL.createObjectURL(file.file);
      const fileName = file.file.name;
      const mimeType = file.file.type;
      const size = file.file.size;

      allFiles.push({
        blobUrl: blobUrl,
        mimeType: mimeType,
        fileName: fileName,
        size: size
      });
    });
    return allFiles;
  };

  const handleClose = (file: Media) => {
    if (files) {
      const fls: Media[] = [...files];

      const index = fls.indexOf(file);
      if (index > -1) {
        // only splice array when item is found
        fls.splice(index, 1); // 2nd parameter means remove one item only
      }
      setFiles(fls);
      setLastUploadedImage({} as UploadedImage);
      setIsLargeImageSize(false);
      setIsValidFileExtension(false);
      setIsFileEmpty(true);

      // remove URL and resource if exists
      setLastImageUrl('');
      setLastImageId('');
    }
  };

  const hideVideoControls = () => {
    const vids = document.querySelectorAll('video');
    vids.forEach((video) => {
      video.controls = false;
    });
  };

  useEffect(
    () => {
      loadFile();

      if (files.length > 0) {
        setShowDefaultPhoto(false);
      } else {
        setShowDefaultPhoto(true);
      }
    },
    // eslint-disable-next-line
    [files]
  );

  useEffect(() => {
    setLastImageUrl('');
    hideVideoControls();
  }, []);

  return (
    <>
      <Window
        modal={true}
        className={'shadow'}
        initialWidth={640}
        initialHeight={400}
        minimizeButton={() => null}
        maximizeButton={() => null}
        onClose={() => toggleDialog()}
        title={title}
        {...props}>
        <TabStrip selected={selected} onSelect={onTabSelect} animation={false}>
          <TabStripTab title="Upload" disabled={hideUploadTab}>
            <>
              {showDefaultPhoto && !acceptedExtension.includes('image') && (
                <div
                  className={'round-card-upload rounded-full h-7 w-7 mx-auto'}>
                  <div className={'mask'}>
                    <Button
                      className={'border border-1 text-white p-0'}
                      fillMode={'flat'}
                      rounded={'full'}
                      size={'small'}
                    />
                  </div>
                </div>
              )}

              {!isMultipleFiles &&
                !showDefaultPhoto &&
                acceptedExtension.includes('image') && (
                  <div className={'text-center mb-2'}>
                    <img
                      src={lastImageUrl}
                      alt={''}
                      className={'img-fluid rounded'}
                    />
                  </div>
                )}

              <ExternalDropZone
                className={`mb-2 text-center${lastImageUrl ? '' : ' h-100'}`}
                uploadRef={uploadRef}
                customNote={<>It should image or video allowed</>}
                customHint={
                  <>
                    Drop files to upload
                    <br />
                    or{' '}
                    <a className={'text-primary'} onClick={onUploadClick}>
                      browse
                    </a>
                  </>
                }
              />

              <Upload
                className={'d-none'}
                ref={uploadRef}
                batch={false}
                multiple={isMultipleFiles}
                withCredentials={false}
                restrictions={{
                  allowedExtensions: [acceptedExtension]
                }}
                accept={acceptedExtension}
                onAdd={onDropFiles}
                showFileList={false}
                saveUrl={(files) => {
                  return new Promise((resolve) => {
                    resolve({ uid: files[0].uid });
                  });
                }}
              />

              {isValidFileExtension ? (
                <Error>
                  Invalid File Type: Please upload images or video only.
                </Error>
              ) : (
                isLargeImageSize && (
                  <Error>
                    Please reduce your file size below {maxImageSize}
                  </Error>
                )
              )}

              <ul className={'list-group w-100'}>
                {files &&
                  files.map((file, index) => {
                    return (
                      <li
                        className={
                          'list-group-item rounded-0 d-flex justify-content-between align-items-center p-0'
                        }
                        key={index}>
                        <span className={'material-symbols-outlined ms-2 me-2'}>
                          add_photo_alternate
                        </span>
                        <span className={'text-break w-100'}>
                          {file.file.name} {file.file.size}
                        </span>
                        <Button
                          themeColor={'error'}
                          fillMode={'flat'}
                          onClick={() => handleClose(file)}>
                          <span className={'material-symbols-outlined'}>
                            delete
                          </span>
                        </Button>
                      </li>
                    );
                  })}
              </ul>
            </>
          </TabStripTab>
        </TabStrip>

        <WindowActionsBar>
          <Button themeColor={'secondary'} onClick={() => toggleDialog()}>
            Cancel
          </Button>
          <Button
            themeColor={'primary'}
            onClick={() => {
              if (isMultipleFiles) {
                onSubmitMultipleFiles(convertFilesToMedia());
              } else {
                onSubmit(lastUploadedImage);
              }
            }}
            disabled={isValidFileExtension || isFileEmpty}>
            Save
          </Button>
        </WindowActionsBar>
      </Window>
    </>
  );
};
