import React from 'react';

const Popup = (props: any) => {
  return (
    <div className={'popup-box'}>
      <div className={props.className ?? 'box d-flex flex-column gap-3'}>
        <div
          className={'d-flex align-items-center justify-content-between mb-3'}>
          <h3 className={'fw-bold fs-2 m-0'}>{props.title}</h3>
          <span
            className={'material-symbols-outlined fw-bold'}
            onClick={props.handleClose}
            role={'button'}>
            close
          </span>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Popup;
