import { ApiBaseUrl } from "./api";

/**
 * Gets ALL List Avatar.
 * @param endpoint - the API v2 Endpoint for get list avatar
 * @returns all entity objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
 export async function GetListAvatar(endpoint: string) {
    return fetch(`${ApiBaseUrl}/${endpoint}`, {
      method: 'GET'
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json().then((data) => data);
    });
  }