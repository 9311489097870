import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FeedbackTitle from '../../features/feedback-header-title';
import { FeedbackDocumentContext } from '../../contexts/feedback-document';
import {
  FeedbackDetailOptions,
  FeedbackDetails,
  FeedbackPayload
} from '../../types/feedback-document';
import { AnswerType } from '../../types/game-document/entities/task-content';
import { AnswerFeedback } from '../../components/feedback-questionnaire';
import cloneDeep from 'lodash.clonedeep';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { FeedbackContentHtml } from './feedback-content-html';
import { FeedbackCompleted } from './feedback-completed';
import {
  GetFeedbackResourceValue,
  UpdateFeedbackDetailsState
} from '../../utils/feedback-document';
import { updateFeedbacksDocumentAsync } from '../../services/feedbacks';
import {
  UploadSingleBlobAsync,
  UploadSingleFileAsync
} from '../../utils/storage';
import { GameDocumentContext } from '../../contexts/game-document';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageFeedbackContext } from '../../contexts/display-languages-feedback';

export const FeedbackForm = () => {
  const [state, setState] = useContext(FeedbackDocumentContext);
  const [gameDocument] = useContext(GameDocumentContext);
  const [feedbackDisplayLangContext] = useContext(
    DisplayLanguageFeedbackContext
  );

  const [feedbackDetails, setFeedbackDetails] = useState<FeedbackDetails[]>(
    state.feedbackDocument?.feedbackDetails ?? []
  );
  const [formIndex, setFormIndex] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);

  const onChangeAnswerInput = (
    value: string | number | File,
    index: number,
    size?: number
  ) => {
    if (typeof value === 'string' || typeof value === 'number') {
      if (typeof value === 'string' && value.startsWith('blob:')) {
        setIsLoading(true);
        UploadSingleBlobAsync(gameDocument?.gameCode!, value)
          .then((res) => {
            if (res !== undefined) {
              updateAnswerValue(res.url!, index, size);
            }
          })
          .catch((error) => console.error(error))
          .finally(() => {
            setIsLoading(false);
            setIsFileUploaded(true);
          });
      } else {
        updateAnswerValue(value as string | number, index, size);
      }
    } else {
      setIsLoading(true);

      UploadSingleFileAsync(gameDocument?.gameCode!, value as File)
        .then((res) => {
          if (res !== undefined) {
            updateAnswerValue(res.url!, index, size);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
          setIsFileUploaded(true);
        });
    }
  };

  const updateAnswerValue = (
    answer: string | number | File,
    index: number,
    size?: number
  ) => {
    let selectFeedbackDetails = cloneDeep(feedbackDetails[index]);
    selectFeedbackDetails.answers = [answer];
    if (size && size > 0) {
      selectFeedbackDetails.size = size;
    }

    let newFeedbacksDetails = cloneDeep(feedbackDetails);
    newFeedbacksDetails[index] = selectFeedbackDetails;
    setFeedbackDetails(newFeedbacksDetails);

    //state update feedback details
    setState(UpdateFeedbackDetailsState(state, newFeedbacksDetails));
  };

  const onChangeCheckbox = (index: number, value: boolean) => {
    let selectFeedbackDetails = cloneDeep(feedbackDetails[formIndex]);
    const newAnswer = selectFeedbackDetails?.feedbackDetailOptions![index]!;
    if (selectFeedbackDetails.answers === undefined) {
      selectFeedbackDetails.answers = [];
    }

    if (value && newAnswer) {
      const isExistData = selectFeedbackDetails.answers.find(
        (item) => item.id === newAnswer.id
      );
      if (!isExistData) {
        selectFeedbackDetails.answers.push(newAnswer as any);
      }
    } else {
      selectFeedbackDetails.answers?.splice(index, 1);
    }
    let newFeedbacksDetails = cloneDeep(feedbackDetails);
    newFeedbacksDetails[formIndex] = selectFeedbackDetails;
    setFeedbackDetails(newFeedbacksDetails);
    //state update feedback details
    setState(UpdateFeedbackDetailsState(state, newFeedbacksDetails));
  };

  const onChangeRadio = (index: number) => {
    let selectFeedbackDetails = cloneDeep(feedbackDetails[formIndex]);
    const newAnswer = selectFeedbackDetails?.feedbackDetailOptions![index]!;
    if (selectFeedbackDetails.answers === undefined) {
      selectFeedbackDetails.answers = [];
    }
    if (index >= 0 && newAnswer) {
      selectFeedbackDetails.answers = [newAnswer];
      selectFeedbackDetails.feedbackDetailOptions =
        selectFeedbackDetails.feedbackDetailOptions?.map((item, indexItem) => {
          if (indexItem === index) {
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        });
    }

    let newFeedbacksDetails = cloneDeep(feedbackDetails);
    newFeedbacksDetails[formIndex] = selectFeedbackDetails;
    setFeedbackDetails(newFeedbacksDetails);
    //state update feedback details
    setState(UpdateFeedbackDetailsState(state, newFeedbacksDetails));
  };

  const handleContinueForm = () => {
    setFormIndex(formIndex + 1);
  };

  const handlePreviousForm = () => {
    setFormIndex(formIndex - 1);
  };

  const onSubmit = async () => {
    const newFeedbacksDetails = state.feedbackDocument?.feedbackDetails!.map(
      (item: any) => {
        let newItem = cloneDeep(item);
        newItem.answers = JSON.stringify(newItem.answers ?? '[]');
        return newItem;
      }
    );

    const feedbackPayload: FeedbackPayload = {
      id: state.feedbackDocument?.id ?? 0,
      name: state.feedbackDocument?.name,
      title: state.feedbackDocument?.title,
      organisationId: state.feedbackDocument?.organisationId,
      language: state.feedbackDocument?.language,
      htmlContent: state.feedbackDocument?.htmlContentResId,
      description: state.feedbackDocument?.description,
      version: state.feedbackDocument?.version,
      createdDateUtc: state.feedbackDocument?.createdDateUtc,
      createdById: state.feedbackDocument?.createdByUserProfileId ?? 0,
      createdBy: state.feedbackDocument?.createdBy,
      modifiedDateUtc: state.feedbackDocument?.modifiedDateUtc,
      modifiedById: state.feedbackDocument?.modifiedByUserProfileId,
      modifiedBy: state.feedbackDocument?.modifiedBy,
      playerName: state.feedbackDocument?.playerName ?? '',
      playerCode: state.feedbackDocument?.playerCode ?? null,
      feedbackDetails: newFeedbacksDetails,
      games: state.feedbackDocument?.games ?? [],
      isDeleted: state.feedbackDocument?.isDeleted ?? false
    };

    await updateFeedbacksDocumentAsync(state.feedbackCode!, feedbackPayload!);

    handleContinueForm();
  };

  useEffect(() => {
    if (
      feedbackDetails.length === 0 &&
      state.feedbackDocument?.feedbackDetails
    ) {
      setFeedbackDetails(state.feedbackDocument?.feedbackDetails);
    }
  }, [formIndex]);
  return (
    <div className={'d-flex flex-column feedback-form'}>
      <div
        className={
          'overlay-header-feedback layout-feedback d-flex justify-content-center'
        }>
        <div className={'w-full mt-2 mb-3'}>
          <div
            className={
              'd-flex header-left-menu justify-content-between align-items-center mt-2'
            }>
            <FeedbackTitle
              title={
                GetFeedbackResourceValue(
                  state.feedbackDocument!,
                  state.feedbackDocument?.titleResId!
                ) ?? ''
              }
              imageUrl={state.feedbackDocument?.logoUrl ?? ''}
            />
            <div className="d-flex float-right mr-2">
              <span className={'fw-bold'}>
                {state.feedbackDocument?.playerName!}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={'layout-feedback layout-feedback-form mt-3'}>
        <Container className={'layout-feedback-form'}>
          <Row>
            <Col>
              {formIndex >= 0 &&
                formIndex <= feedbackDetails.length - 1 &&
                feedbackDetails &&
                feedbackDetails
                  .filter((i, ind) => ind === formIndex)
                  .map((item: any, index: number) => {
                    const question = GetFeedbackResourceValue(
                      state?.feedbackDocument!,
                      item?.questionResId!,
                      state?.selectedLanguageId
                    );
                    return (
                      <div key={index} className={'layout-feedback-form__main'}>
                        <div>
                          <div
                            className={'d-flex justify-content-between mt-3'}>
                            <label className={'feedback-title text-dark'}>
                              {generateTitleById(
                                '5463fd5e-5a92-4e61-9cd7-162fb43668fe',
                                state,
                                feedbackDisplayLangContext
                                  .displayLanguageSelected.resources!,
                                'feedback'
                              ) || 'Question'}{' '}
                              {formIndex + 1}
                            </label>

                            <label className={'feedback-title text-dark'}>
                              {formIndex + 1}/{feedbackDetails.length}
                            </label>
                          </div>
                          <hr />
                          <div
                            className={'d-flex justify-content-center w-full'}>
                            <div
                              className={
                                'feedback-question-container w-full d-flex justify-content-center'
                              }>
                              <label
                                className={
                                  'feedback-question text-dark mt-3 mb-3'
                                }>
                                {question !== '' ? question : item?.question}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className={'d-flex justify-content-center w-full'}>
                          <div className={'feedback-answer-container'}>
                            <AnswerFeedback
                              answerType={item.answerType! as AnswerType}
                              formId={item.id}
                              index={formIndex}
                              checkboxOption={
                                item.feedbackDetailOptions as FeedbackDetailOptions[]
                              }
                              answerInput={
                                item.answerType === 'radio' ||
                                item.answerType === 'checkbox'
                                  ? item.answers
                                  : item?.answers
                                  ? item?.answers![0]
                                  : ''
                              }
                              onChangeAnswerInput={onChangeAnswerInput}
                              onCheckboxChange={onChangeCheckbox}
                              onChangeOptionIndex={onChangeRadio}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              {formIndex === -1 && state.feedbackDocument?.htmlContentResId && (
                <FeedbackContentHtml
                  contentHtml={GetFeedbackResourceValue(
                    state?.feedbackDocument!,
                    state.feedbackDocument?.htmlContentResId!,
                    ''
                  )}
                />
              )}
              {formIndex > feedbackDetails.length - 1 && (
                <FeedbackCompleted
                  contentHtmlOutro={GetFeedbackResourceValue(
                    state?.feedbackDocument!,
                    state.feedbackDocument?.htmlOutroContentResId!,
                    ''
                  )}
                />
              )}
            </Col>
          </Row>
          <hr />
          <Row className={'mt-3 mb-3 d-flex justify-content-between'}>
            <Col className={'d-flex align-items-center'}>
              {formIndex <= feedbackDetails.length - 1 && formIndex > -1 && (
                <div className={'d-flex align-items-center'}>
                  <span
                    className={
                      'material-symbols-outlined button-skip-continue cursor-pointer'
                    }
                    onClick={handlePreviousForm}>
                    arrow_back_ios
                  </span>
                  <span
                    className={'mr-3 button-skip-continue cursor-pointer'}
                    onClick={handlePreviousForm}>
                    {generateTitleById(
                      '714c5007-ce05-477e-8dc2-8f9502eb4c45',
                      state,
                      feedbackDisplayLangContext.displayLanguageSelected
                        .resources!,
                      'feedback'
                    ) || 'Previous'}
                  </span>
                </div>
              )}
            </Col>
            <Col
              className={'d-flex justify-content-end align-items-center col'}>
              {formIndex <= feedbackDetails.length - 1 && formIndex < 0 && (
                <Button
                  id={'feedback-form-button-continue'}
                  className={'mr-2'}
                  themeColor={'success'}
                  fillMode={formIndex > -1 ? 'flat' : 'solid'}
                  onClick={handleContinueForm}>
                  <span>
                    {generateTitleById(
                      '84bccd9b-ed5d-4daa-b564-1aeb96c0929a',
                      state,
                      feedbackDisplayLangContext.displayLanguageSelected
                        .resources!,
                      'feedback'
                    ) || 'Continue'}
                  </span>
                </Button>
              )}

              {formIndex <= feedbackDetails.length - 1 && formIndex > -1 && (
                <span
                  className={'mr-3 button-skip-continue cursor-pointer'}
                  onClick={handleContinueForm}>
                  {generateTitleById(
                    '6101dd6b-0e30-4518-b9d9-37a4173c1031',
                    state,
                    feedbackDisplayLangContext.displayLanguageSelected
                      .resources!,
                    'feedback'
                  ) || 'Next'}
                </span>
              )}

              {formIndex > -1 && formIndex <= feedbackDetails.length - 1 && (
                <Button
                  id={'feedback-form-button-submit-answer'}
                  themeColor={'success'}
                  onClick={() =>
                    // formIndex === feedbackDetails.length - 1
                    //   ? onSubmit()
                    //   : handleContinueForm()
                    onSubmit()
                  }>
                  <span>
                    {isLoading
                      ? generateTitleById(
                          '71a2dffb-7ae7-422f-8423-93b0d4f15a4a',
                          state,
                          feedbackDisplayLangContext.displayLanguageSelected
                            .resources!,
                          'feedback'
                        ) || 'Uploading...'
                      : `${
                          generateTitleById(
                            '9a5c9d53-6aae-4be4-b556-213fee60eeb7',
                            state,
                            feedbackDisplayLangContext.displayLanguageSelected
                              .resources!,
                            'feedback'
                          ) || 'Submit Answer'
                        } `}
                  </span>
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
