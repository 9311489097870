import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useContext,
  useMemo
} from 'react';
import { GameDocumentContext } from '../../contexts/game-document';
import { GetResourceValue } from '../../utils/game-document/resources';
import { Col } from 'react-bootstrap';
import { PlayerContext } from '../../contexts/player';

/**
 * Renders the game header image.
 * @remarks Relies on game document context.
 */
export const GameHeader = (
  props: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [player] = useContext(PlayerContext);
  const headerResource = useMemo(
    () =>
      GetResourceValue(
        gameDocument.gameDocument!,
        gameDocument.gameDocument!.overview?.headerResId!,
        player?.playerState?.language?.name! ?? ''
      ),
    [gameDocument.gameCode]
  );
  return (
    <Col
      className={'w-100 h-12'}
      style={{
        backgroundImage: `url('${headerResource}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
      }}
      {...props}
    />
  );
};
