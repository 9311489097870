import React, { useContext, useEffect } from 'react';
import { GameDocument } from '../types/game-document';
import { useLocalStorage } from 'usehooks-ts';
import { Theme } from '../types/theme';
import * as serviceWorker from '../serviceWorker';
import { GameContext } from './game';
import { AssessmentEntity } from '../types/state/assessment/assessment';
import { getDisplayLanguagesAsync } from '../services/display-language';
import { DisplayLanguageEntity } from '../types/game-document/entities/display-languages';
import { useGameDocument } from '../hooks/use-game-document';
import { useGameTheme } from '../hooks/use-game-theme';

const GameDocumentContext = React.createContext<
  [
    initialState: GameDocumentState,
    setState: React.Dispatch<React.SetStateAction<GameDocumentState>>
  ]
>([{ isLoaded: false, isDirty: false }, () => {}]);

interface GameDocumentProviderProps {
  gameCode: string;
  children: React.ReactNode;
}

const GameDocumentProvider = ({
  gameCode,
  ...props
}: GameDocumentProviderProps) => {
  const gameDocument = useGameDocument(gameCode);
  const gameTheme = useGameTheme(gameCode);
  const [state, setState] = useLocalStorage<GameDocumentState>(
    `${gameCode}-game`,
    {
      gameCode: gameCode,
      isLoaded: false,
      isDirty: false
    }
  );
  const [game, setGame] = useContext(GameContext);

  const loadServiceWorker = async () => {
    // ask notification permission
    await serviceWorker.askPermission();
    await new Promise((r) => {
      serviceWorker.register({ onSuccess: (e) => console.log(e) });
    });
  };

  useEffect(() => {
    loadServiceWorker();
    if (!state.isLoaded && game.activityState?.hasGame) {
      const loadState = async () => {
        let displayLanguages: DisplayLanguageEntity;
        setState((state) => ({
          ...state,
          isLoaded: true,
          gameDocument: gameDocument.data,
          theme: gameTheme.data
        }));
      };
      loadState().catch(console.error);
    }
  }, []);

  return (
    <GameDocumentContext.Provider value={[state, setState]}>
      {props.children}
    </GameDocumentContext.Provider>
  );
};

export interface GameDocumentState {
  gameCode?: string;
  gameDocument?: GameDocument;
  assessmentDocument?: AssessmentEntity;
  theme?: Theme;
  isLoaded?: boolean;
  isDirty: boolean;
}

export { GameDocumentContext, GameDocumentProvider };
