import { Input } from '@progress/kendo-react-inputs';
import { Col, Container, Row } from 'react-bootstrap';
import { AssessmentOutcome } from './assessment-outcome';
import { AssessmentEntity } from '../../types/state/assessment/assessment';
import Counter from '../input/counter';
import { Action } from '../../types/state/assessment/assessment-players-answer-state';
import { useContext, useEffect, useState } from 'react';
import { AssessmentRatingDetailRequest } from '../../services/assessments';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';

interface AssessmentVoteProps {
  assessment?: AssessmentEntity;
  actions?: Action[];
  showAddAction?: boolean;
  showAverageRate?: boolean;
  showDescription?: boolean;
  placeholder?: string;
  className?: string;
  disableAddVote?: boolean;
  actionVotes?: AssessmentRatingDetailRequest[];
  onRatingChange?: (actionId: string, score: number) => void;
  setIsShouldShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AssessmentVote = ({ ...props }: AssessmentVoteProps) => {
  const [disableAddVote, setDisableAddVote] = useState<boolean>(false);
  const [totalVotes, setTotalVotes] = useState<number>(10);
  const { playerState: assessmentDocument } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [displayLanguageContext, setDisplayLanguageContext] = useContext(
    DisplayLanguageContext
  );
  useEffect(() => {
    props.setIsShouldShowDialog(totalVotes === 10);
  }, [totalVotes]);

  return (
    <Container className={'d-flex flex-column assessment-content'}>
      <Row className={'mb-3'}>
        <Col className={'d-flex flex-column align-items-center text-center'}>
          <span className={'fw-bold'}>
            {generateTitleById(
              '8359bac9-81c4-4973-9368-6d0e36b6ed46',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) ||
              `You have 10 votes to allocate. The more you like an idea, the more votes you should give it.`}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <small className={'text-dark fw-bold'}>{`${
            generateTitleById(
              '7186690f-ff05-4378-99a3-dc0d6d9edba4',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Votes remaining'
          }: ${totalVotes}`}</small>
        </Col>
      </Row>
      <Row className={'mt-1'}>
        <Col>
          {props?.assessment?.assessmentOutcomes?.map((item, index) => (
            <AssessmentOutcome
              key={index}
              showAverageRate={props.showAverageRate}
              showDescription={props.showDescription}
              assessmentOutcome={item}
              assessment={props.assessment!}>
              {props.showAddAction && (
                <Row>
                  <Col>
                    <Input placeholder={props.placeholder}></Input>
                  </Col>
                </Row>
              )}

              <Row className={'mt-1'}>
                <Col>
                  {props?.actions
                    ?.filter((x) => x.outcomeId === item.id)
                    .map((action, indexAction) => {
                      return (
                        <div
                          key={indexAction}
                          className="card mt-2 success_bg-opacity-20 vote">
                          <div className="card-body text-dark fw-bold d-flex justify-content-between ">
                            <span>{action?.name}</span>
                            <Counter
                              disableAdd={
                                props.disableAddVote || disableAddVote
                              }
                              maxValue={10}
                              onChange={(score) => {
                                const maxVote = 10;
                                let totalVote = 0;
                                let newVotes = props.actionVotes
                                  ? [...props.actionVotes]
                                  : [];

                                let voteIndex =
                                  props.actionVotes?.findIndex(
                                    (x) => x.actionId === action.id
                                  ) ?? -1;

                                if (voteIndex > -1) {
                                  newVotes[voteIndex].vote = score;
                                } else {
                                  newVotes.push({
                                    actionId: action.id!,
                                    vote: score
                                  });
                                }

                                newVotes?.forEach((action) => {
                                  totalVote = totalVote + (action?.vote ?? 0);
                                });

                                setTotalVotes(maxVote - totalVote);

                                if (
                                  props.onRatingChange &&
                                  totalVote <= maxVote
                                ) {
                                  props.onRatingChange(action.id!, score);

                                  //disable button add vote when totalVote >= maxVote
                                  if (totalVote < maxVote) {
                                    setDisableAddVote(false);
                                  } else {
                                    setDisableAddVote(true);
                                  }
                                } else {
                                  setDisableAddVote(true);
                                }
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}

                  {props?.actions?.filter((x) => x.outcomeId === item.id)
                    .length === 0 && (
                    <small className={'text-muted'}>
                      {generateTitleById(
                        'bcc1f317-7c84-44bf-ab15-c24600091d47',
                        assessmentDocument,
                        displayLanguageContext.displayLanguageSelected
                          .resources!,
                        'assessment'
                      ) || 'No actions found'}
                    </small>
                  )}
                </Col>
              </Row>
            </AssessmentOutcome>
          ))}
        </Col>
      </Row>
    </Container>
  );
};
