import React, { useContext, useEffect, useState } from 'react';
import { DisplayLanguagePublished } from '../types/game-document/entities/display-languages';
import { GameDocumentContext } from './game-document';
import { GetDisplayLanguageJsonAsync } from '../utils/game-document/display-languages';
import { DisplayLangResources } from '../constants/display-languages';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from './assessment-document';
import { FeedbackDocumentContext } from './feedback-document';
import { PlayerContext } from './player';

interface initialDisplayLanguageState {
  displayLanguageOriginal: DisplayLanguagePublished;
  displayLanguageSelected: DisplayLanguagePublished;
  isLoaded: boolean;
}
const initialDisplayLanguage: initialDisplayLanguageState = {
  displayLanguageOriginal: {
    id: 0,
    languageCode: '',
    languageName: '',
    description: '',
    resources: DisplayLangResources,
    resourcePacks: []
  },
  displayLanguageSelected: {
    id: 0,
    languageCode: '',
    languageName: '',
    description: '',
    resources: DisplayLangResources,
    resourcePacks: []
  },
  isLoaded: false
};

const DisplayLanguageFeedbackContext = React.createContext<
  [
    initialState: initialDisplayLanguageState,
    setState: React.Dispatch<React.SetStateAction<initialDisplayLanguageState>>
  ]
>([initialDisplayLanguage, () => {}]);

const DisplayLanguageFeedbackProvider = (props: any) => {
  const [state, setState] = useState<initialDisplayLanguageState>(
    initialDisplayLanguage
  );
  const [feedbackDocument] = useContext(FeedbackDocumentContext);

  useEffect(() => {
    const getUrl = feedbackDocument?.feedbackDocument?.displayLanguageUrl;
    if (!state.isLoaded) {
      if (getUrl) {
        GetDisplayLanguageJsonAsync(getUrl).then((response) => {
          setState((prev) => ({
            displayLanguageOriginal: response,
            displayLanguageSelected: response,
            isLoaded: true
          }));
        });
      }
    } else {
      if (getUrl) {
        GetDisplayLanguageJsonAsync(getUrl).then((response) => {
          setState((prev) => ({
            ...prev,
            displayLanguageSelected: response,
            isLoaded: true
          }));
        });
      }
    }
  }, [feedbackDocument?.feedbackDocument?.displayLanguageUrl]);

  return (
    <DisplayLanguageFeedbackContext.Provider value={[state, setState]}>
      {props.children}
    </DisplayLanguageFeedbackContext.Provider>
  );
};

export { DisplayLanguageFeedbackContext, DisplayLanguageFeedbackProvider };
