import React, { useContext } from 'react';
import { GameDocumentContext } from '../../contexts/game-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { generateTitleById } from '../../utils/game-document/display-languages';

type Props = {
  isCorrectAnswer?: boolean;
};

const AnswerIncorrect = ({ isCorrectAnswer, ...props }: Props) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  return isCorrectAnswer === undefined ? (
    <></>
  ) : (
    <div
      className={`text-end text-${
        isCorrectAnswer ? 'success' : 'danger'
      } px-2`}>
      <span className={'fw-bold'}>
        {isCorrectAnswer
          ? generateTitleById(
              '80884474-f011-402e-b4ea-6aa791457ed9',
              gameDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Correct'
          : 'Incorrect'}
      </span>
    </div>
  );
};

export default AnswerIncorrect;
