import { GalleryState } from '../../../components/gallery';
import { GameDocument } from '../../../types/game-document';
import { TaskContentForm } from '../../../types/game-document/entities/task-content';
import { GetResourceValue, ResourceGetById } from '../resources';

/**
 * Get task content by id from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @param taskContentId - The task content id you are looking for
 * @constructor
 */
export const GetTaskContentById = (
  gameDocument: GameDocument | undefined,
  taskContentId: string
) => {
  return gameDocument?.assets.taskContents?.find((x) => x.id === taskContentId);
};

export const GetCustomFeedback = (
  gameDocument: GameDocument | undefined,
  taskContentId: string,
  formIndex: number
) => {
  return gameDocument?.assets.taskContents?.find((x) => x.id === taskContentId)
    ?.forms![formIndex].customFeedback;
};

export const GetFormMedia = (
  gameDocument: GameDocument,
  form: TaskContentForm
) => {
  let galleryItems: GalleryState[] = [];

  if (form.medias) {
    form?.medias.forEach((resourceId) => {
      let resource = ResourceGetById(gameDocument?.resources, resourceId);

      galleryItems.push({
        id: resource?.id,
        name: resource?.name,
        fileType: resource?.type,
        url: resource?.value
      });
    });
  }
  return galleryItems;
};

export const GetQuestionsFromTaskContent = (
  gameDocument: GameDocument,
  id: string
) => {
  let forms = gameDocument?.assets?.taskContents?.find(
    (x) => x.id === id
  )?.forms;

  if (forms) {
    let extractedData = forms?.map(({ id, questionResId }) => ({
      id,
      description: GetResourceValue(gameDocument, questionResId!, '')
    }));

    return extractedData;
  }
  return [];
};

export const GetForm = (
  gameDocument: GameDocument,
  taskContentid: string,
  formId: string
) => {
  let form = gameDocument?.assets?.taskContents
    ?.find((x) => x.id === taskContentid)
    ?.forms?.find((x) => x.id === formId);

  return form ?? undefined;
};
