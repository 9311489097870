import { useContext, useEffect } from 'react';
import { useInterval, useLocalStorage } from 'usehooks-ts';
import { GameDocumentContext } from '../contexts/game-document';
import { OverlayContext } from '../contexts/overlay';
import { PlayerContext, PlayerContextState } from '../contexts/player';
import { GetTimerObjectById } from '../utils/game-document/assets';
import { TimerStatus } from '../types/entities/timer';
import { StopTimer } from '../utils/game-engine/player';
import {
  GetGameLogo,
  GetGameName,
  UpdatePlayerStateAsync
} from '../utils/game-engine/base';
import usePrevious from './use-previous';
import { cloneDeep, isEqual } from 'lodash';
import { LogoArea } from '../components/map/map';
import { TimerDirection } from '../types/game-document/entities/timer';

interface ClockTimer {
  id: string;
  count: number;
  interval: number;
  percentage: number;
  percentageBar: number;
  status: TimerStatus;
  repeats: boolean;
  show: boolean;
  direction: TimerDirection;
}
export interface GameClockOutput {
  timers: ClockTimer[];
  start: () => void;
  stop: () => void;
  pause: () => void;
  resume: () => void;
  addTimer: (timerId: string, task: LogoArea, taskId: string) => void;
  startTimer: (timerId: string) => void;
  stopTimer: (player: PlayerContextState, timerId: string) => void;
  pauseTimer: (timerId: string) => void;
  resumeTimer: (timerId: string) => void;
}

export interface GameClockOptions {
  showLogs?: boolean;
}

export interface GameClockEvents {
  onStart?: (timerId: string) => Promise<void>;
  onTick?: () => Promise<void>;
  onStop?: (timerId: string) => Promise<void>;
  onTimerElapsed?: (timerId: string) => Promise<void>;
}

export const useGameClock = (
  options = { showLogs: false } as GameClockOptions,
  events = {} as GameClockEvents
): GameClockOutput => {
  const [player, setPlayer] = useContext(PlayerContext);
  const [gameDocument] = useContext(GameDocumentContext);
  const [overlay, setOverlay] = useContext(OverlayContext);
  const [timers, setTimers] = useLocalStorage<ClockTimer[]>(
    `${gameDocument?.gameCode!}-clock`,
    []
  );

  const prevPlayerTimers = usePrevious(cloneDeep(player.playerState?.timers));
  const prevOpenedPlayerTask = usePrevious(
    cloneDeep(player.playerState?.openedTask)
  );
  const prevShowTimer = usePrevious(cloneDeep(timers.find((x) => x.show)));

  const currentOpenedTask = player.playerState?.openedTask
    ? player.playerState.openedTask[player.playerState.openedTask.length - 1]
    : undefined;

  useInterval(
    () => {
      handleTick();

      setTimers((prev) => {
        const updatedClockTimer = prev.map((clockTimer: ClockTimer) => {
          const copyClockTimer = clockTimer;
          if (
            copyClockTimer.status === 'start' ||
            copyClockTimer.status === 'resume'
          ) {
            if (copyClockTimer.direction === 'up') {
              copyClockTimer.count += 1;
              if (copyClockTimer.count >= copyClockTimer.interval) {
                copyClockTimer.count = 0;
                if (copyClockTimer.repeats) {
                  if (events.onTimerElapsed)
                    events.onTimerElapsed(copyClockTimer.id);
                } else {
                  copyClockTimer.status = 'stop';
                  setPlayer((prev) => {
                    const stopTimer = StopTimer(
                      gameDocument,
                      prev,
                      copyClockTimer.id,
                      'stop'
                    );
                    return UpdatePlayerStateAsync(prev, stopTimer);
                  });
                  if (events.onTimerElapsed) {
                    events.onTimerElapsed(copyClockTimer.id).then(() => {
                      if (events.onStop) {
                        events.onStop(copyClockTimer.id);
                      }
                    });
                  } else if (events.onStop) {
                    events.onStop(copyClockTimer.id);
                  }
                }
              }
            } else {
              copyClockTimer.count -= 1;
              if (copyClockTimer.count <= 0) {
                copyClockTimer.count = copyClockTimer.interval;
                if (copyClockTimer.repeats) {
                  if (events.onTimerElapsed)
                    events.onTimerElapsed(copyClockTimer.id);
                } else {
                  copyClockTimer.status = 'stop';
                  setPlayer((prev) => {
                    const stopTimer = StopTimer(
                      gameDocument,
                      prev,
                      copyClockTimer.id,
                      'stop'
                    );
                    return UpdatePlayerStateAsync(prev, stopTimer);
                  });
                  if (events.onTimerElapsed) {
                    events.onTimerElapsed(copyClockTimer.id).then(() => {
                      if (events.onStop) {
                        events.onStop(copyClockTimer.id);
                      }
                    });
                  } else if (events.onStop) {
                    events.onStop(copyClockTimer.id);
                  }
                }
              }
            }
          }

          if (copyClockTimer.show) {
            if (copyClockTimer.direction === 'up') {
              copyClockTimer.percentage = Math.floor(
                (copyClockTimer.count / copyClockTimer.interval) * 100
              );
              copyClockTimer.percentageBar = copyClockTimer.percentage;
            } else {
              copyClockTimer.percentage = Math.floor(
                ((copyClockTimer.interval - copyClockTimer.count) /
                  copyClockTimer.interval) *
                  100
              );
              copyClockTimer.percentageBar = copyClockTimer.percentage;
            }
          }

          return copyClockTimer;
        });
        return updatedClockTimer;
      });
    },
    // 1 Second
    timers.filter((x) => x.status === 'start' || x.status === 'resume').length >
      0
      ? 1000
      : null
  );

  const startTimer = async (timerId: string) => {
    if (
      player.playerState?.timers?.findIndex(
        (i) => i.id === timerId && i.status === 'start'
      ) !== -1
    ) {
      setTimers((prev) =>
        prev.map((timer: ClockTimer) =>
          timer.id === timerId ? { ...timer, status: 'start' } : { ...timer }
        )
      );
      if (events?.onStart) {
        events.onStart(timerId);
      }
    }
  };

  const start = async () => await handleStart();

  const handleStart = async () => {
    logEvent('Start');
  };

  const handleTick = async () => {
    logEvent('Tick');
    if (events?.onTick) events.onTick();
  };

  const stopTimer = async (player: PlayerContextState, timerId: string) => {
    if (
      player.playerState?.timers?.findIndex(
        (i) => i.id === timerId && i.status === 'stop'
      ) !== -1
    ) {
      setTimers((prev) =>
        prev.map((clockTimer: ClockTimer) =>
          clockTimer.id === timerId
            ? {
                ...clockTimer,
                status: 'stop',
                count: clockTimer.direction === 'up' ? 0 : clockTimer.interval,
                percentage: 0,
                percentageBar: 0
              }
            : { ...clockTimer }
        )
      );

      if (events?.onStop) events.onStop(timerId);
    }
  };

  const stop = async () => handleStop();

  const handleStop = async () => {
    logEvent('Stop');

    setTimers((prevState) =>
      prevState.map((item: ClockTimer) => ({
        ...item,
        status: 'stop',
        count: item.direction === 'up' ? 0 : item.interval,
        percentage: 0,
        percentageBar: 0
      }))
    );
  };

  const resume = async () => handleResume();
  const handleResume = async () => {
    logEvent('Resume');
    const pausedPlayerTimerIds =
      player.playerState?.timers
        ?.filter((x) => x.status === 'resume')
        .map((x) => x.id ?? '') ?? [];
    setTimers((prev) =>
      prev.map((timer: ClockTimer) =>
        pausedPlayerTimerIds.includes(timer.id)
          ? { ...timer, status: 'resume' }
          : { ...timer }
      )
    );
  };

  const resumeTimer = async (timerId: string) => {
    if (
      player.playerState?.timers?.findIndex(
        (i) => i.id === timerId && i.status === 'resume'
      ) !== -1
    ) {
      setTimers((prev) =>
        prev.map((timer: ClockTimer) =>
          timer.id === timerId ? { ...timer, status: 'resume' } : { ...timer }
        )
      );
    }
  };

  const pauseTimer = async (timerId: string) => {
    if (
      player.playerState?.timers?.findIndex(
        (i) => i.id === timerId && i.status === 'pause'
      ) !== -1
    ) {
      setTimers((prev) =>
        prev.map((timer: ClockTimer) =>
          timer.id === timerId ? { ...timer, status: 'pause' } : { ...timer }
        )
      );
    }
  };

  const pause = async () => handlePause();
  const handlePause = async () => {
    logEvent('Pause');
    setTimers((prev) =>
      prev.map((item: ClockTimer) => ({ ...item, status: 'pause' }))
    );
  };

  const addTimer = async (
    timerId: string,
    type: '' | 'zone' | 'task' = '',
    taskId: string
  ) => {
    logEvent('Add Timer');
    const timerEntity = GetTimerObjectById(gameDocument.gameDocument!, timerId);
    const timerData = timers.findIndex((timer) => timer.id === timerId);
    if (timerData === -1) {
      let show: boolean = false;
      if (type === 'zone') {
        show =
          gameDocument.gameDocument?.rules.worldMap.zones.findIndex(
            (x) =>
              x.timerAssId === timerId &&
              x.zoneAssId === player.playerState?.location
          ) !== -1;
      } else if (type === 'task') {
        show =
          gameDocument.gameDocument?.assets.tasks?.findIndex(
            (x) => x.timerAssId === timerId && x.id === taskId
          ) !== -1;
      } else {
        show = gameDocument.gameDocument?.rules.worldMap.timerAssId === timerId;
      }
      const timerStatus: TimerStatus | undefined =
        player.playerState?.timers?.find((x) => x.id === timerId)?.status;
      setTimers((prev) => {
        if (
          prev.findIndex((clock: ClockTimer) => clock.id === timerId) === -1
        ) {
          prev.push({
            id: timerId,
            status: timerStatus ?? 'stop', //default if player timer status is undefined
            count:
              timerEntity?.direction === 'up' ? 0 : timerEntity?.interval ?? 0,
            interval: timerEntity?.interval ?? 0,
            repeats: timerEntity?.repeats ?? false,
            show,
            direction: timerEntity?.direction ?? 'down',
            percentage: 0,
            percentageBar: 0
          });
        }
        return prev;
      });
    } else {
      setTimeout(() => {
        setTimers((prev) => {
          let timerIdComparison: string = '';
          if (
            player.playerState?.openedTask !== undefined &&
            player.playerState?.openedTask.length > 0
          ) {
            timerIdComparison =
              gameDocument.gameDocument?.assets.tasks?.find(
                (x) =>
                  x.id ===
                  player.playerState?.openedTask![
                    player.playerState.openedTask!.length - 1
                  ]
              )?.timerAssId ?? '';
          } else if (
            player.playerState?.location !== undefined &&
            player.playerState?.location !== 'world-map'
          ) {
            timerIdComparison =
              gameDocument.gameDocument?.rules.worldMap.zones.find(
                (x) => x.zoneAssId === player.playerState?.location
              )?.timerAssId ?? '';
          } else if (player.playerState?.location === 'world-map') {
            timerIdComparison =
              gameDocument.gameDocument?.rules.worldMap.timerAssId ?? '';
          }
          const timerStatus = player.playerState?.timers?.find(
            (x) => x.id === timerIdComparison
          )?.status;
          return prev.map((timer: ClockTimer) =>
            timer.id === timerIdComparison
              ? { ...timer, show: true, status: timerStatus ?? timer.status }
              : { ...timer, show: false }
          );
        });
      }, 0);
    }
  };

  const logEvent = (message: string) => {
    if (options.showLogs)
      console.debug(
        `[${new Date().toLocaleTimeString()}] Game Clock: ${message}`
      );
  };

  const setGameInfo = (
    type: LogoArea,
    field?: 'name' | 'logo',
    isTimeUp?: boolean
  ) => {
    if (type === '') {
      return field === 'name'
        ? GetGameName(gameDocument, player)
        : GetGameLogo(gameDocument, player);
    } else {
      return field === 'name'
        ? isTimeUp
          ? "Time's Up"
          : type === 'zone'
          ? 'This area expires in'
          : 'Task time remaining'
        : type === 'zone'
        ? 'https://stsharedappstorage.blob.core.windows.net/dev-catalyst-files/blob%20%28209%29.' //default image for zone timer
        : 'https://stsharedappstorage.blob.core.windows.net/dev-catalyst-files/blob%20%28208%29.';
    }
  };

  useEffect(() => {
    const showTimer = timers.find((x) => x.show);
    if (!isEqual(prevShowTimer, showTimer)) {
      setOverlay((prev) => {
        const copyPrev = cloneDeep({ ...prev });
        let type: LogoArea = ''; // default to world-map
        if (
          player.playerState?.openedTask !== undefined &&
          player.playerState.openedTask.length > 0
        ) {
          if (
            gameDocument.gameDocument?.assets.tasks?.findIndex(
              (x) =>
                x.id ===
                  player.playerState?.openedTask![
                    player.playerState.openedTask!.length - 1
                  ] &&
                x.timerAssId !== undefined &&
                x.timerAssId === showTimer?.id
            ) !== -1
          ) {
            type = 'task';
          } else if (
            player.playerState.location !== undefined &&
            player.playerState.location !== 'world-map' &&
            gameDocument.gameDocument.rules.worldMap.zones.findIndex(
              (x) =>
                x.zoneAssId === player.playerState?.location &&
                x.timerAssId !== undefined &&
                x.timerAssId === showTimer?.id
            ) !== -1
          ) {
            type = 'zone';
          }
        } else if (
          player.playerState?.location !== undefined &&
          player.playerState.location !== 'world-map'
        ) {
          if (
            gameDocument.gameDocument?.rules.worldMap.zones.findIndex(
              (x) =>
                x.zoneAssId === player.playerState?.location &&
                x.timerAssId !== undefined &&
                x.timerAssId === showTimer?.id
            ) !== -1
          ) {
            type = 'zone';
          }
        }
        copyPrev.timerAssId = showTimer?.id;
        copyPrev.gameLogo = setGameInfo(type, 'logo');
        copyPrev.gameName = setGameInfo(
          type,
          'name',
          showTimer && !showTimer.repeats && showTimer.status === 'stop'
        );
        copyPrev.interval = showTimer?.count ?? 0;
        copyPrev.progress = showTimer?.percentage ?? 0;
        copyPrev.progressBar = showTimer?.percentageBar ?? 0;
        return copyPrev;
      });
    }
  }, [timers]);

  useEffect(() => {
    setTimeout(() => {
      if (
        player.playerState &&
        player.playerState.timers &&
        !isEqual(prevPlayerTimers, player.playerState.timers)
      ) {
        for (const playerTimer of player.playerState.timers) {
          let type: LogoArea = '';
          if (
            player.playerState.openedTask !== undefined &&
            player.playerState.openedTask.length > 0
          )
            type = 'task';
          else if (
            player.playerState.location !== undefined &&
            player.playerState.location !== 'world-map'
          )
            type = 'zone';
          addTimer(playerTimer.id!, type, currentOpenedTask ?? '');
        }
      }
    }, 0);
  }, [player.playerState?.timers]);

  useEffect(() => {
    if (player.playerState) {
      if (
        currentOpenedTask !== undefined &&
        currentOpenedTask !== '' &&
        (prevOpenedPlayerTask && prevOpenedPlayerTask.length > 0
          ? prevOpenedPlayerTask[prevOpenedPlayerTask.length - 1]
          : undefined) !== currentOpenedTask
      ) {
        const taskTimerId = gameDocument.gameDocument?.assets.tasks?.find(
          (x) => x.id === currentOpenedTask
        )?.timerAssId;
        if (taskTimerId !== undefined && taskTimerId !== '') {
          setTimers((prev) =>
            prev.map((timer) =>
              timer.id === taskTimerId
                ? { ...timer, show: true }
                : { ...timer, show: false }
            )
          );
        } else {
          if (
            player.playerState.location !== undefined &&
            player.playerState.location !== 'world-map'
          ) {
            const zoneTimerId =
              gameDocument.gameDocument?.rules.worldMap.zones.find(
                (x) => x.zoneAssId === player.playerState?.location
              )?.timerAssId;
            if (zoneTimerId !== undefined && zoneTimerId !== '') {
              setTimers((prev) =>
                prev.map((timer) =>
                  timer.id === zoneTimerId
                    ? { ...timer, show: true }
                    : { ...timer, show: false }
                )
              );
            }
          } else if (player.playerState.location === 'world-map') {
            const worldmapTimerId =
              gameDocument.gameDocument?.rules.worldMap.timerAssId;
            if (worldmapTimerId !== undefined && worldmapTimerId !== '') {
              setTimers((prev) =>
                prev.map((timer) =>
                  timer.id === worldmapTimerId
                    ? { ...timer, show: true }
                    : { ...timer, show: false }
                )
              );
            }
          } else {
            setTimers((prev) =>
              prev.map((timer) => ({ ...timer, show: false }))
            );
          }
        }
      } else if (
        player.playerState.location !== undefined &&
        player.playerState.location !== 'world-map'
      ) {
        const zoneTimerId =
          gameDocument.gameDocument?.rules.worldMap.zones.find(
            (x) => x.zoneAssId === player.playerState?.location
          )?.timerAssId;
        if (zoneTimerId !== undefined && zoneTimerId !== '') {
          setTimers((prev) =>
            prev.map((timer) =>
              timer.id === zoneTimerId
                ? { ...timer, show: true }
                : { ...timer, show: false }
            )
          );
        } else {
          const worldmapTimerId =
            gameDocument.gameDocument?.rules.worldMap.timerAssId;
          if (worldmapTimerId !== undefined && worldmapTimerId !== '') {
            setTimers((prev) =>
              prev.map((timer) =>
                timer.id === worldmapTimerId
                  ? { ...timer, show: true }
                  : { ...timer, show: false }
              )
            );
          } else {
            setTimers((prev) =>
              prev.map((timer) => ({ ...timer, show: false }))
            );
          }
        }
      } else if (player.playerState.location === 'world-map') {
        const worldmapTimerId =
          gameDocument.gameDocument?.rules.worldMap.timerAssId;
        if (worldmapTimerId !== undefined && worldmapTimerId !== '') {
          setTimers((prev) =>
            prev.map((timer) =>
              timer.id === worldmapTimerId
                ? { ...timer, show: true }
                : { ...timer, show: false }
            )
          );
        } else {
          setTimers((prev) => prev.map((timer) => ({ ...timer, show: false })));
        }
      }
    }
  }, [player.playerState?.location, currentOpenedTask]);

  return {
    timers,
    start,
    stop,
    pause,
    resume,
    addTimer,
    startTimer,
    stopTimer,
    pauseTimer,
    resumeTimer
  };
};
