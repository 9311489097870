import { ScrollView } from '@progress/kendo-react-scrollview';

import MediaPlayer from './media-player';
import ImageViewWithFullScreen from './image-view-with-fullscreen';

export interface GalleryState {
  id?: string;
  name?: string;
  url?: string;
  fileType?: string | 'image' | 'video';
}

export interface GalleryProps {
  items?: GalleryState[];
  className?: string;
}

export const Gallery = ({ ...props }: GalleryProps) => {
  return (
    <ScrollView
      className={`${props.className}`}
      endless={false}
      pageable={props.items && props.items?.length > 1}
      automaticViewChange={false}>
      {props.items &&
        props.items.map((item) => {
          return (
            <div
              key={item.id}
              className={`d-flex ${
                item.fileType !== 'audio'
                  ? 'media-image-container'
                  : 'justify-content-center align-items-center h-full'
              }`}>
              {item.fileType === 'video' ? (
                <MediaPlayer item={item} type={'video'} />
              ) : item.fileType === 'image' ? (
                <ImageViewWithFullScreen
                  src={item.url ?? ''}
                  alt={item.name ?? ''}
                />
              ) : (
                item.fileType === 'audio' && (
                  <MediaPlayer item={item} type={'audio'} />
                )
              )}
            </div>
          );
        })}
    </ScrollView>
  );
};
