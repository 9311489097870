import { CSSProperties } from 'react';

export interface MaterialIconProps {
  icon: string | 'arrow_back_ios' | 'sync';
  className?: string | undefined;
  style?: CSSProperties | undefined;
}

export const MaterialIcon = ({ icon, ...props }: MaterialIconProps) => {
  return (
    <span
      {...props}
      role={'img'}
      translate={'no'}
      className={`material-symbols-outlined ${props.className ?? ''}`.trim()}>
      {icon}
    </span>
  );
};

export default MaterialIcon;
