import { AssessmentEntity } from '../../types/state/assessment/assessment';

export const getResourceDescription = (
  resourceId: string,
  assessment: AssessmentEntity,
  languageId: string
) => {
  let resource = assessment?.resources?.find((x) => x.id === resourceId);

  if (resource) {
    if (languageId) {
      let selectedLanguage = assessment?.resourcePacks?.find(
        (x) => x.id === languageId
      );

      if (selectedLanguage) {
        let languagePackRes = selectedLanguage?.resources?.find(
          (x) => x.id === resourceId
        );

        if (languagePackRes) {
          return languagePackRes?.value;
        } else {
          return resource?.value;
        }
      } else {
        return resource?.value;
      }
    } else {
      return resource?.value;
    }
  }
  return '';
};
