import React, { createRef, useRef, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { blobToFile, loadImageToBlobUrl } from '../../utils/image-helper';
import Webcam from 'react-webcam';
import { FileExtension } from '../../types/fileExtensions';
import {
  ExternalDropZone,
  Upload,
  UploadOnAddEvent
} from '@progress/kendo-react-upload';
import { uuid } from '../../types/common-helper';

type Props = {
  allowedExtension?: FileExtension[];
  isDirectUpload?: boolean;
  allowedFileTypes?: string;
  profileImg?: string;
  onSelectedImageCallback?: (file: File | undefined) => void;
};

const CameraUpload = ({
  profileImg = '',
  onSelectedImageCallback = () => {},
  ...props
}: Props) => {
  const videoRef = useRef<Webcam>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const uploadRef = createRef<Upload>();
  const [blobImage, setBlobImage] = useState<string>(profileImg);
  const [isDisplayWebcam, setIsDisplayWebcam] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const validateFile = (extension: string) => {
    extension = extension?.toLowerCase();
    if (
      extension?.includes('jpg') ||
      extension?.includes('jpeg') ||
      extension?.includes('png')
    ) {
      setErrorMessage('');
      return true;
    } else {
      setErrorMessage('Please use an image that is PNG/JPG/JPEG format.');
      return false;
    }
  };

  const handleSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validateFile(e.target.files![0].type)) {
      const blobUrl = loadImageToBlobUrl(e.target.files![0]);
      onSelectedImageCallback(e.target.files![0]);
      setBlobImage(blobUrl);
    }
  };

  const onDropFiles = (event: UploadOnAddEvent) => {
    event.newState.forEach((file) => {
      if (validateFile(file.extension!)) {
        if (file && file.getRawFile) {
          let blobUrl = loadImageToBlobUrl(file.getRawFile());
          onSelectedImageCallback(file.getRawFile());
          setBlobImage(blobUrl);
        }
      }
    });
  };

  const onAddImageClick = () => inputFile.current && inputFile.current.click();

  // new ones
  const turnOnCameraClick = () => setIsDisplayWebcam(true);

  const turnOffCameraClick = () => {
    const { stream } = videoRef.current!;
    const tracks = stream?.getTracks();
    tracks?.forEach((item) => item.stop());
    setBlobImage('');
    onSelectedImageCallback(undefined);
    setIsDisplayWebcam(false);
  };

  const onTakePictureClickHandler = () => {
    const screenShoot = videoRef.current?.getScreenshot();
    if (screenShoot && screenShoot !== '') {
      fetch(screenShoot)
        .then((result) => result.blob())
        .then((response) => {
          const blobUrl = loadImageToBlobUrl(response);
          setBlobImage(blobUrl);
          onSelectedImageCallback(blobToFile(response, uuid()));
        })
        .catch((error) => console.error(error))
        .finally(() => setIsDisplayWebcam(false));

      setErrorMessage('');
    }
  };

  return (
    <div className={'flex-grow-1 pt-2 d-flex flex-column gap-2'}>
      <div className={'border-2 border-bottom mb-2'}>
        <div className={'text-success d-flex align-items-center gap-2 pb-2'}>
          <span className={'material-symbols-outlined'}>account_circle</span>
          <h2 className={'m-0 fw-bold'}>Edit profile photo</h2>
        </div>
      </div>

      <div className={'row'}>
        <div className={'col'}>
          <div className={'text-center h-100'}>
            {isDisplayWebcam ? (
              <Webcam
                id={'answer-webcam'}
                className={'py-2'}
                audio={false}
                videoConstraints={{
                  height: 300,
                  facingMode: 'user'
                }}
                screenshotFormat={'image/jpeg'}
                autoPlay={true}
                ref={videoRef}></Webcam>
            ) : (
              <>
                <div className={'d-flex'}>
                  <small className={'text-danger'}>{errorMessage}</small>
                </div>

                <div
                  className={
                    'd-flex justify-content-center align-items-center py-5'
                  }>
                  {blobImage === '' ? (
                    <div
                      className={
                        'user-avatar border border-4 border-success overflow-hidden bg-white p-0 rounded-circle d-flex align-items-center justify-content-center fs-1'
                      }
                      style={{
                        height: 120,
                        width: 120,
                        backgroundImage: `url(${blobImage})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}></div>
                  ) : (
                    <div
                      className={
                        'user-avatar border border-4 border-success overflow-hidden bg-white p-0 rounded-circle d-flex align-items-center justify-content-center fs-1'
                      }
                      style={{
                        height: 120,
                        width: 120,
                        backgroundImage: `url(${blobImage})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}></div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-6'}>
          <Button
            className={'w-100 upload-button p-2'}
            onClick={
              isDisplayWebcam ? onTakePictureClickHandler : onAddImageClick
            }>
            <div
              className={
                'd-flex justify-content-center align-items-center gap-3'
              }>
              <span
                className={'material-symbols-outlined mr-2 text-success'}
                style={{ cursor: 'pointer' }}>
                add_photo_alternate
              </span>
              <span>
                {isDisplayWebcam ? 'Take picture' : 'Upload from device'}
              </span>
            </div>
          </Button>
          <input
            type={'file'}
            id={'file-upload'}
            className={'d-none'}
            onChange={handleSelectImage}
            onClick={(event) => {
              event.currentTarget.value = '';
            }}
            ref={inputFile}
            accept={'image/jpg,image/jpeg,image/png'}
          />
        </div>
        <div className={'col-6'}>
          <Button
            className={'w-100 upload-button p-2'}
            onClick={isDisplayWebcam ? turnOffCameraClick : turnOnCameraClick}>
            <div
              className={
                'd-flex justify-content-center align-items-center gap-3'
              }>
              <span
                className={'material-symbols-outlined mr-2 text-success'}
                style={{ cursor: 'pointer' }}>
                add_a_photo
              </span>
              <span>{isDisplayWebcam ? 'Turn off camera' : 'Use camera'}</span>
            </div>
          </Button>
        </div>
      </div>
      <div className={'row'}>
        <div>
          <div className={'row p-2'}>
            <div className={'col-md-12 dropzone-upload'}>
              <div className={'text-center'}>
                <ExternalDropZone
                  className={
                    'w-100 h-100 text-primary p-4 mt-2 external-dropzone-background'
                  }
                  uploadRef={uploadRef}
                  customHint={' '}
                  customNote={'Drop photo here'}
                />
                <Upload
                  className={'d-none'}
                  ref={uploadRef}
                  batch={false}
                  multiple={false}
                  withCredentials={false}
                  restrictions={{
                    allowedExtensions: ['.jpg', '.png', '.jpeg']
                  }}
                  onAdd={onDropFiles}
                  showFileList={true}
                />
              </div>
            </div>
            <span>200x200px and .png .jpg recommended.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraUpload;
