import React from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import {
  Authenticating,
  AuthenticationError,
  SessionLost,
  SignOutRedirect
} from './';
import { Button } from '@progress/kendo-react-buttons';

interface AuthRequiredProps {
  children?: React.ReactNode;
}

export const AuthRequired = ({ children }: AuthRequiredProps) => {
  const auth = useAuth();

  // automatically sign-in
  React.useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect({ state: { returnUrl: window.location.pathname } });
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect
  ]);

  /**
   * For silent sign in we don't want to interrupt the user experience. So
   * just show the normal page content. We could add a toast here if required.
   */
  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <>{children}</>;
    case 'signoutRedirect':
      return <SignOutRedirect />;
  }

  // User is authenticating
  if (auth.isLoading) {
    return <Authenticating />;
  }

  // There was an error during authentication
  if (auth.error) {
    return (
      <AuthenticationError>
        <>
          <p>{auth.error.message}.</p>
          <div className={'d-flex justify-content-center'}>
            <Button
              themeColor={'warning'}
              size={'small'}
              fillMode={'flat'}
              onClick={async () => {
                await auth.removeUser();
                await auth.clearStaleState();
                await auth.signinRedirect({
                  state: { returnUrl: window.location.pathname }
                });
              }}>
              Force login
            </Button>
            <Button
              themeColor={'error'}
              size={'small'}
              fillMode={'flat'}
              onClick={async () => {
                await auth.removeUser();
                await auth.clearStaleState();
                await auth.signoutRedirect();
              }}>
              Force logout
            </Button>
          </div>
        </>
      </AuthenticationError>
    );
  }

  // User is authenticated
  if (auth.isAuthenticated) {
    return <>{children}</>;
  }

  // User is not authenticated and does not meet any prior condition
  return <SessionLost />;
};
