import { useContext, useEffect, useState } from 'react';
import { TaskGrid } from '../../components/facilitator/task/task-grid';
import { PlayerContextState } from '../../contexts/player';
import {
  GetManualScoringAnswers,
  getPlayerState,
  submitScoringAsync,
  submitTaskAnswerScoringAsync
} from '../../services/players';
import { GameContext } from '../../contexts/game';
import { GameDocumentContext } from '../../contexts/game-document';
import {
  TaskList,
  TaskState
} from '../../components/facilitator/task/task-list';
import { GridMode } from '../../components/grid/grid-tool-bar';
import { ManualScoring } from '../../types/manual-scoring';
import { GetTaskById } from '../../utils/game-document/assets';
import { GetResourceValue } from '../../utils/game-document/resources';
import { GetForm } from '../../utils/game-document/assets/task-contents';
import { capitalizeFirstLetter } from '../../utils/string';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import React from 'react';
import { process } from '@progress/kendo-data-query';
import { TaskContentAnswer } from '../../types/entities/task';
import { getTeamList } from '../../services/teams';
import { TeamResponse } from '../../types/responses/team-response';
import { FileZipDownloadRequest } from '../../types/facilitator/file-zip-download';
import { downloadFilesAndZip } from '../../utils/file-utility';
import { AnswerType } from '../../types/game-document/entities/task-content';

export const AllTask = () => {
  const [game] = useContext(GameContext);
  const [gridMode, setGridMode] = useState<GridMode>('grid');
  const [gameDocument] = useContext(GameDocumentContext);
  const [playerStates, setPlayerStates] = useState<PlayerContextState[]>([]);
  const [manualScoringList, setManualScoringList] = useState<ManualScoring[]>(
    []
  );
  const [data, setData] = useState<TaskState[]>([]);
  const getPlayersStateAsync = async () => {
    const playerStatePromises = game?.gameState?.players?.map(
      async (player) => {
        return await getPlayerState(gameDocument?.gameCode!, player?.code!);
      }
    );

    if (playerStatePromises) {
      const playerStates = await Promise.all(playerStatePromises);
      setPlayerStates(playerStates);
    }
  };

  const updatePlayerStateManualScore = (scoring: ManualScoring) => {
    setPlayerStates((prevPlayerStates) => {
      // Find the player state index
      const playerStateIndex = prevPlayerStates.findIndex(
        (x) => x.playerState?.code === scoring.playerCode
      );

      if (playerStateIndex === -1) return prevPlayerStates; // Player not found

      // Find the task index
      const tasks = prevPlayerStates[playerStateIndex]?.playerState?.tasks;
      const taskIndex = tasks?.findIndex((x) => x.id === scoring.taskId);

      if (taskIndex === -1 || taskIndex === undefined) return prevPlayerStates; // Task not found

      // Find the form index
      const taskContentFormAnswers = tasks![taskIndex]?.taskContentFormAnswers;
      const formIndex = taskContentFormAnswers?.findIndex(
        (x) => x.formId === scoring.formId
      );

      if (formIndex === -1 || formIndex === undefined) return prevPlayerStates; // Form not found

      // Update the score immutably
      const newPlayerStates = [...prevPlayerStates];
      const newTasks = [
        ...newPlayerStates[playerStateIndex].playerState!.tasks!
      ];
      const newFormAnswers = [...newTasks[taskIndex].taskContentFormAnswers!];

      newFormAnswers[formIndex] = {
        ...newFormAnswers[formIndex],
        score: scoring.score
      };

      newTasks[taskIndex] = {
        ...newTasks[taskIndex],
        taskContentFormAnswers: newFormAnswers
      };

      newPlayerStates[playerStateIndex] = {
        ...newPlayerStates[playerStateIndex],
        playerState: {
          ...newPlayerStates[playerStateIndex].playerState!,
          tasks: newTasks
        }
      };

      return newPlayerStates;
    });
  };

  const getManualScoringlist = async () => {
    let response = await GetManualScoringAnswers(gameDocument?.gameCode!);
    setManualScoringList(response as ManualScoring[]);
  };

  const submitScoring = async (scoring: ManualScoring) => {
    try {
      scoring.id = manualScoringList?.find(
        (x) =>
          x.formId === scoring.formId &&
          x.taskId === scoring.taskId &&
          x.playerCode === scoring.playerCode
      )?.id;

      if (!scoring.isScored) {
        //manual task answer scoring
        await submitScoringAsync(scoring);
      } else {
        //adjust task answer scoring
        await submitTaskAnswerScoringAsync(scoring);
      }
    } catch (error) {
      console.error('Failed submit scoring');
    } finally {
      updatePlayerStateManualScore(scoring);
    }
  };

  const _exporter = React.createRef<ExcelExport>();
  const excelExport = () => {
    if (_exporter.current) {
      // need to get the team list from the game code
      getTeamList(gameDocument?.gameCode!).then((response) => {
        let filesToDownload: FileZipDownloadRequest[] = [];
        if (response && (response as TeamResponse[]).length > 0) {
          for (const team of response as TeamResponse[]) {
            for (let player of team.teamPlayers!) {
              const playerTasks = data.filter(
                (task) =>
                  task.playerCode === player.playerCode &&
                  (task.questionType === 'Image' ||
                    task.questionType === 'Video') &&
                  task.answers
              );
              if (playerTasks.length > 0) {
                filesToDownload = filesToDownload.concat(
                  playerTasks.map((task) => ({
                    containerFolder: `${task.questionType}/${team.name}/`,
                    fileUrl: task.answers as string
                  }))
                );
              }
            }
          }
        } else {
          const tasks = data
            .filter((task) => task !== undefined)
            .filter(
              (task) =>
                (task.questionType === 'Image' ||
                  task.questionType === 'Video') &&
                task.answers
            );

          filesToDownload = tasks.map((task) => ({
            containerFolder: `${task.questionType}/`,
            fileUrl: task.answers as string
          }));
        }

        if (filesToDownload.length > 0) {
          downloadFilesAndZip(
            filesToDownload,
            `${gameDocument?.gameCode!}-Media.zip`
          );
        }
      });

      _exporter.current.save();
    }
  };

  const generateTasks = () => {
    let tasks: TaskState[] = [];

    if (playerStates) {
      let data = playerStates.map((playerState) => {
        try {
          return playerState?.playerState!.tasks?.map((task) => {
            return task?.taskContentFormAnswers?.map((taskContentAnswer) => {
              const taskById = GetTaskById(
                gameDocument.gameDocument,
                task.id || ''
              );
              let taskName = '';
              let question = '';
              let questionType = '';
              let scoringType = '';
              let taskContentId = '';
              let answers = '';

              if (taskById && gameDocument.gameDocument) {
                const form = GetForm(
                  gameDocument.gameDocument,
                  taskById.taskContentId || '',
                  taskContentAnswer.formId || ''
                );
                if (form) {
                  taskName = taskById.name;
                  question = GetResourceValue(
                    gameDocument.gameDocument,
                    form.questionResId || '',
                    ''
                  );
                  questionType = capitalizeFirstLetter(form.type || '');
                  scoringType = form.isManualScoring ? 'M' : 'A';
                  taskContentId = taskById.taskContentId || '';
                  answers = buildAnswers(form.type, taskContentAnswer) || '';
                }
              }

              return {
                taskId: task?.id,
                taskName,
                question,
                playerName: playerState?.playerState?.name,
                playerCode: playerState?.playerState?.code,
                questionType,
                scoringType,
                formId: taskContentAnswer.formId,
                score: parseInt(taskContentAnswer?.score?.toString() ?? '0'),
                taskContentId,
                answers
              };
            });
          });
        } catch (error) {}
      });

      if (data) {
        data.forEach((dataTasks) => {
          if (dataTasks) {
            dataTasks?.forEach((item) => {
              item !== undefined && tasks.push(item as TaskState);
            });
          }
        });
        setData(tasks.flat());
      }
    }
  };

  const buildAnswers = (
    answerType: AnswerType,
    taskContentAnswer: TaskContentAnswer
  ) => {
    if (answerType === 'checkbox' || answerType === 'radio') {
      return taskContentAnswer?.answers
        ?.map((x) => {
          {
            return GetResourceValue(
              gameDocument?.gameDocument!,
              x.answerResId,
              ''
            );
          }
        })
        ?.join(', ');
    } else {
      return taskContentAnswer?.answers?.map((x) => x.value).join(', ');
    }
  };

  useEffect(() => {
    getPlayersStateAsync();
  }, [game.gameState!.players]);

  useEffect(() => {
    getManualScoringlist();
    generateTasks();
  }, [playerStates]);

  return (
    <>
      {gridMode === 'grid' && (
        <TaskList
          playerStates={playerStates}
          onViewChange={(e) => setGridMode(e)}
          viewMode={gridMode}
          onSubmitScore={submitScoring}
          onExport={excelExport}
        />
      )}

      {gridMode !== 'grid' && (
        <TaskGrid
          playerStates={playerStates}
          onViewChange={(e) => setGridMode(e)}
          viewMode={gridMode}
          onSubmitScore={submitScoring}
          onExport={excelExport}
        />
      )}
      <ExcelExport
        data={process(data, {})?.data}
        collapsible={true}
        fileName="Tasks.xlsx"
        ref={_exporter}>
        <ExcelExportColumn field="taskName" title="Task" width={200} />
        <ExcelExportColumn field="playerName" title="Player" width={200} />
        <ExcelExportColumn
          field="questionType"
          title="Question type"
          width={200}
        />
        <ExcelExportColumn field="scoringType" title="Type" width={100} />
        <ExcelExportColumn field="answers" title="Answer" width={500} />
        <ExcelExportColumn field="score" title="Score" width={200} />
      </ExcelExport>
    </>
  );
};
