import { Button } from '@progress/kendo-react-buttons';

import { NumericTextBox } from '@progress/kendo-react-inputs';
import { ManualScoring } from '../../types/manual-scoring';
import { useCallback, useContext, useEffect, useState } from 'react';
import { TaskEntity } from '../../types/game-document/entities';
import { GetTaskById } from '../../utils/game-document/assets';
import { GameDocumentContext } from '../../contexts/game-document';
import { GetResourceValue } from '../../utils/game-document/resources';
import {
  GetFormMedia,
  GetTaskContentById
} from '../../utils/game-document/assets/task-contents';
import { Answer } from '../questionnaire';
import {
  CheckboxOption,
  TaskContentAnswer as TaskContentAnswerGame,
  TaskContentForm
} from '../../types/game-document/entities/task-content';
import { ContentAnswer, TaskContentAnswer } from '../../types/entities/task';
import cloneDeep from 'lodash.clonedeep';
import { Gallery, GalleryState } from '../gallery';
import { getPlayerState } from '../../services/players';
import { getTeamSelectedAsync } from '../../services/teams';
import { GetTeamSelectedResponse } from '../../types/responses/team-response';
import { isEmpty } from 'lodash';

interface DetailProps {
  dataPlayer?: ManualScoring;
  onSubmitScore?: (e: ManualScoring) => void;
}

export const DetailPlayer = ({
  dataPlayer,
  onSubmitScore = () => {}
}: DetailProps) => {
  const [assetTask, setAssetTask] = useState<TaskEntity & { title: string }>();
  const [gameDocumentState] = useContext(GameDocumentContext);
  const [assetForm, setAssetForm] = useState<TaskContentForm>();
  const [mediaForm, setMediaForm] = useState<GalleryState[]>([]);
  const [checkboxOption, setCheckboxOption] = useState<CheckboxOption[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [answerInput, setAnswerInput] = useState<string>();
  const [scoring, setScoring] = useState<ManualScoring>(dataPlayer!);
  const [team, setTeam] = useState<string>('');
  const onChangeInput = (value: number) => {
    setScoring({ ...scoring, score: value ?? 0 });
  };

  useEffect(() => {
    setScoring(dataPlayer!);
  }, [dataPlayer]);

  const getOptions = useCallback(
    (options: TaskContentAnswerGame[]) => {
      let optionsDescription: string[] = [];

      options?.forEach((opt) => {
        const description = GetResourceValue(
          gameDocumentState?.gameDocument!,
          opt.answerResId,
          ''
        );
        if (description) {
          optionsDescription.push(description);
        }
      });
      setOptions(optionsDescription);
    },
    [gameDocumentState?.gameDocument]
  );

  const playerDetail = useCallback(async () => {
    const { playerState } = await getPlayerState(
      gameDocumentState?.gameCode!,
      dataPlayer?.playerCode as string
    );
    if (playerState) {
      if (playerState.teamCode) {
        const team = (await getTeamSelectedAsync(
          gameDocumentState?.gameCode!,
          playerState.teamCode
        )) as GetTeamSelectedResponse;
        setTeam(team.name as string);
      } else {
        setTeam('');
      }
    }
  }, [dataPlayer?.playerCode, gameDocumentState?.gameCode]);

  useEffect(() => {
    if (dataPlayer?.taskId !== undefined || dataPlayer?.taskId !== '') {
      playerDetail();
      const task = GetTaskById(
        gameDocumentState?.gameDocument,
        dataPlayer?.taskId!
      );

      const taskTitle = GetResourceValue(
        gameDocumentState?.gameDocument!,
        task?.titleResId as string,
        ''
      );

      setAssetTask({ ...task!, title: taskTitle });

      if (
        assetTask?.taskContentId !== '' ||
        assetTask?.taskContentId !== undefined
      ) {
        let taskContent = GetTaskContentById(
          gameDocumentState?.gameDocument,
          task?.taskContentId!
        );

        let form = taskContent?.forms?.find((x) => x.id === dataPlayer?.formId);

        setAssetForm(form);

        if (form) {
          const getMedia = GetFormMedia(gameDocumentState?.gameDocument!, form);
          setMediaForm(getMedia);
          if (form?.type === 'checkbox') {
            if (form.answers) {
              let option: CheckboxOption[] = [];
              form?.answers?.forEach((answer) => {
                const description = GetResourceValue(
                  gameDocumentState?.gameDocument!,
                  answer.answerResId,
                  ''
                );
                option.push({
                  answerResId: answer.answerResId,
                  description: description ?? '',
                  isChecked:
                    (JSON.parse(dataPlayer?.answer!)?.answers?.find(
                      (x: ContentAnswer) =>
                        x.answerResId === answer?.answerResId
                    )?.value as boolean) ?? false
                });
              });

              setCheckboxOption(cloneDeep(option));
            }
          }
          getOptions(form?.answers!);
        }

        if (dataPlayer?.answer !== '' || dataPlayer?.answer !== undefined) {
          let answer = JSON.parse(dataPlayer?.answer!) as TaskContentAnswer;

          if (answer?.answers![0]) {
            setAnswerInput(answer?.answers![0]!.value ?? '');
          } else {
            setAnswerInput('');
          }
        }
      }
    }
  }, [
    assetTask?.taskContentId,
    dataPlayer,
    gameDocumentState?.gameDocument,
    getOptions,
    playerDetail
  ]);

  return (
    <>
      <div
        className={'d-flex h-100 flex-column'}
        style={{
          gap: '20px'
        }}>
        <div className={'d-flex flex-column gap-2'}>
          <span>
            {dataPlayer?.playerName}
            {!isEmpty(team) ? `, ${team}` : ''}
          </span>
          <h2 className={'p-0 m-0'}>
            <b className={'text-dark'}>{assetTask?.title}</b>
          </h2>
        </div>

        <div
          className={'row m-0 h-100'}
          style={{
            gap: '30px'
          }}>
          <div
            className={'col d-flex flex-column p-0'}
            style={{
              gap: '30px'
            }}>
            <div
              className={
                'd-flex border border-secondary rounded text-dark fw-bold'
              }
              style={{
                flexShrink: 0,
                padding: '15px'
              }}>
              Form question:{' '}
              {GetResourceValue(
                gameDocumentState?.gameDocument!,
                assetForm?.questionResId!,
                ''
              )}
            </div>
            <div
              className={
                'd-flex align-items-center justify-content-center border border-secondary rounded'
              }
              style={{
                height: '100%',
                padding: '15px'
              }}>
              {mediaForm.length === 0 ? (
                "This form doesn't have any media"
              ) : (
                <Gallery className={'task-content-media'} items={mediaForm} />
              )}
            </div>
          </div>
          <div className={'col p-0'}>
            <div
              className={
                'd-flex align-items-center justify-content-center h-100 border border-primary rounded'
              }
              style={{
                padding: '15px'
              }}>
              <Answer
                key={answerInput}
                isReadonly={true}
                answerInput={answerInput}
                answerOptionIndex={
                  JSON.parse(dataPlayer?.answer!)?.answers?.findIndex(
                    (item: ContentAnswer) => item.value as boolean
                  ) ?? -1
                }
                answerType={assetForm?.type}
                checkboxOption={checkboxOption}
                formId={assetForm?.id ?? ''}
                isCorrectAnswer={undefined}
                name={assetTask?.name}
                options={options}
                taskId={assetTask?.id}
              />
            </div>
          </div>
        </div>

        <div className={'d-flex justify-content-end align-items-center'}>
          <p className={'mb-0'}>
            Suggest score:{' '}
            <b>
              {assetForm?.manualScoring?.min} - {assetForm?.manualScoring?.max}
            </b>
          </p>
          <NumericTextBox
            min={assetForm?.manualScoring?.min}
            max={assetForm?.manualScoring?.max}
            width={100}
            placeholder={'00'}
            className={'border mx-3'}
            value={scoring?.score ?? assetForm?.manualScoring?.min}
            onChange={(e) => {
              onChangeInput(e.target.value as number);
            }}
          />
          <Button
            themeColor={dataPlayer?.isScored ? 'secondary' : 'success'}
            className={'w-10 ml-2'}
            onClick={() => onSubmitScore(scoring!)}>
            {dataPlayer?.isScored ? 'Adjust points' : 'Submit'}
          </Button>
        </div>
      </div>
    </>
  );
};
