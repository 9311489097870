import cloneDeep from 'lodash.clonedeep';
import {
  Feedback,
  FeedbackDetailOptions,
  FeedbackDetails,
  FeedbackDocumentState
} from '../../types/feedback-document';
import merge from 'lodash.merge';

export const UpdateFeedbackDocumentState = (
  state: FeedbackDocumentState,
  updated: Feedback,
  isDirty: boolean = true
) => {
  return merge({
    ...state,
    isDirty,
    feedbackDocument: cloneDeep({ ...updated })
  });
};

export const UpdateFeedbackDetailsState = (
  state: FeedbackDocumentState,
  updatedDetails: FeedbackDetails[],
  isDirty: boolean = true
) => {
  return merge({
    ...state,
    isDirty,
    feedbackDocument: merge({
      ...state.feedbackDocument,
      feedbackDetails: updatedDetails
    })
  });
};

export const GetFeedbackResourceValue = (
  feedback: Feedback,
  resourceId: string,
  languageId?: string
) => {
  let resource = feedback?.resources?.find((x) => x.id === resourceId);

  if (resource) {
    if (languageId) {
      let selectedLanguage = feedback?.resourcePacks?.find(
        (x) => x.id === languageId
      );

      if (selectedLanguage) {
        let languagePackRes = selectedLanguage?.resources?.find(
          (x) => x.id === resourceId
        );

        if (languagePackRes) {
          return languagePackRes?.value;
        } else {
          return resource?.value;
        }
      } else {
        return resource?.value;
      }
    } else {
      return resource?.value;
    }
  }
  return '';
};
