import { Outlet, useParams } from 'react-router-dom';
import { PlayerStateProvider } from '../contexts/player';
import { PlayerAssessmentProvider } from '../contexts/player-assessment';
import { OverlayAssessment } from '../features/in-game/overlay/overlay-assessment';
import { AssessmentDocumentProvider } from '../contexts/assessment-document';
import { DisplayLanguageProvider } from '../contexts/display-languages';
import { Suspense } from 'react';
import { observer } from 'mobx-react';
import { Spinner } from '../components/spinner';
import { toastStore } from '../stores/toast-store';
import { Toast } from '../components/toast';

/**
 * The layout for in-game screens.
 */
export const AssessmentLayout = observer(() => {
  const { gameCode } = useParams();
  const getStyle = () => {
    const style = toastStore.getStyle();
    return style ? style : 'none';
  };

  return (
    <Suspense fallback={<Spinner />}>
      <AssessmentDocumentProvider assessmentCode={gameCode!}>
        <PlayerStateProvider gameCode={gameCode!}>
          <PlayerAssessmentProvider>
            <DisplayLanguageProvider>
              <div className={'layout layout--in-game'}>
                <div className={'layout__container'}>
                  <div className={'assessment__canvas'}>
                    <Outlet />
                  </div>
                </div>
              </div>
              <OverlayAssessment />
              <div>
                <Toast
                  style={getStyle()}
                  show={toastStore.isShow()}
                  title={toastStore.getTitle()}>
                  {toastStore.getMessage()}
                </Toast>
              </div>
            </DisplayLanguageProvider>
          </PlayerAssessmentProvider>
        </PlayerStateProvider>
      </AssessmentDocumentProvider>
    </Suspense>
  );
});

export default AssessmentLayout;
