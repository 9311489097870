import { NodeSpec } from '@progress/kendo-react-editor';

const iframe: NodeSpec = {
  // NodeSpec interface
  // http://prosemirror.net/docs/ref/#model.NodeSpec
  attrs: {
    src: { default: null },
    width: { default: null },
    height: { default: null },
    style: { default: null }
  },
  group: 'block',
  selectable: false,
  parseDOM: [
    {
      tag: 'iframe',
      getAttrs: (dom: any) => ({
        src: dom.getAttribute('src'),
        width: dom.getAttribute('width'),
        height: dom.getAttribute('height'),
        style: dom.getAttribute('style')
      })
    }
  ],
  toDOM: (node) => {
    const attrs = {
      src: node.attrs.src,
      style: node.attrs.style,
      frameborder: '0',
      allowfullscreen: 'true',
      width: node.attrs.width,
      height: node.attrs.height
    };
    return ['iframe', attrs];
  }
};

export { iframe };
