import { Input, InputProps } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { useBoolean } from 'usehooks-ts';

export interface SearchInputProps extends InputProps {}

export const SearchInput = (props: SearchInputProps) => {
  const { value, toggle } = useBoolean(false);
  return (
    <div className={'d-flex align-items-center justify-content-end'}>
      <Button
        onClick={toggle}
        fillMode={'flat'}
        themeColor={'primary'}
        size={'small'}>
        <span className={'material-symbols-outlined'}>search</span>
      </Button>
      <div
        className={`search-input__wrapper ${
          value
            ? 'search-input__wrapper--expanded'
            : 'search-input__wrapper--collapsed'
        }`}>
        <Input
          {...props}
          className={`search-input ${props.className ?? ''}`.trim()}>
          {props.children}
        </Input>
      </div>
    </div>
  );
};

export default SearchInput;
