export const AssessmentStepType = {
  StepQRCode: -2, // QR
  StepTeams: -1, // teams
  Step0: 0, // lobby
  Step1: 1, // competencies
  Step2: 2, // summary
  Step3: 3, // ideas
  Step4: 4, // vote
  Step5: 5, // approval
  Step6: 6, // measureable
  CompleteAssessment: 7 // finish or share tab
};
