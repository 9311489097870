import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Badge } from '@progress/kendo-react-indicators';

import { ChatRoomPlayer } from './chat-room';
import { TeamContext } from '../../contexts/team';
import { PlayerContext } from '../../contexts/player';
import { isExistUnreadChat } from '../../utils/game-engine/chat';
import { GameDocumentContext } from '../../contexts/game-document';
import { PlayerResponse } from '../../types/responses/player-response';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { generateTitleById } from '../../utils/game-document/display-languages';

interface CommonTabProps {
  activeTab: string;
  totalUnreadDirectChat: number;
  chatContacts: PlayerResponse[];
  otherPlayers: ChatRoomPlayer[];

  handleSwitch: (
    chatType: string,
    unreadCode: string,
    withPlayerCode?: string
  ) => void;
  handleSwitchToDirectChat: () => void;
  chatTypeText: (type: string) => string;
}

const CommonTab: React.FC<CommonTabProps> = ({
  activeTab,
  otherPlayers,
  chatContacts,
  totalUnreadDirectChat,
  handleSwitch,
  chatTypeText,
  handleSwitchToDirectChat
}) => {
  const [gameDocumentstate] = React.useContext(GameDocumentContext);
  const [displayLanguageContext] = React.useContext(DisplayLanguageContext);
  const [playerState] = React.useContext(PlayerContext);
  const [teamState] = React.useContext(TeamContext);

  const isInLobby = playerState.playerState?.status === 'waiting';
  const preGameSetting = gameDocumentstate.gameDocument?.settings.preGame;
  const inGameSetting = gameDocumentstate.gameDocument?.settings.inGame;

  const showAllPlayerChat = isInLobby
    ? preGameSetting?.showPreGameAllPlayerChat
    : inGameSetting?.showInGameAllPlayerChat;
  const showFacilitatorChat = isInLobby
    ? preGameSetting?.showPreGameFacilitatorChat
    : inGameSetting?.showInGameFacilitatorChat;
  const showTeamChat = isInLobby
    ? preGameSetting?.showPreGameTeamChat
    : inGameSetting?.showInGameTeamChat;
  const showGroupChat = isInLobby
    ? preGameSetting?.showPreGameGroupChat
    : inGameSetting?.showInGameGroupChat;
  const showDirectChat = isInLobby
    ? preGameSetting?.showPreGameDirectChat
    : inGameSetting?.showInGameDirectChat;

  return (
    <div className="d-none d-md-flex align-items-center gap-3 my-3">
      <span className="text-dark m-0">
        {generateTitleById(
          'e20069a1-1a00-4183-b62e-7375d02b4721',
          gameDocumentstate,
          displayLanguageContext.displayLanguageSelected.resources!,
          'game'
        ) || 'Chat with'}
      </span>
      <div className="example-wrapper">
        {showAllPlayerChat && (
          <Button
            className={'mr-1'}
            onClick={() =>
              handleSwitch(
                'All Player',
                `GlobalChat_${gameDocumentstate?.gameCode}`
              )
            }
            themeColor={activeTab === 'All Player' ? 'dark' : 'light'}>
            {chatTypeText('All Player')}
            {isExistUnreadChat(
              gameDocumentstate?.gameCode!,
              `GlobalChat_${gameDocumentstate?.gameCode}`
            ) && <span className={'k-icon k-i-circle text-danger'}></span>}
          </Button>
        )}

        {showTeamChat && (
          <Button
            className={'mr-1'}
            onClick={() =>
              handleSwitch('Team', `TeamChat_${teamState.teamState?.code}`)
            }
            themeColor={activeTab === 'Team' ? 'dark' : 'light'}>
            {chatTypeText('Team')}
            {isExistUnreadChat(
              gameDocumentstate?.gameCode!,
              `TeamChat_${teamState.teamState?.code}`
            ) && <span className={'k-icon k-i-circle text-danger'}></span>}
          </Button>
        )}

        {showDirectChat && otherPlayers.length > 0 && (
          <Button
            className={'mr-1'}
            onClick={() => {
              handleSwitchToDirectChat();
            }}
            themeColor={activeTab === 'Direct' ? 'dark' : 'light'}>
            {chatTypeText('Direct')}
            {totalUnreadDirectChat > 0 && (
              <Badge themeColor="info">{totalUnreadDirectChat}</Badge>
            )}
          </Button>
        )}

        {showGroupChat && (
          <Button
            className={'mr-1'}
            onClick={() =>
              handleSwitch(
                'Group',
                `GroupChat_${teamState.teamState?.groupCode}`
              )
            }
            themeColor={activeTab === 'Group' ? 'dark' : 'light'}>
            {chatTypeText('Group')}
            {isExistUnreadChat(
              gameDocumentstate?.gameCode!,
              `GroupChat_${teamState.teamState?.groupCode}`
            ) && <span className={'k-icon k-i-circle text-danger'}></span>}
          </Button>
        )}

        {showFacilitatorChat && (
          <Button
            className={'mr-1'}
            onClick={() =>
              handleSwitch(
                'Facilitators',
                `PlayerChat_${playerState?.playerState?.code}_Facilitator`
              )
            }
            themeColor={activeTab === 'Facilitators' ? 'dark' : 'light'}>
            {chatTypeText('Facilitators')}
            {isExistUnreadChat(
              gameDocumentstate?.gameCode!,
              `PlayerChat_${playerState?.playerState?.code}_Facilitator`
            ) && <span className={'k-icon k-i-circle text-danger'}></span>}
          </Button>
        )}

        {chatContacts?.map((item) => {
          return (
            <>
              <Button
                className={'mr-1'}
                onClick={() =>
                  handleSwitch(
                    `${item.code}`,
                    `PlayerChat_${playerState?.playerState?.code}_${item.code}`,
                    `${item.code!}`
                  )
                }
                themeColor={activeTab === `${item.code}` ? 'dark' : 'light'}>
                {item?.name}
                {isExistUnreadChat(
                  gameDocumentstate?.gameCode!,
                  `PlayerChat_${playerState?.playerState?.code}_${item.code}`
                ) && <span className={'k-icon k-i-circle text-danger'}></span>}
              </Button>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default CommonTab;
