interface FeedbackContentHtmlProps {
  contentHtml?: string;
}
export const FeedbackContentHtml = ({
  contentHtml = ''
}: FeedbackContentHtmlProps) => {
  return (
    <div className={'layout-feedback-form__main'}>
      <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
    </div>
  );
};
