import { Button } from '@progress/kendo-react-buttons';
import { DrawerTabs } from './tabs';

interface OverlayTabButtonProps {
  thisTab: DrawerTabs;
  activeTab: DrawerTabs;
  titleIcon: string;
  icon: string;
  onSetActiveTab: (activeTab: DrawerTabs) => void;
}

export const TabButton = ({
  thisTab,
  activeTab,
  titleIcon,
  icon,
  onSetActiveTab,
  ...props
}: OverlayTabButtonProps) => {
  return (
    <Button
      className={`col align-self-start k-button--tab ${
        activeTab === thisTab ? 'k-button--tab-active' : ''
      } m-0`}
      fillMode={'flat'}
      themeColor={activeTab === thisTab ? 'primary' : 'base'}
      onClick={() => onSetActiveTab(thisTab)}>
      <span className="material-symbols-outlined icon">{icon}</span>
      <span className="label">{titleIcon}</span>
    </Button>
  );
};
