import { Outlet, useParams } from 'react-router-dom';
import React, { Suspense } from 'react';
import FacilitatorMenu from './facilitator-menu';
import { AuthRequired } from '../../components/authentication';
import { NotificationAlert } from '../../components/notification';
import { toastStore } from '../../stores/toast-store';
import { Toast } from '../../components/toast';
import { appStore } from '../../stores/app-store';
import { observer } from 'mobx-react';
import { Spinner } from '../../components/spinner';
import { FacilitatorProvider } from '../../contexts/facilitator';
import { ErrorBoundary } from 'react-error-boundary';
import { LayoutProvider } from '../../contexts/layout-provider';

/**
 * The layout for private pages.
 * @returns
 */
export const FacilitatorLayout = observer(() => {
  const { gameCode } = useParams();
  const getStyle = () => {
    const style = toastStore.getStyle();
    return style ? style : 'none';
  };

  return (
    <AuthRequired>
      <ErrorBoundary fallbackRender={(props) => <>{props.error.toString()}</>}>
        <Suspense fallback={<Spinner />}>
          <LayoutProvider>
            <FacilitatorProvider gameCode={gameCode!}>
              {/* <DisplayLanguageProvider> */}
              {appStore.isLoading() && <Spinner />}
              <div className={'layout layout--facilitator'}>
                <FacilitatorMenu gameCode={gameCode!} />
                <div className={'app-main'}>
                  <Outlet />
                </div>
                <Toast
                  style={getStyle()}
                  show={toastStore.isShow()}
                  title={toastStore.getTitle()}>
                  {toastStore.getMessage()}
                </Toast>
              </div>
              <NotificationAlert />
              {/* </DisplayLanguageProvider> */}
            </FacilitatorProvider>
          </LayoutProvider>
        </Suspense>
      </ErrorBoundary>
    </AuthRequired>
  );
});

export default FacilitatorLayout;
