/**
 * Get formatted date
 * @param date
 * @returns
 */
export const getFormattedDateString = (date: Date) => {
  if (typeof date === 'object' && date !== null && 'getDate' in date) {
    let day = date.getDate();
    let month = date.getMonth() + 1; //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth?retiredLocale=id
    let year = date.getFullYear();
    let hour = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year} ${hour}:${minute}:${second}`;
  }
};

/**
 * Get formatted date
 * @param date
 * @returns
 */
export const getFormattedDateOnlyString = (date: Date) => {
  if (typeof date === 'object' && date !== null && 'getDate' in date) {
    let day = date.getDate();
    let month = date.getMonth() + 1; //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth?retiredLocale=id
    let year = date.getFullYear();
    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year}`;
  }
};

/**
 *
 * @param startDate Get total second between date
 * @param endDate
 * @returns
 */
export const getTotalSecondsBetweenDate = (startDate: Date, endDate: Date) => {
  const diffInMilliseconds = Math.abs(
    endDate.getTime() - new Date(startDate).getTime()
  );

  // Convert milliseconds to seconds
  return Math.floor(diffInMilliseconds / 1000) ?? 0;
};

/**
 * Convert json date to date object
 * @param jsonDate
 * @returns
 */
export const convertJsonDateToDate = (jsonDate: Date | undefined) => {
  if (jsonDate) {
    return new Date(JSON.parse(JSON.stringify(jsonDate)));
  }
};

export const getDateDuration = (startDate: Date, endDate: Date) => {
  const start = new Date(startDate).getTime();
  const end = new Date(endDate ?? new Date()).getTime();

  if (!start || !end) {
    return 'Invalid Date';
  } else {
    const timeDifference = end - start;
    // const seconds = Math.floor(timeDifference / 1000) % 60;
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return `${days}d, ${hours}h, ${minutes}min`;
  }
};

export const getTotalMinutes = (startDateUtc: Date, endDateUtc: Date) => {
  let difference = endDateUtc.getTime() - startDateUtc.getTime(); // This will give difference in milliseconds
  return Math.round(difference / 60000);
};
