import React, { useContext, useEffect, useRef, useState } from 'react';
import { PreGameWizard } from '../../types/pre-game';
import { PlayerContext } from '../../contexts/player';
import { useSessionStorage } from 'usehooks-ts';
import { AvatarImage } from '../../types/tab-content';
import { getAvatars, uploadAvatar } from '../../services/players';
import { UpdatePlayerContext } from '../../utils/player-state';
import { WizardContent, AvatarPreview, AvatarLibrary } from '.';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { GameDocumentContext } from '../../contexts/game-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';

const StepFour = ({ nextStepCallback, currentStep = 1 }: PreGameWizard) => {
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [state] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const avatarUpload = useRef<HTMLInputElement>(null);
  const [avatarList, setAvatarList] = useState<AvatarImage[]>([]);
  const [isAvatarLibraryShow, setIsAvatarLibraryShow] =
    useState<boolean>(false);
  const [selectedAvatar, setSelectedAvatar] = useSessionStorage(
    'last-avatar',
    playerState.playerState?.avatarImage ?? ''
  );

  useEffect(() => {
    if (playerState.gameCode !== undefined) {
      getDefaultAvatars(playerState.gameCode);
    }
    //eslint-disable-next-line
  }, []);

  const handleMoreButtonClicked = () => {
    setIsAvatarLibraryShow(true);
  };

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const response = await uploadAvatar(file);
    setAvatar(response?.result);
    nextStepCallback(currentStep + 1);
  };

  const handleUploadAvatar = () => {
    avatarUpload.current?.click();
  };

  const handleAvatarClick = (url: string) => {
    setAvatar(url);
    nextStepCallback(currentStep + 1);
  };

  const getDefaultAvatars = async (gameCode: string) => {
    const response = await getAvatars(gameCode);
    if (response !== undefined) {
      setAvatarList(
        response.map((item: string, index: number) => ({
          id: index,
          src: item
        }))
      );
    }
  };

  const setAvatar = (selectedAvatar: string) => {
    setSelectedAvatar(selectedAvatar);
    setPlayerState((prev) =>
      UpdatePlayerContext(prev, {
        ...prev.playerState!,
        avatarImage: selectedAvatar
      })
    );
  };

  return (
    <WizardContent
      additionalContainerClass={'position-relative'}
      wizardTitle={
        isAvatarLibraryShow
          ? 'Choose an avatar from our library'
          : generateTitleById(
              '4e040b03-759f-49e3-b48a-7d0c0a1914e9',
              state,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Upload an image of your choice'
      }
      isShowButton={false}>
      {isAvatarLibraryShow ? (
        <div
          className={
            'd-flex flex-wrap justify-content-between align-items-center my-4 '
          }>
          <AvatarLibrary
            items={avatarList}
            handleAvatarClick={handleAvatarClick}
            isButtonMoreShow={false}
          />
        </div>
      ) : (
        <>
          <AvatarPreview
            avatarSource={selectedAvatar}
            handleUploadAvatar={handleUploadAvatar}>
            <input
              type={'file'}
              ref={avatarUpload}
              onChange={handleChangeFile}
              style={{ opacity: '0' }}
              accept={'image/*'}
            />
          </AvatarPreview>

          <p className={'text-center text-dark fw-bold my-4'}>
            {generateTitleById(
              '750604c5-d26a-4dfb-9db1-25eda7a6d95f',
              state,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Or choose an avatar from our library'}
          </p>
          <div
            className={
              'd-flex mb-4 justify-content-between align-items-center position-relative'
            }>
            <AvatarLibrary
              limit={4}
              items={avatarList}
              handleAvatarClick={handleAvatarClick}
              handleMoreButtonClicked={handleMoreButtonClicked}
              isButtonMoreShow={true}
            />
          </div>
        </>
      )}
    </WizardContent>
  );
};

export default StepFour;
