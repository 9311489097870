import React from 'react';
import ProgressPill from '../components/progress-pill';
import { ScoringReductionEntity } from '../types/entities/task';

interface TimeRemainingProps {
  scoreReduction: ScoringReductionEntity;
  titleProps: string;
}
export default function ProgressTimeRemaining({
  scoreReduction,
  titleProps = 'Time Remaining'
}: TimeRemainingProps): JSX.Element {
  return (
    <ProgressPill
      className={'progress-pill-time-remaining'}
      title={titleProps}
      progress={100}
      toggleTimerVisibility={true}>
      {scoreReduction?.maximumTimeLimit}
    </ProgressPill>
  );
}
