import { GameDocument } from '../../../types/game-document';
import { TaskEntity } from '../../../types/game-document/';

/**
 * Get all tasks from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTasks = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.tasks ?? [];
};

/**
 * Get tasks by Ids from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTasksByIds = (
  gameDocument: GameDocument | undefined,
  ids: string[] | undefined
) => {
  return (
    gameDocument?.assets?.tasks?.filter((x) => ids?.indexOf(x.id) !== -1) ?? []
  );
};

/**
 * Get task by Id from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @param id - The ID you want to search
 * @constructor
 */
export const GetTaskById = (
  gameDocument: GameDocument | undefined,
  id: string
) => {
  return gameDocument?.assets?.tasks?.find((x) => x.id === id);
};

/**
 * Get task timer by task id from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @param zoneId - The task id you are looking for
 * @constructor
 */
export const GetTaskTimerById = (
  gameDocument: GameDocument | undefined,
  taskId: string
) => {
  return gameDocument?.assets.tasks?.find((x) => x.id === taskId);
};

/**
 * Get tasks by Zone Id from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTasksByZoneId = (
  zoneId: string,
  gameDocument: GameDocument | undefined
) => {
  let tasks: TaskEntity[] = [];

  if (gameDocument) {
    let zone = gameDocument?.assets?.zones?.find((x) => x.id === zoneId);

    const taskIds = zone?.tasks?.map(function (e) {
      return e.taskAssId;
    });

    if (taskIds) {
      return GetTasksByIds(gameDocument, taskIds);
    }
  }

  return tasks;
};
