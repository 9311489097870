import { GameDocument } from '../../../types/game-document';
import { GetResourceValue } from '../resources';

/**
 * Get all items from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetItems = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.items ?? [];
};

/**
 * Get item image url
 * @param gameDocument
 * @param id
 * @param languageId
 * @returns
 */
export const GetItemImage = (
  gameDocument: GameDocument,
  id: string,
  languageId?: string
) => {
  let item = gameDocument?.assets?.items?.find((x) => x.id === id);

  return GetResourceValue(gameDocument!, item?.imageResId!, languageId ?? '');
};

/**
 *
 * @param gameDocument Get item title
 * @param id
 * @param languageId
 * @returns
 */
export const GetItemTitle = (
  gameDocument: GameDocument,
  id: string,
  languageId?: string
) => {
  let item = gameDocument?.assets?.items?.find((x) => x.id === id);

  return GetResourceValue(gameDocument!, item?.titleResId!, languageId ?? '');
};
