import React from 'react';
import { Button } from 'react-bootstrap';

const UserNotification: React.FC = () => {
  return (
    <div className={'flex-grow-1 pt-2 d-flex flex-column gap-2'}>
      <div className={'border-2 border-bottom'}>
        <div className={'text-success d-flex align-items-center gap-2 pb-2'}>
          <span className="material-symbols-outlined">notifications</span>
          <h2 className={'m-0 fw-bold'}>Notifications</h2>
        </div>
      </div>

      <div
        className={
          'd-flex justify-content-end mt-2 pb-2 border-2 border-bottom'
        }>
        <Button variant="secondary">Clear all</Button>
      </div>

      <div className="mt-2 px-2 d-flex flex-column gap-3">
        {Array.from({ length: 2 }).map((_, idx) => (
          <div key={idx} className="p-3 row border bg-light">
            <div
              className="col-2 text-black-50"
              style={{
                fontSize: '14px'
              }}>
              20:04 09/24
            </div>
            <div className="col-9 pl-0">
              User created a new challenge{' '}
              <span
                className="text-primary"
                style={{
                  cursor: 'pointer'
                }}>
                <u>Escape the Mob</u>
              </span>
            </div>
            <div className="col-1 d-flex justify-content-end">
              <span
                className="material-symbols-outlined"
                style={{
                  height: 'fit-content',
                  cursor: 'pointer'
                }}>
                close
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserNotification;
