import React from 'react';
import { PlayerRank } from '../../types/scoreboard';
import { AvatarPreview } from '../wizard';

const PlayerScore = ({
  avatarImage,
  name,
  score,
  playerRank,
  playerTotal,
  isShowScore
}: PlayerRank) => {
  return (
    <div id={'leaderboard'} className={'bg-light w-100 my-2 py-2'}>
      <AvatarPreview avatarSource={avatarImage} isShowPencil={false} />
      <div className={'text-center py-2'}>
        <h4>
          <strong>{name}</strong>
        </h4>
        {isShowScore && (
          <h1>
            <strong>{score}</strong>
          </h1>
        )}
        <h3>{`${playerRank}/${playerTotal}`}</h3>
      </div>
    </div>
  );
};

export default PlayerScore;
