import React, { useState } from 'react';

type IconType = 'notifications' | 'chat_bubble' | 'warning' | 'chat';

type NotificationPosition =
  | 'top-start'
  | 'top-center'
  | 'top-end'
  | 'middle-start'
  | 'middle-center'
  | 'middle-end'
  | 'bottom-start'
  | 'bottom-center'
  | 'bottom-end';

export interface NotificationContent {
  message: JSX.Element | string;
  icon: IconType;
  isHide: boolean;
  isShowCloseButton?: boolean; // we can remove it later if not necessary
  autohide?: boolean; // we can remove it later if not necessary
  hideDelay?: number; // we can remove it later if not necessary
  color?: string; // this is css class for background color, currently only available 'k-button--gradient', we can change into string option later
  onCloseCallback?: () => void; // if someone need callback once notification vanish or user click close button
}

interface NotificationState {
  position: NotificationPosition;
  content: NotificationContent[];
}

const initialNotificationState: NotificationState = {
  position: 'bottom-end',
  content: []
};

const NotificationContext = React.createContext<
  [
    initialState: NotificationState,
    setState: React.Dispatch<React.SetStateAction<NotificationState>>
  ]
>([initialNotificationState, () => { }]);

const NotificationProvider = (props: any) => {
  const [state, setState] = useState<NotificationState>({
    ...initialNotificationState
  });

  return (
    <NotificationContext.Provider value={[state, setState]}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
