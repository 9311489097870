import { useParams, useResolvedPath } from 'react-router-dom';
import { GameControl } from '../../components/facilitator/game-control';
import React, { Suspense } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { useGame } from '../../hooks/use-game';
import { Button } from '@progress/kendo-react-buttons';
import AssessmentControl, {
  AssessmentControlError,
  AssessmentControlLoading
} from '../../features/facilitator/assessment/control';

export const DashboardPage = () => {
  const { gameCode } = useParams();
  const game = useGame(gameCode!);
  const leadeboardPath = useResolvedPath('../leaderboard').pathname;
  const gamePath = useResolvedPath('../facilitator/game').pathname;
  const leadeboardFullUrl = `${window.location.origin}${leadeboardPath}`;

  const openInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <Container fluid>
        <GameControl />
        <Row className={'align-items-center border-bottom py-3'}>
          <Col className={'fw-bold'}>Join link and QR code:</Col>
          <Col xs={'auto'}>
            <Button
              icon={'hyperlink-open'}
              themeColor={'light'}
              className={'w-10 ml-2'}
              onClick={() => openInNewTab(gamePath)}
              title={'Launch the games join link and QR code in a new window'}>
              Launch
            </Button>
          </Col>
        </Row>
        <Row className={'align-items-center border-bottom py-3'}>
          <Col className={'fw-bold'}>Leaderboard :</Col>
          <Col xs={'auto'}>
            <Button
              icon={'hyperlink-open'}
              themeColor={'light'}
              className={'w-10 ml-2'}
              onClick={() => openInNewTab(leadeboardFullUrl)}
              title={'Launch the leaderboard in a new window'}>
              Launch
            </Button>
          </Col>
        </Row>
        {game.data?.assessmentId && (
          <Suspense fallback={AssessmentControlLoading()}>
            <ErrorBoundary fallbackRender={AssessmentControlError}>
              <AssessmentControl />
            </ErrorBoundary>
          </Suspense>
        )}
      </Container>
    </>
  );
};
