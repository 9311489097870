import { Outlet } from 'react-router-dom';
import { FacilitatorPage } from '../../components/facilitator/facilitator-page';

export const FacilitatorLayoutTab = () => {
  return (
    <FacilitatorPage>
      <div className="flex-grow-1">
        <Outlet />
      </div>
    </FacilitatorPage>
  );
};
