import { GameDocument } from '../../types/game-document';
import { MergeResources } from './assets';
import { uuid } from '../../types/common-helper';
import { ResourceType } from '../../types/game-document/resource-type';
import { API_URL } from '../../constants';
import { BearerHeader } from '../../services/api';
import { EntityBase } from '../../types/game-document/entities/entity-base';

import { ResourceEntity } from '../../types/game-document/';
import { GetResourcePackResourceValue } from './resource-packs';

/**
 * Get resource by id.
 * @param resources - The list of Resources to check
 * @param id - The ID of the current Resource
 * @returns Resource data.
 */
export const ResourceGetById = (
  resources: Array<ResourceEntity>,
  id?: string
) => {
  return resources.find((x) => x.id === id);
};

/**
 * Checks to see if a Resources with the defined name already exists.
 * @param resources - The list of Resources to check
 * @param name - The Name of the Resource
 * @param id - The ID of the current Resource to ignore
 * @returns True if a Resource with the same name already exists.
 */
export const ResourceExistsAsync = async (
  resources: Array<ResourceEntity>,
  name: string,
  id?: string
) => {
  let resourceIndex = resources.findIndex(
    (i) => i.name === name && i.id !== id
  );
  return resourceIndex !== -1;
};

/**
 * Gets the next unique Resource name.
 * @param resources - The list of Resources to check
 * @param name - The Name of the Resource
 * @param id - The ID of the current Resource to ignore. I.e. for Edit.
 * @returns The next unique Resource name.
 */
export const GetNextResourceNameAsync = async (
  resources: Array<ResourceEntity>,
  name: string,
  id?: string
) => {
  let resourceExists = await ResourceExistsAsync(resources, name, id);
  let counter = 1;
  let nextResourceName = name;
  while (resourceExists) {
    nextResourceName = `${name}-${counter}`;
    resourceExists = await ResourceExistsAsync(resources, nextResourceName, id);
    counter += 1;
  }
  return nextResourceName;
};

/**
 Adds a new Resource to the Game document.
 @param gameDocument - The Game Document to modify
 @param name - The Name of the new Resource
 @param description - The Description for the new Resource
 @param resourceType - The Type for the new Resource
 @param value - The value for the new Resource
 @param resourceId - The new ID for added resource
 @returns The updated Game Document
 */
export const AddResourceAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string,
  resourceType: ResourceType,
  value: string,
  resourceId: string = uuid()
) =>
  AddResourceEntityAsync(gameDocument, {
    id: resourceId,
    name,
    description,
    type: resourceType,
    value
  });

/**
 * Adds a new Resource to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param entity - The Resource entity to add to the document
 * @returns The updated Game Document
 */
export const AddResourceEntityAsync = async (
  gameDocument: GameDocument,
  entity: ResourceEntity
) => {
  let resources = GetResources(gameDocument);
  let resourceName = await GetNextResourceNameAsync(resources, entity.name);
  resources.push({ ...entity, name: resourceName });
  return MergeResources(gameDocument, resources);
};

/**
 * Deletes the identified Resource from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param resourceId - The ID of the resource to delete
 * @returns The updated Game Document
 */
export const DeleteResourceAsync = async (
  gameDocument: GameDocument,
  resourceId: string
) => {
  let resources = GetResources(gameDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  resources.splice(resourceIndex, 1);
  return MergeResources(gameDocument, resources);
};

/**
 * Updates the identified Resource in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param resourceId - The ID of the Resource to update
 * @param resource - The updated Resource
 */
export const UpdateResourceAsync = async (
  gameDocument: GameDocument,
  resourceId: string,
  resource: ResourceEntity
) => {
  let resources = GetResources(gameDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  resource.name = await GetNextResourceNameAsync(
    resources,
    resource.name,
    resource.id
  );
  resources[resourceIndex] = resource;
  return MergeResources(gameDocument, resources);
};

/**
 * Save the Resource in the Game Document it will check the resource is exist or not.
 * @param gameDocument - The Game Document to modify
 * @param resourceId - The ID of the Resource to update
 * @param resource - The updated Resource
 * @constructor
 */
export const SaveResourceAsync = async (
  gameDocument: GameDocument,
  resource: ResourceEntity
) => {
  const resourceExists = gameDocument?.resources?.find(
    (x) => x.id === resource.id
  );

  if (resourceExists) {
    return await UpdateResourceAsync(gameDocument, resource.id, resource);
  }
  if (!resourceExists) {
    return await AddResourceAsync(
      gameDocument,
      resource.name,
      resource.description,
      resource.type as ResourceType,
      resource.value!,
      resource.id
    );
  }
};

/**
 * Create a copy of the Resource in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param resourceId - The ID of the Resource to copy
 * @param copiedResourceId - The new ID of the copied Resource
 * @returns The updated Game Document
 */
export const CopyResourceAsync = async (
  gameDocument: GameDocument,
  resourceId: string,
  copiedResourceId: string = uuid()
) => {
  let resources = GetResources(gameDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  let resourceCopy = { ...resources[resourceIndex] };
  resourceCopy.id = copiedResourceId;
  resourceCopy.name += '-copy';
  resourceCopy.name = await GetNextResourceNameAsync(
    resources,
    resourceCopy.name,
    resourceCopy.id
  );
  resources.push(resourceCopy);
  return MergeResources(gameDocument, resources);
};

/**
 * Creates a new Azure BLOB Resource via the API.
 *
 * @remarks
 * This should technically be done via the services and redaxios/axios library.
 * However, direct fetch was used to debug issues with uploading.
 *
 * @param fileBlob - the blob file content to upload.
 * @returns The new resource entity
 */

export const CreateResourceFileAsync = async (
  fileBlob: Blob
): Promise<ResourceEntity> => {
  let endpoint = `${
    window?._env_?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL
  }${API_URL.resources}`;

  let formData = new FormData();
  formData.append('file', fileBlob);

  return fetch(endpoint, {
    method: 'POST',
    headers: { ...BearerHeader },
    body: formData
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json().then((data) => data.result as ResourceEntity);
  });
};

/**
 * Retrieves all resource entities from the game document.
 * @param gameDocument - The Game Document to modify
 */
export const GetResources = (gameDocument: GameDocument): ResourceEntity[] => {
  return (gameDocument.resources as ResourceEntity[]) ?? [];
};

/**
 * Retrieves a resource entity from the game document by id.
 * @param gameDocument - The Game Document to modify
 * @param resourceId - The ID of the Resource to update
 */
export const GetResourceEntity = (
  gameDocument: GameDocument,
  resourceId: string
): ResourceEntity => {
  let resources = GetResources(gameDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  return resources[resourceIndex];
};

/**
 * Retrieves a resource value from the game document by id.
 * @param gameDocument - The Game Document to modify
 * @param resourceId - The ID of the Resource to update
 */
export const GetResourceValue = (
  gameDocument: GameDocument,
  resourceId: string,
  language: string
): string => {
  if (language === undefined || language === '' || language === 'Default') {
    let resourceEntity = GetResourceEntity(gameDocument, resourceId);
    return resourceEntity?.value ?? '';
  } else {
    return GetResourcePackResourceValue(gameDocument!, language!, resourceId);
  }
};

export const GetResourcesByIds = (
  gameDocument: GameDocument | undefined,
  ids: string[]
) => {
  return (
    (gameDocument?.resources?.filter(
      (x) => ids.indexOf(x.id!) !== -1
    ) as ResourceEntity[]) ?? []
  );
};

/**
 * Retrieves all resource keys from the Entity. I.e. *ResId's
 * @param entity - the entity to retrieve Resource
 */
export const GetResourceKeys = (entity: EntityBase) => {
  return (Object.keys(entity) as (keyof typeof entity)[]).filter((key) =>
    key.toString().endsWith('ResId')
  );
};
