import { Col, Row } from 'react-bootstrap';
import { Skeleton } from '@progress/kendo-react-indicators';
import React from 'react';

export const AssessmentControlLoading = () => (
  <Row className={'align-items-center border-bottom py-3'}>
    <Col className={'fw-bold'}>Assessment:</Col>
    <Col xs={'auto'} className={'text-muted'}>
      <Skeleton shape={'text'} style={{ width: '160px', height: '38px' }} />
    </Col>
    <Col xs={12} sm={'auto'} className={'d-flex'}>
      <Skeleton
        shape={'rectangle'}
        className={'ml-2'}
        style={{ width: '160px', height: '38px' }}
      />
      <Skeleton
        shape={'rectangle'}
        className={'ml-2'}
        style={{ width: '160px', height: '38px' }}
      />
    </Col>
  </Row>
);
