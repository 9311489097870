import { AuthRequired } from '../components/authentication';
import { useAuth } from 'react-oidc-context';

export const SigninOidcPage = () => {
  const auth = useAuth();
  return (
    <AuthRequired>
      <pre style={{ fontSize: '0.7rem' }} className={'text-break mt-4'}>
        {JSON.stringify(auth, null, 2)}
      </pre>
    </AuthRequired>
  );
};
