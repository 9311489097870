import React from 'react';
import { StackLayout } from '@progress/kendo-react-layout';
import { AvatarPreview } from '../wizard';
import { Rank } from '../../types/scoreboard';

type Props = {
  items: Rank[];
  isShowNumber?: boolean;
  isShowScore?: boolean;
};

const Leaderboard = ({
  items,
  isShowNumber = false,
  isShowScore = true
}: Props) => {
  return (
    <StackLayout orientation={'vertical'} className={'w-100'}>
      {items
        .sort((a: Rank, b: Rank) => b.score - a.score)
        .map((item: Rank, index: number) => (
          <div
            key={index}
            id={`rank-${index}`}
            className={
              'd-flex flex-row justify-content-start align-items-center bg-light p-2 my-2'
            }>
            {isShowNumber && (
              <div className={'pr-1'}>
                <h4>
                  <strong>{`${index + 1}.`}</strong>
                </h4>
              </div>
            )}
            <div className={`${isShowNumber ? 'px-1' : 'pr-1'}`}>
              <AvatarPreview
                avatarSource={item.avatarImage}
                isShowPencil={false}
                imageSize={'h-4 w-4'}
              />
            </div>
            <div className={'px-1 flex-grow-1'}>
              <h4>
                <strong>{item.name}</strong>
              </h4>
            </div>
            <div className={'pl-1'}>
              {isShowScore && (
                <h4>
                  <strong>{item.score}</strong>
                </h4>
              )}
            </div>
          </div>
        ))}
    </StackLayout>
  );
};

export default Leaderboard;
