import { isEmpty } from 'lodash';

interface ListenerHandler {
  [key: string]: (args: {}) => void;
}

class ListenerManager {
  private _listHandler: ListenerHandler;

  /**
   * set list of handler
   * @param listHandler - The list of handler
   */
  constructor(listHandler: ListenerHandler) {
    this._listHandler = listHandler;
  }

  /**
   * get handler by key index
   * @param key - key index from message.action
   */
  getHandler(key: string) {
    return this._listHandler[key];
  }

  /**
   * execute the listener handler
   * @param message - message from signalR broadcast
   */
  listen(message: string) {
    if (isEmpty(message)) return;
    const parsedMessage = JSON.parse(message);
    const handler = this.getHandler(parsedMessage.action);
    if (parsedMessage.argument !== undefined) {
      handler(parsedMessage.argument);
    } else {
      handler(parsedMessage);
    }
  }
}

export default ListenerManager;
