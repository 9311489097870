import { TaskEntity, TimerEntity } from '../../types';
import { GameState, InventoryItem, TeamStateFull } from '../../types/state';
import { PlayerState, PlayerStateFull } from '../../types/state';
import _, { merge } from 'lodash';
import { PlayerContextState } from '../../contexts/player';
import { GetAreas, MergeArea } from './assets/areas';
import { AddZoneEntity, AddZoneEntityAsync, MergeZone } from './assets/zones';
import { MergeTitle } from './assets/titles';
import {
  AddTask,
  AddTaskAsync,
  GetTaskById,
  GetTimerById,
  UpdateTaskAsync,
  UpdateTimer,
  UpdateTimerAsync
} from './assets';
import { GetTaskById as GetGameTaskById } from '../game-document/assets';
import {
  TaskContentAnswerEntity,
  TaskEntity as TaskEntityPlayer
} from '../../types/entities';
import { GameDocument } from '../../types/game-document';
import {
  AlgorithmControlStructure,
  AlgorithmStep
} from '../../types/algorithm';
import { GetInGameSetting, GetPreGameSetting } from '../game-document/settings';
import { GameDocumentState } from '../../contexts/game-document';
import { distributeTask, distributeTaskAsync } from '../../services/players';
import { TimerStatus } from '../../types/entities/timer';
import { TaskContentForm } from '../../types/game-document/entities/task-content';
import cloneDeep from 'lodash.clonedeep';
import { Coordinate } from 'ol/coordinate';

/**
 * Function to check if arg is PlayerStateFull or TeamStateFull
 * @param args response from AlgorithmEventHandler
 * @param playerCode playerCode in playerState
 * @returns arg is PlayerStateFull
 */

export const IsArgsPlayerStateFull = (
  args: PlayerStateFull | TeamStateFull,
  playerCode: string
): args is PlayerStateFull => {
  return playerCode !== '' && args.code === playerCode;
};

export const PlayerAlgorithmHandlerAsync = async (
  gameDocument: GameDocumentState,
  player: PlayerContextState,
  algorithm: AlgorithmStep | AlgorithmControlStructure
): Promise<PlayerStateFull> => {
  const { operation = '' } = algorithm;

  switch (operation) {
    case 'navigateToWorldMap':
      return merge(player.playerState, NavigateToWorldMap(player));
    case 'navigateToZone':
      return merge(
        player.playerState,
        await PlayerNavigateToZoneAsync(
          player,
          algorithm.argumentAssetId! as string
        )
      );
    case 'distributeTask':
      const taskIds: string[] = await distributeTaskAsync(
        gameDocument?.gameCode!,
        player?.playerState?.code!,
        {
          stepId: algorithm?.id,
          taskIds: algorithm?.argumentAssetId as string[],
          quantity: algorithm?.argumentQuantity
        }
      );

      for (const taskId of taskIds) {
        merge(player.playerState, await ShowTaskAsync(player, taskId));
      }

      return merge(player.playerState);
    case 'randomTask':
      await ShowRandomTaskAsync(algorithm, player);
      return merge(player.playerState);
    case 'giveItem':
      return merge(
        player.playerState,
        await PlayerGiveItemAsync(
          player,
          algorithm.argumentAssetId! as string,
          algorithm.argumentQuantity ?? 0
        )
      );
    case 'removeItem':
      return merge(
        player.playerState,
        await PlayerRemoveItemAsync(
          player,
          algorithm.argumentAssetId! as string,
          algorithm.argumentQuantity ?? 0
        )
      );
    case 'giveScore':
      return merge(
        player.playerState,
        GiveScore(player, algorithm.argumentQuantity ?? 0)
      );
    case 'removeScore':
      return merge(
        player.playerState,
        RemoveScore(player, algorithm.argumentQuantity ?? 0)
      );
    case 'giveTitle':
      return merge(
        player.playerState,
        await PlayerGiveTheTitleAsync(
          player,
          algorithm.argumentAssetId! as string
        )
      );
    case 'removeTitle':
      return merge(
        player.playerState,
        await PlayerRemoveTheTitleAsync(
          player,
          algorithm.argumentAssetId! as string
        )
      );
    case 'hideArea':
      return merge(
        player.playerState,
        HideZoneAreaAsync(player, algorithm.argumentAssetId! as string)
      );
    case 'showArea':
      return merge(
        player.playerState,
        await ShowZoneAreaAsync(player, algorithm.argumentAssetId! as string)
      );
    case 'hideTask':
      return merge(
        player.playerState,
        await HideTaskAsync(player, algorithm.argumentAssetId! as string)
      );
    case 'openTask':
      return merge(
        player.playerState,
        await OpenTask(player, algorithm.argumentAssetId! as string)
      );
    case 'showTask':
      return merge(
        player.playerState,
        await ShowTaskAsync(player, algorithm.argumentAssetId! as string)
      );
    case 'pauseTimer':
      return merge(
        player.playerState,
        PauseTimer(
          gameDocument,
          player,
          algorithm.argumentAssetId! as string,
          'pause'
        )
      );
    case 'stopTimer':
      return merge(
        player.playerState,
        StopTimer(
          gameDocument,
          player,
          algorithm.argumentAssetId! as string,
          'stop'
        )
      );
    case 'startTimer':
      return merge(
        player.playerState,
        StartTimer(
          gameDocument,
          player,
          algorithm.argumentAssetId! as string,
          'start'
        )
      );
    case 'resumeTimer':
      return merge(
        player.playerState,
        ResumeTimer(
          gameDocument,
          player,
          algorithm.argumentAssetId! as string,
          'resume'
        )
      );
    case 'completeTask':
      return merge(player.playerState, CompleteTask(player));
    default:
      return merge(player.playerState);
  }
};

export const PlayerAlgorithmHandler = (
  gameDocument: GameDocumentState,
  player: PlayerContextState,
  algorithm: AlgorithmStep | AlgorithmControlStructure
) => {
  const { operation = '' } = algorithm;

  switch (operation) {
    case 'navigateToWorldMap':
      return merge(player.playerState, NavigateToWorldMap(player));
    case 'navigateToZone':
      return merge(
        player.playerState,
        PlayerNavigateToZone(player, algorithm.argumentAssetId! as string)
      );
    case 'distributeTask':
      distributeTask(gameDocument?.gameCode!, player?.playerState?.code!, {
        stepId: algorithm?.id,
        taskIds: algorithm?.argumentAssetId as string[],
        quantity: algorithm?.argumentQuantity
      }).then((response) => {
        for (const taskId of response?.data) {
          merge(player.playerState, ShowTaskAsync(player, taskId));
        }
        return player.playerState;
      });

      return merge(player.playerState);
    case 'randomTask':
      return merge(player.playerState, ShowRandomTask(algorithm, player));
    case 'giveItem':
      return merge(
        player.playerState,
        PlayerGiveItemAsync(
          player,
          algorithm.argumentAssetId! as string,
          algorithm.argumentQuantity ?? 0
        )
      );
    case 'removeItem':
      return merge(
        player.playerState,
        PlayerRemoveItemAsync(
          player,
          algorithm.argumentAssetId! as string,
          algorithm.argumentQuantity ?? 0
        )
      );
    case 'giveScore':
      return merge(
        player.playerState,
        GiveScore(player, algorithm.argumentQuantity ?? 0)
      );
    case 'removeScore':
      return merge(
        player.playerState,
        RemoveScore(player, algorithm.argumentQuantity ?? 0)
      );
    case 'giveTitle':
      return merge(
        player.playerState,
        PlayerGiveTheTitleAsync(player, algorithm.argumentAssetId! as string)
      );
    case 'removeTitle':
      return merge(
        player.playerState,
        PlayerRemoveTheTitleAsync(player, algorithm.argumentAssetId! as string)
      );
    case 'hideArea':
      return merge(
        player.playerState,
        HideZoneAreaAsync(player, algorithm.argumentAssetId! as string)
      );
    case 'showArea':
      return merge(
        player.playerState,
        ShowZoneAreaAsync(player, algorithm.argumentAssetId! as string)
      );
    case 'hideTask':
      return merge(
        player.playerState,
        HideTaskAsync(player, algorithm.argumentAssetId! as string)
      );
    case 'openTask':
      return merge(
        player.playerState,
        OpenTask(player, algorithm.argumentAssetId! as string)
      );
    case 'showTask':
      return merge(
        player.playerState,
        ShowTaskAsync(player, algorithm.argumentAssetId! as string)
      );
    case 'pauseTimer':
      return merge(
        player.playerState,
        PauseTimer(
          gameDocument,
          player,
          algorithm.argumentAssetId! as string,
          'pause'
        )
      );
    case 'stopTimer':
      return merge(
        player.playerState,
        StopTimer(
          gameDocument,
          player,
          algorithm.argumentAssetId! as string,
          'stop'
        )
      );
    case 'startTimer':
      return merge(
        player.playerState,
        StartTimer(
          gameDocument,
          player,
          algorithm.argumentAssetId! as string,
          'start'
        )
      );
    case 'resumeTimer':
      return merge(
        player.playerState,
        ResumeTimer(
          gameDocument,
          player,
          algorithm.argumentAssetId! as string,
          'resume'
        )
      );
    case 'completeTask':
      return merge(player.playerState, CompleteTask(player));
    default:
      return merge(player.playerState);
  }
};

/**
 * Get all player by team id.
 * @param gameState -
 * @param teamCode -
 * @constructor
 */
export const GetPlayersByTeamId = (gameState: GameState, teamCode: string) => {
  return gameState.players.find((x) => x.teamCode === teamCode);
};

/**
 * Get all timers from PlayerStateFull.
 * @param player - The player as PlayerStateFull
 * @constructor
 */
export const GetTimers = (player: PlayerStateFull | undefined) => {
  return player?.timers ?? [];
};

export const PlayerNavigateToZoneAsync = async (
  player: PlayerContextState,
  zoneId: string
) => {
  let zones = player.playerState?.zones ?? [];
  if (zones.findIndex((f) => f.id === zoneId) === -1) {
    await AddZoneEntityAsync(player, {
      id: zoneId,
      timeStampUtc: new Date().getTime(),
      ZoneAreas: []
    });
  }
  await NavigateToLocationAsync(player, zoneId);

  return MergeZone(player.playerState!, zones);
};

export const PlayerNavigateToZone = (
  player: PlayerContextState,
  zoneId: string
) => {
  let zones = player.playerState?.zones ?? [];
  if (zones.findIndex((f) => f.id === zoneId) === -1) {
    AddZoneEntity(player, {
      id: zoneId,
      timeStampUtc: new Date().getTime(),
      ZoneAreas: []
    });
  }
  NavigateToLocation(player, zoneId);

  return MergeZone(player.playerState!, zones);
};

export const HideZoneArea = (player: PlayerContextState, areaId: string) => {
  return ShowHideZoneArea(player, areaId, false);
};

export const ShowZoneArea = (player: PlayerContextState, areaId: string) => {
  return ShowHideZoneArea(player, areaId, true);
};

export const ShowHideZoneArea = (
  player: PlayerContextState,
  areaId: string,
  isVisible: boolean = true
) => {
  let areas = GetAreas(player.playerState!);
  let areaIndex = areas.findIndex((f) => f.id === areaId);
  if (areaIndex === -1) {
    areas.push({
      id: areaId,
      timestampUtc: new Date().getTime(),
      isVisible: isVisible
    });
  } else areas[areaIndex].isVisible = isVisible;
  return MergeArea(player.playerState!, areas);
};

export const HideZoneAreaAsync = async (
  player: PlayerContextState,
  areaId: string
) => {
  return ShowHideZoneAreaAsync(player, areaId, false);
};

export const ShowZoneAreaAsync = async (
  player: PlayerContextState,
  areaId: string
) => {
  return ShowHideZoneAreaAsync(player, areaId, true);
};

export const ShowHideZoneAreaAsync = async (
  player: PlayerContextState,
  areaId: string,
  isVisible: boolean = true
) => {
  let areas = GetAreas(player.playerState!);
  let areaIndex = areas.findIndex((f) => f.id === areaId);
  if (areaIndex === -1) {
    areas.push({
      id: areaId,
      timestampUtc: new Date().getTime(),
      isVisible: isVisible
    });
  } else areas[areaIndex].isVisible = isVisible;
  return MergeArea(player.playerState!, areas);
};

export const PlayerGiveTheTitleAsync = async (
  player: PlayerContextState,
  titleId: string
) => {
  let titleIndex = player?.playerState?.titles?.findIndex((f) => f === titleId);
  if (titleIndex === -1) {
    player.playerState?.titles!.push(titleId);
  }
  return MergeTitle(player.playerState!, player.playerState!.titles!);
};

export const PlayerRemoveTheTitleAsync = async (
  player: PlayerContextState,
  titleId: string
) => {
  let titles = player.playerState!.titles! ?? [];
  let titleIndex = titles.findIndex((f) => f === titleId);
  titles.splice(titleIndex, 1);
  return MergeTitle(player.playerState!, player.playerState!.titles!);
};

export const NavigateToWorldMap = (player: PlayerContextState) => {
  return merge(player.playerState, { location: 'world-map' });
};

export const NavigateToLocation = (
  player: PlayerContextState,
  locationId: string
) => {
  return merge(player.playerState, { location: locationId });
};

export const NavigateToLocationAsync = async (
  player: PlayerContextState,
  locationId: string
) => {
  return merge(player.playerState, { location: locationId });
};

export const UpdatePlayerCoordinate = (
  player: PlayerContextState,
  coordinate: Coordinate | [number, number]
) => {
  const [longitude, latitude] = coordinate;
  return merge(player.playerState, {
    coordinates: { ...player.playerState?.coordinates, longitude, latitude }
  });
};

export const UpdatePlayerAccuracy = (
  player: PlayerContextState,
  accuracy: number
) => {
  return merge(player.playerState, {
    coordinates: { ...player.playerState?.coordinates, accuracy }
  });
};

/**
 * Set show task in game-engine
 * @param player - The PlayerState
 * @param taskId - The task ID you wan to show
 */
export const ShowTask = (player: PlayerContextState, taskId: string) => {
  return ShowHideTask(player, taskId, true);
};

/**
 * Set show task async in game-engine
 * @param player - The PlayerState
 * @param taskId - The task ID you wan to show
 */
export const ShowTaskAsync = async (
  player: PlayerContextState,
  taskId: string
) => {
  return ShowHideTaskAsync(player, taskId, true);
};

/**
 * Set hide task in game-engine
 * @param player - The PlayerState
 * @param taskId - The task ID you wan to hode
 */
export const HideTask = (player: PlayerContextState, taskId: string) => {
  return ShowHideTask(player, taskId, false);
};

/**
 * Set hide task async in game-engine
 * @param player - The PlayerState
 * @param taskId - The task ID you wan to hode
 */
export const HideTaskAsync = async (
  player: PlayerContextState,
  taskId: string
) => {
  return ShowHideTaskAsync(player, taskId, false);
};

/**
 * Set hide/show task in game-engine
 * It will check the task in game-engin already exist or not
 * If task exist then update the task, If task doesn't exist it will push a new data
 * @param player - The PlayerState
 * @param taskId - The task ID you wan to hide/show
 */
const ShowHideTask = (
  player: PlayerContextState,
  taskId: string,
  isVisible: boolean
) => {
  let task = GetTaskById(player, taskId);
  if (task) {
    task.isVisible = isVisible;
    return UpdateTaskAsync(player, taskId, task);
  }

  let newTask: TaskEntity = {
    id: taskId,
    isVisible: isVisible
  };

  return AddTask(player, newTask);
};

/**
 * Set hide/show task in game-engine
 * It will check the task in game-engin already exist or not
 * If task exist then update the task, If task doesn't exist it will push a new data
 * @param player - The PlayerState
 * @param taskId - The task ID you wan to hide/show
 */
const ShowHideTaskAsync = async (
  player: PlayerContextState,
  taskId: string,
  isVisible: boolean
) => {
  let task = GetTaskById(player, taskId);
  if (task) {
    task.isVisible = isVisible;
    return UpdateTaskAsync(player, taskId, task);
  }

  let newTask: TaskEntity = {
    id: taskId,
    isVisible: isVisible
  };

  return AddTaskAsync(player, newTask);
};

/**
 * Player Show random task Async in game-engine
 * It will random the task in game-engine and merge into player state
 * @param gameDocument - The GameDocument for get the event argument
 * @param player - The Player for updated task random result
 */
export const ShowRandomTaskAsync = async (
  algorithm: AlgorithmStep,
  player: PlayerContextState
) => {
  let step = algorithm;
  if (step !== undefined) {
    //check if random task has argumentAssetId
    if (step.argumentAssetId && step.argumentQuantity) {
      let randomTask = _.sampleSize(
        _.differenceWith(
          step.argumentAssetId,
          player.playerState?.tasks!,
          (i: string, task: TaskEntity) => i === task.id
        ),
        step.argumentQuantity
      );

      (randomTask as string[]).forEach((taskId: string) => {
        //Add task to player
        const taskPlayer = GetTaskById(player, taskId!);
        //If not exist then add
        if (!taskPlayer) {
          let newTask: TaskEntityPlayer = {
            id: taskId,
            isVisible: true
          };

          AddTaskAsync(player, newTask);
        }
      });
    }
  }
};

/**
 * Player Show random task in game-engine
 * It will random the task in game-engine and merge into player state
 * @param gameDocument - The GameDocument for get the event argument
 * @param player - The Player for updated task random result
 */
export const ShowRandomTask = (
  algorithm: AlgorithmStep,
  player: PlayerContextState
) => {
  let step = algorithm;
  if (step !== undefined) {
    //check if random task has argumentAssetId
    if (step.argumentAssetId && step.argumentQuantity) {
      let randomTask = _.sampleSize(
        _.differenceWith(
          step.argumentAssetId,
          player.playerState?.tasks!,
          (i: string, task: TaskEntity) => i === task.id
        ),
        step.argumentQuantity
      );

      (randomTask as string[]).forEach((taskId: string) => {
        //Add task to player
        const taskPlayer = GetTaskById(player, taskId!);
        //If not exist then add
        if (!taskPlayer) {
          let newTask: TaskEntityPlayer = {
            id: taskId,
            isVisible: true
          };

          AddTask(player, newTask);
        }
      });
    }
  }
};

/**
 * Player Add list of task
 * It will merge into player state
 * @param taskIds - The list of task ids
 * @param player - The Player for updated list of task
 */
export const AddListTask = async (
  player: PlayerContextState,
  taskIds: string[]
) => {
  if (taskIds !== undefined) {
    taskIds?.forEach((taskId: string) => {
      //check if the player have a task
      const taskPlayer = GetTaskById(player, taskId!);
      //If not exist then add
      if (!taskPlayer) {
        let newTask: TaskEntityPlayer = {
          id: taskId,
          isVisible: true
        };
        AddTaskAsync(player, newTask);
      }
    });
  }
  return merge(player.playerState, taskIds);
};

export const CompleteTask = async (player: PlayerContextState) => {
  if (player.playerState?.openedTask) {
    const openedTaskIds = player.playerState.openedTask;

    let tasks: TaskEntityPlayer[];

    tasks = player.playerState?.tasks!.map((item) =>
      openedTaskIds.includes(item.id!)
        ? { ...item, isForceComplete: true }
        : item
    );

    return merge(player.playerState!, { tasks });
  }

  return merge(player.playerState, {});
};

/**
 * Player Show random task by tasks
 * It will random the task in game-engine and merge into player state
 * @param taskList - The Task List to receive player state
 * @param player - The Player for updated task random result
 */
export const ShowRandomTaskByTaskList = async (
  taskList: string[],
  player: PlayerContextState
) => {
  if (taskList) {
    (taskList as string[]).forEach((taskId: string) => {
      const taskPlayer = GetTaskById(player, taskId!);

      if (!taskPlayer) {
        //Add task to player
        const taskPlayer = GetTaskById(player, taskId!);
        //If not exist then add
        if (!taskPlayer) {
          let newTask: TaskEntityPlayer = {
            id: taskId,
            isVisible: true
          };

          AddTaskAsync(player, newTask);
        }
      }
    });
  }
};

/**
 * Add score to the game player state
 * @param gamePlayer - The PlayerStateFull state
 * @param score - The score you want to add
 */
export const GiveScore = (player: PlayerContextState, score: number) => {
  let newScore: number = 0;

  if (player?.playerState?.score !== undefined) {
    newScore = player?.playerState?.score + score;
  } else {
    newScore += score;
  }

  return merge(player.playerState, { score: newScore });
};

/**
 * Adjust score to the game player state
 * @param gamePlayer - The PlayerStateFull state
 * @param score - The score you want to add
 */
export const AdjustScore = (player: PlayerContextState, score: number) => {
  let newScore: number = score ?? 0;
  return merge(player.playerState, { score: newScore });
};

/**
 * Remove score to the game player state
 * @param gamePlayer - The PlayerStateFull state
 * @param score - The score you want to remove
 */
export const RemoveScore = (player: PlayerContextState, score: number) => {
  let newScore: number = 0;

  if (player?.playerState?.score !== undefined) {
    newScore = player?.playerState?.score - score;
  } else {
    newScore -= score;
  }

  return merge(player?.playerState, { score: newScore });
};

/**
 * Push to open task array [Async]
 * @return all data of task in the GameDocument.assets.task
 */
export const OpenTaskAsync = async (
  player: PlayerContextState,
  taskId: string
) => {
  player.playerState = merge(
    player.playerState,
    await ShowTaskAsync(player, taskId)
  );

  if (player.playerState.openedTask === undefined) {
    player.playerState = merge(player.playerState, {
      openedTask: [`${taskId}`]
    });
  } else if (
    player.playerState.openedTask &&
    !player.playerState.openedTask?.includes(taskId)
  ) {
    player.playerState.openedTask!.push(taskId);
  }

  return player.playerState;
};

/**
 * Push to open task array
 * @return all data of task in the GameDocument.assets.task
 */
export const OpenTask = (
  player: PlayerContextState,
  taskId: string,
  isVisible: boolean = true
) => {
  player.playerState = merge(
    player.playerState,
    ShowHideTask(player, taskId, isVisible)
  );

  if (player.playerState.openedTask === undefined) {
    player.playerState = merge(player.playerState, {
      openedTask: [`${taskId}`]
    });
  } else if (
    player.playerState.openedTask &&
    !player.playerState.openedTask?.includes(taskId)
  ) {
    player.playerState.openedTask!.push(taskId);
  }

  return player.playerState;
};

/**
 *  Remove current opened task content
 * @param player - The player state
 */
export const ClearOpenedTask = (player: PlayerStateFull) => {
  let newPlayer = cloneDeep(player);
  newPlayer.openedTask?.pop();
  return newPlayer;
};

/**
 * Get Task content by Task ID
 * @param gameDocument - The GameDocument local storage as datasource
 * @param taskId - The task ID of task content
 * @return all data of task in the GameDocument.assets.task
 */
export const GetTaskContentByTaskId = async (
  gameDocument: GameDocument,
  taskId: string
) => {
  const gameTask = GetGameTaskById(gameDocument, taskId);

  return gameDocument?.assets?.taskContents?.find(
    (x) => x.id === gameTask!.taskContentId
  );
};

/**
 * Add timer to the player state
 * @param player - The Player state
 * @param timerId -
 */
export const AddTimer = (
  player: PlayerContextState,
  timerId: string,
  status?: TimerStatus
) => {
  let timers: TimerEntity[] = GetTimers(player.playerState!);
  const timerIndex = timers.findIndex((x: TimerEntity) => x.id === timerId);

  if (timerIndex === -1) {
    timers.push({
      id: timerId,
      timestampUtc: new Date().getTime(),
      status: status
    });
  }
  return merge(player.playerState, { timers: timers });
};

/**
 * Start timer to the game player state
 * @param player - The GamePlayer state
 * @param gamePlayer -
 * @param gameDocument -
 */
export const GetTimerGameDoc = async (
  player: PlayerContextState,
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  let gameDocTimer: TimerEntity[] = gameDocument?.assets.timers ?? [];
  let timers: TimerEntity[] = GetTimers(player.playerState!);
  gameDocTimer.forEach((t: TimerEntity) => {
    const timerIndex = timers.findIndex((x: TimerEntity) => x.id === t.id);
    if (timerIndex === -1) {
      timers.push({
        id: t.id,
        timestampUtc: new Date().getTime(),
        startedTime: new Date().toISOString(),
        status: 'start'
      });
    }
  });

  return merge(gamePlayer, { timers: timers });
};

/**
 * Start timer to the game player state
 * @param player - The PlayerContextState that you want to update
 * @param timerId - The id of timer that you want to pased
 * @param status - The 'start' timer status
 */
export const StartTimer = (
  gameDocument: GameDocumentState,
  player: PlayerContextState,
  timerId: string,
  status: 'start'
) => {
  const playerTimers = GetTimerById(player, timerId);
  if (playerTimers) {
    //Update Status
    playerTimers.timestampUtc = new Date().getTime();
    playerTimers.status = status;
    return UpdateTimer(player, timerId, playerTimers);
  } else {
    return AddTimer(player, timerId, 'start');
  }
};

/**
 * Stop the timer to the game player state
 * @param player - The PlayerContextState that you want to update
 * @param timerId - The id of timer that you want to pased
 * @param status - The 'stop' timer status
 */
export const StopTimer = (
  gameDocument: GameDocumentState,
  player: PlayerContextState,
  timerId: string,
  status: 'stop'
) => {
  const playerTimers = GetTimerById(player, timerId);
  if (playerTimers) {
    //Update Status
    playerTimers.timestampUtc = new Date().getTime();
    playerTimers.status = status;
    playerTimers.pausedTime = new Date().toISOString();
    return UpdateTimer(player, timerId, playerTimers);
  } else {
    return AddTimer(player, timerId, 'stop');
  }
};

/**
 * Pause the timer to the game player state
 * @param player - The PlayerContextState that you want to update
 * @param timerId - The id of timer that you want to pased
 * @param status - The 'pause' timer status
 */
export const PauseTimer = (
  gameDocument: GameDocumentState,
  player: PlayerContextState,
  timerId: string,
  status: 'pause'
) => {
  const playerTimers = GetTimerById(player, timerId);

  if (playerTimers) {
    //Update Status
    playerTimers.timestampUtc = new Date().getTime();
    playerTimers.status = status;
    playerTimers.pausedTime = new Date().toISOString();

    return UpdateTimer(player, timerId, playerTimers);
  } else {
    return AddTimer(player, timerId, 'pause');
  }
};

/**
 * Resume the timer to the game player state
 * @param player - The PlayerContextState that you want to update
 * @param timerId - The id of timer that you want to pased
 * @param status - The 'resume' timer status
 */
export const ResumeTimer = (
  gameDocument: GameDocumentState,
  player: PlayerContextState,
  timerId: string,
  status: 'resume'
) => {
  const playerTimers = GetTimerById(player, timerId);

  if (playerTimers) {
    playerTimers.timestampUtc = new Date().getTime();
    playerTimers.status = status;

    return UpdateTimer(player, timerId, playerTimers);
  } else {
    return AddTimer(player, timerId, 'resume');
  }
};

/**
 * Update timer to the game player state
 * @param player - The PlayerContextState that you want to update
 * @param timerId - The id of timer that you want to pased
 * @param status - The new assigned timer status
 */
export const UpdateStatusTimer = async (
  player: PlayerContextState,
  timerId: string,
  status: TimerStatus
): Promise<PlayerStateFull | (PlayerStateFull & { timers: TimerEntity[] })> => {
  let timers = GetTimerById(player, timerId);

  if (timers) {
    timers.timestampUtc = new Date().getTime();
    timers.status = status;
    return UpdateTimerAsync(player, timerId, timers);
  }
  return player.playerState!;
};

export const StartAllTimers = (player: PlayerContextState) => {
  const playerTimers: TimerEntity[] = player.playerState?.timers ?? [];
  for (const timer of playerTimers) {
    timer.status = 'start';
  }
  return player.playerState!;
};

export const PauseAllTimers = (player: PlayerContextState) => {
  const playerTimers: TimerEntity[] =
    player.playerState?.timers?.filter(
      (x) => x.status === 'start' || x.status === 'resume'
    ) ?? [];
  for (const timer of playerTimers) {
    timer.status = 'pause';
  }
  return player.playerState!;
};

export const ResumeAllTimers = (player: PlayerContextState) => {
  const playerTimers: TimerEntity[] =
    player.playerState?.timers?.filter((x) => x.status === 'pause') ?? [];
  for (const timer of playerTimers) {
    timer.status = 'resume';
  }
  return player.playerState!;
};

export const StopAllTimers = (player: PlayerContextState) => {
  const playerTimers: TimerEntity[] = player.playerState?.timers ?? [];
  for (const timer of playerTimers) {
    timer.status = 'stop';
  }
  return player.playerState!;
};

/**
 * Set visibility of game info
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowGameInfo = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const inGameSettings = GetInGameSetting(gameDocument);
    if (inGameSettings) {
      return inGameSettings.showInGameInfo;
    }
  }
  return true;
};

/**
 * Set visibility of game chat
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowGameChat = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const inGameSettings = GetInGameSetting(gameDocument);
    return inGameSettings.showInGameAllPlayerChat;
  }
  return true;
};

/**
 * Set visibility of team chat
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowTeamChat = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const inGameSettings = GetInGameSetting(gameDocument);
    return inGameSettings.showInGameTeamChat;
  }
  return true;
};

/**
 * Set visibility of direct chat
 * @param gameDocument - The Game document
 */
export const ShowDirectChat = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const inGameSettings = GetInGameSetting(gameDocument);
    return inGameSettings.showInGameDirectChat;
  }

  if (gamePlayer?.status === 'waiting') {
    const inGameSettings = GetPreGameSetting(gameDocument);
    return inGameSettings.showPreGameDirectChat;
  }

  return false;
};

/**
 * Set visibility of task name
 * @param gamePlayer - The GamePlayer state
 * @param gameDocument - The Game document
 */
export const ShowTaskNameVisibility = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const inGameSettings = GetInGameSetting(gameDocument);
    return inGameSettings.showTasknameVisibility;
  }
  return false;
};

/**
 * Set visibility of Leaderboard
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowLeaderboard = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const inGameSettings = GetInGameSetting(gameDocument);
    return inGameSettings.showInGameLeaderboard;
  }
  return true;
};

/**
 * Set visibility of scoreboard
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowScoreboard = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const inGameSettings = GetInGameSetting(gameDocument);
    return inGameSettings.showInGameScoreboard;
  }
  return true;
};

/**
 * Set visibility of team or player score
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowTeamOrPlayerScore = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const inGameSettings = GetInGameSetting(gameDocument);
    return inGameSettings.showInGamePlayerTeamScore;
  }
  return true;
};

/**
 * To add item quantity for the player
 * @param gamePlayer - The Player Context
 * @param itemId - The item id
 * @param quantity - the item quantity
 */
export const PlayerGiveItemAsync = async (
  gamePlayer: PlayerContextState,
  itemId: string,
  quantity: number
) => {
  let inventories = gamePlayer.playerState?.inventory ?? [];
  let inventoryIndex = inventories.findIndex(
    (inventory) => inventory.id === itemId
  );
  if (inventoryIndex === -1) {
    inventories.push({
      id: itemId,
      quantity: quantity
    });
  } else {
    inventories[inventoryIndex].quantity += quantity;
  }

  return MergeItem(gamePlayer.playerState!, inventories);
};

/**
 * To reduce item quantity for the player
 * @param gamePlayer - The TeamPlayer state
 * @param itemId - The item id
 * @param quantity - the item quantity
 */
export const PlayerRemoveItemAsync = async (
  gamePlayer: PlayerContextState,
  itemId: string,
  quantity: number
) => {
  let inventories = gamePlayer.playerState?.inventory ?? [];
  let inventoryIndex = inventories.findIndex(
    (inventory) => inventory.id === itemId
  );
  if (inventoryIndex !== -1) {
    inventories[inventoryIndex].quantity -= quantity;
    //remove if quantity of inventory is 0
    if (inventories[inventoryIndex].quantity === 0) {
      inventories.splice(inventoryIndex, 1);
    }
  }
  return MergeItem(gamePlayer.playerState!, gamePlayer.playerState!.inventory!);
};

export const MergeItem = (
  player: PlayerStateFull,
  items: Array<InventoryItem>
) => {
  return merge(player, { inventory: items });
};

/**
 * Check event step already executed
 * @param player: - The player state
 * @param stepId - The step id
 */
export const IsStepExecuted = (player: PlayerContextState, stepId: string) => {
  if (!player?.playerState?.executedStepIds?.find((x) => x === stepId)) {
    return false;
  } else {
    return true;
  }
};
/**
 * Add step id if already executed
 * @param player: - The player state
 * @param stepId - The step id
 */
export const AddExecutedStepAsync = (
  player: PlayerContextState,
  stepId: string
) => {
  let executedStepIds = player?.playerState?.executedStepIds ?? [];
  executedStepIds?.push(stepId);
  return merge(player?.playerState, { executedStepIds });
};

export const setPlayerScoreReduction = (
  player: PlayerContextState,
  taskId: string,
  forms: TaskContentForm[],
  formIndex: number,
  currentTask: TaskEntity,
  formId: string
) => {
  if (player.playerState && forms && taskId) {
    if (currentTask) {
      if (!currentTask.taskContentFormAnswers) {
        currentTask.taskContentFormAnswers = [];
      }

      if (formIndex >= 0) {
        const taskContentForm = currentTask.taskContentFormAnswers![formIndex];
        if (!taskContentForm) {
          let newForm: TaskContentAnswerEntity = {
            formId: formId,
            isScoringReduction: forms[formIndex].isScoringReduction,
            scoringReductions: forms[formIndex].scoringReduction
          };
          newForm.scoringReductions = {
            ...newForm.scoringReductions,
            totalScoreReduction: 0
          };

          currentTask.taskContentFormAnswers?.push(newForm);
        }
      }

      return UpdateTaskAsync(player, taskId, currentTask);
    }
  }
};
