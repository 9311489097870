import React, { useContext } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { FeedbackDetailOptions } from '../../types/feedback-document';
import { GetFeedbackResourceValue } from '../../utils/feedback-document';
import { FeedbackDocumentContext } from '../../contexts/feedback-document';

type AnswerOptionProps = {
  group?: string;
  checkboxOptions?: FeedbackDetailOptions[];
  onChange?: (index: number, value: boolean) => void;
};

const AnswerCheckbox = ({
  group,
  checkboxOptions,
  onChange = () => {}
}: AnswerOptionProps) => {
  const [state] = useContext(FeedbackDocumentContext);
  const onCheckboxChangeHandler = (index: number, value: boolean) => {
    onChange(index, value);
  };

  return (
    <div id={'answer-checkbox'}>
      {checkboxOptions &&
        checkboxOptions.length > 0 &&
        checkboxOptions.map((option: FeedbackDetailOptions, index: number) => {
          const labelCheckbox = GetFeedbackResourceValue(
            state?.feedbackDocument!,
            option?.optionResId,
            state?.selectedLanguageId
          );

          return (
            <div
              className={`d-flex flex-row justify-content-center align-items-center card border border-2 w-100 my-2 border-dark`}
              key={index}>
              <div className={'card-body flex-grow-1'}>
                <Checkbox
                  id={`checkbox-option-${index}`}
                  name={group}
                  className={'input-checkbox'}
                  size={'large'}
                  label={labelCheckbox}
                  checked={option?.checked}
                  onChange={(e) =>
                    onCheckboxChangeHandler(index, e.target.value as boolean)
                  }
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default AnswerCheckbox;
