import { GameDocument } from '../../../types/game-document';
import { GetResourceValue } from '../resources';

/**
 * Get all titles from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTitles = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.titles ?? [];
};

/**
 * Get title's image
 * @param gameDocument
 * @param id
 * @param languageId
 * @returns
 */
export const GetTitleImage = (
  gameDocument: GameDocument,
  id: string,
  languageId?: string
) => {
  let item = gameDocument?.assets?.titles?.find((x) => x.id === id);

  return GetResourceValue(gameDocument!, item?.imageResId!, languageId ?? '');
};

/**
 *
 * @param gameDocument Get title's description
 * @param id
 * @param languageId
 * @returns
 */
export const GetTitle = (
  gameDocument: GameDocument,
  id: string,
  languageId?: string
) => {
  let item = gameDocument?.assets?.titles?.find((x) => x.id === id);

  return GetResourceValue(gameDocument!, item?.titleResId!, languageId ?? '');
};
