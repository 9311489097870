import React, { useContext, useEffect, useMemo } from 'react';
import Row from 'react-bootstrap/esm/Row';
import { AssessmentEntity } from '../../types/state/assessment/assessment';
import Col from 'react-bootstrap/esm/Col';
import {
  Action,
  Measurable
} from '../../types/state/assessment/assessment-players-answer-state';
import { AssessmentOutcome } from './assessment-outcome';
import { Container } from 'react-bootstrap';
import { AssessmentAddMeasurables } from './assessment-add-measurables';
import { PlayerContext } from '../../contexts/player';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { generateTitleById } from '../../utils/game-document/display-languages';
interface MeasurablesProps {
  assessment?: AssessmentEntity;
  actions?: Action[];
  measurables?: Measurable[];
  showAddAction?: boolean;
  showAverageRate?: boolean;
  showDescription?: boolean;
  placeholder?: string;
  className?: string;
  children?: React.ReactNode;
  setIsShouldShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AssessmentMeasurables = ({ ...props }: MeasurablesProps) => {
  const [player] = useContext(PlayerContext);
  const { playerState: assessmentDocument } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const orderedAction = useMemo(
    () =>
      props.assessment?.assessmentOutcomes.map((outcome) => ({
        ...outcome,
        actions: props.actions
          ?.filter((action) => action.outcomeId === outcome.id)
          .sort((a, b) => (b.votes ?? 0) - (a.votes ?? 0))
      })),
    [props?.assessment?.assessmentOutcomes, props?.actions]
  );

  useEffect(() => {
    if (props.measurables && player.assessmentState) {
      const playerCode = player.assessmentState.code;
      if (playerCode) {
        const myMeasurables = props.measurables.filter(
          (measureable) => measureable.playerCode === playerCode
        );
        props.setIsShouldShowDialog(
          !Boolean(props.measurables.length) || !Boolean(myMeasurables.length)
        );
      }
    }
  }, [props.measurables, player.assessmentState]);

  return (
    <div className={'d-flex flex-column'}>
      <Container>
        <Row>
          <Col
            className={
              'd-flex flex-column justify-content-between align-items-center text-center'
            }>
            <span className={'fw-bold text-dark'}>
              {generateTitleById(
                'ee8536b9-0b87-4c92-b321-91aebffebd64',
                assessmentDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) ||
                'How will you know that these actions are helping to achieve the purpose?'}
            </span>
            <span className={'fw-bold text-dark'}>
              {generateTitleById(
                '4d60fe43-6260-4627-9837-bb5715b0832d',
                assessmentDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || 'What does success look like?'}
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            {orderedAction?.map((item) => (
              <AssessmentOutcome
                key={item.id}
                showAverageRate={props.showAverageRate}
                showDescription={props.showDescription}
                assessmentOutcome={item}
                assessment={props.assessment!}>
                {item.actions?.length === 0 ? (
                  <span className={'text-muted'}>
                    {generateTitleById(
                      'am-60',
                      assessmentDocument,
                      displayLanguageContext.displayLanguageSelected.resources!
                    ) || 'No actions found'}
                  </span>
                ) : (
                  item.actions
                    ?.filter((x) => x.status === 'accepted')
                    ?.map((action) => (
                      <div
                        key={action.id}
                        className="card mt-2 success_bg-opacity-20">
                        <div className="card-body text-dark">
                          <div className={'d-flex flex-wrap'}>
                            <div className={'d-flex w-75 flex-column'}>
                              <span>{action?.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                )}
              </AssessmentOutcome>
            ))}
          </Col>
        </Row>
        <div className={'mt-3'}>
          <AssessmentAddMeasurables
            measurables={props.measurables}
            showDetails={true}
          />
        </div>
      </Container>
    </div>
  );
};
