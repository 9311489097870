import React from 'react';
import {
  Editor,
  EditorChangeEvent,
  EditorProps,
  EditorTools,
  ProseMirror,
  EditorUtils
} from '@progress/kendo-react-editor';

import { insertImagePlugin, onImageInsert } from './insert-image-plugin';
import { InsertImage } from './insert-image-tools';
import { iframe } from '../../utils/new-node';
import { tagMark } from '../../utils/new-mark';

const { Schema, EditorView, EditorState } = ProseMirror;

interface EditorInputProps extends EditorProps {
  handleHtmlChange?: (event: EditorChangeEvent) => void;
}

const { imageResizing } = EditorUtils;

export function HtmlEditor({ ...props }: EditorInputProps) {
  const value = props.value;
  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell
  } = EditorTools;

  const onMount = (event: any) => {
    const state = event.viewProps.state;
    const plugins = [
      ...state.plugins,
      insertImagePlugin(onImageInsert),
      imageResizing()
    ];
    const { schema } = state;

    // Add the 'dir' attribute to paragraph node.
    const paragraph = {
      ...schema.spec.nodes.get('paragraph')
    };
    paragraph.attrs = paragraph.attrs || {};
    paragraph.attrs['dir'] = {
      default: null
    };
    let nodes = schema.spec.nodes.update('paragraph', paragraph);

    // Append the new node.
    nodes = nodes.addToEnd('iframe', iframe);

    // Append a new mark representing the <s> formatting tag.
    const mark = tagMark('s');
    let marks = schema.spec.marks.append(mark);

    // Create the new schema.
    const mySchema = new Schema({
      nodes,
      marks
    });

    // Create a new document using the modified schema.
    let doc = EditorUtils.createDocument(mySchema, props.value as string);

    return new EditorView(
      {
        mount: event.dom
      },
      {
        ...event.viewProps,
        state: EditorState.create({
          // @ts-ignore
          doc,
          plugins
        })
      }
    );
  };

  const onChange = (e: EditorChangeEvent) => {
    if (props.handleHtmlChange) props.handleHtmlChange(e);
  };

  return (
    <Editor
      tools={[
        [Bold, Italic, Underline, Strikethrough],
        [Subscript, Superscript],
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        FontSize,
        FontName,
        FormatBlock,
        [Undo, Redo],
        [
          Link,
          Unlink,
          (pluginProps: any) =>
            InsertImage({ ...pluginProps, resizeImage: true }),
          ViewHtml
        ],
        [InsertTable],
        [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
        [DeleteRow, DeleteColumn, DeleteTable],
        [MergeCells, SplitCell]
      ]}
      contentStyle={{
        height: 500,
        width: '100%'
      }}
      onChange={onChange}
      onMount={onMount}
      value={value}
    />
  );
}
