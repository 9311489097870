import { Col, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { GameAlgorithm } from '../../../components/game-algorithm';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  giveItem as playerGiveItem,
  giveScore as playerGiveScore,
  giveTitle as playerGiveTitle,
  navigateToWorldMap as playerNavigateToWorldMap,
  navigateToZone as playerNavigateToZone,
  showArea as playerShowArea,
  showTask as playerShowTask,
  openTask as playerOpenTask,
  pauseTimer as playerPauseTimer,
  startTimer as playerStartTimer
} from '../../../services/players';
import {
  NotificationContent,
  NotificationContext
} from '../../../contexts/notification';
import { getPlayers } from '../../../services/players';
import { PlayerResponse } from '../../../types/responses/player-response';
import { GameContext } from '../../../contexts/game';

interface AllPlayerFacilitatorStateProps {
  isDisableConfirm?: boolean;
}

interface AlgorithmState {
  operation?: string;
  argument?: string | string[];
  quantity?: number;
}

export const AllPlayerFacilitatorState = ({
  isDisableConfirm = false
}: AllPlayerFacilitatorStateProps) => {
  const [game] = useContext(GameContext);
  const [players, setPlayers] = useState<PlayerResponse[]>([]);
  const [algorithmState, setAlgorithmState] = useState<AlgorithmState>({});
  const [notification, setNotification] = useContext(NotificationContext);

  const getAllPlayers = async () => {
    let playersResult = (await getPlayers(game?.gameCode!)) as PlayerResponse[];
    playersResult = playersResult.filter((item) => item.activityTypeId === 1);

    setPlayers(playersResult);
  };

  const responseTeamNotification = (
    message: string,
    isError: boolean = false
  ) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: `${isError ? 'k-button--gradient-error' : 'k-button--gradient'}`
    };
    const content: NotificationContent[] = notification.content;
    content.push(responseTeamNotification);
    setNotification({ ...notification, content });
  };

  const onConfirmHandler = async () => {
    for (const player of players) {
      switch (algorithmState?.operation) {
        case 'navigateToWorldMap':
          await playerNavigateToWorldMap(game.gameCode!, player.code!);
          break;
        case 'navigateToZone':
          await playerNavigateToZone(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string
          );
          break;
        case 'openTask':
          await playerOpenTask(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string
          );
          break;
        case 'showTask':
          await playerShowTask(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'hideTask':
          await playerShowTask(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'showArea':
          await playerShowArea(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'hideArea':
          await playerShowArea(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'startTimer':
          await playerStartTimer(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'stopTimer':
          await playerStartTimer(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'pauseTimer':
          await playerPauseTimer(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'resumeTimer':
          await playerPauseTimer(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'giveItem':
          await playerGiveItem(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            algorithmState?.quantity!,
            true
          );
          break;
        case 'removeItem':
          await playerGiveItem(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            algorithmState?.quantity! * -1,
            false
          );
          break;
        case 'giveScore':
          await playerGiveScore(
            game.gameCode!,
            player.code!,
            algorithmState?.quantity!,
            true
          );
          break;
        case 'removeScore':
          await playerGiveScore(
            game.gameCode!,
            player.code!,
            algorithmState?.quantity! * -1,
            false
          );
          break;
        case 'giveTitle':
          await playerGiveTitle(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'removeTitle':
          await playerGiveTitle(
            game.gameCode!,
            player.code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        default:
          break;
      }
    }

    responseTeamNotification('Successfully sent the command');
  };

  const onChangeOperationHandler = (operation?: string) => {
    setAlgorithmState({ ...algorithmState, operation: operation });
  };

  const onChangeArgumentHandler = (selectedArguments?: string | string[]) => {
    setAlgorithmState({ ...algorithmState, argument: selectedArguments });
  };

  const onChangeQuantityHandler = (quantity?: number) => {
    setAlgorithmState({ ...algorithmState, quantity: quantity });
  };

  useEffect(() => {
    getAllPlayers();
  }, []);

  return (
    <Row className={'d-flex flex-column gap-4 h-100'}>
      <Col md={{ span: 12 }} className={'pl-1'} style={{ flex: 1 }}>
        <hr className={'ml-2'} />
        <div>
          <label className={'pl-2 text-secondary'}>Task Description</label>
          <div
            className={
              'd-flex justify-content-between align-items-center w-100'
            }>
            <div>
              <GameAlgorithm
                onChangeOperation={onChangeOperationHandler}
                onChangeArgument={onChangeArgumentHandler}
                onChangeQuantity={onChangeQuantityHandler}
                disabled={isDisableConfirm}
              />
            </div>
            <Button
              themeColor={'success'}
              className={'w-8 ml-2'}
              disabled={isDisableConfirm}
              onClick={onConfirmHandler}>
              Confirm
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
