import {
  ChatMessage,
  ChatMessageProps
} from '@progress/kendo-react-conversational-ui';

const MessageChat = (props: ChatMessageProps) => (
  <ChatMessage {...props} dateFormat={'t'} />
);

export default MessageChat;
