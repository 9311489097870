import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Stepper } from '../../components/stepper';
import { ReactComponent as Logo } from '../../assets/cg-icon-globe.svg';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
  step?: number;
  stepCount?: number;
  header?: string;
  handleOnClick?: () => void;
};

const WizardHeader = ({
  step = 1,
  stepCount = 4,
  header = '',
  handleOnClick = () => {}
}: Props) => {
  return (
    <>
      <Row className={'align-items-center'}>
        <Col>
          {step !== 1 && (
            <Button
              className={'k-button--icon'}
              themeColor={'primary'}
              fillMode={'flat'}
              onClick={handleOnClick}>
              <span className={'material-symbols-outlined'}>
                arrow_back_ios
              </span>
            </Button>
          )}
        </Col>
        <Col className={'text-center'}>
          <Logo style={{ width: 64 }} />
        </Col>
        <Col />
      </Row>
      <Row className={'gy-4 mt-4'}>
        <Col xs={12} className={'text-center'}>
          <h1>{header}</h1>
        </Col>
        <Col xs={12}>
          <Stepper stepCount={stepCount} currentStep={step + 1} />
        </Col>
      </Row>
    </>
  );
};

export default WizardHeader;
