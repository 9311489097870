const fullHex = (hex: string) => {
  let r = hex.slice(1, 2);
  let g = hex.slice(2, 3);
  let b = hex.slice(3, 4);

  r = parseInt(r + r, 16).toString();
  g = parseInt(g + g, 16).toString();
  b = parseInt(b + b, 16).toString();

  return `rgba(${r},${g},${b},1)`;
};

export const hexToRGB = (color?: string) => {
  if (!color) return '';
  if (color.includes('rgb')) return color;

  // if hex only 4 string, example #fff
  if (color.length === 4) {
    return fullHex(color);
  }

  const r = parseInt(color.slice(1, 3), 16).toString();
  const g = parseInt(color.slice(3, 5), 16).toString();
  const b = parseInt(color.slice(5, 7), 16).toString();

  return `rgba(${r},${g},${b},1)`;
};
