import { PlayerContextState } from '../contexts/player';
import { AssessmentLanguage } from '../types/game-document/entities/assessment';
import { AssessmentEntity } from '../types/state/assessment/assessment';
import { DeleteAsync, GetAsync, PostAsync } from './api';
import { HttpStatus } from './http-client';

export interface Response {
  isError: boolean;
  statusCode: HttpStatus;
  message: string;
  result: any;
}

export interface AssessmentActionRequest {
  assessmentCode: string;
  actions: AssessmentActionDetailRequest[];
}

export interface AssessmentRatingRequest {
  playerCode: string;
  votes: AssessmentRatingDetailRequest[];
}

export interface AssessmentRatingDetailRequest {
  actionId: string;
  vote?: number;
}

export interface AssessmentScoreRequest {
  playerCode: string;
  outcomes: AssessmentScoreOutcomeRequest[];
}

export interface AssessmentScoreOutcomeRequest {
  outcomeId: string;
  score: number;
}

export interface AssessmentActionDetailRequest {
  outcomeId: string;
  action: string;
}

export const getAssessmentAsync = async (code: string) => {
  try {
    const response = await PostAsync(`hubs/assessmentstate/${code}`, {});
    return response;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

export const AddActionAsync = async (
  gameCode: string,
  playerCode: string,
  request: AssessmentActionRequest
) => {
  try {
    const response = await PostAsync(
      `assessments/${gameCode}/action/${playerCode}`,
      request
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

export const getAssessmentDocumentAsync = async (code: string) => {
  try {
    const response = await GetAsync<AssessmentEntity>(
      `assessments/${code}/document`
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

export const giveActionRatingAsync = async (
  gameCode: string,
  request: AssessmentRatingRequest
) => {
  try {
    const response = await PostAsync(`assessments/${gameCode}/vote`, request);
    return response;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

export const giveOutcomeScoreAsync = async (
  gameCode: string,
  request: AssessmentScoreRequest
) => {
  try {
    const response = await PostAsync(`assessments/${gameCode}/score`, request);
    return response;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
};

export function postStatusStepAssessmentAsync(
  gameCode?: string,
  stepNumber?: number
) {
  return PostAsync(`assessments/${gameCode}/steps/${stepNumber}/start`, {
    stepOrder: stepNumber
  });
}

export function postAssementMeasurableAsync(
  assessmentCode?: string,
  playerCode?: string,
  comment?: string
) {
  return PostAsync(`assessments/${assessmentCode}/measurables`, {
    playerCode: playerCode,
    comment: comment
  });
}

export function postInitAssessmentAsync(gameCode?: string) {
  return PostAsync(`assessments/${gameCode}/init`, {});
}

export function addActionAssessmentAsync(
  gameCode?: string,
  playerCode?: string,
  outcomeId?: string,
  action?: string
) {
  return PostAsync(`assessments/${gameCode}/action/${playerCode}`, {
    assessmentCode: playerCode,
    actions: [
      {
        outcomeId,
        action
      }
    ]
  });
}

export function previewActionAssesment(gameCode?: string, playerCode?: string) {
  return PostAsync(`assessments/${gameCode}/preview/${playerCode}`, {});
}

export function finishAssessment(gameCode?: string, playerCode?: string) {
  return PostAsync(`assessments/${gameCode}/finish/${playerCode}`, {});
}

export function finishMeasurablesAssessment(
  gameCode?: string,
  playerCode?: string
) {
  return PostAsync(`assessments/${gameCode}/measurables/${playerCode}`, {});
}

export const PostAssessmentStatus = (
  gameCode?: string,
  actionId?: string,
  pic?: string | null,
  targetDate?: string | Date | null,
  status?: string
) => {
  return PostAsync(`assessments/${gameCode}/status`, {
    actionId,
    pic,
    targetDate,
    status
  });
};

export const GetAssessmentLanguage = async (code: string) => {
  const response = await GetAsync<AssessmentLanguage[]>(
    `assessments/${code}/languages`
  );
  return response;
};

export async function getAssessmentPlayers(code: string, playerCode: string) {
  const response = await GetAsync<PlayerContextState>(
    `assessments/${code}/players/${playerCode}/states`
  );
  return response;
}

export const PostAssessmentToggleSort = (code: string) => {
  return PostAsync(`assessments/${code}/outcomes/toggle-sort`, {});
};

export const DeleteActionAsync = (code: string, id: string) => {
  return DeleteAsync(`assessments/${code}/action/${id}`);
};

/**
 * End the assessment for the given game code.
 *
 * @param {string} [gameCode] - The game code of the assessment. (optional)
 * @returns {Promise} - A Promise that resolves when the assessment is ended.
 */
export const EndAssessmentAsync = (gameCode?: string) =>
  PostAsync(`assessments/${gameCode}/end`, {});
