import { Col, Row } from 'react-bootstrap';
import {
  Assessment,
  DataChart,
  DataRangeChart,
  Outcome
} from '../../types/assessment';
import { ChartContainer } from '../../components/chart/radar-chart';
import React, { useContext, useEffect, useState } from 'react';
import { getFormattedDateOnlyString } from '../../utils/date';
import { RangeBarChart } from '../../components/chart/range-bar';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { Button } from '@progress/kendo-react-buttons';
import { getResourceDescription } from '../../utils/assessment-state/resource';
import { AssessmentPurposeStatement } from '../../components/assessment/assessment-purpose-statement';
import { AssessmentEntity } from '../../types/state/assessment/assessment';
import { getAssessmentDocumentAsync } from '../../services/assessments';
import { useParams } from 'react-router-dom';

interface AssessmentProp {
  assessment: Assessment;
  isExportClicked: boolean;
}
export const AssessmentExport = ({ ...props }: AssessmentProp) => {
  let { gameCode } = useParams();
  const [assessmentDocumentData, setAssessmentDocumentData] =
    useState<AssessmentEntity>();
  const { playerState: assessmentDocument } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;

  const logoUrl = assessmentDocument.assessmentDocument?.logoUrl ?? '';

  const getAssessmentDocsAsync = async () => {
    let assessmentDocument = (await getAssessmentDocumentAsync(
      gameCode!
    )) as AssessmentEntity;

    if (assessmentDocument) {
      setAssessmentDocumentData(assessmentDocument);
    }
  };

  const getAverageScore = (outCome: Outcome) => {
    let total = 0;
    const length = outCome?.responses?.length;

    outCome?.responses?.forEach((item) => {
      total += item?.score ?? 0;
    });

    return total === 0 && length === 0 ? 0 : (total / length).toFixed(1);
  };

  const renderChart = () => {
    const dataChart: DataChart[] = props?.assessment.outcomes.map((item) => {
      const scores = item.responses
        .map((item) => item.score)
        .reduce(
          (accumulator: number, currentValue: number) =>
            accumulator + currentValue,
          0
        );
      const resultScore = scores / item.responses.length;
      const resultData: DataChart = {
        name: item.title,
        score: resultScore ? Math.round(resultScore) : 0
      };
      return resultData;
    });

    const dataRange = props?.assessment.outcomes.map((outcome) => {
      const scores = outcome.responses.map((response) => response.score);
      const minScore = Math.min(...scores);
      const maxScore = Math.max(...scores);

      return {
        id: outcome.id,
        title: outcome.title,
        min: minScore,
        max: maxScore,
        avg: getAverageScore(outcome)
      };
    });

    return (
      <div
        className={'d-flex bd-highlight flex-column gap-4 border border-dark'}>
        <div
          className={'p-4 bd-highlight flex-grow-1'}
          style={{
            width: props?.isExportClicked ? '1250px' : '100%',
            height: props?.isExportClicked ? '1000px' : '400px',
            overflowX: 'auto'
          }}>
          <ChartContainer data={dataChart} isExported={props.isExportClicked} />
        </div>
        <div
          className={'d-flex flex-column gap-2 p-2 bd-highlight'}
          style={{
            width: props?.isExportClicked ? '1220px' : '100%',
            height: '450px'
          }}>
          <span>
            The bar chart shows the spread of ratings submitted by participants.
            Where no bar is shown, it indicates that participant responses were
            aligned.
          </span>
          <RangeBarChart
            data={dataRange as DataRangeChart[]}
            minTitle={'Min effectiveness'}
            maxTitle={'Max effectiveness'}
            maxValue={10}
            isExported={props.isExportClicked}
          />
        </div>
      </div>
    );
  };

  const actionsMap = React.useCallback(
    (type: 'accepted' | 'declined') => {
      const actions = props?.assessment?.actions?.filter(
        (x) => x.status === type
      );
      const outcomes = props?.assessment?.outcomes.map((outcome) => ({
        ...outcome,
        actions: actions.filter((action) => action.outcomeId === outcome.id)
      }));

      return outcomes.filter((outcome) => outcome.actions.length > 0);
    },
    [props.assessment]
  );

  useEffect(() => {
    getAssessmentDocsAsync();
  }, []);

  return (
    <>
      <div className={'d-flex justify-content-center'}>
        <div
          className={'w-100'}
          style={{
            fontSize: props.isExportClicked ? '20px' : '16px'
          }}>
          <div className={'d-flex gap-3'}>
            {logoUrl !== '' && <img src={logoUrl} alt="img" width={64} />}
            <div className={'d-flex flex-column justify-content-center '}>
              <h3
                className={'text-primary p-0 m-0 fw-bold'}
                style={{
                  fontSize: props.isExportClicked ? '20px' : '16px'
                }}>
                {props.assessment.eventName}
              </h3>
              <span>
                {getResourceDescription(
                  assessmentDocument?.assessmentDocument?.titleResId!,
                  assessmentDocument?.assessmentDocument as any,
                  ''
                )}
              </span>
            </div>
          </div>

          <div
            className="border w-full mt-4"
            style={{
              height: '2px'
            }}
          />

          <div className={'mt-3'}>
            <AssessmentPurposeStatement assessment={assessmentDocumentData} />
          </div>

          <Row className={'mt-4 ml-1'}>
            <Col className={'p-0'}>
              <h3
                className={'text-primary p-0 m-0 fw-bold'}
                style={{
                  fontSize: props.isExportClicked ? '20px' : '16px'
                }}>
                Summary
              </h3>
            </Col>
          </Row>

          <div
            className={`d-flex ${
              !props?.isExportClicked && 'flex-column flex-md-row'
            } mt-2 gap-2`}>
            <div
              className={
                'd-flex flex-column align-items-center justify-content-center p-4 outcome-bg gap-2 summary-export-card'
              }>
              <Button
                className={
                  'score-rounded border border-success rounded-circle rounded-lg text-success fw-bold'
                }
                themeColor={'success'}
                fillMode={'outline'}
                style={{
                  height: '40px',
                  width: '40px'
                }}>
                {props?.assessment?.outcomes?.length ?? 0}
              </Button>
              <h4
                className={'text-primary fw-bold text-center'}
                style={{
                  fontSize: props.isExportClicked ? '20px' : '14px'
                }}>
                Competencies
              </h4>
            </div>
            <div
              className={
                'd-flex flex-column align-items-center justify-content-center p-4 outcome-bg gap-2 summary-export-card'
              }>
              <Button
                className={
                  'score-rounded border border-success rounded-circle rounded-lg text-success fw-bold'
                }
                themeColor={'success'}
                fillMode={'outline'}
                style={{
                  height: '40px',
                  width: '40px'
                }}>
                {props?.assessment?.players?.length ?? 0}
              </Button>
              <h4
                className={'text-primary fw-bold text-center'}
                style={{
                  fontSize: props.isExportClicked ? '20px' : '14px'
                }}>
                Participants
              </h4>
            </div>
            <div
              className={
                'd-flex flex-column align-items-center justify-content-center p-4 outcome-bg gap-2 summary-export-card'
              }>
              <Button
                className={
                  'score-rounded border border-success rounded-circle rounded-lg text-success fw-bold'
                }
                themeColor={'success'}
                fillMode={'outline'}
                style={{
                  height: '40px',
                  width: '40px'
                }}>
                {props?.assessment?.actions?.length ?? 0}
              </Button>
              <h4
                className={'text-primary fw-bold text-center'}
                style={{
                  fontSize: props.isExportClicked ? '20px' : '14px'
                }}>
                Proposed Actions
              </h4>
            </div>
            <div
              className={
                'd-flex flex-column align-items-center justify-content-center p-4 outcome-bg gap-2 summary-export-card'
              }>
              <Button
                className={
                  'score-rounded border border-success rounded-circle rounded-lg text-success fw-bold'
                }
                themeColor={'success'}
                fillMode={'outline'}
                style={{
                  height: '40px',
                  width: '40px'
                }}>
                {props?.assessment?.actions?.filter(
                  (x) => x.status === 'accepted'
                )?.length ?? 0}
              </Button>
              <h4
                className={'text-primary fw-bold text-center'}
                style={{
                  fontSize: props.isExportClicked ? '20px' : '14px'
                }}>
                Approved Actions
              </h4>
            </div>
          </div>

          <Row className={'mt-2'}>
            <Col>{renderChart()}</Col>
          </Row>

          <Row className={'page-break mt-4 ml-1'}>
            <Col className={'p-0'}>
              <h3
                className={'text-primary p-0 m-0 fw-bold'}
                style={{
                  fontSize: props.isExportClicked ? '20px' : '16px'
                }}>
                Competencies
              </h3>
            </Col>
          </Row>

          <div className={'d-flex flex-column gap-2 mt-2 mx-3'}>
            {props?.assessment?.outcomes?.map((item) => (
              <div className={'row outcome-bg py-3 p-2'} key={item.id}>
                <div
                  className={
                    'col-1 d-flex align-items-center justify-content-center fw-bold text-primary'
                  }>
                  {getAverageScore(item)}
                </div>
                <div
                  className={`${
                    props?.isExportClicked ? 'col-2' : 'col-9 col-md-2'
                  } d-flex align-items-center fw-bold text-primary`}
                  style={{
                    wordBreak: 'break-all'
                  }}>
                  {item?.title}
                </div>
                <div
                  className={`${
                    props?.isExportClicked ? 'col' : 'col-12 col-md-9'
                  } mt-2 mt-md-0 px-2 px-md-0`}>
                  {item?.description}
                </div>
              </div>
            ))}
          </div>

          <Row className={'page-break mt-4 ml-1'}>
            <Col className={'p-0'}>
              <h3
                className={'text-primary p-0 m-0 fw-bold'}
                style={{
                  fontSize: props?.isExportClicked ? '20px' : '16px'
                }}>
                Approved Actions
              </h3>
            </Col>
          </Row>

          {actionsMap('accepted').map((item) => (
            <div className={'d-flex flex-column gap-2 mt-2'} key={item.id}>
              <div
                className={
                  'd-flex align-items-center outcome-bg p-3 fw-bold text-primary'
                }>
                {item.title}
              </div>

              {item.actions.map((action) => (
                <div className={'row'} key={action.id}>
                  <div
                    className={`${
                      props?.isExportClicked ? 'col-9' : 'col-12 col-md-9'
                    } pr-0`}>
                    <div
                      className={
                        'd-flex align-items-center flex-grow-1 py-3 px-4 fw-bold text-primary justify-content-between h-full'
                      }
                      style={{
                        backgroundColor: '#d2f3e8'
                      }}>
                      <span>{action.name}</span>
                      <span>{action.votes}</span>
                    </div>
                  </div>
                  {(action?.targetDate ||
                    props?.assessment?.players?.find(
                      (x) => x.code === action?.pic
                    )?.name) && (
                    <div
                      className={`${
                        props?.isExportClicked ? 'col-3' : 'col-12 col-md-3'
                      } mt-2 mt-md-0 pr-0`}>
                      <div className={'row h-100'}>
                        <div className={'col-5 pr-0'}>
                          <div
                            className={
                              'd-flex align-items-center justify-content-center py-3 py-md-0 px-3 border rounded text-center w-full h-full'
                            }>
                            {action?.targetDate &&
                              getFormattedDateOnlyString(
                                new Date(action?.targetDate)
                              )?.replaceAll('/', ' / ')}
                          </div>
                        </div>

                        <div className={'col-7'}>
                          <div
                            className={
                              'd-flex align-items-center justify-content-center py-3 py-md-0 px-3 border rounded text-center w-full h-full'
                            }>
                            {props?.assessment?.players?.find(
                              (x) => x.code === action?.pic
                            )?.name ?? ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}

          <Row className={'page-break mt-4 ml-1'}>
            <Col className={'p-0'}>
              <h3
                className={'text-primary p-0 m-0 fw-bold'}
                style={{
                  fontSize: props.isExportClicked ? '20px' : '16px'
                }}>
                {`Measurables (${props?.assessment?.measurables?.length ?? 0})`}
              </h3>
            </Col>
          </Row>

          <Row>
            <>
              <Row className={'d-flex h- w-100'}>
                <div
                  className={
                    'd-flex flex-column justify-items-center align-items-center pr-0'
                  }>
                  <div className={'w-100'}>
                    {props?.assessment?.measurables &&
                      props?.assessment?.measurables?.map(
                        (measurable, index) => {
                          return (
                            <>
                              <div key={index} className={'p-2 outcome-bg'}>
                                <div
                                  className={
                                    'd-flex align-items-center w-full'
                                  }>
                                  <div className={'p-1 w-10 mr-3'}>
                                    <span className={'text-primary fw-bold'}>
                                      {props?.assessment?.players?.find(
                                        (x) => x.code === measurable?.playerCode
                                      )?.name ?? 'Facilitator'}
                                    </span>
                                  </div>
                                  <div className={'p-1 d-flex w-90'}>
                                    <span>{measurable?.comment}</span>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                </div>
              </Row>
            </>
          </Row>

          <Row className={'page-break mt-4 ml-1'}>
            <Col className={'p-0'}>
              <h3
                className={'text-primary p-0 m-0 fw-bold'}
                style={{
                  fontSize: props.isExportClicked ? '20px' : '16px'
                }}>
                Unapproved Actions
              </h3>
            </Col>
          </Row>

          {actionsMap('declined').map((item) => (
            <div className={'d-flex flex-column gap-2 mt-2'} key={item.id}>
              <div
                className={
                  'd-flex align-items-center outcome-bg p-3 fw-bold text-primary'
                }>
                {item.title}
              </div>

              {item.actions.map((action) => (
                <div className={'row'} key={action.id}>
                  <div
                    className={`${
                      props?.isExportClicked ? 'col-9' : 'col-12 col-md-9'
                    } pr-0`}>
                    <div
                      className={
                        'd-flex align-items-center flex-grow-1 py-3 px-4 fw-bold text-primary justify-content-between'
                      }
                      style={{
                        backgroundColor: '#d2f3e8'
                      }}>
                      <span>{action.name}</span>
                      <span>{action.votes}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
