import cloneDeep from 'lodash.clonedeep';
import { GameContextState } from '../../contexts/game';
import { GameDocumentState } from '../../contexts/game-document';
import { PlayerContextState } from '../../contexts/player';
import { TeamContextState } from '../../contexts/team';
import {
  GameState,
  PlayerState,
  PlayerStateFull,
  TeamStateFull
} from '../../types/state';
import { GetResourceValue } from '../game-document/resources';

/**
 * Update player state local storage.
 * @param playerState - The playerstate
 * @param updated - The updated playerstate
 * @constructor
 */
export const UpdatePlayerStateAsync = (
  playerState: PlayerContextState,
  updated: PlayerStateFull,
  isDirty: boolean = true
) => {
  return cloneDeep({
    ...playerState,
    isDirty,
    playerState: cloneDeep({ ...updated })
  });
};
/**
 * Update Game player state local storage.
 * @param gamePlayerState - The updated gameplayer
 * @constructor
 */
export const UpdateGamePlayerStateAsync = (
  gamePlayerState: PlayerState,
  isDirty: boolean = true
) => {
  return { ...gamePlayerState, isDirty };
};

/**
 * Update game state local storage.
 * @param game - The GameContextState
 * @param updated - The updated GameState
 * @param isDirty - Update GameContextState isDirty status
 * @returns updated Promise<GameContextState>
 * @constructor
 */
export const UpdateGameStateAsync = (
  game: GameContextState,
  updated: GameState,
  isDirty: boolean = true
) => {
  return cloneDeep<GameContextState>({
    ...game,
    gameState: cloneDeep({ ...updated }),
    isDirty
  });
};

/**
 * Update team state local storage.
 * @param game - The TeamContextState
 * @param updated - The updated TeamState
 * @param isDirty - Update teamContextState isDirty status
 * @returns updated Promise<TeamContextState>
 * @constructor
 */
export const UpdateTeamStateAsync = (
  team: TeamContextState,
  updated: TeamStateFull,
  isDirty: boolean = true
) => {
  return cloneDeep<TeamContextState>({
    ...team,
    teamState: cloneDeep({ ...updated }),
    isDirty
  });
};

export const GetGameLogo = (
  gameDocument: GameDocumentState,
  player: PlayerContextState
): string => {
  if (gameDocument.gameDocument!.overview!.logoResId! === '') {
    return '../../cg-icon-globe.svg';
  } else {
    const gameImage = GetResourceValue(
      gameDocument.gameDocument!,
      gameDocument.gameDocument!.overview!.logoResId!,
      player?.playerState?.language?.name! ?? ''
    );
    return gameImage;
  }
};

export const GetGameName = (
  gameDocument: GameDocumentState,
  player: PlayerContextState
): string => {
  const titleRes = GetResourceValue(
    gameDocument.gameDocument!,
    gameDocument.gameDocument!.overview!.titleResId!,
    player?.playerState?.language?.name! ?? ''
  );
  return titleRes;
};
