import React from 'react';
import { AvatarPreview } from '../wizard';

interface ScoreCardProps {
  rank: number;
  avatarImage?: string;
  name?: string;
  score?: number;
}

const ScoreCard = (props: ScoreCardProps) => {
  const { rank, avatarImage, name, score } = props;
  return (
    <div className={'p-2 border-bottom'}>
      <div className={'d-flex container justify-content-center'}>
        <div className={'d-flex align-items-center'}>
          <h2>{`${rank}.`}</h2>
        </div>
        <div className={'col-2 justify-content-start align-items-center'}>
          <AvatarPreview
            avatarSource={avatarImage}
            isShowPencil={false}
            imageSize={'h-3 w-3'}
          />
        </div>
        <div className={'col-3 d-flex align-items-center'}>
          <h2>{name}</h2>
        </div>
        <div className={'col-3 d-flex justify-content-end align-items-center'}>
          <h2 className={'text-right'}>{score}</h2>
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;
