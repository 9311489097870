import saveAs from 'file-saver';
import JSZip from 'jszip';
import { FileZipDownloadRequest } from '../../types/facilitator/file-zip-download';

export const fileName = async (name: string, fileType: string, blob: Blob) => {
  let result = name;

  const hasExtension = (fileName: string) => {
    const length = fileName.split('.').pop();
    return fileName.includes('.') && length && length.length > 0;
  };

  if (!hasExtension(name) && fileType === 'application/octet-stream') {
    const arrayBuffer = await blob.arrayBuffer();
    const byteArray = new Uint8Array(arrayBuffer);

    // Simple check for common video/image formats by inspecting the first few bytes
    if (
      // JPG file signature
      (byteArray[0] === 0xff && byteArray[1] === 0xd8) ||
      // PNG file signature
      (byteArray[0] === 0x89 && byteArray[1] === 0x50)
    ) {
      result = `${result}jpg`;
    } else if (
      // WebM file signature
      (byteArray[0] === 0x1a && byteArray[1] === 0x45) ||
      // MP4 file signature
      (byteArray[0] === 0x00 && byteArray[1] === 0x00)
    ) {
      result = `${result}mp4`;
    }
  }

  return result;
};

export const downloadFilesAndZip = async (
  files: FileZipDownloadRequest[],
  filename: string
) => {
  const zip = new JSZip();

  const filePromises = files.map(async (file) => {
    // Get the blob name before downloading the content
    const urlObject = new URL(file.fileUrl);
    const pathname = urlObject.pathname;
    const parts = pathname.split('/');
    let blobName = decodeURIComponent(parts.pop()!) || null;

    if (blobName) {
      const response = await fetch(file.fileUrl);
      const blob = await response.blob();
      const fileType = blob.type;

      blobName = await fileName(blobName, fileType, blob);

      zip.file(`${file.containerFolder}${blobName}`, blob, {
        binary: true
      });
    }
  });

  // Wait for all files to be fetched and added to the zip
  await Promise.all(filePromises);

  // Generate the zip file and trigger download
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, filename);
  });
};
