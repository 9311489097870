import { ComponentPropsWithRef, useContext } from 'react';
import { GameDocumentContext } from '../contexts/game-document';
import { Color } from '../types/theme';

interface ProgressPillProps extends ComponentPropsWithRef<'div'> {
  imageUrl?: string;
  title: string;
  toggleTimerVisibility: boolean;
  progress?: number | undefined;
}

export const ProgressPill = ({
  imageUrl,
  title,
  progress,
  toggleTimerVisibility,
  ...props
}: ProgressPillProps) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const theme: Color[] = gameDocument?.theme?.colors ?? [];
  const infoColor = theme?.find((x) => x.type === 'Info')?.color;
  const backgroundColor = theme?.find(
    (x) => x.type === 'Background & Text'
  )?.color;

  return (
    <div
      {...props}
      className={`progress-pill ${props.className ?? ''}`.trim()}
      style={{
        backgroundColor: backgroundColor ?? 'rgb(0,58,100)'
      }}>
      <div
        className={'progress-pill__progress'}
        style={{
          backgroundImage: `conic-gradient(${
            infoColor ?? 'rgb(13,197,232)'
          } ${progress}%, transparent ${progress}%)`
        }}>
        <img
          className={'progress-pill__image'}
          src={!imageUrl ? '/cg-icon-globe.svg' : imageUrl}
          alt={title}
        />
      </div>
      <div
        className={'d-flex flex-column justify-content-center overflow-hidden'}>
        <div className={'progress-pill__title'}>{title}</div>
        {toggleTimerVisibility && (
          <div className={'progress-pill__subtitle'}>{props.children}</div>
        )}
      </div>
    </div>
  );
};

export default ProgressPill;
