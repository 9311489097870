import { Button } from '@progress/kendo-react-buttons';
import { Error } from '@progress/kendo-react-labels';
import { CardSubtitle } from '@progress/kendo-react-layout';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Webcam from 'react-webcam';
import {
  GetFileExtensionsBySource,
  dataURItoBlob,
  loadImageToBlobUrl
} from '../../utils/image-helper';
import { GameDocumentContext } from '../../contexts/game-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { uuid } from '../../types/common-helper';

const generateRandomString = () => {
  return uuid().split('-').join('').substring(0, 5);
};

type Props = {
  isReadonly?: boolean;
  selectedImage?: string;
  onSelectedImageCallback?: (blobUrl: string | File, size?: number) => void;
  isDisabled?: boolean;
  formId: string;
};

const AnswerImage = ({
  isReadonly = false,
  selectedImage = '',
  onSelectedImageCallback = () => {},
  isDisabled = false,
  formId
}: Props) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const videoRef = useRef<Webcam>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const [blobImage, setBlobImage] = useState<string>(selectedImage);
  const [isDisplayWebcam, setIsDisplayWebcam] = useState<boolean>(false);
  const [imageExtension, setImageExtension] = useState<string>('');
  const [fileUpload, setFileUpload] = useState<File>();
  const [isLargeImageSize, setIsLargeImageSize] = useState<boolean>(false);
  const [isValidFileExtension, setIsValidFileExtension] =
    useState<boolean>(true);
  const [facingMode, setFacingMode] = useState<string>('user');

  const handleSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const blobUrl = loadImageToBlobUrl(file);
      const fileName = file.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
      const baseName = fileName.slice(0, fileName.lastIndexOf('.'));
      const newFileName = `${baseName}-${generateRandomString()}${fileExtension}`;

      const sizeImage = file.size;
      const maxImageSize = 512000; //max image size in byte
      if (sizeImage > maxImageSize) {
        setIsLargeImageSize(true);
      } else {
        setIsLargeImageSize(false);
      }

      setIsValidFileExtension(true);
      let extensionFound = undefined;

      if (fileExtension) {
        setImageExtension(fileExtension);

        // set file ext checker
        let imageExtensionList = GetFileExtensionsBySource(
          'Assets - Task Content - Image Answer Type'
        );

        if (imageExtensionList) {
          extensionFound = imageExtensionList.find(
            (validFileExtension) =>
              validFileExtension ===
              fileExtension.replaceAll('.', '').toLowerCase()
          );
          setIsValidFileExtension(extensionFound ? true : false);
        }
      }

      if (extensionFound) {
        const newFile = new File([file], newFileName, { type: file.type });
        setBlobImage(blobUrl);
        onSelectedImageCallback(newFile, newFile.size ?? 0);
        setFileUpload(newFile);
      } else {
        setBlobImage('');
        onSelectedImageCallback('');
        setFileUpload(undefined);
      }
    }
  };
  const onAddImageClick = () => inputFile.current && inputFile.current.click();

  const turnOnCameraClick = () => {
    setIsDisplayWebcam(true);
    setIsValidFileExtension(true);
  };

  const turnOffCameraClick = () => {
    const { stream } = videoRef.current!;
    const tracks = stream?.getTracks();
    tracks?.forEach((item) => item.stop());
    setBlobImage('');
    onSelectedImageCallback('');
    setIsDisplayWebcam(false);
  };

  const onTakePictureClickHandler = () => {
    const screenShoot = videoRef.current?.getScreenshot();
    if (screenShoot && screenShoot !== '') {
      fetch(screenShoot)
        .then((result) => {
          const imageFile = dataURItoBlob(result.url);
          let myFile = imageFile;
          if (imageFile instanceof Blob) {
            const extension = imageFile.type.split('/')[1];
            myFile = new File(
              [imageFile],
              `captured-image-${generateRandomString()}.${extension}`,
              {
                type: imageFile.type
              }
            );
          }
          onSelectedImageCallback(myFile as File, imageFile.size);
          setBlobImage(result.url);
        })
        .catch((error) => console.error(error))
        .finally(() => setIsDisplayWebcam(false));
    }
  };

  const getFileExtension = (filename: string) => {
    // get file extension
    const extension = filename.substring(
      filename.lastIndexOf('.') + 1,
      filename.length
    );
    return extension;
  };

  const allowedImages = () => {
    return (
      getFileExtension(selectedImage).toLocaleLowerCase() === 'png' ||
      getFileExtension(selectedImage).toLocaleLowerCase() === 'jpg' ||
      getFileExtension(selectedImage).toLocaleLowerCase() === 'jpeg' ||
      getFileExtension(selectedImage).toLocaleLowerCase() === 'gif' ||
      getFileExtension(selectedImage).toLocaleLowerCase() === 'heic' ||
      getFileExtension(selectedImage) === ''
    );
  };

  const handleSwitchCamera = useCallback(() => {
    setFacingMode((prev) => (prev === 'user' ? 'environment' : 'user'));
  }, [setFacingMode]);

  return (
    <div id={'answer-image'}>
      <Row>
        <Col>
          <div className={'border text-center h-100 position-relative'}>
            {isDisplayWebcam ? (
              <>
                <div className={'camera-action'}>
                  <span
                    className={
                      'material-symbols-outlined text-white switch-icon'
                    }
                    onClick={handleSwitchCamera}>
                    cameraswitch
                  </span>
                </div>
                <Webcam
                  id={'answer-webcam'}
                  className={'py-1'}
                  audio={false}
                  videoConstraints={{
                    height: 480,
                    width: 480,
                    facingMode: facingMode ?? 'user'
                  }}
                  screenshotFormat={'image/jpeg'}
                  autoPlay={true}
                  ref={videoRef}
                />
              </>
            ) : (
              <>
                <div
                  className={
                    'd-flex justify-content-center align-items-center py-1'
                  }>
                  {blobImage === '' || selectedImage === '' ? (
                    <span
                      className={
                        'material-symbols-outlined text-success text-size-64'
                      }>
                      wallpaper
                    </span>
                  ) : allowedImages() ? (
                    <img
                      src={selectedImage ?? blobImage}
                      height={300}
                      alt={'Preview'}
                    />
                  ) : (
                    <p>
                      <span
                        className={
                          'material-symbols-outlined text-primary text-size-64'
                        }>
                        cloud_done
                      </span>
                      <br />
                      {!isReadonly ? (
                        (fileUpload?.name ??
                        decodeURI(
                          selectedImage.substring(
                            selectedImage.lastIndexOf('/') + 1
                          )
                        ))
                      ) : (
                        <>
                          <span>
                            {decodeURI(
                              selectedImage.substring(
                                selectedImage.lastIndexOf('/') + 1
                              )
                            )}
                          </span>{' '}
                          <br />
                          <a href={selectedImage}>Download</a>
                        </>
                      )}
                    </p>
                  )}
                </div>
                {blobImage === '' && (
                  <CardSubtitle>
                    {generateTitleById(
                      '567963b7-47c0-40e9-8659-2e43a5a5befb',
                      gameDocument,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Preview'}
                  </CardSubtitle>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
      {/* currently commented as requested from User Story #5365 */}
      {/* {isLargeImageSize && (
        <Row>
          <Error>Please reduce your file size below 500 Kb</Error>
        </Row>
      )} */}
      {!isReadonly && (
        <Row className={'py-2'}>
          {!isValidFileExtension && (
            <Col className={'mb-4'} md={12}>
              <Error>
                {generateTitleById(
                  '4af50ef9-db25-48cf-9fa2-2a5ad295d6cf',
                  gameDocument,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'game'
                ) || 'Invalid File Type: Please upload image files only.'}
              </Error>
            </Col>
          )}
          {!isDisabled && (
            <>
              <Col md={6}>
                <Button
                  className={'w-100 upload-button p-2 mb-2'}
                  onClick={
                    isDisplayWebcam
                      ? onTakePictureClickHandler
                      : onAddImageClick
                  }>
                  <div
                    className={
                      'd-flex justify-content-center align-items-center gap-3'
                    }>
                    <span
                      className={'material-symbols-outlined mr-2 text-success'}
                      role={'button'}>
                      add_photo_alternate
                    </span>
                    <span>
                      {isDisplayWebcam
                        ? generateTitleById(
                            '7b63892e-236c-4b6a-bfec-d5d3d66794af',
                            gameDocument,
                            displayLanguageContext.displayLanguageSelected
                              .resources!,
                            'game'
                          ) || 'Take picture'
                        : generateTitleById(
                            'b6d7d97b-37bd-4ef9-aa9e-0e02a404a375',
                            gameDocument,
                            displayLanguageContext.displayLanguageSelected
                              .resources!,
                            'game'
                          ) || 'Add from files'}
                    </span>
                  </div>
                </Button>
                <input
                  type={'file'}
                  id={'answer-file-upload'}
                  className={'d-none'}
                  onChange={handleSelectImage}
                  onClick={(event) => {
                    event.currentTarget.value = '';
                  }}
                  ref={inputFile}
                  accept={'image/*'}
                />
              </Col>
              <Col md={6}>
                <Button
                  className={'w-100 upload-button p-2 mb-2'}
                  onClick={
                    isDisplayWebcam ? turnOffCameraClick : turnOnCameraClick
                  }>
                  <div
                    className={
                      'd-flex justify-content-center align-items-center gap-3'
                    }>
                    <span
                      className={'material-symbols-outlined mr-2 text-success'}
                      role={'button'}>
                      add_a_photo
                    </span>
                    <span>
                      {isDisplayWebcam
                        ? generateTitleById(
                            '59473aee-f6c3-4657-aa6e-f72a9726e4dc',
                            gameDocument,
                            displayLanguageContext.displayLanguageSelected
                              .resources!,
                            'game'
                          ) || 'Turn off camera'
                        : generateTitleById(
                            '51689c93-1e57-4c72-9308-5deab301614a',
                            gameDocument,
                            displayLanguageContext.displayLanguageSelected
                              .resources!,
                            'game'
                          ) || 'Take from camera'}
                    </span>
                  </div>
                </Button>
              </Col>
            </>
          )}
        </Row>
      )}
    </div>
  );
};

export default AnswerImage;
