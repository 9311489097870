import { merge } from 'lodash';
import { PlayerStateFull } from '../../../types/state';
import { PlayerContextState } from '../../../contexts/player';

export const AddTitleAsync = async (
  state: PlayerContextState,
  titleId: string
) => {
  const { titles = [] as string[] } = state.playerState!;
  //check to see if the player already has the title.
  const titleIndex = titles.findIndex((i) => i === titleId);
  if (titleIndex === -1) {
    titles.push(titleId);
    return MergeTitle(state.playerState!, titles);
  }
  return state.playerState!;
};

export const AddTitle = (state: PlayerContextState, titleId: string) => {
  const { titles = [] as string[] } = state.playerState!;
  //check to see if the player already has the title.
  const titleIndex = titles.findIndex((i) => i === titleId);
  if (titleIndex === -1) {
    titles.push(titleId);
    return MergeTitle(state.playerState!, titles);
  }
  return state.playerState!;
};

export const RemoveTitleAsync = async (
  state: PlayerContextState,
  titleId: string
) => {
  const { titles = [] as string[] } = state.playerState!;
  const itemIndex = titles.findIndex((i) => i === titleId);
  if (itemIndex !== -1) {
    titles.splice(itemIndex, 1);
    return MergeTitle(state.playerState!, titles);
  }
  return state.playerState!;
};

export const RemoveTitle = (state: PlayerContextState, titleId: string) => {
  const { titles = [] as string[] } = state.playerState!;
  const itemIndex = titles.findIndex((i) => i === titleId);
  if (itemIndex !== -1) {
    titles.splice(itemIndex, 1);
    return MergeTitle(state.playerState!, titles);
  }
  return state.playerState!;
};

export const MergeTitle = (player: PlayerStateFull, titles: Array<string>) => {
  return merge(player, { titles: titles });
};
