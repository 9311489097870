import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Error } from '@progress/kendo-react-labels';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Col, Container, Row } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../assets/cg-icon-globe.svg';
import { Stepper } from '../../components/stepper';
import { useSessionStorage } from 'usehooks-ts';
import { ValidateGameCodeAsync } from '../../services/games';

export const EnterGameCodePage = () => {
  const navigate = useNavigate();

  const [selectedGameCode, setSelectedGameCode] = useSessionStorage(
    'last-game-code',
    ''
  );
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleGameCodeChange = (e: InputChangeEvent) => {
    setSelectedGameCode(e.value);
    if (!isValid) setIsValid(true);
  };

  const handleNext = async () => {
    let codeIsValid = await ValidateGameCodeAsync(selectedGameCode);
    if (codeIsValid) {
      navigate(`/games/${selectedGameCode}`);
    }
    setIsValid(codeIsValid);
  };

  return (
    <div className={'layout layout--pre-game'}>
      <div className={'layout__container'}>
        <Container className={'rounded shadow'}>
          <Row className={'align-items-center'}>
            <Col />
            <Col className={'text-center'}>
              <Logo style={{ width: 64 }} />
            </Col>
            <Col />
          </Row>
          <Row className={'gy-4 mt-4'}>
            <Col xs={12} className={'text-center'}>
              <h1>Your code</h1>
            </Col>
            <Col xs={12}>
              <Stepper stepCount={4} currentStep={1} />
            </Col>
            <Col xs={12} className={'text-center'}>
              <strong>Welcome, let's start by entering your code</strong>
            </Col>
            <Col xs={12}>
              <Input
                value={selectedGameCode}
                onChange={handleGameCodeChange}
                placeholder={'Game code'}
                className={'w-100'}
                valid={isValid}
                aria-describedby={'gameCodeError'}
              />
              {!isValid && (
                <Error id={'gameCodeError'}>Game code is invalid.</Error>
              )}
            </Col>
            <Col xs={12}>
              <Button
                className={'k-button--gradient w-100'}
                themeColor={'primary'}
                onClick={handleNext}>
                Next
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
