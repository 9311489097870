import { useContext, useEffect, useState } from 'react';
import { QRAssessmentPage } from '../../components/assessment/QR';
import { AssessmentEntity } from '../../types/state/assessment/assessment';
import { AssessmentRating } from '../../components/assessment/assessment-rating';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { AssessmentAction } from '../../components/assessment/assessment-action';
import { AssessmentVote } from '../../components/assessment/assessment-vote';
import { AssessmentApproval } from '../../components/assessment/assessmentApproval';
import React from 'react';
import { PlayerAssessmentContext } from '../../contexts/player-assessment';
import {
  getAnswerActiveAssessment,
  getLastAssessmentStep,
  showAssessmentModal
} from '../../utils/assessment-state/player-answer-assessment';
import {
  AddActionAsync,
  AssessmentActionDetailRequest,
  AssessmentActionRequest,
  AssessmentRatingRequest,
  AssessmentScoreRequest,
  giveOutcomeScoreAsync,
  giveActionRatingAsync,
  AssessmentRatingDetailRequest,
  previewActionAssesment,
  finishAssessment,
  addActionAssessmentAsync,
  getAssessmentDocumentAsync,
  finishMeasurablesAssessment
} from '../../services/assessments';
import { PlayerContext } from '../../contexts/player';
import {
  Action,
  AssessmentState,
  OutcomeRating
} from '../../types/state/assessment/assessment-players-answer-state';
import AssessmentDialog from '../../components/assessment/assessment-dialog';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GetPlayerAssessment,
  joinAssessmentPlayer
} from '../../services/players';
import {
  ResetPlayerAssessmentContext,
  UpdatePlayerAssessmentContext
} from '../../utils/player-state';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { getResourceDescription } from '../../utils/assessment-state/resource';
import { Spinner } from '../../components/spinner';
import { Assessment } from '../../types/assessment';
import { AssessmentPurposeStatement } from '../../components/assessment/assessment-purpose-statement';
import { AssessmentStepTitle } from '../../components/assessment/assessment-step-title';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AssessmentStepType } from '../../constants/assessment';
import { AssessmentMeasurables } from '../../components/assessment/assessment-measurables';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';

export const PlayerAssessmentPage = () => {
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const { playerState: assessmentDocument } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const [step, setStep] = useState<number>(0);
  const [playerAssessment, setPlayerAssessment] = useState<AssessmentState>();
  const [newAssessment] = useContext(PlayerAssessmentContext);
  const [data, setData] = useState<AssessmentEntity>();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogDescription, setDialogDescription] = useState<string>('');
  const [playerActions, setPlayerActions] = useState<
    AssessmentActionDetailRequest[]
  >([]);
  const [disabledAddVote, setDisableAddVote] = useState<boolean>(false);
  const [outcomesScore, setOutcomesScore] = useState<OutcomeRating[]>([]);
  const [myActions, setMyActions] = useState<Action[]>([]);
  const [actionVotes, setActionVotes] = useState<
    AssessmentRatingDetailRequest[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAssessmentDialog, setShowAssessmentDialog] =
    useState<boolean>(false);
  const [showPlayerInvalidDialog, setShowPlayerInvalidDialog] =
    useState<boolean>(false);
  const [isShouldShowDialog, setIsShouldShowDialog] = useState(true);
  const [showDialogConfirmation, setShowDialogConfirmation] =
    useState<boolean>(false);

  const toggleDialog = () => {
    setShowDialogConfirmation((prev) => !prev);
  };
  const navigate = useNavigate();

  const handleValidatePlayer = async () => {
    if (playerState.assessmentState?.code && step > 0) {
      try {
        await GetPlayerAssessment(
          playerState.gameCode!,
          playerState.assessmentState.code
        );
        return true;
      } catch (err) {
        return false;
      }
    }
    return true;
  };

  const handleNext = async () => {
    try {
      setIsLoading(true);
      const isValidPlayer = await handleValidatePlayer();
      if (isValidPlayer) {
        if (
          !playerState?.assessmentState?.code &&
          playerState?.assessmentState?.isDeleted !== true
        ) {
          let result = await joinAssessmentPlayer(
            gameCode,
            playerState?.assessmentState?.name,
            ''
          );
          setPlayerState((prev) =>
            UpdatePlayerAssessmentContext(prev, {
              ...prev.assessmentState!,
              code: result.code,
              isDeleted: false
            })
          );
        }
        await handleSaveDataAsync();
        setShowAssessmentDialog(true);
        setStep(step + 1);
      } else {
        setShowPlayerInvalidDialog(true);
        setPlayerState((prev) =>
          UpdatePlayerAssessmentContext(prev, {
            ...prev.assessmentState!,
            isDeleted: true
          })
        );
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  let { page, gameCode } = useParams();

  const getAssessmentDocument = async () => {
    let assessmentDocument = (await getAssessmentDocumentAsync(
      gameCode!
    )) as AssessmentEntity;
    if (assessmentDocument) {
      setData(assessmentDocument);
    }
  };

  const handleAddAction = async (outcomeId: string, action: string) => {
    if (action === '') return;
    await addActionAssessmentAsync(
      gameCode,
      playerState?.assessmentState?.code,
      outcomeId,
      action
    );

    let actions = [...playerActions];
    actions.push({ outcomeId: outcomeId, action: action });
    setPlayerActions(actions);

    let myNewActions = [...myActions];
    myNewActions.push({ outcomeId: outcomeId, name: action });
    setMyActions(myNewActions);
  };

  const onChangeRatingAsync = async (actionId: string, score: number) => {
    const maxVote = 10;

    let totalVote = 0;

    let newVotes = [...actionVotes];

    let voteIndex = actionVotes.findIndex((x) => x.actionId === actionId);

    if (voteIndex > -1) {
      newVotes[voteIndex].vote = score;
    } else {
      newVotes.push({ actionId: actionId, vote: score });
    }

    newVotes?.forEach((action) => {
      totalVote = totalVote + (action?.vote ?? 0);
    });

    if (totalVote <= maxVote) {
      setDisableAddVote(false);
      setActionVotes(newVotes);
    } else {
      setDisableAddVote(true);
    }
  };

  const handleSaveVotesAsync = async () => {
    let request: AssessmentRatingRequest = {
      playerCode: playerState?.assessmentState?.code!,
      votes: []
    };

    actionVotes?.forEach((item) => {
      request.votes.push({
        actionId: item.actionId!,
        vote: item.vote!
      });
    });

    await giveActionRatingAsync(gameCode!, request);
  };

  const handleSaveDataAsync = async () => {
    if (step === AssessmentStepType.Step1) {
      try {
        let request: AssessmentScoreRequest = {
          playerCode: playerState?.assessmentState?.code!,
          outcomes: []
        };

        outcomesScore?.forEach((item) => {
          request.outcomes.push({
            outcomeId: item.outcomeId!,
            score: item.score!
          });
        });

        await giveOutcomeScoreAsync(gameCode!, request);
      } catch (error) {
      } finally {
        setOutcomesScore([]);
      }
    } else if (step === AssessmentStepType.Step2) {
      try {
        let request: AssessmentActionRequest = {
          assessmentCode: gameCode!,
          actions: []
        };

        if (request) {
          await AddActionAsync(
            gameCode!,
            playerState?.assessmentState?.code!,
            request
          );
        }
      } catch (error) {
      } finally {
        setPlayerActions([]);
      }
    } else if (step === AssessmentStepType.Step3) {
      try {
        await previewActionAssesment(
          gameCode!,
          playerState?.assessmentState?.code!
        );
      } catch (error) {}
    } else if (step === AssessmentStepType.Step4) {
      try {
        await handleSaveVotesAsync();
      } catch (error) {}
    } else if (step === AssessmentStepType.Step5) {
      try {
        await finishAssessment(gameCode!, playerState?.assessmentState?.code!);
      } catch (error) {}
    } else if (step === AssessmentStepType.Step6) {
      try {
        await finishMeasurablesAssessment(
          gameCode!,
          playerState?.assessmentState?.code!
        );
      } catch (error) {}
    }
  };

  const onCloseAssessment = async () => {
    try {
      await finishMeasurablesAssessment(
        gameCode!,
        playerState?.assessmentState?.code!
      );

      setStep(AssessmentStepType.CompleteAssessment);
      handleLastStep();
      handleTitleDescriptionDialog();
      setShowAssessmentDialog(true);
    } catch (error) {
    } finally {
    }
  };

  const handleShowAssessmentDialog = () => {
    if (step === 0) {
      setShowAssessmentDialog(false);

      return;
    }

    let showDialog = showAssessmentModal(
      playerState?.assessmentState?.code!,
      step,
      playerAssessment!
    );

    setShowAssessmentDialog(showDialog);

    if (step === AssessmentStepType.CompleteAssessment) {
      setShowAssessmentDialog(true);
    }
  };

  const handleLastStep = () => {
    if (step === 0) return;
    let assessmentStep = getLastAssessmentStep(playerAssessment!);

    if (step < assessmentStep) {
      setStep(assessmentStep);
    }
  };

  const handleTitleDescriptionDialog = () => {
    if (step === AssessmentStepType.CompleteAssessment) {
      setDialogTitle(
        generateTitleById(
          '484a765c-43bf-43bb-820e-5cf41f668473',
          assessmentDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'assessment'
        ) || 'Thanks for taking part'
      );
      setDialogDescription(
        generateTitleById(
          '8d991c05-f6b5-4f90-9d1b-35485bbfebf3',
          assessmentDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'assessment'
        ) || 'The assessment has now ended'
      );
      return;
    }

    if (
      step === AssessmentStepType.Step6 &&
      !data?.settings?.enableSuccessMeasurables
    ) {
      setDialogTitle(
        generateTitleById(
          '484a765c-43bf-43bb-820e-5cf41f668473',
          assessmentDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'assessment'
        ) || 'Thanks for taking part'
      );
      setDialogDescription(
        generateTitleById(
          '8d991c05-f6b5-4f90-9d1b-35485bbfebf3',
          assessmentDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'assessment'
        ) || 'The assessment has now ended'
      );

      return;
    }

    if (
      step >= AssessmentStepType.Step2 &&
      step < AssessmentStepType.CompleteAssessment
    ) {
      setDialogTitle(
        generateTitleById(
          '927bb333-71fa-407c-b466-5320064cb489',
          assessmentDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'assessment'
        ) || 'Thanks for your submission'
      );
      setDialogDescription(
        generateTitleById(
          'b4f56ee6-99a9-4a90-922c-7b8bedf6fa5e',
          assessmentDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'assessment'
        ) || 'The assessment will continue shortly'
      );
    } else {
      setDialogTitle(
        generateTitleById(
          'faa0b7bb-014c-43ed-b71f-befb8b701418',
          assessmentDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'assessment'
        ) || 'Welcome'
      );
      setDialogDescription(
        generateTitleById(
          'a6855a76-d452-4243-bb4b-0b6016ba1a99',
          assessmentDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'assessment'
        ) || 'The assessment will begin shortly'
      );
    }
  };

  const redirectToRegister = () => {
    setShowPlayerInvalidDialog(false);
    setPlayerState((prev) => ResetPlayerAssessmentContext(prev));
    navigate('../register-assessment');
  };

  useEffect(() => {
    setPlayerAssessment(newAssessment);
    if (
      playerState.assessmentState &&
      playerState.assessmentState?.isDeleted === true
    ) {
      setShowPlayerInvalidDialog(true);
    } else {
      setShowPlayerInvalidDialog(false);
    }
    //eslint-disable-next-line
  }, [newAssessment]);

  useEffect(() => {
    handleLastStep();
    handleShowAssessmentDialog();
    handleTitleDescriptionDialog();
    setPlayerAssessment(newAssessment);
    if (playerAssessment) {
      setMyActions(getAnswerActiveAssessment(playerAssessment!)?.actions ?? []);
    }

    if (!data) {
      getAssessmentDocument();
    }

    //eslint-disable-next-line
  }, [playerAssessment]);

  useEffect(() => {
    getAssessmentDocument();

    if (page && parseInt(page) > step) {
      setStep(parseInt(page));
    }
  }, []);

  useEffect(() => {
    handleLastStep();
    handleShowAssessmentDialog();
    handleTitleDescriptionDialog();

    //eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    handleTitleDescriptionDialog();

    //eslint-disable-next-line
  }, [displayLanguageContext]);

  return (
    <>
      <div className={'assessment-container'}>
        {showPlayerInvalidDialog && (
          <Dialog>
            <p className={'m-4 text-center'}>
              you can't submit an assessment because you have been removed by
              the facilitator.
            </p>
            <DialogActionsBar>
              <Button
                className={
                  'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
                }
                themeColor={'primary'}
                onClick={redirectToRegister}>
                Register Assessment
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
        {showAssessmentDialog && (
          <AssessmentDialog
            title={dialogTitle}
            subTitle={dialogDescription}
            showResult={step === AssessmentStepType.CompleteAssessment}
            assessment={
              playerAssessment?.assessmentStates?.at(0) as
                | Assessment
                | undefined
            }
          />
        )}
        {!showAssessmentDialog && (
          <>
            <Row className={'player-header'}>
              <Col>
                <Container>
                  <div className={'d-flex justify-content-between'}>
                    <div className={'d-flex align-items-center mt-3 mb-3'}>
                      {assessmentDocument?.assessmentDocument?.logoUrl && (
                        <img
                          width={100}
                          src={
                            assessmentDocument
                              ? assessmentDocument?.assessmentDocument?.logoUrl
                              : ''
                          }
                          className={'position-relative w-5 mr-3'}
                          alt={''}
                        />
                      )}
                      <div
                        className={'d-flex flex-column justify-content-center'}>
                        <small className={'text-light'}>
                          {getResourceDescription(
                            assessmentDocument?.assessmentDocument?.titleResId!,
                            data!,
                            playerState?.assessmentState?.languageId ?? ''
                          )}
                        </small>
                        <AssessmentStepTitle step={step} />
                      </div>
                    </div>
                    <div className={'d-flex align-items-center mt-3 mb-3'}>
                      <h3 className={'text-light fw-bold mt-3 mb-3'}>
                        {playerState?.assessmentState?.name}
                      </h3>
                    </div>
                  </div>
                </Container>
              </Col>
            </Row>

            <div className={'d-flex flex-column container'}>
              <div className={'d-flex justify-content-center mt-4'}>
                {step === 0 && (
                  <>
                    {isLoading && <Spinner />}
                    <QRAssessmentPage onJoin={handleNext} />
                  </>
                )}
                <div className={'d-flex flex-column w-full'}>
                  {step > 0 && (
                    <>
                      <Container className={'mb-3'}>
                        <AssessmentPurposeStatement assessment={data} />
                      </Container>
                    </>
                  )}
                  {step === AssessmentStepType.Step1 && (
                    <AssessmentRating
                      scores={outcomesScore}
                      assessment={data}
                      showDescription={true}
                      onChange={(result) => setOutcomesScore(result ?? [])}
                      setIsShouldShowDialog={setIsShouldShowDialog}
                    />
                  )}
                  {step === AssessmentStepType.Step2 && (
                    <AssessmentAction
                      assessment={data}
                      outcomes={
                        playerAssessment?.assessmentStates?.at(0)?.outcomes ||
                        []
                      }
                      showAddAction={true}
                      showAverageRate={true}
                      showDescription={true}
                      placeholder={'actions...'}
                      actions={myActions ?? []}
                      onAddAction={(outcomeId, action) =>
                        handleAddAction(outcomeId, action)
                      }
                      showPageTitle={true}
                      pageDescription={
                        generateTitleById(
                          '4f7e2692-0416-4547-882c-ce049bb44132',
                          assessmentDocument,
                          displayLanguageContext.displayLanguageSelected
                            .resources!,
                          'assessment'
                        ) ||
                        'Add in your suggestion for actions the group could take.'
                      }
                      holdActionFromParticipant={
                        getAnswerActiveAssessment(playerAssessment!)?.settings
                          ?.holdActionFromParticipant ?? false
                      }
                      setIsShouldShowDialog={setIsShouldShowDialog}
                    />
                  )}
                  {step === AssessmentStepType.Step3 && (
                    <AssessmentAction
                      assessment={data}
                      outcomes={
                        playerAssessment?.assessmentStates?.at(0)?.outcomes ||
                        []
                      }
                      showDescription={false}
                      actions={
                        getAnswerActiveAssessment(playerAssessment!)?.actions ??
                        []
                      }
                      showPageTitle={true}
                      pageDescription={''}
                    />
                  )}
                  {step === AssessmentStepType.Step4 && (
                    <AssessmentVote
                      disableAddVote={disabledAddVote}
                      assessment={data}
                      actions={
                        getAnswerActiveAssessment(playerAssessment!)?.actions ??
                        []
                      }
                      onRatingChange={onChangeRatingAsync}
                      actionVotes={actionVotes}
                      setIsShouldShowDialog={setIsShouldShowDialog}
                    />
                  )}
                  {step === AssessmentStepType.Step5 && (
                    <AssessmentApproval
                      assessment={data}
                      actions={
                        getAnswerActiveAssessment(playerAssessment!)?.actions ??
                        []
                      }
                    />
                  )}
                  {step === AssessmentStepType.Step6 && (
                    <AssessmentMeasurables
                      assessment={data}
                      measurables={
                        getAnswerActiveAssessment(playerAssessment!)
                          ?.measurables ?? []
                      }
                      actions={
                        getAnswerActiveAssessment(playerAssessment!)?.actions ??
                        []
                      }
                      setIsShouldShowDialog={setIsShouldShowDialog}
                    />
                  )}
                </div>
              </div>
              {step > 0 && (
                <Container>
                  <Row>
                    <Col className={'d-flex justify-content-end'}>
                      {step === AssessmentStepType.Step6 && (
                        <Button
                          className={
                            'mt-5 k-rounded-md k-button--gradient mb-3'
                          }
                          disabled={isLoading}
                          onClick={
                            isShouldShowDialog ? toggleDialog : handleNext
                          }>
                          <div className={'d-flex align-items-center'}>
                            {isLoading && (
                              <span
                                className={`k-icon k-i-loading mr-2`}
                                style={{
                                  fontSize: '20px',
                                  color: 'white'
                                }}
                              />
                            )}
                            <span className="k-button-text text-light ">
                              {generateTitleById(
                                'ecb9b044-f0da-4334-b1cf-98d1fd8701ff',
                                assessmentDocument,
                                displayLanguageContext.displayLanguageSelected
                                  .resources!,
                                'assessment'
                              ) || 'Finish'}
                            </span>
                          </div>
                        </Button>
                      )}
                      {step < AssessmentStepType.Step6 && (
                        <Button
                          className={
                            'mt-5 k-rounded-md k-button--gradient mb-3'
                          }
                          disabled={isLoading}
                          onClick={
                            [
                              AssessmentStepType.Step3,
                              AssessmentStepType.Step5
                            ].includes(step) || !isShouldShowDialog
                              ? handleNext
                              : toggleDialog
                          }>
                          <div className={'d-flex align-items-center'}>
                            {isLoading && (
                              <span
                                className={`k-icon k-i-loading mr-2`}
                                style={{
                                  fontSize: '20px',
                                  color: 'white'
                                }}
                              />
                            )}
                            <span className="k-button-text text-light">
                              {generateTitleById(
                                '43507c9f-5dc4-4ef7-997c-f6bb2202bfb5',
                                assessmentDocument,
                                displayLanguageContext.displayLanguageSelected
                                  .resources!,
                                'assessment'
                              ) || 'Next'}
                            </span>
                          </div>
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Container>
              )}
            </div>
          </>
        )}
      </div>

      {showDialogConfirmation && (
        <Dialog
          title={
            generateTitleById(
              '7ee661fa-3410-40d6-84cf-abd3bb445705',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Are you sure you want to proceed?'
          }
          onClose={toggleDialog}>
          <p style={{ margin: '25px', textAlign: 'center' }}>
            {generateTitleById(
              'cdd979af-adbb-47e7-8284-68af16f8414a',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || "You can't go back if you proceed."}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialog}>
              {generateTitleById(
                '0c4ecb1b-19d4-4290-998a-cb569250b917',
                assessmentDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || 'No'}
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => {
                toggleDialog();
                step === AssessmentStepType.Step6
                  ? onCloseAssessment()
                  : handleNext();
              }}>
              {generateTitleById(
                '9707034c-8fd8-4873-ab11-b246aae97715',
                assessmentDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || 'Yes'}
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};
