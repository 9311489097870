import React, { useContext } from 'react';
import Row from 'react-bootstrap/esm/Row';
import {
  AssessmentEntity,
  AssessmentOutcomeEntity
} from '../../types/state/assessment/assessment';
import Col from 'react-bootstrap/esm/Col';
import { getResourceDescription } from '../../utils/assessment-state/resource';
import { PlayerContext } from '../../contexts/player';

interface OutcomeProps {
  assessmentOutcome: AssessmentOutcomeEntity;
  assessment: AssessmentEntity;
  showAverageRate?: boolean;
  showDescription?: boolean;
  averageRate?: number;
  children?: React.ReactNode;
}

export const AssessmentOutcome = ({ ...props }: OutcomeProps) => {
  const [playerState] = useContext(PlayerContext);
  return (
    <div className="card mt-2 primary_bg-opacity-20">
      <div className="card-body">
        <div className={'d-flex flex-column'}>
          <Row>
            <Col
              className={'d-flex justify-content-between align-items-center'}>
              <span className={'fw-bold text-dark'}>
                {getResourceDescription(
                  props?.assessmentOutcome?.titleResId!,
                  props?.assessment!,
                  playerState?.assessmentState?.languageId ?? ''
                )}
              </span>
              <div>
                {props.showAverageRate && (
                  <div className="w-3 h-3 rounded-circle border border-3 border-dark bg-white d-flex align-items-center justify-content-center ml-1">
                    <span className={'fw-bold text-dark mb-0'}>
                      {isNaN(Number(props.averageRate)) ? 0 : props.averageRate}
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className={'mt-2'}>
            <Col>
              <div className={'d-flex'}>
                <div className={'flex-grow-1'}>
                  {props.showDescription && (
                    <Row>
                      <Col>
                        <div className={'d-flex'}>
                          <p className={'flex-grow-1'}>
                            {getResourceDescription(
                              props?.assessmentOutcome?.descriptionResId!,
                              props?.assessment!,
                              playerState?.assessmentState?.languageId ?? ''
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {props?.children && (
                    <Row className={'mb-3'}>
                      <Col>{props?.children}</Col>
                    </Row>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
