import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import Popup from '../popup-window';
import { AvatarPreview } from '../wizard';
import { uploadAvatar } from '../../services/players';

type Props = {
  inputTitle?: string;
  saveBtnTitle?: string;
  errorMessage?: string;
  onToggleProfileWidget?: () => void;
  onSubmit?: (name?: string, avatar?: string) => void;
  isShowAvatar?: boolean;
  predefinedName?: string;
  predefinedAvatar?: string;
};

const SimpleEntity = ({
  inputTitle,
  saveBtnTitle = 'Save',
  errorMessage,
  onToggleProfileWidget = () => {},
  onSubmit = () => {},
  isShowAvatar = true,
  predefinedName,
  predefinedAvatar
}: Props) => {
  const avatarRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');

  const handleClosePopup = () => {
    onToggleProfileWidget();
  };

  const handlePlayerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handlePlayerAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (!file?.type?.includes('image')) return;
    uploadAvatar(file).then((response) => {
      setAvatar(response?.result);
    });
  };

  const onSaveClick = async () => {
    onSubmit(name, avatar);
  };

  const handlePencilClick = () => {
    avatarRef.current?.click();
  };

  useEffect(() => {
    if (predefinedName) {
      setName(predefinedName);
    }
    if (predefinedAvatar) {
      setAvatar(predefinedAvatar);
    }
  }, []);

  return (
    <Popup
      handleClose={handleClosePopup}
      className={'box box-small d-flex flex-column gap-3'}>
      {isShowAvatar && (
        <div>
          <Col md={{ span: 12 }} className={'position-relative'}>
            <AvatarPreview
              avatarSource={avatar}
              handleUploadAvatar={handlePencilClick}>
              <input
                type={'file'}
                ref={avatarRef}
                onChange={handlePlayerAvatarChange}
                style={{ opacity: '0' }}
                accept={'image/*'}
              />
            </AvatarPreview>
          </Col>
        </div>
      )}

      <div>
        <Form.Label className={'text-dark'}>{inputTitle}</Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type={'text'}
            placeholder={''}
            aria-describedby={'inputGroupPrepend'}
            required
            className={'py-2'}
            size={'lg'}
            onChange={handlePlayerNameChange}
            value={name}
          />
          <Form.Control.Feedback
            type={'invalid'}
            style={{ display: errorMessage !== '' ? 'block' : 'none' }}>
            {errorMessage}
          </Form.Control.Feedback>
        </InputGroup>
      </div>
      <Button
        themeColor={'primary'}
        className={'k-button--gradient'}
        onClick={onSaveClick}>
        {saveBtnTitle}
      </Button>
    </Popup>
  );
};

export default SimpleEntity;
