import { Outlet } from 'react-router-dom';

/**
 * The layout for public unstructured pages. E.g. Privacy policy and TAC.
 * @returns
 */
export const PublicLayout = () => {
  return (
    <div className={'layout layout--public'}>
      <div className={'layout__container'}>
        <Outlet />
      </div>
    </div>
  );
};

export default PublicLayout;
