import React, { useContext, useEffect, useState } from 'react';
import {
  DropDownList,
  DropDownListProps
} from '@progress/kendo-react-dropdowns';
import { GameDocumentContext } from '../../contexts/game-document';
import { Combobox } from '../../types/combobox';
import { GetQuestionsFromTaskContent } from '../../utils/game-document/assets/task-contents';

interface TaskContentFormComboBoxProps extends DropDownListProps {
  taskContentId?: string;
  selectedFormId?: string;
  onValueChange?: (value: Combobox) => void;
}

export const TaskContentFormComboBox = (
  props: TaskContentFormComboBoxProps
) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [forms, setForms] = useState<Combobox[]>([]);

  useEffect(() => {
    setForms(
      GetQuestionsFromTaskContent(
        gameDocument?.gameDocument!,
        props.taskContentId!
      ) ?? []
    );
  }, [props.taskContentId, props.selectedFormId]);

  return (
    <DropDownList
      data={forms}
      value={forms?.find((x) => x.id === props.selectedFormId) ?? null}
      textField={'description'}
      onChange={(e) => {
        if (props.onValueChange) {
          props.onValueChange(e.value);
        }
      }}
    />
  );
};

export default TaskContentFormComboBox;
