import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import Popup from '../popup-window'; /** Load dummy avatars from CG assets */
import { AvatarPreview, AvatarLibrary } from '../wizard';
import { PlayerContext } from '../../contexts/player';
import { getAvatars, updatePlayer, uploadAvatar } from '../../services/players';
import { AvatarImage } from '../../types/tab-content';
import { PlayerResponse } from '../../types/responses/player-response';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { GameDocumentContext } from '../../contexts/game-document';

type Props = {
  onToggleProfileWidget?: () => void;
};

const PopupProfile = ({ onToggleProfileWidget = () => {} }: Props) => {
  const avatarRef = useRef<HTMLInputElement>(null);
  const [state] = useContext(GameDocumentContext);
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const [name, setName] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [showAvatarLibrary, setShowAvatarLibrary] = useState<boolean>(false);
  const [avatarLibrary, setAvatarLibrary] = useState<AvatarImage[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorValidation, setErrorValidation] = useState<string>('');

  const handleClosePopup = () => {
    setIsError(false);
    setErrorValidation('');
    onToggleProfileWidget();
  };

  const handlePlayerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handlePlayerAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    uploadAvatar(file).then((response) => {
      setAvatar(response?.result);
    });
  };

  const handleAvatarClick = (url: string) => {
    setAvatar(url);
  };

  const onSaveClick = async () => {
    if (name === '' || name.length < 2 || name.length > 128) {
      setErrorValidation('Please enter a name that is 2 - 128 characters');
      setIsError(true);
    }

    const response = await updatePlayer(
      playerState?.playerState?.code,
      playerState.gameCode,
      name,
      avatar
    );

    const res = response as PlayerResponse;
    if (!res) {
      setErrorValidation('Player name already taken');
      setIsError(true);
      return;
    }

    setPlayerState((prev) => ({
      ...prev,
      playerState: {
        ...prev.playerState!,
        name: name,
        avatarImage: avatar
      }
    }));

    onToggleProfileWidget();
  };

  const handlePencilClick = () => {
    avatarRef.current?.click();
  };

  const getAvatarLibrary = async (gameCode: string) => {
    const response = await getAvatars(gameCode);
    if (response !== undefined) {
      setAvatarLibrary(
        response.map((item: string, index: number) => {
          return {
            id: index,
            src: item
          };
        })
      );
    }
  };

  const handleMoreButtonClicked = () =>
    setShowAvatarLibrary(!showAvatarLibrary);

  useEffect(() => {
    if (playerState.gameCode !== undefined) {
      getAvatarLibrary(playerState.gameCode);
    }
    setName(playerState?.playerState?.name ?? '');
    setAvatar(playerState?.playerState?.avatarImage ?? '');
  }, []);

  return (
    <Popup handleClose={handleClosePopup}>
      <div>
        <Col md={{ span: 12 }} className={'position-relative'}>
          <AvatarPreview
            avatarSource={avatar}
            handleUploadAvatar={handlePencilClick}>
            <input
              type={'file'}
              // ref={avatarUpload}
              ref={avatarRef}
              onChange={handlePlayerAvatarChange}
              style={{ opacity: '0' }}
              accept={'image/*'}
            />
          </AvatarPreview>

          <div
            className={
              'd-flex mb-4 justify-content-between align-items-center position-relative flex-wrap'
            }>
            <AvatarLibrary
              limit={4}
              items={avatarLibrary}
              handleAvatarClick={handleAvatarClick}
              handleMoreButtonClicked={handleMoreButtonClicked}
              isButtonMoreShow={true}
            />
          </div>
          {showAvatarLibrary && (
            <div
              className={
                'd-flex flex-wrap mb-4 justify-content-between align-items-center position-relative bg-light mt-4 p-2'
              }>
              <AvatarLibrary
                items={avatarLibrary}
                handleAvatarClick={handleAvatarClick}
              />
            </div>
          )}
        </Col>
      </div>

      <div>
        <Form.Label className={'text-dark'}>
          {generateTitleById(
            '82f09d89-f2e6-4ec2-ac6a-644b40d503bf',
            state,
            displayLanguageContext.displayLanguageSelected.resources!,
            'game'
          ) || 'Name'}
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type={'text'}
            placeholder={''}
            aria-describedby={'inputGroupPrepend'}
            required
            className={'py-2'}
            size={'lg'}
            onChange={handlePlayerNameChange}
            value={name}
          />
          <Form.Control.Feedback
            type={'invalid'}
            style={{ display: isError ? 'block' : 'none' }}>
            {errorValidation}
          </Form.Control.Feedback>
        </InputGroup>
      </div>
      <Button color={'primary'} onClick={onSaveClick}>
        {generateTitleById(
          '6db126df-9980-4fed-b80a-1d7a29dbb662',
          state,
          displayLanguageContext.displayLanguageSelected.resources!,
          'game'
        ) || 'Save'}
      </Button>
    </Popup>
  );
};

export default PopupProfile;
