import React from 'react';
import { ReactComponent as Logo } from '../assets/cg-icon-globe.svg';

export function Spinner() {
  return (
    <div className="spinner overlay w-100 h-100 position-absolute d-flex justify-content-center align-items-center">
      <span className={'loader'} role="status"></span>
    </div>
  );
}
