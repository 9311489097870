import React, { PropsWithChildren, useEffect, useState } from 'react';

type DisplayType = 'mobile' | 'tablet' | 'laptop' | 'pc';

const DisplayContext = React.createContext<
  [
    initialState: DisplayType,
    setState: React.Dispatch<React.SetStateAction<DisplayType>>
  ]
>(['pc', () => {}]);

const DisplayProvider = (props: PropsWithChildren) => {
  const [state, setState] = useState<DisplayType>('pc');

  const handleWindowSizeChange = () => {
    const device: DisplayType =
      window.innerWidth <= 640
        ? 'mobile'
        : window.innerWidth > 640 && window.innerWidth <= 1024
        ? 'tablet'
        : window.innerWidth > 1024 && window.innerWidth <= 1280
        ? 'laptop'
        : 'pc';
    setState(device);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <DisplayContext.Provider value={[state, setState]}>
      {props.children}
    </DisplayContext.Provider>
  );
};

export { DisplayContext, DisplayProvider };
