import { Col, Container, Row } from 'react-bootstrap';
import { AssessmentOutcome } from './assessment-outcome';
import { AssessmentEntity } from '../../types/state/assessment/assessment';
import { Action } from '../../types/state/assessment/assessment-players-answer-state';
import { getFormattedDateString } from '../../utils/date';
import { getPlayerName } from '../../utils/assessment-state/player-answer-assessment';
import { PlayerAssessmentContext } from '../../contexts/player-assessment';
import { useContext, useMemo } from 'react';
import { generateTitleById } from '../../utils/game-document/display-languages';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';

interface AssessmentApprovalProps {
  assessment?: AssessmentEntity;
  actions?: Action[];
  showAddAction?: boolean;
  showAverageRate?: boolean;
  showDescription?: boolean;
  placeholder?: string;
  className?: string;
}
export const AssessmentApproval = ({ ...props }: AssessmentApprovalProps) => {
  const [playerAssessment] = useContext(PlayerAssessmentContext);
  const { playerState: assessmentDocument } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const orderedActionByVote = useMemo(
    () =>
      props.assessment?.assessmentOutcomes.map((outcome) => ({
        ...outcome,
        actions: props.actions
          ?.filter((action) => action.outcomeId === outcome.id)
          .sort((a, b) => (b.votes ?? 0) - (a.votes ?? 0))
      })),
    [props?.assessment?.assessmentOutcomes, props?.actions]
  );

  return (
    <Container className={'d-flex flex-column assessment-content'}>
      <Row className={'mb-3'}>
        <Col className={'d-flex flex-column'}></Col>
      </Row>
      {orderedActionByVote?.map((item) => (
        <AssessmentOutcome
          key={item.id}
          showAverageRate={props.showAverageRate}
          showDescription={props.showDescription}
          assessmentOutcome={item}
          assessment={props.assessment!}>
          {item.actions?.length === 0 ? (
            <span className={'text-muted'}>
              {generateTitleById(
                'bcc1f317-7c84-44bf-ab15-c24600091d47',
                assessmentDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || 'No actions found'}
            </span>
          ) : (
            item.actions?.map((action) => (
              <div key={action.id} className="card mt-2 success_bg-opacity-20">
                <div className="card-body text-dark">
                  <div className={'d-flex flex-wrap'}>
                    <div className={'d-flex w-75 flex-column mb-3'}>
                      <cite title="Action">
                        <small className={'text-muted'}>
                          {generateTitleById(
                            '013a790c-683b-4d9d-a54b-b5f41a642b89',
                            assessmentDocument,
                            displayLanguageContext.displayLanguageSelected
                              .resources!,
                            'assessment'
                          ) || 'Action'}
                        </small>
                      </cite>
                      <span className={'fw-bold'}>{action?.name}</span>
                    </div>
                    <div className={'d-flex flex-wrap justify-content-between'}>
                      <div className={'d-flex flex-column mr-3'}>
                        <cite title="Total vote">
                          <small className={'text-muted'}>
                            {generateTitleById(
                              '419aec07-4626-4068-a964-b06444c20d5e',
                              assessmentDocument,
                              displayLanguageContext.displayLanguageSelected
                                .resources!,
                              'assessment'
                            ) || 'Vote'}
                          </small>
                        </cite>
                        <span className={'fw-bold'}>
                          ({action?.votes ?? 0})
                        </span>
                      </div>
                      <div className={'d-flex flex-column mr-3'}>
                        <cite title="Status">
                          <small className={'text-muted'}>
                            {generateTitleById(
                              '3f95d8ff-c918-4a71-a725-b35807e0a1a5',
                              assessmentDocument,
                              displayLanguageContext.displayLanguageSelected
                                .resources!,
                              'assessment'
                            ) || 'Status'}
                          </small>
                        </cite>
                        <span
                          className={`fw-bold ${
                            action?.status === 'accepted'
                              ? 'text-success'
                              : action?.status
                              ? 'text-danger'
                              : ''
                          }`}>
                          {action.status === 'accepted'
                            ? generateTitleById(
                                '7a0cd700-6c03-4b3a-acad-cb80cecb0ef1',
                                assessmentDocument,
                                displayLanguageContext.displayLanguageSelected
                                  .resources!,
                                'assessment'
                              ) || 'Approved'
                            : action?.status === 'declined'
                            ? generateTitleById(
                                'd401566a-a157-4542-bedc-74ef76cabccf',
                                assessmentDocument,
                                displayLanguageContext.displayLanguageSelected
                                  .resources!,
                                'assessment'
                              ) || 'Unapproved'
                            : '--'}
                        </span>
                      </div>
                      <div className={'d-flex flex-column mr-3'}>
                        <cite title="Date">
                          <small className={'text-muted'}>
                            {generateTitleById(
                              'fd8be6bf-8aa0-4cc7-8b26-cd835ca8691c',
                              assessmentDocument,
                              displayLanguageContext.displayLanguageSelected
                                .resources!,
                              'assessment'
                            ) || 'Date'}
                          </small>
                        </cite>
                        <span className={'fw-bold'}>
                          {action.targetDate &&
                            getFormattedDateString(
                              new Date(action.targetDate)
                            )?.split(' ')[0]}
                          {!action.targetDate && '--'}
                        </span>
                      </div>
                      <div className={'d-flex flex-column mr-3'}>
                        <cite title="Person in Charge">
                          <small className={'text-muted'}>
                            {generateTitleById(
                              '8eceb0d5-ee97-4fad-b694-23513a9b825a',
                              assessmentDocument,
                              displayLanguageContext.displayLanguageSelected
                                .resources!,
                              'assessment'
                            ) || 'Person in Charge'}
                          </small>
                        </cite>
                        <span className={'fw-bold'}>
                          {getPlayerName(playerAssessment, action?.pic!)}
                          {!action.pic && '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </AssessmentOutcome>
      ))}
    </Container>
  );
};
