import { useContext, useEffect, useState } from 'react';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import SelectionItem from '../../types/selection-item';
import Operations from '../../constants/operations';
import { GameDocument } from '../../types/game-document';
import { ArgumentList } from './argument-list';
import {
  GetZones,
  GetTasks,
  GetTimers,
  GetDocumentAreas
} from '../../utils/game-document/assets/index';
import { GameDocumentContext } from '../../contexts/game-document';
import { GetItems } from '../../utils/game-document/assets/items';
import { GetTitles } from '../../utils/game-document/assets/titles';
import ComboboxInput from '../forms/combobox-input';

interface ArgumentProps {
  onChangeOperation?: (operationName?: string) => void;
  onChangeArgument?: (selectedArguments?: string | string[]) => void;
  onChangeQuantity?: (quantity?: number) => void;
  disabled?: boolean;
}
export const GameAlgorithm = ({
  onChangeOperation = () => {},
  disabled = false,
  ...props
}: ArgumentProps) => {
  const [selectedList, setSelectedList] = useState<string>('');
  const [showQuantity, setShowQuantity] = useState<boolean>(false);
  const [argumentList, setArgumentList] = useState<SelectionItem[] | undefined>(
    []
  );
  const [state] = useContext(GameDocumentContext);
  const gameDocument = state.gameDocument;

  const handleChange = (e: ComboBoxChangeEvent) => {
    let res = e.target.value;
    if (res !== null) {
      setSelectedList(res.list);
      setShowQuantity(res.showQuantity);
      onChangeOperation(res.name);
      setArgumentList(getArgumentList(res.list, gameDocument));
    } else {
      setSelectedList('');
      setShowQuantity(false);
      onChangeOperation('');
      setArgumentList([]);
    }
  };

  const getArgumentList = (
    listName: string | undefined,
    gameDocument: GameDocument | undefined
  ) => {
    switch (listName) {
      case 'zone':
        return GetZones(gameDocument) as SelectionItem[];
      case 'task':
        return GetTasks(gameDocument) as SelectionItem[];
      case 'area':
        return GetDocumentAreas(gameDocument) as SelectionItem[];
      case 'item':
        return GetItems(gameDocument) as SelectionItem[];
      case 'title':
        return GetTitles(gameDocument) as SelectionItem[];
      case 'timer':
        return GetTimers(gameDocument) as SelectionItem[];
      default:
        return undefined;
    }
  };

  return (
    <div className={'d-flex flex-wrap'}>
      <div className={'p-2'}>
        <ComboboxInput
          disabled={disabled}
          data={Operations}
          textField={'description'}
          dataItemKey={'name'}
          className={'border border-dark w-20'}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </div>
      <div className={'p-2'}>
        <ArgumentList
          selectedList={selectedList}
          showQuantity={showQuantity}
          argumentList={argumentList}
          onChangeArgument={props?.onChangeArgument}
          onChangeQuantity={props?.onChangeQuantity}
        />
      </div>
    </div>
  );
};
