/**
 * Function to truncate decimal value into X decimal place
 * @param value source number that want to be truncate
 * @param decimalPlace how many decimal number to keep
 * @returns truncated value without rounding
 */
export const truncateDecimalNumber = (
  value: number,
  decimalPlace: number
): number => {
  let strValue = value.toString();
  strValue = strValue.slice(0, strValue.indexOf('.') + decimalPlace + 1);
  return Number(strValue);
};

/**
 * Function to rounding decimal number into X decimal place
 * @param value source number that want to be round
 * @param decimalPlace how many decimal number to round
 * @returns rounded value
 */
export const roundingDecimalNumber = (
  value: number,
  decimalPlace: number
): number => {
  const strValue = parseFloat(value.toString()).toFixed(decimalPlace);
  return Number(strValue);
};
