/**
 * Sample player name already taken : alzah | wawan | fajar | arbi | tristan
 *
 */
import redaxios from 'redaxios';
import { API_URL } from '../constants';
import { AlghoritmTask } from '../types/alghoritm-task';
import { HubResponse } from '../types/responses/hub-response';
import { TeamResponse } from '../types/responses/team-response';
import { TeamStateFull } from '../types/state';
import {
  DeleteAsync,
  GetAllAsync,
  GetAsync,
  PatchAsync,
  PostAsync
} from './api';
import { getUrl, HttpStatus } from './http-client';
import { KendoGridResult } from '../types/KendoGridResult';

export interface Response {
  isError: boolean;
  statusCode: HttpStatus;
  message: string;
  result: string;
}

export interface PostChat {
  teamCode: string;
  playerCode: string;
  message: string;
  sentDateUtc: string;
}

export interface TeamChatHistory {
  id: number;
  teamId: string;
  playerCode: string;
  playerName: string;
  playerAvatar: string;
  sentDateUtc: string;
  message: string;
}

export async function createNewTeam(
  code: string | undefined,
  teamName: string
): Promise<Response> {
  try {
    const url = getUrl(`${API_URL.teams}/${code}/team/${teamName}`);
    const response = await redaxios.post(url);
    return response?.data?.result;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const err = error as any;
    const errorRes: Response = {
      isError: true,
      statusCode: err?.status,
      message: err?.data,
      result: err?.data
    };
    return errorRes;
  }
}

export async function getTeamPlayersList(code: string): Promise<Response> {
  try {
    const url = getUrl(`${API_URL.teams}/${code}/players`);
    const response = await redaxios.get(url);
    return response?.data?.result;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const err = error as any;
    const errorRes: Response = {
      isError: true,
      statusCode: err?.status,
      message: err?.data,
      result: err?.data
    };
    return errorRes;
  }
}

export async function getTeamList(code: string) {
  try {
    const response = await GetAllAsync<TeamResponse>(`games/${code}/teams`);
    return response?.data;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const err = error as any;
    const errorRes: Response = {
      isError: true,
      statusCode: err?.status,
      message: err?.data,
      result: err?.data
    };
    return errorRes;
  }
}

/**
 * @description Handle player join team
 * @param teamCode
 * @param playerCode
 * @param gameCode
 *
 */

export async function joinTeam(
  teamCode: string,
  playerCode: string,
  gameCode: string
) {
  try {
    const response = PostAsync<any>(
      `games/${gameCode}/teams/${teamCode}/players/${playerCode}`,
      null
    );
    return response;
  } catch (error) {
    throw error;
  }
}

/**
 * @description Handle player to leave team
 * @param teamCode
 * @param playerCode
 * @param gameCode
 *
 */

export async function leaveTeam(
  teamCode: string,
  playerCode: string,
  gameCode: string
) {
  try {
    const response = DeleteAsync<any>(
      `games/${gameCode}/teams/${teamCode}/players/${playerCode}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

/**
 *
 * @param teamId // teamId you want to join chat
 */
export const GetTeamChatHub = async (teamCode: string) => {
  try {
    const url = getUrl(`${API_URL.hubsV2}/teamchat/${teamCode}`);
    const response = await redaxios.post<HubResponse>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 *
 * @param teamId // teamId you want to join chat
 */
export const PostTeamChat = async (
  teamCode: string,
  gameCode: string,
  message: PostChat
) => {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/teams/${teamCode}/chats`
    );
    const response = await redaxios.post(url, message);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Get team chat history
 * @param teamId // Team ID
 */
export const GetTeamChatHistory = async (
  teamCode: string,
  gameCode: string
) => {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/teams/${teamCode}/chats`
    );
    const response = await redaxios.get<KendoGridResult<TeamChatHistory>>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

export function navigateToWorldMap(gameCode: string, teamCode: string) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/locate?zoneId=world-map`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function navigateToZone(
  gameCode: string,
  teamCode: string,
  zoneId: string
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/locate?zoneId=${zoneId}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function openTask(gameCode: string, teamCode: string, taskId: string) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/tasks/${taskId}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function showTask(
  gameCode: string,
  teamCode: string,
  taskId: string,
  visible: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/tasks/${taskId}/?visible=${visible}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function randomTask(
  gameCode: string,
  teamCode: string,
  tasks: AlghoritmTask
) {
  try {
    let response = await PostAsync<string[]>(
      `games/${gameCode}/teams/${teamCode}/tasks/random`,
      tasks as never
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function showArea(
  gameCode: string,
  teamCode: string,
  areaId: string,
  visible: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/areas/${areaId}/?visible=${visible}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function startTimer(
  gameCode: string,
  teamCode: string,
  timerId: string,
  start: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/timers/${timerId}/start/?start=${start}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function pauseTimer(
  gameCode: string,
  teamCode: string,
  timerId: string,
  pause: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/timers/${timerId}/pause/?pause=${pause}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function giveItem(
  gameCode: string,
  teamCode: string,
  itemId: string,
  quantity: number,
  add: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/items/${itemId}/?quantity=${quantity}&add=${add}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function giveScore(
  gameCode: string,
  teamCode: string,
  score: number,
  add: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/score/?score=${score}&add=${add}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function giveTitle(
  gameCode: string,
  teamCode: string,
  titleId: string,
  add: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/titles/${titleId}/?add=${add}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getTeamStateAsync(gameCode: string, teamCode: string) {
  let response = await GetAsync<TeamStateFull>(
    `games/${gameCode}/teams/${teamCode}/states`
  );
  return response;
}

export async function postTeamStateAsync(gameCode: string, teamCode: string) {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/teams/${teamCode}/states`
    );
    const response = await redaxios.post(url);
    return response?.data?.result;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    const err = error as any;
    const errorRes: Response = {
      isError: true,
      statusCode: err?.status,
      message: err?.data,
      result: err?.data
    };
    return errorRes;
  }
}

export async function getTeamSelectedAsync(gameCode: string, teamCode: string) {
  let response = await GetAsync<TeamResponse>(
    `games/${gameCode}/teams/${teamCode}`
  );
  return response;
}

export function sendFacilitatorTeamChat(
  gameCode?: string,
  teamCode?: string,
  userId?: number,
  name?: string,
  message?: string
) {
  const response = PostAsync(
    `games/${gameCode}/teams/${teamCode}/facilitator/chats`,
    {
      facilitatorUserProfileId: userId,
      facilitatorName: name,
      message: message
    }
  )
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

export async function teamDistributeTaskAsync(
  gameCode: string,
  teamCode: string,
  tasks: AlghoritmTask
) {
  try {
    let response = await PostAsync<string[]>(
      `games/${gameCode}/teams/${teamCode}/tasks/distribute`,
      tasks as never
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function teamStartStopTimerAsync(
  gameCode: string,
  teamCode: string,
  timerId: string,
  start: boolean
) {
  try {
    let response = await PostAsync<string[]>(
      `games/${gameCode}/teams/${teamCode}/timers/${timerId}/start?start=${start}`,
      start as never
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function teamPauseResumeTimerAsync(
  gameCode: string,
  teamCode: string,
  timerId: string,
  pause: boolean
) {
  try {
    let response = await PostAsync<string[]>(
      `games/${gameCode}/teams/${teamCode}/timers/${timerId}/pause?pause=${pause}`,
      pause as never
    );
    return response;
  } catch (error) {
    throw error;
  }
}

/**
 * send content to teams
 * @param gameCode
 * @param teamCode
 */
export const sendTeamsContent = async (
  gameCode: string,
  teamCode: string,
  message?: string
) => {
  try {
    await PostAsync(`games/${gameCode}/teams/${teamCode}/content`, message);
  } catch (e) {
    console.error(e);
  }
};

export const addNewTeams = async (
  gameCode: string,
  name: string,
  avatar?: string
) => {
  try {
    return await PostAsync<any>(`games/${gameCode}/teams`, {
      name: name,
      avatar: avatar
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export function LockAsync(
  gameCode: string,
  teamCode: string,
  isLocked: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/lock/?isLocked=${isLocked}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function DisableTeamChatAsync(
  gameCode: string,
  teamCode: string,
  isDisableTeamChat: boolean
) {
  try {
    const response = PostAsync(
      `games/${gameCode}/teams/${teamCode}/disable-chat/?disabled=${isDisableTeamChat}`,
      {}
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteTeamAsync = async (gameCode: string, teamCode: string) => {
  try {
    return await DeleteAsync<any>(`games/${gameCode}/teams/${teamCode}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const checkPlayersOnTeam = async (
  gameCode: string,
  teamCode: string
) => {
  try {
    return await GetAsync<boolean>(
      `games/${gameCode}/teams/${teamCode}/players/check`
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const patchTeamAsync = async (
  gameCode: string,
  teamCode: string,
  name: string,
  avatar?: string
) => {
  try {
    return await PatchAsync<any>(`games/${gameCode}/teams/${teamCode}`, {
      name: name,
      avatar: avatar
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
