import { PlayerContextState } from '../../../contexts/player';
import { PlayerStateFull } from '../../../types/state/player/player-state-full';
import { TimerEntity } from '../../../types/entities/';
import { merge } from 'lodash';
import { TeamContextState } from '../../../contexts/team';
import { TeamStateFull } from '../../../types/state';

/**
 * Get timer by id.
 * @param player - The player state full that save the engine data
 * @param id - The ID of timer you want to search
 * @constructor
 */
export const GetTimerById = (player: PlayerContextState, id: string) => {
  return player?.playerState?.timers?.find((x) => x.id === id);
};

/**
 * Get team timer by id.
 * @param team - The team state full that save the engine data
 * @param id - The ID of timer you want to search
 * @constructor
 */
export const GetTeamTimerById = (team: TeamContextState, id: string) => {
  return team?.teamState?.timers?.find((x) => x.id === id);
};

/**
 * Start timer by id.
 * @param player - The player state full that save the engine data
 * @param timerId - The ID of timer you want to start
 * @param timer - Started timer
 * @constructor
 */
export const StartTimerById = async (
  player: PlayerContextState,
  timerId: string,
  timer: TimerEntity
) => {
  let timers = player?.playerState?.timers ?? [];
  let timerIndex = timers.findIndex((z) => z.id === timerId)!;
  timers[timerIndex] = timer;
  return MergePlayerTimer(player?.playerState!, timers);
};

/**
 * Updated Timer
 * @param player - The player state full that save the engine data
 * @param timerId - The timerId of timer that you want to update
 * @param timer - Updated timer
 * @constructor
 */

export const UpdateTimerAsync = async (
  player: PlayerContextState,
  timerId: string,
  timer: TimerEntity
) => {
  let timers = player?.playerState?.timers ?? [];
  let timerIndex = timers.findIndex((z) => z.id === timerId);
  if (timerIndex !== -1) timers[timerIndex] = timer;
  return MergePlayerTimer(player?.playerState!, timers);
};

/**
 * Updated Timer
 * @param player - The player state full that save the engine data
 * @param timerId - The timerId of timer that you want to update
 * @param timer - Updated timer
 * @constructor
 */

export const UpdateTimer = (
  player: PlayerContextState,
  timerId: string,
  timer: TimerEntity
) => {
  let timers = player?.playerState?.timers ?? [];
  let timerIndex = timers.findIndex((z) => z.id === timerId);
  if (timerIndex !== -1) timers[timerIndex] = timer;
  return MergePlayerTimer(player?.playerState!, timers);
};

export const MergePlayerTimer = (
  state: PlayerStateFull,
  timers: Array<TimerEntity>
) => {
  return merge(state, { timers });
};

export const UpdateTeamTimerAsync = async (
  team: TeamContextState,
  timerId: string,
  timer: TimerEntity
) => {
  let timers = team?.teamState?.timers ?? [];
  let timerIndex = timers.findIndex((z) => z.id === timerId);
  if (timerIndex !== -1) timers[timerIndex] = timer;
  return MergeTeamTimer(team?.teamState!, timers);
};

export const MergeTeamTimer = (
  state: TeamStateFull,
  timers: Array<TimerEntity>
) => {
  return merge(state, { timers });
};
