import { Avatar } from '@progress/kendo-react-layout';
import React from 'react';
import avatars from '../../content/avatar';
import { AvatarImage } from '../../types/tab-content';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
  items?: AvatarImage[];
  limit?: number;
  handleAvatarClick?: (url: string) => void;
  handleMoreButtonClicked?: () => void;
  isButtonMoreShow?: boolean;
};

const AvatarLibrary = ({
  items = avatars,
  limit = undefined,
  handleAvatarClick = () => {},
  handleMoreButtonClicked = () => {},
  isButtonMoreShow = false
}: Props) => {
  return (
    <div
      className={`col d-flex flex-wrap ${
        isButtonMoreShow ? 'justify-content-center' : 'justify-content-between'
      } align-items-center`}>
      {typeof items !== 'undefined' &&
        items?.length > 0 &&
        items?.slice(0, limit ?? items.length).map((item: AvatarImage) => (
          <Avatar
            size={'large'}
            key={item.id}
            type={'image'}
            themeColor={null}
            className={'mb-2'}>
            <img
              src={item.src}
              alt={''}
              role={'button'}
              onClick={() => handleAvatarClick(item.src)}
            />
          </Avatar>
        ))}
      {isButtonMoreShow && (
        <Avatar themeColor={'dark'} type="text" size="large">
          <Button
            themeColor={'dark'}
            size={'large'}
            className={'text-white rounded-circle border-0 fs-1 px-1 w-4 h-4'}
            onClick={handleMoreButtonClicked}>
            <span className="material-symbols-outlined">more_horiz</span>
          </Button>
        </Avatar>
      )}
    </div>
  );
};

export default AvatarLibrary;
