import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GameDocumentContext } from '../../contexts/game-document';
import { PlayerContext } from '../../contexts/player';
import { joinPlayer } from '../../services/players';
import { PreGameWizard } from '../../types/pre-game';
import { PlayerResponse } from '../../types/responses/player-response';
import { UpdatePlayerContext } from '../../utils/player-state';
import { WizardContent, AvatarPreview } from '.';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';

const StepFive = ({ nextStepCallback, currentStep = 1 }: PreGameWizard) => {
  const navigate = useNavigate();
  const [gameDocument] = useContext(GameDocumentContext);
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const handleFinishButton = async () => {
    const response: PlayerResponse = await joinPlayer(
      gameDocument.gameCode ?? '',
      playerState.playerState?.code ?? '',
      playerState.playerState?.name ?? '',
      playerState.playerState?.avatarImage
    );
    if (response) {
      setPlayerState((prev) =>
        UpdatePlayerContext(prev, {
          ...prev.playerState!,
          name: response.name,
          avatarImage: response.avatar
        })
      );

      const navigateToLoby = () => {
        navigate(`lobby`);
      };

      if (!gameDocument?.theme) {
        navigateToLoby();
      } else {
        if (
          gameDocument?.theme?.logoUrl === '' ||
          gameDocument?.theme?.logoUrl === undefined
        ) {
          navigateToLoby();
        } else {
          navigate(`splash`);
        }
      }
    }
  };
  const handleUploadAvatar = () => {
    nextStepCallback(currentStep - 1);
  };

  return (
    <WizardContent
      additionalContainerClass={'position-relative'}
      isShowTitle={false}
      nextButtonText={
        generateTitleById(
          '5f243f13-e05b-448b-8a19-b45e16f9719c',
          gameDocument,
          displayLanguageContext.displayLanguageSelected.resources!,
          'game'
        ) || 'Finish'
      }
      additionalButtonContainerClass={'w-100'}
      additionalButtonNextClass={'mt-4'}
      handleNextButtonClicked={handleFinishButton}>
      <AvatarPreview
        avatarSource={playerState.playerState?.avatarImage}
        handleUploadAvatar={handleUploadAvatar}
      />
    </WizardContent>
  );
};

export default StepFive;
