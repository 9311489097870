import { useLocalStorage } from 'usehooks-ts';
import React, { useContext, useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import { Theme } from '../types/theme';
import { Assessment } from '../types/assessment';
import * as serviceWorker from '../serviceWorker';
import {
  getAssessmentAsync,
  postInitAssessmentAsync
} from '../services/assessments';
import { HubResponse } from '../types/responses/hub-response';
import { AssessmentEntity } from '../types/state/assessment/assessment';
import { GameContext } from './game';
import { useAssessmentDocument } from '../hooks/use-assessment-document';

interface PlayerState {
  assessmentCode?: string;
  assessmentDocument?: AssessmentEntity & {
    outcomeOrder: 'ascending' | 'descending';
  };
  theme?: Theme;
  isLoaded?: boolean;
  isDirty: boolean;
}

export interface AssessmentDocumentContextProps {
  playerState: PlayerState;
  facilitatorState: Assessment[] | null;
  isFacilitatorStateLoaded: boolean;
}

const AssessmentDocumentContext =
  React.createContext<AssessmentDocumentContextProps | null>(null);

interface AssessmentDocumentProviderProps {
  assessmentCode: string;
  children: React.ReactNode;
}

const AssessmentDocumentProvider = ({
  assessmentCode,
  children
}: AssessmentDocumentProviderProps) => {
  const [game] = useContext(GameContext);
  const assessmentDocument = useAssessmentDocument(assessmentCode);
  const [playerState, setPlayerState] = useLocalStorage<PlayerState>(
    `${assessmentCode}-assessment`,
    {
      assessmentDocument: assessmentDocument.data,
      assessmentCode: assessmentCode,
      isLoaded: false,
      isDirty: false
    }
  );

  const [isFacilitatorStateLoaded, setIsFacilitatorStateLoaded] =
    useState(false);
  const [facilitatorState, setFacilitatorState] = useState<Assessment[] | null>(
    null
  );

  const [assessmentHub, setAssessmentHub] = useState<{
    response: HubResponse | null;
    connection: HubConnection | null;
  }>({
    response: null,
    connection: null
  });

  const loadServiceWorker = async () => {
    await new Promise((r) => {
      serviceWorker.register({ onSuccess: (e) => console.log(e) });
    });
  };

  const createAssessmentHubConnection = React.useCallback(() => {
    getAssessmentAsync(assessmentCode!).then((response: any) => {
      if (response) {
        const con = new HubConnectionBuilder()
          .withUrl(`${response.endpointUrl as any}`, {
            accessTokenFactory: () => response.accessToken!
          })
          .withAutomaticReconnect()
          .build();
        setAssessmentHub({
          response,
          connection: con
        });
      }
    });
  }, [assessmentCode]);

  useEffect(() => {
    if (game.activityState && game.activityState.hasAssessment) {
      loadServiceWorker();
      createAssessmentHubConnection();
      if (!playerState.isLoaded) {
        setPlayerState((prev) => ({
          ...prev,
          isLoaded: true,
          assessmentDocument: assessmentDocument.data
        }));
      }
    }
  }, [game.activityState?.hasAssessment, assessmentCode]);

  useEffect(() => {
    const { connection, response } = assessmentHub;
    if (connection) {
      try {
        connection
          .start()
          .then(() => {
            connection.on(response?.method!, (data) => {
              const assessmentState: Assessment[] =
                JSON.parse(data).assessmentStates;
              setFacilitatorState(assessmentState);
              setPlayerState((prev) => ({
                ...prev,
                assessmentDocument: {
                  ...prev.assessmentDocument!,
                  outcomeOrder: assessmentState[0].outcomeOrder
                }
              }));
              setIsFacilitatorStateLoaded(true);
            });
          })
          .catch(() => {
            // do nothing
          });
      } catch (error) {
        // do nothing
      }
    }

    return () => {
      connection?.stop();
    };
  }, [assessmentHub]);

  // HACK: Hotfix for #6527 Not a permanent solution.
  // The assessment page requires the state from the signalr hub.
  // This appears to be the only way to get it...
  if (assessmentHub.connection && !facilitatorState) {
    postInitAssessmentAsync(assessmentCode);
  }

  return (
    <AssessmentDocumentContext.Provider
      value={{
        playerState,
        facilitatorState,
        isFacilitatorStateLoaded
      }}>
      {children}
    </AssessmentDocumentContext.Provider>
  );
};

export { AssessmentDocumentContext, AssessmentDocumentProvider };

//http://localhost:3000/games/0BD9E2CC/facilitator/assessment
