import React, { useContext, useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { HubResponse } from '../../../types/responses/hub-response';
import { GetAssessmentStateHub } from '../../../services/games';
import { PlayerAssessmentContext } from '../../../contexts/player-assessment';
import { useParams } from 'react-router-dom';

export const OverlayAssessment = () => {
  let { gameCode } = useParams();
  const [connectionAssessmentRef, setConnectionAssessment] =
    useState<HubConnection>();
  const [assessmentHubResponse, setAssessmentHubResponse] =
    useState<HubResponse>();
  const [, setPlayerAssessment] = useContext(PlayerAssessmentContext);

  function createAssessmentHubConnection() {
    GetAssessmentStateHub(gameCode!).then((response) => {
      if (response) {
        setAssessmentHubResponse(response);
        const con = new HubConnectionBuilder()
          .withUrl(`${response.endpointUrl}`, {
            accessTokenFactory: () => response.accessToken!
          })
          .withAutomaticReconnect()
          .build();
        setConnectionAssessment(con);
      }
    });
  }

  useEffect(() => {
    createAssessmentHubConnection();
  }, []);

  useEffect(() => {
    if (connectionAssessmentRef) {
      try {
        connectionAssessmentRef
          .start()
          .then(() => {
            connectionAssessmentRef.on(
              assessmentHubResponse?.method!,
              (data) => {
                setPlayerAssessment((prevState) => {
                  return JSON.parse(data);
                });
              }
            );
          })
          .catch((err: any) => {});
      } catch (error) {}
    }

    return () => {
      connectionAssessmentRef?.stop();
    };
  }, [connectionAssessmentRef]);

  return <div></div>;
};
