import React from 'react';
import { useLocalStorage } from 'usehooks-ts';

export interface QueueCommandHandler {
  key: string;
  parameters?: any[];
}

export interface QueueCommandState {
  content: QueueCommandHandler[];
}

const initialQueueCommandState: QueueCommandState = {
  content: []
};

interface QueueCommandProviderProps {
  gameCode: string;
  children: React.ReactNode;
}

const QueueCommandContext = React.createContext<
  [
    initialState: QueueCommandState,
    setState: React.Dispatch<React.SetStateAction<QueueCommandState>>
  ]
>([initialQueueCommandState, () => {}]);

const QueueCommandProvider = ({
  gameCode,
  ...props
}: QueueCommandProviderProps) => {
  const [state, setState] = useLocalStorage<QueueCommandState>(
    `${gameCode}-queue-command`,
    {
      content: []
    }
  );

  return (
    <QueueCommandContext.Provider value={[state, setState]}>
      {props.children}
    </QueueCommandContext.Provider>
  );
};

export { QueueCommandContext, QueueCommandProvider };
