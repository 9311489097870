import { Outlet } from 'react-router-dom';
import { FacilitatorPage } from '../../components/facilitator/facilitator-page';
export const FacilitatorLayoutFull = () => {
  return (
    <FacilitatorPage>
      <div className={'layout-facilitator--main flex-grow-1'}>
        <Outlet />
      </div>
    </FacilitatorPage>
  );
};
