import { Resource } from '../types/game-document';
import {
  MediaResponse,
  ResourceResponse
} from '../types/responses/media-response';
import { DeleteAsync, PostFileAsync } from './api';

const MediaEndpoint = `media`;

/**
 * Applies a POST form data.
 * @param file - file upload
 * @returns the media object
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostMediaAsync = async (file: File) =>
  PostFileAsync<MediaResponse>(`${MediaEndpoint}`, file);

/**
 * Delete media based on mediaid
 * @param endpoint - the API V2 Endpoint for entity DELETE
 * @returns the deleted media object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteMediaAsync = async (mediaId: number) =>
  DeleteAsync<MediaResponse>(`${MediaEndpoint}/${mediaId}`, true, true);

/**
 * Applies a POST form data.
 * @param file - file upload
 * @returns the resource object
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostResourceAsync = async (file: File | Blob) => {
  const response = await PostFileAsync<ResourceResponse>(
    `${MediaEndpoint}/resources`,
    file
  );
  //cast to resource
  return {
    name: response.name!,
    value: response.url!,
    thumbnailValue: response.thumbnailUrl!,
    type: response.type!
  } as Resource;
};
