import { join } from 'path';

export const API_URL = {
  environments: '/api/environments',

  //Hub
  hubs: '/api/v1/hubs',
  hubsV2: '/api/v2/hubs',

  // Games
  games: '/api/v1/games',
  gamesV2: '/api/v2/games',
  // Assessment
  assessmentV2: '/api/v2/assessment',
  // Teams
  teams: '/api/v1/teams',
  teamsV2: '/api/v2/teams',

  // Players
  player: '/api/v1/players',
  playerV2: '/api/v2/players',
  playerJoin: '/api/v1/players/join',
  playerAvatar: '/api/v1/players/avatars',
  playerValidate: '/api/v1/players/validate',

  //Resources
  resources: '/api/v1/resources'
};

export const API_V2_URL = {
  //Games
  games: '/api/v2/games/'
};
