import { ZoneEntity } from '../../../types/entities';
import { PlayerStateFull } from '../../../types/state';
import { merge } from 'lodash';
import { PlayerContextState } from '../../../contexts/player';

/**
 * Get all zones from PlayerStateFull.
 * @param player - The player as PlayerStateFull
 * @constructor
 */
export const GetZones = (player: PlayerStateFull | undefined) => {
  return player?.zones ?? [];
};

/**
 * Get zone by ZoneId.
 * @param player - The player as PlayerStateFull
 * @param zoneId - Id of the zone
 * @constructor
 */
export const GetZoneById = (player: PlayerStateFull, zoneId: string) => {
  let zones = GetZones(player);
  return zones.find((z) => z.id === zoneId);
};

export const AddZoneEntityAsync = async (
  player: PlayerContextState,
  zone: ZoneEntity
) => {
  let zones = player.playerState?.zones ?? [];
  zones.push(zone);

  return MergeZone(player.playerState!, zones);
};

export const AddZoneEntity = (player: PlayerContextState, zone: ZoneEntity) => {
  let zones = player.playerState?.zones ?? [];
  zones.push(zone);

  return MergeZone(player.playerState!, zones);
};

export const MergeZone = (
  player: PlayerStateFull,
  zones: Array<ZoneEntity>
) => {
  return merge(player, { zones });
};
