import React from 'react';

import { MapContext } from './map-context';
import { MapIllustrationContext } from './map-illustration-context';

const MapResetRotate = () => {
  const map = React.useContext(MapContext);
  const mapIllustration = React.useContext(MapIllustrationContext);

  const handleResetRotate = React.useCallback(() => {
    if (map || mapIllustration) {
      const target = map.getTarget();
      const view = target ? map.getView() : mapIllustration.getView();

      view.setRotation(0);
    }
  }, [map, mapIllustration]);

  return (
    <div className="d-flex align-items-center justify-content-center p-2 rounded-circle map-reset-rotate">
      <div
        className="d-flex align-items-center justify-content-center rounded-circle p-2 bg-white cursor-pointer"
        style={{
          height: '32px',
          width: '32px'
        }}
        onClick={handleResetRotate}>
        <span
          className="material-symbols-outlined"
          style={{
            fontSize: '18px'
          }}>
          refresh
        </span>
      </div>
    </div>
  );
};

export default MapResetRotate;
