import { GameDocument } from '../../../types/game-document';
import { GetZoneRulesById } from './zones';

/**
 * Get map by zone id
 * @param zoneId - The zone id
 * @constructor
 */
export const GetMapByZoneId = (gameDocument: GameDocument, zoneId: string) => {
  let zoneAsset = GetZoneRulesById(gameDocument, zoneId);
  if (zoneAsset) {
    return gameDocument?.assets?.maps?.find(
      (x) => x.id === zoneAsset?.mapAssId
    );
  }
};

/**
 * Get map by asset id
 * @param id - The asset id
 * @constructor
 */
export const GetMapById = (gameDocument: GameDocument, id: string) => {
  return gameDocument?.assets?.maps?.find((x) => x.id === id);
};
