import { EditorUtils, ProseMirror } from '@progress/kendo-react-editor';

export const onImageInsert = (args: any) => {
  const { files, view, event } = args;
  const nodeType = view.state.schema.nodes.image;
  const position =
    event.type === 'drop'
      ? view.posAtCoords({
          left: event.clientX,
          top: event.clientY
        })
      : null;
  insertImageFiles({
    view,
    files,
    nodeType,
    position
  });
  return files.length > 0;
};

export const insertImageFiles = ({
  view,
  files,
  nodeType,
  position,
  attrs = {}
}: any) => {
  if (EditorUtils.canInsert(view.state, nodeType)) {
    const file = files[0];
    if (file) {
      let reader = new FileReader();
      reader.onload = function (e: ProgressEvent<FileReader>) {
        const image = nodeType.createAndFill({
          ...attrs,
          src: e.target?.result
        });
        if (position) {
          view.dispatch(view.state.tr.insert(position.pos, image));
        } else {
          EditorUtils.insertNode(view, image, true);
        }
      };
      const data = 'file' in file ? file.file : file;
      reader.readAsDataURL(data);
    }
  }
};

export const insertImagePlugin = (onInsert: any) =>
  new ProseMirror.Plugin({
    props: {
      handleDOMEvents: {
        paste: (view, event) => insertImages(view, event, onInsert),
        drop: (view, event) => insertImages(view, event, onInsert)
      }
    },
    key: new ProseMirror.PluginKey('insert-image-plugin')
  });

const insertImages = (view: any, event: any, onInsert: any) => {
  const items =
    (event.clipboardData && event.clipboardData.items) ||
    (event.dataTransfer && event.dataTransfer.files);
  if (items) {
    const files: Array<any> = [];
    let file: any;
    for (let i = 0; i < items.length; i++) {
      file =
        items[i].type.indexOf('image') !== -1
          ? 'getAsFile' in items[i]
            ? items[i].getAsFile()
            : items[i]
          : null;
      if (file) {
        files.push(file);
      }
    }

    if (files.length) {
      event.preventDefault();
    }

    return onInsert({ view, files, event });
  }
};
