export const processMediaTags = async (htmlString: string): Promise<string> => {
  const mediaTagRegex = /<(video|audio)[^>]*\s+src=["']([^"']+)["'][^>]*>/gi;

  let iterate = 1;
  let match: RegExpExecArray | null;
  const promises: Promise<void>[] = [];
  const replacements: { match: string; replacement: string }[] = [];

  while ((match = mediaTagRegex.exec(htmlString)) !== null) {
    const [fullMatch, tag, blobUrl] = match;

    const promise = fetch(blobUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const myFile = new File([blob], `media-${iterate}`, {
          type: tag === 'video' ? 'video/mp4' : 'audio/mp3'
        });
        const objectURL = URL.createObjectURL(myFile);
        replacements.push({
          match: fullMatch,
          replacement: fullMatch.replace(blobUrl, objectURL)
        });
      });

    iterate++;
    promises.push(promise);
  }

  await Promise.all(promises);

  let processedHtml = htmlString;
  replacements.forEach(({ match, replacement }) => {
    processedHtml = processedHtml.replace(match, replacement);
  });

  return processedHtml;
};
