import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ChatWindow from '../../../../../components/chat/chat-window';

export const Chat = () => {
  return (
    <>
      <Container className={'drawer__body px-3'} fluid>
        <ChatWindow />
      </Container>

      <Container className={'drawer__footer p-0 text-center pb-2'} />
    </>
  );
};
