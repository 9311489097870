import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { QRCode } from '@progress/kendo-react-barcodes';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';

export const GamePage = () => {
  const [url, setUrl] = useState<string>('asasasas');
  const [, setNotification] = useContext(NotificationContext);

  const notificationHandler = (message: string) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    setNotification((prevNotification) => ({
      ...prevNotification,
      content: [...prevNotification.content, responseTeamNotification]
    }));
  };

  useEffect(() => {
    let newUrl = `${window.location.href}`;
    newUrl = newUrl.replace('/facilitator/game', '');
    setUrl(newUrl);
  }, []);

  return (
    <Row className={'d-flex w-100'}>
      <div
        className={
          'd-flex flex-column justify-items-center align-items-center pr-0'
        }>
        <div className={'mt-4'}>
          <QRCode size={'350'} value={url} errorCorrection="M" />
          <div className={'input-group mb-3 mt-3'}>
            <input
              type={'text'}
              className={'form-control text-primary'}
              disabled={true}
              value={url}
            />
            <div className={'input-group-append'}>
              <button
                className={'btn btn-success text-light'}
                type={'button'}
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  notificationHandler('URL copied!');
                }}>
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </Row>
  );
};
