import React from 'react';
import { Button } from '@progress/kendo-react-buttons';

type DrawerTabNumbers = number | 0 | 1 | 2 | 3 | 4 | 5 | 6;

interface OverlayTabButtonProps {
  thisTabIndex: DrawerTabNumbers;
  activeTab: DrawerTabNumbers;
  title: string;
  onSetActiveTab: (activeTab: DrawerTabNumbers) => void;
}

export const TabButtonComponent = ({
  thisTabIndex,
  activeTab,
  title,
  onSetActiveTab
}: OverlayTabButtonProps) => {
  const isActiveTab = activeTab === thisTabIndex;

  return (
    <Button
      className={`col-3 p-4 d-flex align-items-center justify-items-center k-button--tab ${
        isActiveTab ? 'k-button--tab-active fw-bold' : ''
      }`}
      fillMode={'flat'}
      onClick={() => onSetActiveTab(thisTabIndex)}>
      {title}
    </Button>
  );
};
