import React, { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { PlayerStateFull } from '../types/state';
import { PlayerAssessmentState } from '../types/state/player/player-assessment';
import { v7 as uuid7 } from 'uuid';
import { useNavigate, useLocation } from 'react-router-dom';

const PlayerContext = React.createContext<
  [
    initialState: PlayerContextState,
    setState: React.Dispatch<React.SetStateAction<PlayerContextState>>
  ]
>([{ isLoaded: false, isDirty: false }, () => {}]);

interface PlayerStateProviderProps {
  gameCode: string;
  children: React.ReactNode;
}

const PlayerStateProvider = ({
  gameCode,
  ...props
}: PlayerStateProviderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useLocalStorage<PlayerContextState>(
    `${gameCode}-player`,
    {
      gameCode: gameCode,
      playerState: BuildNewPlayerState(gameCode),
      isLoaded: false,
      isDirty: false
    }
  );

  useEffect(() => {
    if (!state.isLoaded) {
      setState((state) => ({
        ...state,
        isLoaded: true,
        playerState: BuildNewPlayerState(gameCode)
      }));
    } else {
      const isAssessmentPath = location.pathname.includes('assessment');
      if (isAssessmentPath) {
        return;
      }

      if (state.playerState?.name && state.playerState.avatarImage) {
        navigate(state.playerState.status === 'waiting' ? 'lobby' : 'map');
      } else {
        navigate(`/games/${gameCode}`);
      }
    }
  }, []);

  return (
    <PlayerContext.Provider value={[state, setState]}>
      {props.children}
    </PlayerContext.Provider>
  );
};

export interface PlayerContextState {
  gameCode?: string;
  playerState?: PlayerStateFull;
  assessmentState?: PlayerAssessmentState;
  isLoaded?: boolean;
  isDirty: boolean;
}

export const BuildNewPlayerState = (gameCode: string): PlayerStateFull => {
  return {
    id: undefined,
    name: '',
    language: '',
    avatarImage: '',
    status: 'waiting',
    score: 0,
    coordinates: {
      latitude: 0,
      longitude: 0,
      accuracy: 0,
      altitude: 0,
      heading: 0
    },
    displayLanguageUrl: '',
    heading: 0,
    location: '',
    code: uuid7(),
    teamCode: '',
    inventory: [],
    titles: [],
    timers: [],
    zones: [],
    areas: [],
    tasks: [],
    latestCompletedTaskId: ''
  };
};

export { PlayerContext, PlayerStateProvider };
