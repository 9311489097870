import { ComponentPropsWithRef } from 'react';

export interface CardImageProps extends ComponentPropsWithRef<'div'> {
  imageUrl: string;
}

export const CardImage = ({ imageUrl, ...props }: CardImageProps) => {
  return (
    <div
      className={'card__image'}
      style={{ backgroundImage: `url(${imageUrl})` }}
    />
  );
};
