import { useContext } from 'react';
import { GameDocumentContext } from '../contexts/game-document';
import { Color } from '../types/theme';

const Theme = (props: any) => {
  const [gameDocument] = useContext(GameDocumentContext);

  const fontFamily: string = gameDocument?.theme?.fontFamily!;

  const theme: Color[] = gameDocument?.theme?.colors ?? [];

  const primaryColor: string = theme?.find((x) => x.type === 'Primary')?.color!;

  const secondaryColor: string = theme?.find((x) => x.type === 'Secondary')
    ?.color!;

  const warningColor: string = theme?.find((x) => x.type === 'Warning')?.color!;

  const successColor: string = theme?.find((x) => x.type === 'Success')?.color!;

  return (
    <>
      {fontFamily !== undefined && fontFamily !== '' && (
        <style type={'text/css'}>
          {`
            body { --bs-body-font-family: ${fontFamily}} 
            .k-button-text { font-family: ${fontFamily}}
            .k-message { font-family: ${fontFamily}}
            .k-timestamp { font-family: ${fontFamily}}
            .k-message-box { font-family: ${fontFamily}}`}
        </style>
      )}

      {primaryColor && (
        <style type={'text/css'}>{`
        .k-button--gradient { background: ${primaryColor} !important; }
        .border-primary { border-color: ${primaryColor} !important; }
        .text-primary { color: ${primaryColor} !important; }  
        .k-button-solid-primary:focus { box-shadow: 0 0 0px 0.25rem ${primaryColor.replace(
          '1)',
          '0.5)'
        )} !important; }
        .k-button-solid-primary { border-color: ${primaryColor} !important; }
        `}</style>
      )}

      {secondaryColor && (
        <style type={'text/css'}>{`
        .border-secondary { border-color: ${secondaryColor} !important; }
        .text-secondary { color: ${secondaryColor} !important; }  
        `}</style>
      )}

      {successColor && (
        <style type={'text/css'}>{`
        .border-success { border-color: ${successColor} !important; }
        .text-success { color: ${successColor} !important; }  
        `}</style>
      )}

      {warningColor && (
        <style type={'text/css'}>{`
        .border-danger { border-color: ${warningColor} !important; }
        .text-danger { color: ${warningColor} !important; }  
        `}</style>
      )}

      {props.children}
    </>
  );
};

export default Theme;
