import { Col, Container, Row } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import { GameContext } from '../../../../../contexts/game';
import { PlayerContext } from '../../../../../contexts/player';
import { TeamContext } from '../../../../../contexts/team';
import { Leaderboard, PlayerScore } from '../../../../../components/score';
import { Rank } from '../../../../../types/scoreboard';
import { GameState, PlayerState, TeamState } from '../../../../../types/state';
import { GameDocumentContext } from '../../../../../contexts/game-document';
import { generateTitleById } from '../../../../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../../../../contexts/display-languages';

export const Scoreboard = () => {
  const [gameDocumentState] = useContext(GameDocumentContext);
  const [gameState] = useContext(GameContext);
  const [teamState] = useContext(TeamContext);
  const [playerState] = useContext(PlayerContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const [playerRank, setPlayerRank] = useState<number>(1);
  const [playerScore, setPlayerScore] = useState<number>(0);
  const [teamLeaderboard, setTeamLeaderboard] = useState<Rank[]>([]);
  const [playerLeaderboard, setPlayerLeaderboard] = useState<Rank[]>([]);

  const isInLobby = playerState.playerState?.status === 'waiting';
  const inGameSetting = gameDocumentState.gameDocument?.settings.inGame;

  const showPlayerTeamScore = isInLobby
    ? false
    : inGameSetting?.showInGamePlayerTeamScore;
  const showLeaderBoard = isInLobby
    ? false
    : inGameSetting?.showInGameLeaderboard;

  const getAllPlayers = (gameState: GameState) => {
    return gameState?.players.map<Rank>((item: PlayerState) => ({
      code: item?.code ?? '',
      avatarImage: item?.avatarImage ?? '',
      name: item?.name ?? '',
      score: item?.score ?? 0
    }));
  };

  const getPlayersByTeamCode = (GameStateDoc: GameState) => {
    return GameStateDoc?.players
      .filter((player) => player.teamCode === teamState?.teamState?.code)
      .map<Rank>((item: PlayerState) => ({
        code: item?.code ?? '',
        avatarImage: item?.avatarImage ?? '',
        name: item?.name ?? '',
        score: item?.score ?? 0
      }));
  };

  useEffect(() => {
    if (gameState.gameState) {
      const players: Rank[] = getAllPlayers(gameState.gameState);
      setPlayerRank(
        players
          ?.sort((a: Rank, b: Rank) => b.score - a.score)
          .findIndex(
            (item: Rank) => item?.code === playerState.playerState?.code
          ) + 1
      );

      let player = players.find((item) => {
        return item?.code === playerState.playerState?.code;
      });
      setPlayerScore(player?.score ?? 0);
      setPlayerLeaderboard(getPlayersByTeamCode(gameState.gameState));

      setTeamLeaderboard(
        gameState.gameState?.teams?.map<Rank>((item: TeamState) => ({
          avatarImage: item?.avatarImage ?? '',
          name: item?.name ?? '',
          score: item?.score ?? 0
        }))
      );
    }
  }, [gameState]);

  return (
    <>
      <Container className={'drawer__body'} fluid>
        <Row>
          <Col className={'py-4 px-3'}>
            <h1>
              {generateTitleById(
                '6560df9f-604b-4430-83f4-7b61575e25ba',
                gameDocumentState,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Score'}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className={'px-3'} md={6}>
            <PlayerScore
              avatarImage={
                playerState?.playerState?.avatarImage ??
                'https://via.placeholder.com/200'
              }
              name={teamState.teamState?.name ?? ''}
              score={playerScore}
              playerRank={playerRank}
              playerTotal={
                (getAllPlayers(gameState?.gameState!) &&
                  getAllPlayers(gameState?.gameState!).length) ??
                1
              }
              isShowScore={showPlayerTeamScore}
            />
            {showLeaderBoard && (
              <Leaderboard
                items={playerLeaderboard}
                isShowScore={showPlayerTeamScore}
              />
            )}
          </Col>
          <Col className={'px-4'} md={6}>
            {showLeaderBoard && (
              <Leaderboard
                items={teamLeaderboard}
                isShowScore={showPlayerTeamScore}
              />
            )}
          </Col>
        </Row>
      </Container>
      <div className={'drawer__footer p-0 text-center pb-2'} />
    </>
  );
};
