import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { GameContext } from '../../contexts/game';
import { GameStatus } from '../../types/state';
import { UpdateGameStateAsync } from '../../utils/game-engine/base';
import {
  EndGameAsync,
  PausedGameAsync,
  ResumeGameAsync,
  StartGameAsync
} from '../../utils/game-engine/game';
import { ConfirmationDialog } from '../dialog/confirmation-dialog';
import { useElapsedTime } from 'use-elapsed-time';
import { formatTimer } from '../../utils/timer';
import { ElapsedTime } from '../../types/state/game/game-state';
import {
  pauseGameState,
  resumeGameState,
  startGameState,
  stopGameState
} from '../../services/games';
import { Col, Row } from 'react-bootstrap';

export const GameControl = () => {
  const [game, setGame] = useContext(GameContext);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isShowConfirmation, setIsShowConfirmation] = useState<boolean>(false);
  const { elapsedTime, reset } = useElapsedTime({
    isPlaying,
    updateInterval: 1
  });

  const handleStartClick = () => {
    startGameState(game.gameCode!)
      .then(() => StartGameAsync(game.gameState!))
      .then((response) => {
        setGame((prev) => UpdateGameStateAsync(prev, response, true));
        setIsPlaying(true);
      });
  };

  const handleEndClick = () => setIsShowConfirmation(true);

  const handlePauseClick = () => {
    pauseGameState(game.gameCode!)
      .then(() => PausedGameAsync(game.gameState!, game.gameState?.status!))
      .then((response) =>
        setGame((prev) => UpdateGameStateAsync(prev, response, true))
      );
  };

  const handleResumeClick = () => {
    resumeGameState(game.gameCode!)
      .then(() => ResumeGameAsync(game.gameState!))
      .then((response) =>
        setGame((prev) => UpdateGameStateAsync(prev, response, true))
      );
  };

  const handleOnCancelClick = () => setIsShowConfirmation(false);
  const handleOnContinueClick = () => {
    stopGameState(game.gameCode!)
      .then(() => EndGameAsync(game.gameState!))
      .then((response) => {
        setGame((prev) => UpdateGameStateAsync(prev, response, true));
        handleOnCancelClick();
        setIsPlaying(false);
      });
  };

  const renderButton = (): JSX.Element => {
    const status: GameStatus = game?.gameState?.status ?? 'PreGame';
    if (status === 'PreGame' || status === 'Paused') {
      return (
        <>
          <Button
            themeColor={'success'}
            className={'w-10 ml-2'}
            onClick={
              status === 'PreGame' ? handleStartClick : handleResumeClick
            }>
            {status === 'PreGame' ? 'Start Game' : 'Resume Game'}
          </Button>
          <Button
            themeColor={status === 'PreGame' ? 'base' : 'error'}
            className={'w-10 ml-2'}
            onClick={handleEndClick}
            disabled={status === 'PreGame'}>
            End Game
          </Button>
        </>
      );
    } else if (
      status === 'Starting' ||
      status === 'Running' ||
      status === 'PostGame'
    ) {
      return (
        <>
          <Button
            themeColor={'info'}
            className={'w-10 ml-2'}
            onClick={handlePauseClick}>
            Pause Game
          </Button>
          <Button
            themeColor={'error'}
            className={'w-10 ml-2'}
            onClick={handleEndClick}>
            End Game
          </Button>
        </>
      );
    }
    return <span className={'text-danger'}>Game Over</span>;
  };

  const calculateLastElapsedTime = (): number => {
    if (!game.gameState?.elapsedTime || game.gameState.elapsedTime.length === 0)
      return 0;

    const time: number = game.gameState.elapsedTime
      .map<number>((item: ElapsedTime) => {
        const endTime: number =
          item.pausedTime !== undefined && item.pausedTime !== null
            ? new Date(item.pausedTime).getTime()
            : new Date().getTime();
        const startTime: number = new Date(item.startedTime).getTime();
        return endTime - startTime;
      })
      .reduce<number>((prev: number, next: number) => prev + next, 0);
    return time / 1000;
  };

  useEffect(() => {
    if (elapsedTime === 0) {
      reset(calculateLastElapsedTime());
    }

    if (
      game.gameState?.status === 'Starting' ||
      game.gameState?.status === 'Running' ||
      game.gameState?.status === 'PostGame'
    ) {
      setIsPlaying(true);
    } else if (
      game.gameState?.status === 'Paused' ||
      game.gameState?.status === 'Finished'
    ) {
      setIsPlaying(false);
    }
    if (game.activityState && !game.activityState.hasGame) {
      handleOnContinueClick();
    }
  }, [game.gameState?.status]);

  return (
    <>
      <Row className={'align-items-center border-bottom py-3'}>
        <Col className={'fw-bold'}>
          Game Timer :{' '}
          <span className={'text-danger ml-3'}>{formatTimer(elapsedTime)}</span>
        </Col>
        <Col xs={12} sm={'auto'}>
          {renderButton()}
        </Col>
      </Row>
      {isShowConfirmation && (
        <ConfirmationDialog
          title={'Action needed'}
          message={'Are you sure you want to end the game?'}
          onCancelCallback={handleOnCancelClick}
          onContinueCallback={handleOnContinueClick}
        />
      )}
    </>
  );
};
