import { TeamContextState } from '../../contexts/team';
import { GameDocument } from '../../types/game-document';
import {
  InventoryItem,
  PlayerStateFull,
  TeamState,
  TeamStateFull
} from '../../types/state';
import { calculateCenter } from '../map-helper';
import merge from 'lodash.merge';
import {
  AlgorithmControlStructure,
  AlgorithmStep
} from '../../types/algorithm';
import {
  navigateToWorldMap,
  randomTask as TeamRandomTask,
  teamDistributeTaskAsync
} from '../../services/teams';
import _ from 'lodash';
import { GetPreGameSetting } from '../game-document/settings';
import { GameDocumentState } from '../../contexts/game-document';
import { PlayerContextState } from '../../contexts/player';
import {
  giveItem as teamGiveItem,
  giveScore as teamGiveScore,
  giveTitle as teamGiveTitle,
  navigateToWorldMap as teamNavigateToWorldMap,
  navigateToZone as teamNavigateToZone,
  showArea as teamShowArea,
  openTask as teamOpenTask,
  showTask as teamShowTask,
  pauseTimer as teamPauseTimer,
  startTimer as teamStartTimer
} from '../../services/teams';
import { queueCommandHandler } from './queue-handler';
/**
 * Function to check if arg is PlayerStateFull or TeamStateFull
 * @param args response from AlgorithmEventHandler
 * @param teamCode teamCode in teamState
 * @returns arg is TeamStateFull
 */
export const IsArgsTeamStateFull = (
  args: PlayerStateFull | TeamStateFull,
  teamCode: string
): args is TeamStateFull => {
  return teamCode !== '' && args.code === teamCode;
};

export const TeamAlgorithmHandler = async (
  gameDocument: GameDocumentState,
  team: TeamContextState,
  player: PlayerContextState,
  algorithm: AlgorithmStep | AlgorithmControlStructure
): Promise<PlayerStateFull | TeamStateFull> => {
  const { operation = '' } = algorithm;
  switch (operation) {
    case 'navigateToWorldMap':
      try {
        await teamNavigateToWorldMap(
          team?.gameCode!,
          player?.playerState?.teamCode!
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!
        ]);
      } finally {
        return team.teamState!;
      }
    case 'navigateToZone':
      try {
        await teamNavigateToZone(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string
        ]);
      } finally {
        return team.teamState!;
      }
    case 'distributeTask':
      try {
        await teamDistributeTaskAsync(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          {
            stepId: algorithm?.id,
            taskIds: algorithm?.argumentAssetId as string[],
            quantity: algorithm?.argumentQuantity
          }
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          {
            stepId: algorithm?.id,
            taskIds: algorithm?.argumentAssetId as string[],
            quantity: algorithm?.argumentQuantity
          }
        ]);
      } finally {
        return team.teamState!;
      }
    case 'randomTask':
      try {
        await TeamRandomTask(team?.gameCode!, player?.playerState?.teamCode!, {
          stepId: algorithm?.id,
          taskIds: algorithm?.argumentAssetId as string[],
          quantity: algorithm?.argumentQuantity
        });
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          {
            stepId: algorithm?.id,
            taskIds: algorithm?.argumentAssetId as string[],
            quantity: algorithm?.argumentQuantity
          }
        ]);
      } finally {
        return team.teamState!;
      }
    case 'giveItem':
      try {
        await teamGiveItem(
          team?.gameCode!,
          team?.teamState?.code!,
          algorithm.argumentAssetId! as string,
          algorithm.argumentQuantity ?? 0,
          true
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          team?.teamState?.code!,
          algorithm.argumentAssetId! as string,
          algorithm.argumentQuantity ?? 0,
          true
        ]);
      } finally {
        return team.teamState!;
      }
    case 'removeItem':
      try {
        await teamGiveItem(
          team?.gameCode!,
          team?.teamState?.code!,
          algorithm.argumentAssetId! as string,
          algorithm.argumentQuantity! * -1 || 0,
          false
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          team?.teamState?.code!,
          algorithm.argumentAssetId! as string,
          algorithm.argumentQuantity! * -1 || 0,
          false
        ]);
      } finally {
        return team.teamState!;
      }
    case 'giveScore':
      try {
        await teamGiveScore(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentQuantity!,
          true
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentQuantity!,
          true
        ]);
      } finally {
        return team?.teamState!;
      }
    case 'removeScore':
      try {
        await teamGiveScore(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentQuantity! * -1,
          false
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentQuantity! * -1,
          false
        ]);
      } finally {
        return team?.teamState!;
      }
    case 'giveTitle':
      try {
        await teamGiveTitle(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          true
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          true
        ]);
      } finally {
        return team?.teamState!;
      }
    case 'removeTitle':
      try {
        await teamGiveTitle(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          false
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          false
        ]);
      } finally {
        return team?.teamState!;
      }
    case 'hideArea':
      try {
        await teamShowArea(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          false
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          false
        ]);
      } finally {
        return team.teamState!;
      }
    case 'showArea':
      try {
        await teamShowArea(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          true
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          true
        ]);
      } finally {
        return team.teamState!;
      }
    case 'openTask':
      try {
        await teamOpenTask(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string
        ]);
      } finally {
        return team.teamState!;
      }
    case 'showTask':
      try {
        await teamShowTask(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          true
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          true
        ]);
      } finally {
        return team.teamState!;
      }
    case 'hideTask':
      try {
        await teamShowTask(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          false
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          false
        ]);
      } finally {
        return team.teamState!;
      }
    case 'startTimer':
      try {
        await teamStartTimer(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          true
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          true
        ]);
      } finally {
        return team.teamState!;
      }
    case 'stopTimer':
      try {
        await teamStartTimer(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          false
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          false
        ]);
      } finally {
        return team.teamState!;
      }
    case 'pauseTimer':
      try {
        await teamPauseTimer(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          true
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          true
        ]);
      } finally {
        return team.teamState!;
      }
    case 'resumeTimer':
      try {
        await teamPauseTimer(
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          false
        );
      } catch (error) {
        console.error(error);

        queueCommandHandler(team?.gameCode!, operation, [
          team?.gameCode!,
          player?.playerState?.teamCode!,
          algorithm.argumentAssetId! as string,
          false
        ]);
      } finally {
        return team.teamState!;
      }
    default:
      return team.teamState!;
  }
};

/**
 * Add score to the game team state
 * @param gamePlayer - The PlayerStateFull state
 * @param score - The score you want to add
 */
export const GiveScore = (teamPlayer: TeamStateFull, score: number) => {
  let newScore: number = 0;

  if (teamPlayer.score !== undefined) {
    newScore = teamPlayer?.score + score;
  }

  if (teamPlayer.score === undefined) {
    newScore += score;
  }
  return merge(teamPlayer, { score: newScore });
};
/**
 * Remove score to the game team state
 * @param gamePlayer - The PlayerStateFull state
 * @param score - The score you want to remove
 */
export const RemoveScore = (teamPlayer: TeamStateFull, score: number) => {
  let newScore: number = 0;

  if (teamPlayer.score !== undefined) {
    newScore = teamPlayer?.score - score;
  }

  if (teamPlayer.score === undefined) {
    newScore -= score;
  }
  return merge(teamPlayer, { score: newScore });
};

/**
 * To navigate to the world map for the team
 * @param gameCode - The gameCode
 * @param teamPlayer - The TeamContextState
 * @param team - the TeamState
 * @param teamCode - the teamCode
 */
export const TeamNavigateToWorldMapAsync = async (
  gameCode: string,
  teamPlayer: TeamContextState,
  team: TeamState,
  teamCode: string
) => {
  team!.location! = 'world-map';
  await navigateToWorldMap(gameCode, teamCode);
  return merge(teamPlayer, team);
};

export const TeamNavigateToZoneAsync = async (
  teamPlayer: TeamContextState,
  team: TeamState,
  zoneId: string,
  geometry?: any
) => {
  team!.location! = zoneId;
  if (geometry) {
    //add zone as log
    team!.zones!.push({
      id: zoneId,
      timeStampUtc: new Date().getTime(),
      isVisible: true,
      ZoneAreas: []
    });
    let getCalculateCenter = calculateCenter(geometry);
    let centerPoint = getCalculateCenter.center;
    if (centerPoint) {
      const coordinates = getCalculateCenter.coordinates;
      if (coordinates) {
        team!.coordinates!.longitude = coordinates[0] as number;
        team!.coordinates!.latitude = coordinates[1] as number;
      }
    }
  }
  return merge(teamPlayer, team);
};

export const TeamShowZoneArea = async (
  teamPlayer: TeamContextState,
  team: TeamState,
  areaId: string
) => {
  let area = team!.areas!.find((f) => f.id === areaId);
  area!.isVisible! = true;
  return merge(teamPlayer, team);
};

/**
 * To hide zone area for the team
 * @param teamPlayer - The TeamPlayer state
 * @param team - The teamstate
 * @param areaId - area id
 */
export const TeamHideZoneArea = async (
  teamPlayer: TeamContextState,
  team: TeamState,
  areaId: string
) => {
  let area = team!.areas!.find((f) => f.id === areaId);
  area!.isVisible! = false;
  return merge(teamPlayer, team);
};
/**
 * Show the task zone  by team
 * @param teamPlayer - The TeamPlayer state
 * @param team - The teamstate
 * @param taskId - task id
 */

export const TeamShowTask = async (
  teamPlayer: TeamContextState,
  team: TeamState,
  taskId: string
) => {
  let task = team!.tasks!.find((f) => f.id === taskId);
  task!.isVisible! = true;
  return merge(teamPlayer, team);
};
/**
 * To hide zone task for the team
 * @param teamPlayer - The TeamPlayer state
 * @param team - The teamstate
 * @param taskId - task id
 */
export const TeamHideTask = async (
  teamPlayer: TeamContextState,
  team: TeamState,
  taskId: string
) => {
  let task = team!.tasks!.find((f) => f.id === taskId);
  task!.isVisible! = false;
  return merge(teamPlayer, team);
};

/**
 * To add item of item for the team
 * @param teamPlayer - The TeamPlayer state
 * @param itemId - The item id
 * @param quantity - the item quantity
 */
export const TeamGiveItemAsync = (
  teamPlayer: TeamContextState,
  itemId: string,
  quantity: number
) => {
  let inventories = teamPlayer.teamState?.inventory ?? [];
  let inventoryIndex = inventories.findIndex(
    (inventory) => inventory.id === itemId
  );
  if (inventoryIndex === -1) {
    inventories.push({
      id: itemId,
      quantity: quantity
    });
  } else {
    inventories[inventoryIndex].quantity += quantity;
  }
  return MergeInventory(teamPlayer.teamState!, inventories);
};

/**
 * To reduce quantity item of item for the team
 * @param teamPlayer - The TeamPlayer state
 * @param itemId - The item id
 * @param quantity - the item quantity
 */
export const TeamRemoveItemAsync = (
  teamPlayer: TeamContextState,
  itemId: string,
  quantity: number
) => {
  let inventories = teamPlayer.teamState?.inventory ?? [];
  let inventoryIndex = inventories.findIndex(
    (inventory) => inventory.id === itemId
  );
  if (inventoryIndex !== -1) {
    inventories[inventoryIndex].quantity -= quantity;
    //remove if quantity of inventory is 0
    if (inventories[inventoryIndex].quantity === 0) {
      inventories.splice(inventoryIndex, 1);
    }
  }
  return MergeInventory(
    teamPlayer.teamState!,
    teamPlayer.teamState!.inventory!
  );
};

export const MergeInventory = (
  team: TeamStateFull,
  inventory: Array<InventoryItem>
) => {
  return merge(team, { inventory });
};

/**
 * To add title for the team
 * @param teamPlayer - The TeamPlayer state
 * @param titleId - The title id
 */
export const TeamGiveTitleAsync = (
  teamPlayer: TeamStateFull,
  titleId: string
) => {
  let titles = teamPlayer?.titles! ?? [];
  let titleIndex = titles.findIndex((f) => f === titleId);
  if (titleIndex === -1) titles.push(titleId);
  return merge(teamPlayer, { titles: titles });
};

/**
 * To remove title for the team
 * @param teamPlayer - The TeamPlayer state
 * @param titleId - The title id
 */
export const TeamRemoveTitleAsync = (
  teamPlayer: TeamStateFull,
  titleId: string
) => {
  let titles = teamPlayer?.titles! ?? [];
  let titleIndex = titles.findIndex((f) => f === titleId);
  titles.splice(titleIndex, 1);
  return merge(teamPlayer, { titles: titles });
};

/* Set visibility of teams
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowTeams = (gameDocument: GameDocument) => {
  const preGameSettings = GetPreGameSetting(gameDocument);
  return preGameSettings.showPreGameTeams;
};

/**
 * Set visibility of create new team
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowCreateTeam = (gameDocument: GameDocument) => {
  const preGameSettings = GetPreGameSetting(gameDocument);
  return preGameSettings.showPreGameCreateTeams;
};

/**
 * Set visibility of join/leave team
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowJoinLeaveTeam = (gameDocument: GameDocument) => {
  const preGameSettings = GetPreGameSetting(gameDocument);
  return preGameSettings.showPreGameTeams;
};
