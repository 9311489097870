import { apiFetcher } from '../utils/fetchers';
import useSWR from 'swr';
import { GameEntity } from '../types/entities/game';

/**
 * Returns the game data, loading state, and error status based on the provided game code.
 * @param {string} gameCode - The code of the game to be fetched.
 * @returns {Object} - An object containing the game data, loading flag, and error flag.
 *   - data: The game data retrieved from the API.
 *   - isLoading: A boolean flag indicating whether the data is currently being loaded.
 *   - isError: A boolean flag indicating whether an error occurred while retrieving the data.
 */
export const useGame = (gameCode: string) => {
  // load the game record using the game id. SWR should maintain this record (i.e. reload).
  const { data, error, isLoading } = useSWR<GameEntity>(
    `games/${gameCode}`,
    apiFetcher,
    { suspense: true }
  );

  if (error) console.log(error);

  return {
    data,
    isLoading,
    isError: error
  };
};
