import React from 'react';
import { ReactComponent as Logo } from '../../assets/cg-icon-globe.svg';
import Progress from '../../layouts/progress';

interface SignOutRedirectProps {
  children?: React.ReactNode;
}

const SignOutRedirect = ({ children }: SignOutRedirectProps) => (
  <Progress>
    <div className={'text-center'}>
      <Logo className={'spin'} style={{ width: 100 }} />
      <h1 className={'mt-3'}>Sign out in progress</h1>
      <p>You will be redirected to the login page.</p>
      <>{children}</>
    </div>
  </Progress>
);

export default SignOutRedirect;
