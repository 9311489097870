export const sendPushNotification = ({
  message,
  avatar,
  url,
  playerName
}: {
  message: string;
  avatar: string;
  url: string;
  playerName: string;
}) => {
  navigator.serviceWorker.ready.then((registration) => {
    const options: NotificationOptions = {
      body: message,
      icon: avatar,
      badge: '/favicon.ico',
      data: {
        url
      }
    };

    registration.showNotification(playerName, options);
  });
};
