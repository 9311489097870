import React, { Fragment, useContext } from 'react';
import { RadioButton } from '@progress/kendo-react-inputs';
import { AnswerStatus } from '../questionnaire';
import { PlayerContext } from '../../contexts/player';

type AnswerOptionProps = {
  group?: string;
  options?: string[];
  answerOptionIndex?: number;
  isCorrectAnswer?: boolean;
  taskId?: string;
  formId?: string;
  onChangeOptionIndex?: (index: number) => void;
};
const AnswerRadio = ({
  group,
  options,
  answerOptionIndex,
  isCorrectAnswer,
  taskId = '',
  formId = '',
  onChangeOptionIndex
}: AnswerOptionProps) => {
  const [player] = useContext(PlayerContext);
  const onRadioButtonChange = (index: number) => {
    if (onChangeOptionIndex) {
      onChangeOptionIndex(index);
    }
  };

  const renderBorder = (index: number): string => {
    if (formId && taskId !== '' && formId !== '') {
      const playerTaskContentFormAnswer =
        player.playerState?.tasks?.find((x) => x.id === taskId)
          ?.taskContentFormAnswers ?? [];
      const playerAnswerIndex = playerTaskContentFormAnswer
        ? playerTaskContentFormAnswer
            ?.find((x) => x?.formId === formId)
            ?.answers?.findIndex((x) => x.value as boolean) ?? -1
        : -1;
      return index === playerAnswerIndex
        ? isCorrectAnswer === undefined
          ? 'border-dark'
          : isCorrectAnswer
          ? 'border-success'
          : 'border-danger'
        : 'border-dark';
    }
    return 'border-dark';
  };

  const renderAnswerStatus = (index: number): JSX.Element => {
    if (formId && taskId !== '' && formId !== '') {
      const playerTaskContentFormAnswer =
        player.playerState?.tasks?.find((x) => x.id === taskId)
          ?.taskContentFormAnswers ?? [];
      const playerAnswerIndex = playerTaskContentFormAnswer
        ? playerTaskContentFormAnswer
            ?.find((x) => x?.formId === formId)
            ?.answers?.findIndex((x) => x.value as boolean) ?? -1
        : -1;
      return index === playerAnswerIndex ? (
        <AnswerStatus isCorrectAnswer={isCorrectAnswer} />
      ) : (
        <Fragment />
      );
    }
    return <Fragment />;
  };

  return (
    <div id={'answer-radio'}>
      {options &&
        options.map((option: string, index: number) => (
          <div
            className={`d-flex flex-row justify-content-center align-items-center card border border-2 w-100 my-2 ${renderBorder(
              index
            )}`}
            key={index}>
            <div className={'card-body flex-grow-1'}>
              <RadioButton
                id={`radio-option-${index}`}
                name={group}
                className={`border border-2 border-dark ${
                  index === answerOptionIndex && 'bg-success'
                }`}
                size={'large'}
                checked={index === answerOptionIndex}
                onChange={() => onRadioButtonChange(index)}
                label={option}
              />
            </div>
            {renderAnswerStatus(index)}
          </div>
        ))}
    </div>
  );
};

export default AnswerRadio;
