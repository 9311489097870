import { InGame } from '../../types/game-document/settings/in-game';

export const createCustomPlayerPosition = ({
  id,
  name,
  score,
  avatarUrl,
  inGameSettings,
  isFacilitator = false
}: {
  id: string;
  name: string;
  score: string | number;
  avatarUrl?: string;
  inGameSettings?: InGame;
  isFacilitator?: boolean;
}) => {
  const playerContainer = document.createElement('div');
  playerContainer.classList.add('player-container');

  const avatarContainer = document.createElement('div');
  avatarContainer.classList.add('avatar-container');
  const avatarCircle = document.createElement('div');
  avatarCircle.classList.add('avatar-circle');

  if (avatarUrl) {
    const avatar = document.createElement('img');
    avatar.id = `avatar-${id}`;
    avatar.src = avatarUrl;
    avatar.alt = 'avatar';
    avatar.classList.add('avatar-img');
    avatarCircle.append(avatar);
  }

  avatarContainer.append(avatarCircle);

  const profileContainer = document.createElement('div');
  profileContainer.classList.add('profile-container', 'hidden');

  const playerName = document.createElement('span');
  playerName.id = `playerName-${id}`;
  playerName.style.fontWeight = 'bold';
  playerName.innerHTML = name;

  const playerTeam = document.createElement('span');
  playerTeam.id = `playerTeam-${id}`;
  playerTeam.className = 'd-none';
  playerTeam.innerHTML = 'Player Team';

  const playerScore = document.createElement('span');
  playerScore.id = `playerScore-${id}`;
  playerScore.style.fontWeight = 'bold';
  playerScore.innerHTML = score.toString();

  profileContainer.append(playerName, playerTeam, playerScore);

  const clickEvent = () => {
    if (playerContainer.classList.contains('expanded')) {
      playerContainer.classList.remove('expanded');
      profileContainer.classList.add('hidden');
      profileContainer.classList.remove('visible');
    } else {
      playerContainer.classList.add('expanded');
      profileContainer.classList.add('visible');
      profileContainer.classList.remove('hidden');
    }
  };

  playerContainer.style.cursor = 'pointer';
  playerContainer.addEventListener('click', clickEvent);

  if (inGameSettings && !isFacilitator) {
    // hide player score
    // remove gap and padding if no player/team name and score
    if (!inGameSettings.showPlayerScoreOnMap) {
      profileContainer.removeChild(playerScore);
    }

    if (!inGameSettings.showPlayerDetailByClickOnMap) {
      playerContainer.removeEventListener('click', clickEvent);
    }
  }

  playerContainer.append(avatarContainer, profileContainer);
  return playerContainer;
};
