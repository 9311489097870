import { Feature } from 'ol';
import { GameDocument } from '../../types/game-document';
import SelectionItem from '../../types/selection-item';
import { transform } from 'ol/proj';
import { GameContextState } from '../../contexts/game';
import { getTotalSecondsBetweenDate } from '../date';
import {
  AvailablePlayerColor,
  AwayPlayerColor,
  FiveMinuteInSeconds,
  OfflinePlayerColor,
  TenMinuteInSeconds
} from '../../constants/map';
import { GetResourceValue } from './resources';
import { PlayerState } from '../../types/state';
/**
 * Get all locations
 * @param GameDocument
 */
export const getAllLocations = (gameDocument: GameDocument) => {
  let locations: SelectionItem[] = [];

  locations.push({ id: 'world-map', name: 'world-map' });

  gameDocument?.assets?.zones?.forEach((zone) => {
    locations.push({ id: zone.id, name: zone.name });
  });

  return locations;
};

export const getLocation = (gameDocument: GameDocument, locationId: string) => {
  if (locationId === 'world-map') return locationId;

  let zone = gameDocument?.assets?.zones?.find((x) => x.id === locationId);

  return GetResourceValue(gameDocument!, zone?.titleResId!, '');
};

interface PlayerDetail {
  code?: string;
  teamCode?: string;
  groupCode?: string;
}

const getPlayersData = (
  gameState: GameContextState,
  selectedCode?: string,
  selectedCodeType?: string
) => {
  const players = gameState?.gameState?.players || [];

  const playerDetails = players.map((player) => {
    const { code, teamCode } = player || {};
    let groupCode = null;

    if (teamCode) {
      const team = gameState?.gameState?.teams?.find((x) => x.id === teamCode);
      groupCode = team?.groupCode;
    }

    return { code, teamCode, groupCode } as PlayerDetail;
  });

  if (selectedCodeType === 'group') {
    return playerDetails
      ?.filter((x) => x.groupCode === selectedCode)
      ?.map((player) => player.code) as string[];
  } else if (selectedCodeType === 'team') {
    return playerDetails
      ?.filter((x) => x.teamCode === selectedCode)
      ?.map((player) => player.code) as string[];
  } else if (selectedCodeType === 'player') {
    return playerDetails
      ?.filter((x) => x.code === selectedCode)
      ?.map((player) => player.code) as string[];
  } else {
    return [];
  }
};

/**
 * Populate player position as GeoJSON feature
 * @returns
 */
export const getAllPlayerLocationAsMapFeature = (
  gameState: GameContextState,
  isAccuracyFeature?: boolean,
  selectedCode?: string,
  selectedCodeType?: string
): Feature[] => {
  let feature: Feature[] = [];

  let playersSelected = getPlayersData(
    gameState,
    selectedCode,
    selectedCodeType
  );
  const filteredArray: PlayerState[] =
    gameState?.gameState?.players.filter((item) =>
      playersSelected.includes(item.code!)
    ) ?? [];

  let players = selectedCode ? filteredArray : gameState?.gameState?.players;

  players?.forEach((player: any) => {
    if (player.coordinates) {
      feature.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: transform(
            [player?.coordinates?.longitude!, player?.coordinates?.latitude!],
            'EPSG:4326',
            'EPSG:3857'
          )
        },
        properties: {
          isAccuracyFeature: isAccuracyFeature,
          name: player?.name,
          accuracy: player?.coordinates?.accuracy,
          colorStatus: getPlayerLocationColorStatus(player?.lastUpdated)
        }
      } as never);
    }
  });
  return feature;
};

export const getPlayerLocationColorStatus = (lastUpdated: Date | undefined) => {
  if (lastUpdated) {
    const currentUTCDate = new Date(new Date().toUTCString());

    let seconds = getTotalSecondsBetweenDate(lastUpdated, currentUTCDate);

    if (seconds <= FiveMinuteInSeconds) {
      return AvailablePlayerColor;
    }

    if (seconds <= TenMinuteInSeconds) {
      return AwayPlayerColor;
    }
  }
  return OfflinePlayerColor;
};

export const getPlayerLocationStatus = (lastUpdated: Date | undefined) => {
  if (lastUpdated) {
    const currentUTCDate = new Date(new Date().toUTCString());

    let seconds = getTotalSecondsBetweenDate(lastUpdated, currentUTCDate);

    if (seconds <= FiveMinuteInSeconds) {
      return 'available';
    }

    if (seconds <= TenMinuteInSeconds) {
      return 'away';
    }
  }
  return 'offline';
};
