import { QRCode } from '@progress/kendo-react-barcodes';
import { Button } from '@progress/kendo-react-buttons';
import React, { useContext, useState, useEffect } from 'react';
import { PlayerContext } from '../../contexts/player';
import { PlayerAssessmentContext } from '../../contexts/player-assessment';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdatePlayerAssessmentContext } from '../../utils/player-state';
import { toastStore } from '../../stores/toast-store';
interface QRAssessmentPageProps {
  onJoin?: () => void;
}
export const QRAssessmentPage = ({ ...props }: QRAssessmentPageProps) => {
  const navigate = useNavigate();
  const [url, setUrl] = useState<string>('');
  const [player, setPlayerState] = useContext(PlayerContext);
  let { gameCode } = useParams();

  const [playerAssessment] = useContext(PlayerAssessmentContext);
  useEffect(() => {
    let newUrl = `${window.location.origin}`;
    setUrl(
      `${newUrl}/assessments/${gameCode}/${player?.playerState?.teamCode}`
    );
  }, []);

  const setDefaultData = () => {
    let name =
      player?.assessmentState?.name === ''
        ? player?.playerState?.name
        : player?.assessmentState?.name;
    updateName(name ?? '');
  };

  const updateName = (name: string) => {
    setPlayerState((prev) =>
      UpdatePlayerAssessmentContext(prev, {
        ...prev.assessmentState!,
        name: name
      })
    );
  };

  const checkExistingPlayer = () => {
    if (player.assessmentState?.code) {
      navigate(`../../games/${gameCode}/assessment/1`);
    }
  };

  useEffect(() => {
    checkExistingPlayer();
    setDefaultData();
  }, []);

  return (
    <div>
      <div className={'k-card-deck'}>
        <div className={'k-card k-text-center'}>
          <div className={'k-card-header scan'}>
            <div className={'k-card-title fw-bold'}>SCAN AND JOIN NOW</div>
          </div>
          <div className={'k-card-body d-flex flex-column'}>
            <QRCode size={'350'} value={`${url}`} errorCorrection="M" />
            <div className={'input-group mb-3 mt-3'}>
              <input
                type={'text'}
                className={'form-control text-primary'}
                disabled={true}
                value={`${url}`}
              />
              <div className={'input-group-append'}>
                <button
                  className={'btn btn-success text-light'}
                  type={'button'}
                  onClick={() => {
                    navigator.clipboard.writeText(`${url}`);

                    toastStore.show(
                      'Info',
                      <div>Link copied successfully!</div>,
                      'info'
                    );
                  }}>
                  Copy
                </button>
              </div>
            </div>

            <div>
              <Button
                themeColor={'success'}
                className={'mr-2'}
                onClick={() => {
                  window.open(url, '_blank');
                }}>
                Join assessment
              </Button>
              <Button
                themeColor={'secondary'}
                onClick={() => navigate('../map')}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
