import { useGame } from './use-game';
import useSWRImmutable from 'swr/immutable';
import { apiFetcher } from '../utils/fetchers';
import { Theme } from '../types/theme';

/**
 * Retrieves the game theme for a given game code using SWRImmutable.
 *
 * @param {string} gameCode - The unique identifier of the game.
 * @returns {Object} - An object containing the game theme data, loading flag, and error flag.
 *   - data: The game theme data retrieved from the API.
 *   - isLoading: A boolean flag indicating whether the data is currently being loaded.
 *   - isError: A boolean flag indicating whether an error occurred while retrieving the data.
 */
export const useGameTheme = (gameCode: string) => {
  // load the game so we can check that it actually has a game theme.
  const game = useGame(gameCode);

  // load the game theme. immutable as the theme shouldn't change.
  const { data, isLoading, error } = useSWRImmutable<Theme>(
    () => (game.data?.themeFileName ? `games/${gameCode}/theme` : null),
    apiFetcher,
    { suspense: true }
  );

  if (error) console.log(error);

  return {
    data,
    isLoading,
    isError: error
  };
};
