import { AreaEntity } from '../../../types/entities';
import { Area } from '../../../types/game-document/entities/area';
import { merge } from 'lodash';
import { PlayerStateFull } from '../../../types/state/player';
import { GameDocument } from '../../../types/game-document';

/**
 * Get all areas from PlayerStateFull.
 * @param player - The player as PlayerStateFull
 * @constructor
 */
export const GetAreas = (player: PlayerStateFull | undefined) => {
  return player?.areas ?? [];
};

/**
 * Get all areas from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetDocumentAreas = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.areas ?? [];
};

/**
 * Get area by areaid.
 * @param player - The player as PlayerStateFull
 * @param areaId - Id of the area
 * @constructor
 */
export const GetAreaById = (gameDocument: GameDocument, areaId: string) => {
  let areas = GetDocumentAreas(gameDocument);
  return areas.find((z) => z.id === areaId);
};

/**
 * Get areas in world map from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetAreasWorldMap = (gameDocument: GameDocument | undefined) => {
  const areas: Area[] = [];

  if (gameDocument) {
    const zones = gameDocument.assets.zones;

    gameDocument.assets.areas?.forEach((area) => {
      let found = false;
      zones?.forEach((zone) => {
        const zoneAreas = zone.areas?.map((e) => e.areaAssId);
        if (zoneAreas?.includes(area.id)) {
          found = true;
          return;
        }
      });

      if (!found) areas.push(area);
    });
  }

  return areas;
};

/**
 * Get area by zoneId.
 * @param gameDocument - The gameDocument
 * @param zoneId - Id of the zone
 * @constructor
 */
export const GetAreasByZoneId = (
  gameDocument: GameDocument | undefined,
  zoneId: string
) => {
  let areas: Area[] = [];

  if (gameDocument) {
    let zone = gameDocument?.assets?.zones?.find((x) => x.id === zoneId);
    const areaIds = zone?.areas?.map(function (e) {
      return e.areaAssId;
    });

    if (areaIds) {
      return GetAreasByIds(gameDocument, areaIds);
    }
  }

  return areas;
};

/**
 * Get areas by Ids from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @param ids - the area ids
 * @constructor
 */
export const GetAreasByIds = (
  gameDocument: GameDocument | undefined,
  ids: string[]
) => {
  return (
    gameDocument?.assets?.areas?.filter((x) => ids.indexOf(x.id) !== -1) ?? []
  );
};

export const MergeArea = (
  player: PlayerStateFull,
  areas: Array<AreaEntity>
) => {
  return merge(player, { areas });
};
