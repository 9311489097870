import { DisplayLangResources } from '../../constants/display-languages';
import { DisplayLangResource } from '../../types/game-document/entities/display-languages';
import { GameDocumentState } from '../../contexts/game-document';
import { GetDisplayLangResourcePackValue } from './display-languages-packs';
import { AssessmentDocumentContextProps } from '../../contexts/assessment-document';
import { FeedbackDocumentState } from '../../types/feedback-document';

/**
 * Get Display Languages by id.
 * @param displayLanguages - The list of Display Languages to check
 * @param id - The ID of the current Display Languages
 * @returns Display Languages data as string.
 */
export const getDisplayLangResourceValue = (
  displayLanguages: Array<DisplayLangResource>,
  id?: string
) => {
  return displayLanguages
    ? displayLanguages.find((x) => x.id === id)?.value
    : '';
};

export const GetDisplayLanguageJsonAsync = async (
  displayLanguagesUrl: string
) => {
  try {
    const response = await fetch(displayLanguagesUrl!);
    if (!response.ok) {
      throw new Error(`Network response was not ok ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
};

export const generateTitleById = (
  id: string,
  contentDocument?:
    | GameDocumentState
    | AssessmentDocumentContextProps
    | FeedbackDocumentState,
  displayLanguageResSelected?: DisplayLangResource[],
  template?: 'game' | 'assessment' | 'feedback'
) => {
  let getResourceValue = null;

  const displayLanguageOriginalRes = DisplayLangResources.find(
    (x) => x?.id === id
  );
  
  if(contentDocument && displayLanguageResSelected && displayLanguageOriginalRes){
    
  const isOriginalLangMissing = template?.includes('assessment')
    ? !(
        'assessmentDocument' in contentDocument &&
        contentDocument.assessmentDocument?.displayLanguageUrl
      )
    : template?.includes('feedback')
    ? !(
        'feedbackDocument' in contentDocument &&
        contentDocument?.feedbackDocument?.displayLanguageUrl
      )
    : !(
        'gameDocument' in contentDocument &&
        contentDocument.gameDocument?.overview?.displayLanguagesUrl
      );


    getResourceValue =
      isOriginalLangMissing && displayLanguageResSelected.length > 0
        ? getDisplayLangResourceValue(
            DisplayLangResources,
            displayLanguageOriginalRes!.id
          )
        : getDisplayLangResourceValue(
            displayLanguageResSelected,
            displayLanguageOriginalRes!.id
          );
  } else {
    getResourceValue = getDisplayLangResourceValue(
      DisplayLangResources,
      id
    );
  }

  return getResourceValue;
};
