import { AssessmentEntity } from '../../types/state/assessment/assessment';
import { AssessmentOutcome } from './assessment-outcome';
import { Col, Container, Row } from 'react-bootstrap';
import { Rating } from '../input/rating';
import { useEffect, useState, useContext } from 'react';
import { OutcomeRating } from '../../types/state/assessment/assessment-players-answer-state';
import { PlayerContext } from '../../contexts/player';
import { PlayerAssessmentContext } from '../../contexts/player-assessment';
import {
  getAnswerActiveAssessment,
  getAnswerPlayerOutcomeRating
} from '../../utils/assessment-state/player-answer-assessment';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { generateTitleById } from '../../utils/game-document/display-languages';
interface AssessmentRatingProps {
  scores?: OutcomeRating[];
  assessment?: AssessmentEntity;
  showDescription?: boolean;
  setIsShouldShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: (result?: OutcomeRating[]) => void;
}

export const AssessmentRating = ({ ...props }: AssessmentRatingProps) => {
  const [playerState] = useContext(PlayerContext);
  const [playerAssessment] = useContext(PlayerAssessmentContext);
  const [outcomesRating, setOutcomesRating] = useState<OutcomeRating[]>(
    props.scores ?? []
  );
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const { playerState: assessmentDocument } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;

  const handleRatingChange = (outcomeId: string, score?: number) => {
    let newRating = [...outcomesRating];

    if (newRating.find((x) => x.outcomeId === outcomeId)) {
      let ratingIndex = newRating.findIndex((x) => x.outcomeId === outcomeId);

      if (ratingIndex > -1) {
        newRating[ratingIndex].score = score;
      }
    } else {
      newRating.push({ outcomeId: outcomeId, score: score });
    }

    if (props.onChange) {
      props.onChange(newRating);
    }

    setOutcomesRating(newRating);
  };

  useEffect(() => {
    if (props.assessment) {
      const rankedLength = outcomesRating.length;
      props.setIsShouldShowDialog(!Boolean(rankedLength));
    }
  }, [outcomesRating.length, props.assessment]);

  useEffect(() => {
    setOutcomesRating(
      getAnswerPlayerOutcomeRating(
        playerState?.assessmentState?.code!,
        getAnswerActiveAssessment(playerAssessment)?.outcomes ?? []
      )
    );
  }, []);

  return (
    <Container className={'d-flex flex-column assessment-content'}>
      <Row className={'mb-3'}>
        <Col className={'d-flex flex-column align-items-center text-center'}>
          <span className={'fw-bold'}>
            {generateTitleById(
              '272ee4de-783f-4c53-be6e-65eb18eee2ad',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Rank the following statements'}
          </span>
          <div className={'d-flex'}>
            <span>
              {generateTitleById(
                'a1445688-fa0b-4680-b0ce-4119d3892e49',
                assessmentDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || '1 being strongly disagree and 10 being strongly agree.'}
            </span>
          </div>
        </Col>
      </Row>
      {props?.assessment?.assessmentOutcomes?.map((item) => (
        <AssessmentOutcome
          key={item.id}
          showDescription={props.showDescription}
          assessmentOutcome={item}
          assessment={props.assessment!}>
          <Rating
            counter={10}
            className={'d-flex flex-wrap'}
            onChange={(value) => {
              handleRatingChange(item.id, value);
            }}
            value={
              outcomesRating.find((x) => x.outcomeId === item.id)?.score ?? 0
            }></Rating>
        </AssessmentOutcome>
      ))}
    </Container>
  );
};
