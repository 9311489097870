interface FeedbackContentHtmlProps {
  contentHtmlOutro?: string;
}
export const FeedbackCompleted = ({
  contentHtmlOutro = ''
}: FeedbackContentHtmlProps) => {
  return (
    <div className={'layout-feedback-form__main'}>
      <div dangerouslySetInnerHTML={{ __html: contentHtmlOutro }} />
    </div>
  );
};
