import { cloneDeep, omit } from 'lodash';
import { PlayerContextState } from '../../contexts/player';
import { PlayerStateFull } from '../../types/state';
import { PlayerAssessmentState } from '../../types/state/player/player-assessment';

export const UpdatePlayerContext = (
  state: PlayerContextState,
  updated: PlayerStateFull,
  isDirty: boolean = true
) => {
  return cloneDeep({
    ...state,
    isDirty,
    playerState: cloneDeep({ ...updated })
  });
};

export const UpdatePlayerAssessmentContext = (
  state: PlayerContextState,
  updated: PlayerAssessmentState,
  isDirty: boolean = true
) => {
  return cloneDeep({
    ...state,
    isDirty,
    assessmentState: cloneDeep({ ...updated })
  });
};

export const ResetPlayerAssessmentContext = (
  state: PlayerContextState,
  isDirty: boolean = false
) => {
  return omit(state, 'assessmentState');
};
