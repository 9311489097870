import { PlayerState } from '../../types/state';
import { GameDocument } from '../../types/game-document';
import { GetPreGameSetting } from '../game-document/settings';
import cloneDeep from 'lodash.clonedeep';

/**
 * Set visibility of chat
 * @param gameDocument - The Game document
 * @param gamePlayer - The GamePlayer state
 */
export const ShowChat = (
  gamePlayer: PlayerState,
  gameDocument: GameDocument
) => {
  if (gamePlayer?.status === 'playing') {
    const GetPreGameChat = GetPreGameSetting(gameDocument);
    return GetPreGameChat.showPreGameChat;
  }
  return true;
};

/**
 * Get list unread chat local storage by game code
 * @param gameCode
 * @returns
 */
export const getUnreadChatsCode = (gameCode: string): string[] => {
  let unreadChats = localStorage.getItem(getUnreadChatKeyStorage(gameCode));
  if (!unreadChats) {
    unreadChats = JSON.stringify([]);
  }
  return JSON.parse(unreadChats);
};

/**
 * Get unread chat local storage key
 * @param gameCode
 * @returns
 */
export const getUnreadChatKeyStorage = (gameCode: string): string => {
  return `${gameCode}-unread-chat`;
};

/**
 * Add unread chat to local storage
 * @param gameCode
 */
export const addUnreadChat = (gameCode: string, method: string) => {
  let unreadChats: string[] = cloneDeep(getUnreadChatsCode(gameCode));

  let newUnreadChats = [...unreadChats];
  newUnreadChats.push(method);
  localStorage.setItem(
    getUnreadChatKeyStorage(gameCode),
    JSON.stringify(newUnreadChats)
  );
};

/**
 * Check unread chat is exist
 * @param gameCode
 * @param code
 * @returns
 */
export const isExistUnreadChat = (gameCode: string, code: string) => {
  return getUnreadChatsCode(gameCode).filter((x) => x === code).length > 0;
};

/**
 * Remove unread data
 */
export const removeUnreadChat = (gameCode: string, code: string) => {
  let filteredData = getUnreadChatsCode(gameCode);

  filteredData = filteredData?.filter((x) => x !== code);

  localStorage.setItem(
    getUnreadChatKeyStorage(gameCode),
    JSON.stringify(filteredData)
  );
};
