import { HttpStatus } from './http-client';
import { GetAsync, PutAsync } from './api';
import {
  Feedback,
  FeedbackEntity,
  FeedbackGameEvent,
  FeedbackLanguage,
  FeedbackPayload
} from '../types/feedback-document';

export interface Response {
  isError: boolean;
  statusCode: HttpStatus;
  message: string;
  result: any;
}

export const getFeedbackAsync = async (feedbackCode: string) => {
  let response = await GetAsync<FeedbackEntity>(`feedbacks/${feedbackCode}`);
  return response;
};

export const getFeedbacksDocumentAsync = async (feedbackCode: string) => {
  let response = await GetAsync<Feedback>(
    `feedbacks/${feedbackCode}/document/content`
  );
  return response;
};

export const getFeedbacksGamesAsync = async (feedbackCode: string) => {
  let response = await GetAsync<FeedbackGameEvent>(
    `feedbacks/${feedbackCode}/games`
  );
  return response;
};

export const updateFeedbacksDocumentAsync = async (
  feedbackCode: string,
  feedback: FeedbackPayload
) => {
  let response = await PutAsync(`feedbacks/${feedbackCode}/response`, feedback);
  return response;
};

/**
 * Get feedback languages
 * @param feedbackCode
 * @returns
 */
export const getFeedbackLanguagesAsync = async (feedbackCode: string) => {
  let response = await GetAsync<FeedbackLanguage[]>(
    `feedbacks/${feedbackCode}/languages`
  );
  return response;
};
