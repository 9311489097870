import { apiFetcher } from '../utils/fetchers';
import useSWR from 'swr';
import { AssessmentEntity } from '../types/entities/assessment';
import { EndAssessmentAsync } from '../services/assessments';
import { useGame } from './use-game';

/**
 * Returns the assessment data, loading state, and error status based on the provided game code.
 * @param {string} gameCode - The code of the assessment to be fetched.
 * @returns {Object} - An object containing the assessment data, loading flag, and error flag.
 *   - data: The assessment data retrieved from the API.
 *   - isLoading: A boolean flag indicating whether the data is currently being loaded.
 *   - isError: A boolean flag indicating whether an error occurred while retrieving the data.
 */
export const useAssessment = (gameCode: string) => {
  // load the game so we can check that it actually has an assessment.
  const game = useGame(gameCode);

  // load the assessment record using the game id. SWR should maintain this record (i.e. reload).
  const { data, error, isLoading } = useSWR<AssessmentEntity>(
    () => (game.data?.assessmentId ? `assessments/${gameCode}` : null),
    apiFetcher,
    { suspense: true }
  );

  // control function for ending an assessment.
  const endAssessment = async () => EndAssessmentAsync(gameCode);

  if (error) console.log(error);

  return {
    data,
    isLoading,
    isError: error,
    endAssessment
  };
};
