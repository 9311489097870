import React from 'react';
import { Error, Label } from '@progress/kendo-react-labels';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxProps
} from '@progress/kendo-react-dropdowns';
import cloneDeep from 'lodash.clonedeep';

export interface ComboboxInputProps extends ComboBoxProps {
  label?: string;
  error?: string;
  labelStyle?: string;
}

const ComboboxInput: React.FC<ComboboxInputProps> = ({
  label,
  error,
  labelStyle,
  ...inputProps
}) => {
  const dataRef = React.useRef<typeof inputProps.data>();
  const [dataList, setDataList] = React.useState<typeof inputProps.data>([]);

  React.useEffect(() => {
    dataRef.current =
      inputProps.data && inputProps.data.length > 0
        ? cloneDeep(inputProps.data)
        : [];
    setDataList(dataRef.current);
  }, [inputProps.data]);

  React.useEffect(() => {
    if (dataList && dataList.length > 0) {
      dataList.sort((a, b) => {
        if (
          inputProps.textField ||
          inputProps.data?.every((e) => typeof e === 'object')
            ? a[inputProps.textField as string] <
              b[inputProps.textField as string]
            : a < b
        )
          return -1;
        if (
          inputProps.textField ||
          inputProps.data?.every((e) => typeof e === 'object')
            ? a[inputProps.textField as string] >
              b[inputProps.textField as string]
            : a > b
        )
          return 1;
        return 0;
      });
    }
  }, [dataList, inputProps.textField]);

  const filterData = (filter: FilterDescriptor) => {
    const data = dataRef.current!.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    setDataList(filterData(event.filter));
  };

  return (
    <div className={'d-flex flex-column gap-2'}>
      {label && <Label className={labelStyle}>{label}</Label>}
      <ComboBox
        {...inputProps}
        data={dataList}
        onFilterChange={filterChange}
        filterable
      />
      {inputProps?.validationMessage && (
        <Error>{inputProps?.validationMessage}</Error>
      )}
    </div>
  );
};

export default ComboboxInput;
