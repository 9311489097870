import { AssessmentPlayers, AssessmentSteps } from '../../types/assessment';
import { GameDocument } from '../../types/game-document';
import {
  AssessmentAnswerState,
  AssessmentState,
  Outcome,
  OutcomeRating
} from '../../types/state/assessment/assessment-players-answer-state';

/**
 * Get active assessment state
 * @param assessment
 * @returns
 */
export const getAnswerActiveAssessment = (assessment: AssessmentState) => {
  if (assessment.assessmentStates) {
    return assessment.assessmentStates![0]!;
  }
};

/**
 * Get player outcome rating
 * @param playerCode
 * @param outcomes
 * @returns
 */
export const getAnswerPlayerOutcomeRating = (
  playerCode: string,
  outcomes: Outcome[]
): OutcomeRating[] => {
  let playerOutcomes: OutcomeRating[] = [];
  outcomes?.forEach((outcome) => {
    outcome?.responses
      ?.filter((x) => x.playerCode === playerCode)
      .forEach((response) => {
        playerOutcomes.push({ outcomeId: outcome.id, score: response?.score });
      });
  });
  return playerOutcomes;
};

/**
 * Get last player step
 * @param playerCode
 * @param lastPlayerStep
 * @param assessment
 * @returns
 */
export const getLastPlayerStep = (
  playerCode: string,
  lastPlayerStep: number,
  assessment: AssessmentState
) => {
  let activeAssessment = getAnswerActiveAssessment(assessment);

  let player = activeAssessment?.players?.find((x) => x.code === playerCode);

  if (!player || player?.steps?.length === 0) {
    return lastPlayerStep;
  } else {
    const maxValue = player?.steps.reduce((max, current) => {
      return current.stepOrder > max ? current.stepOrder : max;
    }, player?.steps[0].stepOrder);

    if (lastPlayerStep > maxValue!) {
      return lastPlayerStep;
    } else {
      return maxValue;
    }
  }
};

/**
 * Get last assessment step
 * @param assessment
 * @returns
 */
export const getLastAssessmentStep = (assessment: AssessmentState) => {
  let activeAssessment = getAnswerActiveAssessment(assessment);

  let stepOrder = activeAssessment?.steps?.find(
    (x) => x.status === 'running'
  )?.stepOrder;

  if (stepOrder) {
    return stepOrder;
  }

  return 0;
};

/**
 * Set assessment should be showing the content or not
 * @param playerCode
 * @param lastPlayerStep
 * @param assessment
 * @returns
 */
export const showAssessmentModal = (
  playerCode: string,
  lastPlayerStep: number,
  assessment: AssessmentState
) => {
  let playerStep = getLastPlayerStep(playerCode, lastPlayerStep, assessment);
  let assessmentStep = getLastAssessmentStep(assessment);

  return playerStep !== assessmentStep;
};

/**
 *
 * @param assessmentAnswer Get outcome average score
 * @param outcomeId
 * @returns
 */
export const getOutcomeAverageScore = (
  assessmentAnswer: AssessmentAnswerState,
  outcomeId: string
) => {
  let outcomeResponses = assessmentAnswer?.outcomes?.find(
    (x) => x.id === outcomeId
  )?.responses;

  if (!outcomeResponses) return 0;

  let sum = 0;

  for (let i = 0; i < outcomeResponses.length; i++) {
    sum += outcomeResponses[i].score ?? 0;
  }

  return parseFloat((sum / outcomeResponses.length).toFixed(2)) ?? 0;
};

/**
 *
 * @param assessment Get player name by playercode
 * @param playerCode
 * @returns
 */
export const getPlayerName = (
  assessment: AssessmentState,
  playerCode: string
) => {
  let activeAssessment = getAnswerActiveAssessment(assessment);

  if (!activeAssessment) return '';
  return (
    activeAssessment?.players?.find((x) => x.code === playerCode)?.name ?? ''
  );
};

/**
 * Get assessment name from Game document by Assessment Id
 * @param gameDocument
 * @param assessmentId
 * @returns
 */
export const getAssessmentName = (
  gameDocument: GameDocument,
  assessmentId: string
) => {
  return (
    gameDocument?.assessment?.find((x) => x.id === assessmentId)?.name ?? ''
  );
};

/**
 * Get assessment name from Game document by Assessment Id
 * @param gameDocument
 * @param assessmentId
 * @returns
 */
export const getAssessmentLanguage = (gameDocument: GameDocument) => {
  if (gameDocument.assessment?.at(0)) {
    return gameDocument.assessment?.at(0)?.language;
  }
  return [];
};

export const getPlayersSubmitions = (
  players: AssessmentPlayers[],
  stepId: number
) => {
  const filteredSteps: AssessmentSteps[] = players.flatMap((player) =>
    player.steps.filter(
      (step) => step.stepOrder === stepId && step.status === 'completed'
    )
  );

  return filteredSteps.length;
};
