export const isContentHtmlEmpty = (html: string): boolean => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const textContent = div.textContent || div.innerText || '';
  // detect other tags than text
  const containsOther = div.querySelector('img, video, audio, table');
  return (
    (!textContent.trim() || textContent.trim() === '\u00A0') && !containsOther
  );
};
