interface StepperProps {
  stepCount: number;
  currentStep: number;
}

interface StepProps {
  isActive: boolean;
}

const Step = ({ isActive, ...props }: StepProps) => {
  if (isActive) {
    return (
      <div className={'stepper__step stepper__step--active'}>
        <span className={'material-symbols-outlined'}>
          radio_button_checked
        </span>
      </div>
    );
  }
  return (
    <div className={'stepper__step'}>
      <span className={'material-symbols-outlined'}>circle</span>
    </div>
  );
};

export const Stepper = ({ stepCount, currentStep, ...props }: StepperProps) => {
  const steps = [...Array(stepCount)].map((_, i) => {
    return <Step key={`stepper${i}`} isActive={i + 1 <= currentStep} />;
  });
  return <div className={'stepper'}>{steps}</div>;
};
