import { useContext } from 'react';
import { AssessmentStepType } from '../../constants/assessment';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';

interface AssessmentStepTitleProps {
  step: number;
}

export const AssessmentStepTitle = ({ ...props }: AssessmentStepTitleProps) => {
  const { playerState: assessmentDocument } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  return (
    <>
      <h1 className={'text-light fw-bold'}>
        {props.step === AssessmentStepType.Step1 &&
          `${
            generateTitleById(
              'f4bd484c-991d-4470-bc89-de6b7116bde9',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Competencies'
          }`}
        {props.step === AssessmentStepType.Step2 &&
          `${
            generateTitleById(
              '277ea8ef-3999-42f9-8d91-4c9de15096c4',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Actions'
          }`}
        {props.step === AssessmentStepType.Step3 &&
          `${
            generateTitleById(
              'c082579f-1822-411e-92db-5f11e10147c3',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Action Plan'
          }`}
        {props.step === AssessmentStepType.Step4 &&
          `${
            generateTitleById(
              '419aec07-4626-4068-a964-b06444c20d5e',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Vote'
          }`}
        {props.step === AssessmentStepType.Step5 &&
          `${
            generateTitleById(
              '2f9df4c1-568c-4eac-a7aa-4d423c051723',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Review'
          }`}
        {props.step === AssessmentStepType.Step6 &&
          `${
            generateTitleById(
              '87e9994f-235b-4198-86e2-be2683bc2086',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Measurables'
          }`}
      </h1>
    </>
  );
};
