import { useContext, useEffect, useState } from 'react';
import {
  FacilitatorChat,
  FasilitatorChatProps
} from '../../../components/facilitator/chat';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { HubResponse } from '../../../types/responses/hub-response';
import { GameDocumentContext } from '../../../contexts/game-document';
import { ChatState } from '../../../types/state/websocket/chat-state';
import { useAuth } from 'react-oidc-context';
import { GetGroupChatHistory, GetGroupChatHub } from '../../../services/groups';
import { GroupResponse } from '../../../types/responses/group-response';

export const FacilitatorGroupChat = ({
  onSendMessage = () => {},
  ...props
}: FasilitatorChatProps) => {
  const auth = useAuth();
  const [gameDocument] = useContext(GameDocumentContext);
  const [connectionRef, setConnectionRef] = useState<HubConnection>();
  const [messages, setMessages] = useState<ChatState[]>([]);
  const [incomingMessage, setIncomingMessage] = useState<ChatState>();
  const [connectionHubResponse, setConnectionHubResponse] =
    useState<HubResponse>();

  function createHubConnection() {
    GetGroupChatHub((props.item as GroupResponse).code!).then((response) => {
      if (response) {
        setConnectionHubResponse(response);

        const con = new HubConnectionBuilder()

          .withUrl(`${response.endpointUrl}`, {
            accessTokenFactory: () => response.accessToken!
          })

          .withAutomaticReconnect()

          .build();

        setConnectionRef(con);
      }
    });
  }

  const getChatHistoryAsync = () => {
    let newChat: ChatState[] = [];
    GetGroupChatHistory(
      (props.item as GroupResponse).code!,
      gameDocument?.gameCode!
    ).then((response) => {
      response?.data.forEach((chat) => {
        newChat.push({
          author: {
            id:
              chat.playerCode === '00000000-0000-0000-0000-000000000000'
                ? auth?.user?.profile.sub!
                : chat.playerCode,
            name: chat.playerName,
            avatarUrl: chat.playerAvatar
          },
          timestamp: new Date(chat.sentDateUtc),
          group: chat.teamId?.toString(),
          text: chat.message
        });
      });
      setMessages(newChat);
    });
  };

  useEffect(() => {
    if (connectionRef) {
      try {
        connectionRef
          .start()
          .then(() => {
            connectionRef.on(
              connectionHubResponse?.method!,
              (playerCode, playerName, message, avatarUrl, date, method) => {
                let chat = {
                  author: {
                    id:
                      playerCode === '00000000-0000-0000-0000-000000000000'
                        ? auth?.user?.profile.sub!
                        : playerCode,
                    name: playerName,
                    avatarUrl: avatarUrl
                  },
                  timestamp: new Date(date),
                  group: method,
                  text: message
                };
                setIncomingMessage(chat);
              }
            );
          })

          .catch((err: any) => {});
      } catch (error) {}
    }

    return () => {
      connectionRef?.stop();
    };
  }, [connectionRef]);

  useEffect(() => {
    createHubConnection();
  }, []);

  useEffect(() => {
    getChatHistoryAsync();
  }, [props.item]);

  useEffect(() => {
    let newMessages: ChatState[] = [...messages];
    if (incomingMessage) {
      newMessages.push(incomingMessage);
      setMessages(newMessages);
    }
  }, [incomingMessage]);

  return (
    <FacilitatorChat
      item={props.item}
      messages={messages}
      onSendMessage={onSendMessage}
    />
  );
};
