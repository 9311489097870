import { Avatar } from '@progress/kendo-react-layout';
import React, { ReactNode, useEffect, useState } from 'react';
import { Pencil, PersonCircle } from 'react-bootstrap-icons';

type Props = {
  avatarSource?: string;
  handleUploadAvatar?: () => void;
  isShowPencil?: boolean;
  imageSize?: string;
  children?: ReactNode | undefined;
};

const AvatarPreview = ({
  avatarSource = '',
  handleUploadAvatar = () => {},
  isShowPencil = true,
  imageSize = 'h-9 w-9',
  ...props
}: Props) => {
  const [avatarImage, setAvatarImage] = useState<string>(avatarSource);

  useEffect(() => {
    avatarSource ? setAvatarImage(avatarSource) : setAvatarImage('');
  }, [avatarSource]);

  return (
    <div className={'d-flex justify-content-center align-items-center'}>
      <div
        className={`rounded-circle position-relative bg-white ${imageSize}`}
        role={'button'}
        onClick={handleUploadAvatar}>
        {avatarImage ? (
          <Avatar
            type={'image'}
            themeColor={'light'}
            className={`${imageSize} border border-5 p-2 border-success`}>
            <img
              src={avatarImage}
              className={`${imageSize} rounded-circle`}
              alt={'Player Avatar'}
            />
          </Avatar>
        ) : (
          <PersonCircle color={'#4698CB'} className={`${imageSize}`} />
        )}

        {props.children}

        {isShowPencil && (
          <div
            className={
              'position-absolute rounded-circle border border-danger bg-white d-flex justify-content-center align-items-center bottom-0 end-0 w-3 h-3'
            }>
            <Pencil color="#d22630" size={20} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AvatarPreview;
