export type HttpStatus = 200 | 400 | 500;

export interface HttpResponse<T> {
  isError: boolean;
  statusCode: HttpStatus;
  message: string;
  result: T;
}
export interface Response {
  isError: boolean;
  statusCode: HttpStatus;
  message: string;
  result: string;
}

/**
 * Concats base url + endpoint and returns it as a complete api url
 * @param endpoint
 * @returns
 */
export const getUrl = (endpoint: string, params?: string) => {
  // This is temporary solution for undefined get React app base url
  let url =
    (process.env.REACT_APP_API_BASE_URL || window.location.origin) + endpoint;

  //let url =  window?._env_?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL + endpoint;
  if (params) {
    url = url + `/?${params}`;
  }
  return url;
};
