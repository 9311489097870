import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { TaskContentAnswerEntity } from '../../../types';
import { GetTaskContentById } from '../../../utils/game-document/assets/task-contents';
import { GetResourceValue } from '../../../utils/game-document/resources';
import { fileName } from '../../../utils/file-utility';
import { GameDocumentContext } from '../../../contexts/game-document';

const AnswerColumn: React.FC<GridCellProps> = ({ dataItem, field }) => {
  const [gameDocument] = React.useContext(GameDocumentContext);
  const [data, setData] = React.useState<React.ReactNode>(<></>);

  React.useEffect(() => {
    const getAnswerList = async (
      taskContentId: string,
      formId: string,
      answers: TaskContentAnswerEntity
    ) => {
      if (!taskContentId) return;

      let taskContent = GetTaskContentById(
        gameDocument?.gameDocument,
        taskContentId!
      );

      let form = taskContent?.forms?.find((x) => x.id === formId);

      if (form) {
        if (form.type === 'checkbox' || form.type === 'radio') {
          let answerDetail: React.ReactNode[] = [];

          answers?.answers
            ?.filter((x) => x.value)
            ?.forEach((answer, index) => {
              const description = GetResourceValue(
                gameDocument?.gameDocument!,
                answer.answerResId,
                ''
              );
              answerDetail.push(
                <li key={index} className={'text-break'}>
                  {description}
                </li>
              );
            });

          setData(<ul>{answerDetail}</ul>);
        } else if (
          form.type === 'file' ||
          form.type === 'video' ||
          form.type === 'image'
        ) {
          answers?.answers?.forEach(async (answer, index) => {
            const urlObject = new URL(answer.value);
            const pathname = urlObject.pathname;
            const parts = pathname.split('/');
            let blobName = decodeURIComponent(parts.pop()!) || null;
            let result = answer.value;

            if (blobName) {
              const response = await fetch(answer.value);
              const blob = await response.blob();
              blobName = await fileName(blobName, blob.type, blob);
              result = window.URL.createObjectURL(blob);
            }

            setData(
              <a
                href={result as string}
                download={blobName}
                target="_blank"
                className={'text-primary'}>
                Open
              </a>
            );
          });
        } else {
          answers?.answers?.forEach((answer) => {
            setData(
              <label className={'text-break'}>{answer?.value ?? '-'}</label>
            );
          });
        }
      }
    };

    if (dataItem && dataItem.taskContentId) {
      getAnswerList(dataItem.taskContentId, dataItem.formId, dataItem.answers);
    }
  }, [dataItem, field, gameDocument]);

  return <td>{data}</td>;
};

export default AnswerColumn;
