import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem
} from '@progress/kendo-react-charts';
import { DataChart } from '../../types/assessment';
interface ChartProps {
  data?: DataChart[];
  limitLabel?: number;
  isExported?: boolean;
}

export const ChartContainer = ({
  data,
  limitLabel = 15,
  isExported = false
}: ChartProps) => {
  const dataChart = data?.map((item) => {
    const newItem = { ...item };
    newItem.name = item.name ?? '';

    newItem.defaultScore = 10;
    return newItem;
  });

  const seriesLabels = {
    visible: true,
    padding: isExported ? 6 : 2,
    margin: -13,
    font: `bold ${isExported ? '20px' : '12px'} Arial, sans-serif`,
    background: 'red',
    color: 'white'
  };

  const defaultSeriesLabels = {
    visible: true,
    padding: 2,
    margin: -15,
    font: `bold ${isExported ? '20px' : '12px'} Arial, sans-serif`
  };

  return (
    <Chart
      transitions={false}
      className={'h-100'}
      style={{
        minWidth: '500px'
      }}>
      <ChartSeries>
        <ChartSeriesItem
          type="radarLine"
          data={dataChart}
          field="defaultScore"
          categoryField="name"
          labels={defaultSeriesLabels}
          color={'none'}
          border={{ width: 20 }}
        />
        <ChartSeriesItem
          type="radarLine"
          data={dataChart}
          field="score"
          categoryField="name"
          color={'red'}
          labels={seriesLabels}
        />
      </ChartSeries>
      <ChartValueAxis>
        <ChartValueAxisItem
          labels={{
            visible: false
          }}
          min={0}
          max={10}
        />
      </ChartValueAxis>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          labels={{
            font: `normal ${isExported ? '20px' : '12px'} Arial, sans-serif`,
            padding: isExported ? 10 : 2
          }}
        />
      </ChartCategoryAxis>
      <ChartLegend position="bottom" />
    </Chart>
  );
};
