import { postResourceAsync } from '../services/players';
import { uuid } from '../types/common-helper';
import { UploadResponse } from '../types/responses/player-response';
import { blobToFile } from './image-helper';

/**
 * Upload single blob
 * @param url - The local blob URL
 * @returns Resource entity
 */
export const UploadSingleBlobAsync = async (
  gameCode: string,
  fileUpload: string
) => {
  if (fileUpload) {
    try {
      // load the ACTUAL file data from the BLOB url.
      let blobResponse = await fetch(fileUpload);
      let blobContent = await blobResponse.blob();
      // TODO: Big File Support?
      const newResource = (await postResourceAsync(
        gameCode,
        blobContent
      )) as UploadResponse;
      return newResource;
    } catch (ex) {
      console.error(ex);
    }
  }
};

/**
 * Upload single File
 * @param gameCode
 * @returns Azure URL
 */
export const UploadSingleFileAsync = async (
  gameCode: string,
  fileUpload: File
) => {
  if (fileUpload) {
    try {
      const newResource = (await postResourceAsync(
        gameCode,
        fileUpload as File
      )) as UploadResponse;
      return newResource;
    } catch (ex) {
      console.error(ex);
    }
  }
};
