import redaxios from 'redaxios';
import { API_URL } from '../constants';
import { getUrl } from './http-client';
import { GetAsync, JsonHeaders, PostAsync } from './api';
import { HubResponse } from '../types/responses/hub-response';
import { Theme } from '../types/theme';
import { KendoGridResult } from '../types/KendoGridResult';

export interface GameChat {
  gameCode: string;
  playerCode: string;
  message: string;
}

export interface GameChatHistory {
  id: number;
  gameCode: string;
  playerCode: string;
  playerName: string;
  playerAvatar: string;
  sentDateUtc: string;
  message: string;
}

export interface GameFeedback {
  id?: number;
  sourceEventFeedbackId?: number;
  feedbackId?: number;
  name?: string;
  code?: string;
  languages?: string;
  jsonDocumentFileName?: string;
  answerFileName?: any;
}

export async function getDocument(code: string) {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${code}/document`);
    const response = await redaxios.get(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
}

export async function getTheme(code: string) {
  try {
    const response = await GetAsync<Theme>(`games/${code}/theme`);

    return response;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
}

/**
 *
 * @param gameCode // gameCode you want to send to signalR the gameState
 */
export const GetGamesHub = async (gameCode: string) => {
  try {
    const url = getUrl(`${API_URL.hubsV2}/gamestate/${gameCode}`);
    const response = await redaxios.post(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 *
 * @param gameCode // gameCode you want to send to signalR the gameState
 */
export const GetGameStateHub = async (gameCode: string, userId: string) => {
  try {
    const url = getUrl(
      `${API_URL.hubsV2}/gamestate/${gameCode}/users/${userId}`
    );
    const response = await redaxios.post(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 *
 * @param gameCode // gameCode you want to join chat
 */
export const GetGlobalChatHub = async (gameCode: string) => {
  try {
    const url = getUrl(`${API_URL.hubsV2}/globalchat/${gameCode}`);
    const response = await redaxios.post<HubResponse>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 *
 * @param gameCode // gameCode you want to join the assessment
 */
export const GetAssessmentStateHub = async (gameCode: string) => {
  try {
    const url = getUrl(`${API_URL.hubsV2}/assessmentstate/${gameCode}`);
    const response = await redaxios.post<HubResponse>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 *
 * @param teamId // teamId you want to join chat
 */
export const PostGameChat = async (code: string, message: GameChat) => {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${code}/chats`);
    const response = await redaxios.post(url, message);
    return response?.data?.result;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Get game chat history
 * @param code // Event code
 */
export const GetGameChatHistory = async (code: string) => {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${code}/chats`);
    const response = await redaxios.get<KendoGridResult<GameChatHistory>>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Validate the game code against the V1 API.
 * @param gameCode
 */
export const ValidateGameCodeAsync = async (
  gameCode: string
): Promise<boolean> => {
  if (gameCode === '') return false;

  try {
    const res = await PostAsync(`games/${gameCode}/validate`, {});
    return res as boolean;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    return false;
  }
};

export function sendFacilitatorChat(
  gameCode?: string,
  userId?: number,
  name?: string,
  message?: string
) {
  const response = PostAsync(`games/${gameCode}/facilitator/chats`, {
    facilitatorUserProfileId: userId,
    facilitatorName: name,
    message: message
  })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return response;
}

/**
 * Start Game State into BE
 * @param gameCode
 */
export const startGameState = async (gameCode: string) => {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${gameCode}/start`);
    const response = await redaxios.post(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Pause Game State into BE
 * @param gameCode
 */
export const pauseGameState = async (gameCode: string) => {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${gameCode}/pause`);
    const response = await redaxios.post(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Resume Game State into BE
 * @param gameCode
 */
export const resumeGameState = async (gameCode: string) => {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${gameCode}/resume`);
    const response = await redaxios.post(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Stop Game State into BE
 * @param gameCode
 */
export const stopGameState = async (gameCode: string) => {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${gameCode}/stop`);
    const response = await redaxios.post(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * send content to global (All player)
 * @param gameCode
 */
export const sendGlobalContent = async (gameCode: string, message?: string) => {
  try {
    await PostAsync(`games/${gameCode}/content`, message?.toString());
  } catch (e) {
    console.error(e);
  }
};

/**
 * get content facilitator
 * @param gameCode
 * @param code
 */
export const getFacilitatorContent = async (gameCode: string, code: string) => {
  try {
    const url = getUrl(
      `${API_URL.gamesV2}/${gameCode}/facilitatorcontent/${code}`
    );
    const response = await redaxios.get(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

/**
 * Instruct the State manager to broadcast the Game State to the subscriber immediately
 * @param gameCode // The game code
 */
export function postBroadcastGameStateAsync(gameCode?: string) {
  return PostAsync(`games/${gameCode}/broadcast`, {});
}

/**
 * Get game feedback
 * @param gameCode
 */
export const getGameFeedback = async (gameCode: string) => {
  try {
    const url = getUrl(`${API_URL.gamesV2}/${gameCode}/feedbacks`);
    const response = await redaxios.get<GameFeedback[]>(url);
    return response?.data;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};
