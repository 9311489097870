import { ComponentPropsWithRef, ReactElement } from 'react';
import { CardProps } from './card';

interface CardListProps extends ComponentPropsWithRef<'div'> {
  children?: ReactElement<CardProps> | ReactElement<CardProps>[] | undefined;
}

export const CardList = ({ children, ...props }: CardListProps) => {
  let className = ['card-list', props.className].join(' ').trim();
  return (
    <div {...props} className={className}>
      {children}
    </div>
  );
};
