import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ChatMessageSendEvent,
  ChatMessageTemplateProps,
  Message
} from '@progress/kendo-react-conversational-ui';
import { ChatContext } from '../../contexts/chat';
import {
  GetTeamChatHistory,
  PostChat,
  PostTeamChat,
  getTeamSelectedAsync
} from '../../services/teams';
import { ChatState } from '../../types/state/websocket/chat-state';
import {
  GameChat,
  GetGameChatHistory,
  PostGameChat
} from '../../services/games';
import { GameContext } from '../../contexts/game';
import { PlayerContext } from '../../contexts/player';
import { ShowGameChat, ShowTeamChat } from '../../utils/game-engine/player';
import { GameDocumentContext } from '../../contexts/game-document';
import { TeamContext } from '../../contexts/team';
import { GetGroupChatHistory, PostGroupChat } from '../../services/groups';
import { GetTeamSelectedResponse } from '../../types/responses/team-response';
import {
  ChatBetweenPlayer,
  ChatToFacilitator,
  GetChatHistoryBetweeenPlayersAsync,
  GetChatWithPlayersAsync,
  GetPlayerFacilitatorChatHistoryAsync,
  PostChatBetweenPlayer,
  PostChatFacilitator
} from '../../services/players';
import {
  getUnreadChatsCode,
  removeUnreadChat
} from '../../utils/game-engine/chat';
import { PlayerResponse } from '../../types/responses/player-response';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { MessageTemplate } from './message-template';
import MobileTab from './mobile-tab';
import CommonTab from './common-tab';
import { ChatRoom, ChatRoomPlayer } from './chat-room';

const ChatWindow = () => {
  const [gameDocumentstate] = React.useContext(GameDocumentContext);
  const [gameState] = useContext(GameContext);
  const [playerState] = useContext(PlayerContext);
  const [teamState] = useContext(TeamContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const [chatStateContext] = useContext(ChatContext);
  const [messages, setMessages] = useState<ChatState[]>([]);
  const user = {
    id: playerState?.playerState?.code,
    avatarUrl: playerState?.playerState?.avatarImage
  };
  const [visibleButtonChat, setVisibleButtonChat] = useState<boolean>(true);
  const [allPlayersGroup, setAllPlayersGroup] = React.useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>('All Player');
  const [groupCode, setGroupCode] = useState<string>('');
  const [chatContacts, setChatContacts] = useState<PlayerResponse[]>([]);
  const [selectedPlayer, setSelectedPlayer] = useState<string>('');
  const [otherPlayers, setOtherPlayers] = useState<ChatRoomPlayer[]>([]);
  const [totalUnreadDirectChat, setTotalUnreadDirectChat] = useState<number>(0);

  const addNewMessage = async (event: ChatMessageSendEvent) => {
    if (!visibleButtonChat) return;
    try {
      if (activeTab === 'All Player') {
        if (gameState?.gameCode) {
          let postChat: GameChat = {
            playerCode: playerState?.playerState?.code ?? '',
            gameCode: playerState?.gameCode!,
            message: event.message.text ?? ''
          };
          await PostGameChat(gameState?.gameCode, postChat);
        }
      } else if (activeTab === 'Team') {
        if (
          playerState?.playerState?.teamCode &&
          playerState?.playerState?.teamCode !== '' &&
          playerState?.playerState?.teamCode !==
            '00000000-0000-0000-0000-000000000000'
        ) {
          let postChat: PostChat = {
            playerCode: playerState?.playerState?.code ?? '',
            teamCode: playerState?.playerState?.teamCode,
            message: event.message.text ?? '',
            sentDateUtc: new Date().toISOString()
          };
          await PostTeamChat(
            playerState?.playerState?.teamCode,
            gameState.gameCode!,
            postChat
          );
        }
      } else if (activeTab === 'Group') {
        if (
          playerState?.playerState?.teamCode &&
          playerState?.playerState?.teamCode !== '' &&
          playerState?.playerState?.teamCode !==
            '00000000-0000-0000-0000-000000000000'
        ) {
          const teamState = (await getTeamSelectedAsync(
            gameState.gameCode!,
            playerState?.playerState?.teamCode
          )) as GetTeamSelectedResponse;

          let postChat: PostChat = {
            playerCode: playerState?.playerState?.code ?? '',
            teamCode: playerState?.playerState?.teamCode,
            message: event.message.text ?? '',
            sentDateUtc: new Date().toISOString()
          };
          await PostGroupChat(
            teamState.groupCode!,
            gameState.gameCode!,
            postChat
          );
        }
      } else if (activeTab === 'Facilitators') {
        let payload: ChatToFacilitator = {
          fromPlayerCode: playerState?.playerState?.code ?? '',
          message: event.message.text ?? ''
        };

        await PostChatFacilitator(gameState.gameCode!, payload);
      } else if (activeTab === 'Direct') {
        let payload: ChatBetweenPlayer = {
          fromPlayerCode: playerState?.playerState!.code!,
          toPlayerCode: selectedPlayer,
          message: event.message.text ?? ''
        };

        await PostChatBetweenPlayer(gameState?.gameCode!, payload);

        let newMessages: Message[] = [...messages];
        newMessages.push({
          author: {
            id: playerState?.playerState?.code,
            name: playerState?.playerState?.name ?? '',
            avatarUrl: playerState?.playerState?.avatarImage ?? ''
          },
          timestamp: new Date(),
          text: event.message.text ?? ''
        });

        setMessages(newMessages);
      } else {
        let payload: ChatBetweenPlayer = {
          fromPlayerCode: playerState?.playerState!.code!,
          toPlayerCode: activeTab,
          message: event.message.text ?? ''
        };

        await PostChatBetweenPlayer(gameState?.gameCode!, payload);

        let newMessages: Message[] = [...messages];
        newMessages.push({
          author: {
            id: playerState?.playerState?.code,
            name: playerState?.playerState?.name ?? '',
            avatarUrl: playerState?.playerState?.avatarImage ?? ''
          },
          timestamp: new Date(),
          text: event.message.text ?? ''
        });

        setMessages(newMessages);
      }
    } catch (error) {
      console.log('There is an error when sending chat: ', error);
    }
  };

  const getGameChatHistory = () => {
    let newChats: ChatState[] = [];
    GetGameChatHistory(gameState?.gameCode!).then((response) => {
      response?.data.forEach((chat) => {
        newChats.push({
          author: {
            id: chat.playerCode,
            name: chat.playerName ?? '',
            avatarUrl: chat.playerAvatar ?? ''
          },
          timestamp: new Date(chat.sentDateUtc),
          group: chat.gameCode,
          text: chat.message
        });
      });

      setMessages(newChats);
    });
  };

  const getTeamChatHistory = () => {
    let newChats: ChatState[] = [];
    if (
      playerState?.playerState?.teamCode &&
      playerState?.playerState?.teamCode !==
        '00000000-0000-0000-0000-000000000000'
    ) {
      GetTeamChatHistory(
        playerState?.playerState?.teamCode,
        gameState.gameCode!
      ).then((response) => {
        response?.data.forEach((chat) => {
          newChats.push({
            author: {
              id: chat.playerCode,
              name: chat.playerName ?? '',
              avatarUrl: chat.playerAvatar ?? ''
            },
            timestamp: new Date(chat.sentDateUtc),
            group: playerState?.playerState?.teamCode,
            text: chat.message
          });
        });
        setMessages(newChats);
      });
    } else {
      setMessages([]);
    }
  };

  const getGroupChatHistory = async () => {
    let newChats: ChatState[] = [];
    if (
      playerState?.playerState?.teamCode &&
      playerState?.playerState?.teamCode !==
        '00000000-0000-0000-0000-000000000000'
    ) {
      const teamState = (await getTeamSelectedAsync(
        gameState.gameCode!,
        playerState?.playerState?.teamCode
      )) as GetTeamSelectedResponse;

      setGroupCode(teamState.groupCode!);
      GetGroupChatHistory(teamState.groupCode!, gameState.gameCode!).then(
        (response) => {
          response?.data.forEach((chat) => {
            newChats.push({
              author: {
                id: chat.playerCode,
                name: chat.playerName ?? '',
                avatarUrl: chat.playerAvatar ?? ''
              },
              timestamp: new Date(chat.sentDateUtc),
              group: teamState.groupCode,
              text: chat.message
            });
          });
          setMessages(newChats);
        }
      );
    } else {
      setMessages([]);
    }
  };

  const getFacilitatorChatAsync = async () => {
    let newChats: ChatState[] = [];
    GetPlayerFacilitatorChatHistoryAsync(
      gameState?.gameCode!,
      playerState?.playerState!.code!
    ).then((response) => {
      response?.forEach((chat) => {
        newChats.push({
          author: {
            id: chat.playerCode,
            name: chat.playerName ?? '',
            avatarUrl: chat.playerAvatar ?? ''
          },
          timestamp: new Date(chat.sentDateUtc),
          group: 'Facilitators',
          text: chat.message
        });
      });
      setMessages(newChats);
    });
  };

  const getChatBetweenPlayerAsync = async (chatWithPlayerCode: string) => {
    let newChats: ChatState[] = [];
    GetChatHistoryBetweeenPlayersAsync(
      gameState?.gameCode!,
      playerState?.playerState!.code!,
      chatWithPlayerCode
    ).then((response) => {
      response?.forEach((chat) => {
        newChats.push({
          author: {
            id: chat.playerCode,
            name: chat.playerName ?? '',
            avatarUrl: chat.playerAvatar ?? ''
          },
          timestamp: new Date(chat.sentDateUtc),
          group: chatWithPlayerCode,
          text: chat.message
        });
      });
      setMessages(newChats);
    });
  };

  const directPlayerChat = (playerCode: string) => {
    removeUnreadChat(
      gameState?.gameCode!,
      `DirectChat_${playerState.playerState?.code}_${playerCode}`
    );

    setActiveTab('Direct');
    setSelectedPlayer(playerCode!);
    getChatBetweenPlayerAsync(playerCode!);

    const totalUnreadDirectChat = getTotalUnreadDirectChat();
    setTotalUnreadDirectChat(totalUnreadDirectChat);
  };

  const handleSwitchToDirectChat = () => {
    let firstPlayerCode = '';
    if (otherPlayers.length > 0) {
      firstPlayerCode = otherPlayers[0].code!;
    }
    directPlayerChat(firstPlayerCode!);
  };

  const handleSwitch = (
    chatType: string,
    unreadCode: string,
    withPlayerCode?: string
  ) => {
    removeUnreadChat(gameState?.gameCode!, unreadCode);

    setActiveTab(chatType);

    if (chatType === 'All Player') {
      getGameChatHistory();
    } else if (chatType === 'Team') {
      getTeamChatHistory();
    } else if (chatType === 'Group') {
      getGroupChatHistory();
    } else if (chatType === 'Facilitators') {
      getFacilitatorChatAsync();
    } else {
      getChatBetweenPlayerAsync(withPlayerCode!);
    }
  };

  const getTotalUnreadDirectChat = () => {
    return getUnreadChatsCode(gameState?.gameCode!).filter((x) =>
      x.startsWith(`DirectChat_${playerState.playerState?.code}`)
    ).length;
  };

  const onChangePlayer = async (code: string) => {
    setSelectedPlayer(code);
    getChatBetweenPlayerAsync(code!);

    removeUnreadChat(
      gameState?.gameCode!,
      `DirectChat_${playerState.playerState?.code}_${code}`
    );

    const totalUnreadDirectChat = getTotalUnreadDirectChat();
    setTotalUnreadDirectChat(totalUnreadDirectChat);
  };

  const handleChatVisibility = () => {
    if (allPlayersGroup) {
      let visible = ShowGameChat(
        playerState?.playerState!,
        gameDocumentstate?.gameDocument!
      );
      setVisibleButtonChat(visible!);
    } else {
      let visible = ShowTeamChat(
        playerState?.playerState!,
        gameDocumentstate?.gameDocument!
      );
      setVisibleButtonChat(visible!);
    }
  };

  const handleEnabledDisabledTeamChat = () => {
    setVisibleButtonChat(!teamState.teamState?.isChatDisabled!);
  };

  const GetChatContactAsync = async () => {
    let contacts = await GetChatWithPlayersAsync(
      gameState?.gameCode!,
      playerState?.playerState?.code!
    );

    if (contacts) setChatContacts(contacts);
  };

  useEffect(() => {
    if (
      (activeTab === 'All Player' &&
        chatStateContext.group === gameState?.gameCode) ||
      (activeTab === 'Team' &&
        chatStateContext.group === playerState?.playerState?.teamCode) ||
      (activeTab === 'Group' &&
        chatStateContext.group === teamState.teamState?.groupCode) ||
      (activeTab === 'Facilitators' &&
        chatStateContext.group === 'Facilitators') ||
      (activeTab === 'Facilitators' &&
        chatStateContext.group === playerState?.playerState?.code) ||
      activeTab === 'Direct' ||
      activeTab === chatStateContext.group ||
      chatStateContext.group === playerState?.playerState?.code
    ) {
      let newMessages: Message[] = [...messages];
      newMessages.push(chatStateContext);
      setMessages(newMessages);
    }

    if (activeTab === 'All Player') {
      removeUnreadChat(
        gameState?.gameCode!,
        `GlobalChat_${gameDocumentstate?.gameCode}`
      );
    } else if (activeTab === 'Team') {
      removeUnreadChat(
        gameState?.gameCode!,
        `TeamChat_${teamState.teamState?.code}`
      );
    } else if (activeTab === 'Group') {
      removeUnreadChat(
        gameState?.gameCode!,
        `GroupChat_$${teamState.teamState?.groupCode}`
      );
    } else if (activeTab === 'Facilitators') {
      removeUnreadChat(
        gameState?.gameCode!,
        `PlayerChat_${playerState?.playerState?.code}_Facilitator`
      );
    } else if (activeTab === 'Direct') {
      removeUnreadChat(
        gameState?.gameCode!,
        `DirectChat_${playerState.playerState?.code}`
      );
    } else if (activeTab === chatStateContext.group) {
      removeUnreadChat(
        gameState?.gameCode!,
        `PlayerChat_${playerState?.playerState?.code}_${chatStateContext.group}`
      );
    }

    const totalUnreadDirectChat = getTotalUnreadDirectChat();
    setTotalUnreadDirectChat(totalUnreadDirectChat);
  }, [chatStateContext]);

  const getOtherPlayer = () => {
    const otherPlayers =
      gameState.gameState?.players
        .map((item) => {
          if (item.code !== playerState.playerState?.code) {
            const totalUnreadMessage = getUnreadChatsCode(
              gameState?.gameCode!
            ).filter((x) =>
              x.startsWith(
                `DirectChat_${playerState.playerState?.code}_${item.code}`
              )
            ).length;

            const player: ChatRoomPlayer = {
              ...item,
              totalUnreadMessage: totalUnreadMessage
            };

            return player;
          }

          return undefined;
        })
        .filter((player): player is ChatRoomPlayer => !!player)
        .sort((a, b) => a.code!.localeCompare(b.code!)) || [];

    setOtherPlayers(otherPlayers);
  };

  useEffect(() => {
    handleChatVisibility();
  }, [playerState?.playerState?.status]);

  useEffect(() => {
    handleEnabledDisabledTeamChat();
  }, [teamState.teamState?.isChatDisabled]);

  useEffect(() => {
    setActiveTab('All Player');
    getGameChatHistory();
    GetChatContactAsync();
  }, []);

  useEffect(() => {
    getOtherPlayer();
  }, [selectedPlayer, gameState.gameState?.players]);

  const handleDirectChatButton = useCallback((playerCode: string) => {
    directPlayerChat(playerCode);
  }, []);

  const renderMessageTemplate = useCallback(
    (props: ChatMessageTemplateProps) => (
      <MessageTemplate
        onClickDirectChatButton={handleDirectChatButton}
        currentUserCode={user.id!}
        {...props}
      />
    ),
    [handleDirectChatButton, user.id]
  );

  const chatTypeText = React.useCallback(
    (type: string) => {
      switch (type) {
        case 'All Player':
          return (
            generateTitleById(
              'bd2f061c-b665-4fe5-84ce-b986a1008a76',
              gameDocumentstate,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'All players'
          );

        case 'Team':
          return (
            generateTitleById(
              '6a6f31b6-fb91-42f8-acab-f469e0ef1d86',
              gameDocumentstate,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'My team'
          );

        case 'Group':
          return (
            generateTitleById(
              '8fd3fb0f-e237-4717-9272-98a84f4e9af4',
              gameDocumentstate,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'My group'
          );

        case 'Facilitators':
          return (
            generateTitleById(
              'fe72a8db-b666-4720-b08c-6081605cd24c',
              gameDocumentstate,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Facilitators'
          );

        default:
          return 'Player chat';
      }
    },
    [
      gameDocumentstate,
      displayLanguageContext.displayLanguageSelected.resources
    ]
  );

  return (
    <div className={'d-flex flex-column w-100 h-100 mh-100 pb-2'}>
      <CommonTab
        activeTab={activeTab}
        otherPlayers={otherPlayers}
        chatContacts={chatContacts}
        totalUnreadDirectChat={totalUnreadDirectChat}
        chatTypeText={chatTypeText}
        handleSwitch={handleSwitch}
        handleSwitchToDirectChat={handleSwitchToDirectChat}
      />
      <MobileTab
        activeTab={activeTab}
        otherPlayers={otherPlayers}
        selectedPlayer={selectedPlayer}
        totalUnreadDirectChat={totalUnreadDirectChat}
        chatTypeText={chatTypeText}
        handleSwitch={handleSwitch}
        onChangePlayer={onChangePlayer}
        handleSwitchToDirectChat={handleSwitchToDirectChat}
      />

      <div className="d-flex w-100 flex-grow-1">
        <ChatRoom
          messages={messages}
          activeTab={activeTab}
          playerList={otherPlayers}
          selectedPlayer={selectedPlayer}
          visibleButtonChat={visibleButtonChat}
          addNewMessage={addNewMessage}
          onChangePlayer={onChangePlayer}
        />
      </div>
    </div>
  );
};

export default ChatWindow;
