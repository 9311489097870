import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import { useInterval } from 'usehooks-ts';

import { GameState, GameStatus } from '../types/state';
import GameDialog from './dialog/game-dialog';
import { GameContext } from '../contexts/game';
import { PlayerContext } from '../contexts/player';
import { GameDocumentContext } from '../contexts/game-document';
import { UpdateGameStateAsync } from '../utils/game-engine/base';
import { DisplayLanguageContext } from '../contexts/display-languages';
import { generateTitleById } from '../utils/game-document/display-languages';
import { useParams } from 'react-router-dom';
import { useGame } from '../hooks/use-game';

const GameStatusOverlay: React.FC = () => {
  const { gameCode } = useParams();
  const displayTime: number = 2; // Task-4262, show resume notification for 2 seconds
  const [notificationDuration, setNotificationDuration] =
    React.useState<number>(displayTime);
  const [player] = React.useContext(PlayerContext);
  const [game, setGame] = React.useContext(GameContext);
  const [gameDocument] = React.useContext(GameDocumentContext);
  const [displayLanguageContext] = React.useContext(DisplayLanguageContext);

  const { data } = useGame(gameCode!);

  const gameStatus = ((game.gameState?.status === 'PreGame'
    ? data?.gameStatus
    : game.gameState?.status) || 'PreGame') as GameStatus;

  React.useEffect(() => {
    if (notificationDuration === 0) {
      const updatedGameState: GameState = cloneDeep({
        ...game.gameState!,
        isResume: undefined
      });
      setGame((prev) => UpdateGameStateAsync(prev, updatedGameState, true));
      setNotificationDuration(displayTime); // reset back to 2 seconds
    }
  }, [notificationDuration]);

  useInterval(
    () => {
      setNotificationDuration(notificationDuration - 1);
    },
    game.gameState && game.gameState.isResume ? 1000 : null
  );

  return (
    <>
      {gameStatus === 'Paused' && (
        <GameDialog
          title={'The facilitator has paused the game.'}
          subTitle={'You can still access the main menu.'}
          icon={'pause'}
        />
      )}
      {gameStatus === 'Running' && game.gameState?.isResume && (
        <GameDialog
          title={'The game has been resumed by the facilitator'}
          subTitle={`The game will continue in ${notificationDuration} second(s).`}
          icon={'play_arrow'}
        />
      )}
      {(gameStatus === 'Finished' ||
        gameStatus === 'PostGame' ||
        player.playerState?.status === 'kicked') && (
        <GameDialog
          showAssessment={game.activityState?.hasAssessment}
          title={
            generateTitleById(
              'f27a1675-58e4-4e01-93a0-f6a830eba04f',
              gameDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'The game has ended'
          }
          subTitle={
            generateTitleById(
              '6e07cb7a-3a2c-484f-9824-15d1db9629d0',
              gameDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'You can still access the main menu and chat.'
          }
        />
      )}
    </>
  );
};

export default GameStatusOverlay;
