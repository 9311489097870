import { Fade } from '@progress/kendo-react-animation';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTimeout } from 'usehooks-ts';
import { GameDocumentContext } from '../../contexts/game-document';
import { Container } from 'react-bootstrap';

const SplashScreen = () => {
  const navigate = useNavigate();
  const [show, setShow] = React.useState<boolean>(false);
  const [gameDocument] = useContext(GameDocumentContext);
  const contentSplash = show ? (
    <img src={gameDocument?.theme?.logoUrl}></img>
  ) : null;

  let animation = <Fade transitionEnterDuration={750}>{contentSplash}</Fade>;

  useEffect(() => {
    setShow(true);
  }, []);

  const navigateToLoby = () => {
    navigate(`../lobby`);
  };

  useTimeout(navigateToLoby, 2000);

  const onClickHandler = () => {
    navigateToLoby();
  };

  return (
    <Container className={'rounded shadow'}>
      <div className={'splash-image'} onClick={onClickHandler}>
        <div>{animation}</div>
      </div>
    </Container>
  );
};

export default SplashScreen;
