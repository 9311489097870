import React, { useState } from 'react';
import { ChatState } from '../types/state/websocket/chat-state';

export const nll: ChatState = {
  author: { id: '' },
  text: ''
};

const ChatContext = React.createContext<
  [
    initialState: ChatState,
    setState: React.Dispatch<React.SetStateAction<ChatState>>
  ]
>([nll, () => {}]);

const ChatProvider = (props: any) => {
  const [state, setState] = useState<ChatState>(nll);

  return (
    <ChatContext.Provider value={[state, setState]}>
      {props.children}
    </ChatContext.Provider>
  );
};

export { ChatContext, ChatProvider };
