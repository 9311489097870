import React from 'react';
import { useGame } from '../hooks/use-game';
import { useGameDocument } from '../hooks/use-game-document';
import { useAssessment } from '../hooks/use-assessment';
import { useAssessmentDocument } from '../hooks/use-assessment-document';
import { GameStateProvider } from './game';
import { GameDocumentProvider } from './game-document';
import { AssessmentDocumentProvider } from './assessment-document';
import { ChatProvider } from './chat';
import { NotificationProvider } from './notification';
import { FacilitatorPlayerChatProvider } from './facilitator-player-chat';
import { useGameTheme } from '../hooks/use-game-theme';

interface FacilitatorProviderProps {
  gameCode: string;
  children: React.ReactNode;
}

export const FacilitatorProvider = ({
  gameCode,
  ...props
}: FacilitatorProviderProps) => {
  // prefetch all data
  const game = useGame(gameCode);
  const gameDocument = useGameDocument(gameCode);
  const gameTheme = useGameTheme(gameCode);
  const assessment = useAssessment(gameCode);
  const assessmentDocument = useAssessmentDocument(gameCode);

  const isLoading =
    game.isLoading ||
    gameDocument.isLoading ||
    assessment.isLoading ||
    assessmentDocument.isLoading ||
    gameTheme.isLoading;

  // return loading state
  if (isLoading) return <>Loading...</>;

  // return context wrapper
  return (
    <GameStateProvider gameCode={gameCode!}>
      <GameDocumentProvider gameCode={gameCode!}>
        <AssessmentDocumentProvider assessmentCode={gameCode!}>
          <ChatProvider>
            <NotificationProvider>
              <FacilitatorPlayerChatProvider gameCode={gameCode!}>
                {props.children}
              </FacilitatorPlayerChatProvider>
            </NotificationProvider>
          </ChatProvider>
        </AssessmentDocumentProvider>
      </GameDocumentProvider>
    </GameStateProvider>
  );
};
