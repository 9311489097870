import { useContext, useEffect, useState } from 'react';
import { PlayerContext } from '../../contexts/player';
import { useInterval } from 'usehooks-ts';
import { UpdatePlayerContext } from '../../utils/player-state';
import { GameContext } from '../../contexts/game';

export const PlayerPosition = () => {
  const [game] = useContext(GameContext);
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [delay, setDelay] = useState<number>(15000);
  const [isPlaying, setPlaying] = useState<boolean>(false);

  useInterval(
    () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setPlayerState((prev) =>
              UpdatePlayerContext(prev, {
                ...prev.playerState!,
                coordinates: {
                  altitude: position.coords.altitude ?? 0,
                  longitude: position.coords.longitude ?? 0,
                  latitude: position.coords.latitude ?? 0,
                  heading: position.coords.heading ?? 0,
                  accuracy: position.coords.accuracy ?? 0
                }
              })
            );
          },
          (error) => {
            // What should we do here when timeout reach
          },
          { enableHighAccuracy: true, maximumAge: 10000, timeout: 10000 }
        );
      }
    },
    // Delay in milliseconds or null to stop it
    isPlaying ? delay : null
  );

  useEffect(() => {
    if (
      game.gameState?.status === 'Finished' ||
      game.gameState?.status === 'PostGame' ||
      game.gameState?.status === 'Paused' ||
      game.gameState?.status === 'PreGame'
    ) {
      setPlaying(false);
    } else if (
      game.gameState?.status === 'Running' ||
      game.gameState?.status === 'Starting'
    ) {
      setPlaying(true);
    }
  }, [game.gameState?.status]);

  return (
    <div
      style={{
        fontSize: '0.7rem',
        position: 'absolute',
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0, 0.5)',
        padding: '0.25rem',
        borderTopLeftRadius: '0.5rem'
      }}
      className={'text-break text-white m-0 overlay-debug'}>
      latitude: {playerState.playerState?.coordinates?.latitude} | longitude:{' '}
      {playerState.playerState?.coordinates?.longitude}
    </div>
  );
};
