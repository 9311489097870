import { Col, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { GameAlgorithm } from '../../../components/game-algorithm';
import { useContext, useEffect, useState } from 'react';
import {
  giveItem as teamGiveItem,
  giveScore as teamGiveScore,
  giveTitle as teamGiveTitle,
  navigateToWorldMap as teamNavigateToWorldMap,
  navigateToZone as teamNavigateToZone,
  showArea as teamShowArea,
  openTask as teamOpenTask,
  showTask as teamShowTask,
  pauseTimer as teamPauseTimer,
  startTimer as teamStartTimer
} from '../../../services/teams';
import {
  NotificationContent,
  NotificationContext
} from '../../../contexts/notification';
import { getTeamList } from '../../../services/teams';
import { GameContext } from '../../../contexts/game';
import { TeamResponse } from '../../../types/responses/team-response';

interface AllTeamFacilitatorStateProps {
  isDisableConfirm?: boolean;
}

interface AlgorithmState {
  operation?: string;
  argument?: string | string[];
  quantity?: number;
}

export const AllTeamFacilitatorState = ({
  isDisableConfirm = false
}: AllTeamFacilitatorStateProps) => {
  const [game] = useContext(GameContext);
  const [teams, setTeams] = useState<TeamResponse[]>([]);
  const [algorithmState, setAlgorithmState] = useState<AlgorithmState>({});
  const [notification, setNotification] = useContext(NotificationContext);

  const getAllTeams = async () => {
    let teamsResult = (await getTeamList(game?.gameCode!)) as TeamResponse[];
    setTeams(teamsResult);
  };

  const responseTeamNotification = (
    message: string,
    isError: boolean = false
  ) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: `${isError ? 'k-button--gradient-error' : 'k-button--gradient'}`
    };
    const content: NotificationContent[] = notification.content;
    content.push(responseTeamNotification);
    setNotification({ ...notification, content });
  };

  const onConfirmHandler = async () => {
    for (const team of teams) {
      const code = team.teamCode;

      switch (algorithmState?.operation) {
        case 'navigateToWorldMap':
          await teamNavigateToWorldMap(game.gameCode!, code!);
          break;
        case 'navigateToZone':
          await teamNavigateToZone(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string
          );
          break;
        case 'openTask':
          await teamOpenTask(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string
          );
          break;
        case 'showTask':
          await teamShowTask(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'hideTask':
          await teamShowTask(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'showArea':
          await teamShowArea(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'hideArea':
          await teamShowArea(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'startTimer':
          await teamStartTimer(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'stopTimer':
          await teamStartTimer(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'pauseTimer':
          await teamPauseTimer(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'resumeTimer':
          await teamPauseTimer(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'giveItem':
          await teamGiveItem(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            algorithmState?.quantity!,
            true
          );
          break;
        case 'removeItem':
          await teamGiveItem(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            algorithmState?.quantity! * -1,
            false
          );
          break;
        case 'giveScore':
          await teamGiveScore(
            game.gameCode!,
            code!,
            algorithmState?.quantity!,
            true
          );
          break;
        case 'removeScore':
          await teamGiveScore(
            game.gameCode!,
            code!,
            algorithmState?.quantity! * -1,
            false
          );
          break;
        case 'giveTitle':
          await teamGiveTitle(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'removeTitle':
          await teamGiveTitle(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        default:
          break;
      }
    }

    responseTeamNotification('Successfully sent the command');
  };

  const onChangeOperationHandler = (operation?: string) => {
    setAlgorithmState({ ...algorithmState, operation: operation });
  };

  const onChangeArgumentHandler = (selectedArguments?: string | string[]) => {
    setAlgorithmState({ ...algorithmState, argument: selectedArguments });
  };

  const onChangeQuantityHandler = (quantity?: number) => {
    setAlgorithmState({ ...algorithmState, quantity: quantity });
  };

  useEffect(() => {
    getAllTeams();
  }, []);
  return (
    <Row className={'d-flex flex-column gap-4'}>
      <Col md={{ span: 12 }} className={'pl-1'}>
        <hr className={'ml-2'} />
        <div>
          <label className={'pl-2 text-secondary'}>Task Description</label>
          <div
            className={
              'd-flex justify-content-between align-items-center w-100'
            }>
            <div>
              <GameAlgorithm
                onChangeOperation={onChangeOperationHandler}
                onChangeArgument={onChangeArgumentHandler}
                onChangeQuantity={onChangeQuantityHandler}
                disabled={isDisableConfirm}
              />
            </div>
            <Button
              themeColor={'success'}
              className={'w-8 ml-2'}
              onClick={onConfirmHandler}
              disabled={isDisableConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
