import { Button } from '@progress/kendo-react-buttons';
import React, { useState } from 'react';

export interface CounterProps {
  onChange?: (value: number) => void;
  disableAdd?: boolean;
  maxValue?: number;
}

const Counter = ({ ...props }: CounterProps) => {
  const [count, setCount] = useState<number>(0);

  const handleDecrement = () => {
    if (count == 0) return;

    if (props.onChange) {
      props.onChange(count - 1);
    }

    setCount(count - 1);
  };

  const handleIncrement = () => {
    if (props.maxValue) {
      if (count + 1 > props.maxValue) return;
    }

    if (props.onChange) {
      props.onChange(count + 1);
    }

    setCount(count + 1);
  };

  return (
    <div
      className={
        'd-flex justify-content-center align-items-center counter-button'
      }>
      <Button
        onClick={handleDecrement}
        className={'d-flex align-items-center'}
        themeColor={'dark'}
        size={'small'}>
        <span className="material-symbols-outlined font-size d-flex align-items-center">
          remove
        </span>
      </Button>
      <div className={'d-flex justify-content-center ml-2 mr-2'}>{count}</div>
      <Button
        disabled={props.disableAdd}
        onClick={handleIncrement}
        className={''}
        themeColor={'success'}
        size={'small'}>
        <span className="material-symbols-outlined font-size d-flex align-items-center">
          add
        </span>
      </Button>
    </div>
  );
};

export default Counter;
