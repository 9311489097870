import {
  RadioButton,
  RadioButtonChangeEvent
} from '@progress/kendo-react-inputs';
import React, { useState } from 'react';
import { uuid } from '../../types/common-helper';
interface RatingProps {
  value?: number;
  counter: number;
  className?: string;
  onChange?: (score?: number) => void;
}
export const Rating = ({ ...props }: RatingProps) => {
  const newId = uuid();
  const [value, setValue] = useState<number>(0);

  const handleChange = (value: number) => {
    if (props.onChange) {
      setValue(value);
      props.onChange(value);
    }
  };

  // Generate an array of radio buttons based on the counter value
  const radioButtons = Array.from({ length: props.counter }, (_, index) => (
    <div
      key={index}
      className={'d-flex flex-wrap'}
      onClick={() => handleChange(index + 1)}>
      <div
        className={`card border-none rating-container rounded-0 ${
          index + 1 === value ? 'bg-success' : ''
        }`}>
        <div
          className={`card-body rating-container-body fw-bold d-flex justify-content-center align-items-center ${
            index + 1 === value ? 'text-light' : 'text-success'
          }`}>
          <span>{(index + 1).toString()}</span>
        </div>
      </div>
    </div>
  ));
  return <div className={props?.className}>{radioButtons}</div>;
};
