import React, { useContext } from 'react';
import { Toast, ToastBody, ToastContainer } from 'react-bootstrap';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';

const NotificationAlert = () => {
  const [notification, setNotification] = useContext(NotificationContext);

  const handleOnClose = (data: NotificationContent, index: number): void => {
    notification.content[index].isHide = true;
    setNotification({ ...notification });

    if (data && data.onCloseCallback) data.onCloseCallback();
  };

  return (
    <ToastContainer
      className={'p-3'}
      position={notification.position}
      style={{
        zIndex: 3
      }}>
      {notification.content.map<JSX.Element>(
        (item: NotificationContent, index: number) => (
          <Toast
            onClose={() => handleOnClose(item, index)}
            show={!item.isHide}
            delay={item?.hideDelay ?? 5000}
            autohide={item?.autohide ?? true}
            className={`${item.color} py-2`}
            style={{ borderRadius: '10px' }}
            key={index}>
            <ToastBody
              className={
                'd-flex flex-row justify-content align-items-center text-white p-2'
              }>
              <div className={'mx-1'}>
                <span
                  className={'material-symbols-outlined'}
                  style={{ fontSize: '32px' }}
                  color={'white'}>
                  {item.icon}
                </span>
              </div>
              <div className={'mx-3 flex-grow-1'}>
                <span style={{ fontSize: '12px' }}>{item.message}</span>
              </div>
              {(item?.isShowCloseButton ?? true) && (
                <div className={'mx-1'}>
                  <span
                    role={'button'}
                    className={'material-symbols-outlined text-dark fw-bold'}
                    onClick={() => handleOnClose(item, index)}>
                    close
                  </span>
                </div>
              )}
            </ToastBody>
          </Toast>
        )
      )}
    </ToastContainer>
  );
};

export default NotificationAlert;
