import { useContext, useMemo } from 'react';
import { GameDocumentContext } from '../../contexts/game-document';
import { PlayerContext } from '../../contexts/player';
import { GetResourceValue } from '../../utils/game-document/resources';

/**
 * Renders the game long description.
 * @remarks Relies on game document context.
 */
export const GameDescription = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [player] = useContext(PlayerContext);
  const descriptionResource = useMemo(
    () =>
      GetResourceValue(
        gameDocument.gameDocument!,
        gameDocument.gameDocument!.overview?.longDescriptionResId!,
        player?.playerState?.language?.name! ?? ''
      ),
    [gameDocument.gameCode]
  );
  return <>{descriptionResource}</>;
};
