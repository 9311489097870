import { useContext, useEffect, useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { GameDocumentContext } from '../../../contexts/game-document';
import { GetTaskById, GetTasks } from '../../../utils/game-document/assets';
import { GetResourceValue } from '../../../utils/game-document/resources';
import TaskContentFormComboBox from '../../combobox/taskcontent-form-combobox';
import { TaskContentForm } from '../../../types/game-document/entities/task-content';
import { Row } from 'react-bootstrap';
import { TaskEntity } from '../../../types';
import React from 'react';
import { PlayerContextState } from '../../../contexts/player';
import { TaskCard } from './task-card';
import { Task } from '../../../types/game-document/entities/task';
import { GridMode, GridToolBar } from '../../grid/grid-tool-bar';
import { PDFExport } from '@progress/kendo-react-pdf';
import { ManualScoring } from '../../../types/manual-scoring';
interface TaskGridProps {
  playerStates?: PlayerContextState[];
  onViewChange?: (view: GridMode) => void;
  viewMode?: GridMode;
  onSubmitScore?: (e: ManualScoring) => void;
  onExport?: () => void;
}
export const TaskGrid = ({ playerStates, ...props }: TaskGridProps) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [tasks] = useState<Task[]>(GetTasks(gameDocument?.gameDocument) ?? []);
  const [selectedForm, setSelectedForm] = useState<TaskContentForm>();
  const [searchText, setSearchText] = useState<string>('');
  const pdfExportComponent = React.useRef<PDFExport>(null);
  useEffect(() => {
    if (!selectedTask) {
      if (tasks.length > 0) {
        setSelectedTask(tasks[0]);
      }
    }
  }, [tasks]);

  return (
    <>
      <div className={'d-flex mb-3 w-full'}>
        <GridToolBar
          className={'grid-toolbar w-full justify-content-end'}
          showSearch={false}
          showClear={false}
          searchPlaceholder={'Search'}
          columnsToSearch={['taskName', 'question', 'questionType', 'score']}
          onGridModeChange={(e) => {
            if (props.onViewChange) {
              props.onViewChange(e.gridMode);
            }
          }}
          viewMode={props.viewMode}
          handleExport={() => {
            if (props.onExport) {
              props.onExport();
            }
          }}
        />
      </div>
      <div className={'d-flex flex-wrap'}>
        <div className={'d-flex flex-column group-menu'}>
          <div className={'border-list'}>
            <div className={'p-1'}>
              <Input
                id={'search-text'}
                type={'text'}
                className={'form-control'}
                placeholder={'Search task..'}
                onChange={(e) =>
                  setSearchText((e.target.value as string) ?? '')
                }
              />
            </div>
            <ul className="list-group facilitator-border-none">
              {tasks
                .filter(
                  (x) =>
                    x.taskContentId !== undefined &&
                    x.taskContentId !== null &&
                    x.taskContentId !== '' &&
                    x.name?.toLowerCase()?.includes(searchText?.toLowerCase())
                )
                .map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="list-group-item cursor-pointer"
                      onClick={() => {
                        setSelectedTask(item);
                        setSelectedForm(undefined);
                      }}>
                      <div className={'d-flex justify-content-between'}>
                        <small
                          className={`${
                            item.id === selectedTask?.id
                              ? 'fw-bold text-dark'
                              : 'text-dark'
                          }`}>
                          {GetResourceValue(
                            gameDocument!.gameDocument!,
                            GetTaskById(gameDocument!.gameDocument!, item!.id!)!
                              .titleResId!,
                            ''
                          )}
                        </small>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>

        <div className={'group-content'}>
          <div className={'ml-5'}>
            <span className={'fw-bold'}>
              {GetResourceValue(
                gameDocument?.gameDocument!,
                selectedTask?.titleResId!,
                ''
              )}
            </span>

            <div className={'d-flex mt-3 flex-column'}>
              <span className={'text-dark'}>Select a question</span>
              <TaskContentFormComboBox
                className={'w-50'}
                taskContentId={selectedTask?.taskContentId}
                selectedFormId={selectedForm?.id}
                onValueChange={(e) => setSelectedForm(e as any)}
              />
            </div>
            <div className={'mt-4'}>
              <Row>
                {playerStates?.map(
                  (playerState: PlayerContextState, outerIndex: number) => {
                    return playerState?.playerState?.tasks
                      ?.filter((task) => task.id === selectedTask?.id)
                      .map((task: TaskEntity) => {
                        return task?.taskContentFormAnswers
                          ?.filter((x) => x.formId === selectedForm?.id)
                          ?.map((taskContentAnswer, taskContentAnswerIndex) => {
                            return (
                              <TaskCard
                                key={taskContentAnswerIndex}
                                taskContentAnswer={taskContentAnswer}
                                index={taskContentAnswerIndex}
                                selectedTask={selectedTask!}
                                playerName={playerState?.playerState?.name}
                                playerCode={playerState?.playerState?.code}
                                onSubmitScore={(e) => {
                                  if (props.onSubmitScore) {
                                    props.onSubmitScore(e);
                                  }
                                }}
                              />
                            );
                          });
                      });
                  }
                )}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
