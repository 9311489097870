import { PlayerContextState } from '../../../contexts/player';
import { PlayerStateFull } from '../../../types/state/player/player-state-full';
import { Task as TaskEntity } from '../../../types/entities/task';
import { merge } from 'lodash';

/**
 * Function to check if task is exist in playerState
 * @param player - The player context that save the engine data
 * @param id - The ID of task you want to search
 * @returns
 */
export const TaskExistsAsync = async (
  player: PlayerContextState,
  id: string
) => {
  return player.playerState?.tasks?.findIndex((task) => task.id === id) !== -1;
};

/**
 * Get task engine by id.
 * @param player - The player context that save the engine data
 * @param id - The ID of task you want to search
 * @constructor
 */
export const GetTaskById = (player: PlayerContextState, id: string) => {
  return player.playerState?.tasks?.find((x) => x.id === id);
};

/**
 * Function to add new task to player context
 * @param player - The player context that save the engine data
 * @param task - new Task that you want to add into player context
 * @returns
 */
export const AddTask = (player: PlayerContextState, task: TaskEntity) => {
  let tasks = player.playerState?.tasks ?? [];
  tasks.push(task);

  return MergeTask(player.playerState!, tasks);
};

/**
 * Function to add new task to player context [Async]
 * @param player - The player context that save the engine data
 * @param task - new Task that you want to add into player context
 * @returns
 */
export const AddTaskAsync = async (
  player: PlayerContextState,
  task: TaskEntity
) => {
  let tasks = player.playerState?.tasks ?? [];
  tasks.push(task);

  return MergeTask(player.playerState!, tasks);
};

/**
 * Function to update task in player context
 * @param player - The player context that save the engine data
 * @param taskId - The task ID you want to update
 * @param task - Updated task
 * @returns
 */
export const UpdateTaskAsync = (
  player: PlayerContextState,
  taskId: string,
  task: TaskEntity
) => {
  let tasks = player?.playerState?.tasks ?? [];
  let taskIndex = tasks.findIndex((z) => z.id === taskId)!;
  tasks[taskIndex] = task;
  return MergeTask(player?.playerState!, tasks);
};

export const MergeTask = (
  player: PlayerStateFull,
  tasks: Array<TaskEntity>
) => {
  return merge(player, { tasks });
};

/**
 * Function to calculate the sum of an array of numbers
 * @param numbers - array of numbers
 * @returns number
 */
export const CalculateTotal = (numbers: number[]) => {
  const total = numbers?.reduce((accumulator, currentNumber) => {
    const validNumber = typeof currentNumber === 'number' ? currentNumber : 0;
    return accumulator + validNumber;
  }, 0);

  return total;
};
