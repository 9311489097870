import React from 'react';
import UserMenu from './user-menu';
import { useAuth } from 'react-oidc-context';
import ProfileDialogWindow from '../dialog/profile-dialog';
import UserProfile from '../profile/user-profile';
import { useNavigate } from 'react-router-dom';

export const UserIcon = () => {
  const auth = useAuth();

  const avatarRef = React.useRef(null);
  const navigate = useNavigate();
  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const [showProfileWindow, setShowProfileWindow] = React.useState(false);
  const getQueryParam = (name: string): string | null => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(name);
  };
  const showUserProfile = getQueryParam('showtype');

  const hideProfileWindow = () => {
    navigate('./');
    setShowProfileWindow(false);
  };

  // when the avatar is clicked, show the user menu.
  const onAvatarClick = () => setShowUserMenu(!showUserMenu);

  return (
    <>
      {auth.user && (
        <>
          <div className={'header__user-icon'}>
            <button
              className={'btn btn-flat profile-widget d-flex'}
              ref={avatarRef}
              onClick={onAvatarClick}>
              <>
                {auth.user?.profile.avatar && (
                  <img src={auth.user?.profile?.avatar as string} />
                )}
              </>
            </button>
          </div>
          <UserMenu
            avatarRef={avatarRef}
            showUserMenu={showUserMenu}
            showUserWindowByUrl={showUserProfile ? true : false}
            activeTabUserWindowByUrl={
              showUserProfile === 'user-support' ? 'Support' : 'Notifications'
            }
            onBlurUserMenuTimeout={() => setShowUserMenu(false)}
          />
          {showUserProfile && showProfileWindow && (
            <ProfileDialogWindow
              onClose={hideProfileWindow}
              width={'70vw'}
              height={'75vh'}
              className={'profile-window p-0'}>
              <div className={'d-flex w-100 h-100 position-relative'}>
                <span
                  className={
                    'position-absolute top-0 end-0 cursor-pointer p-2 mr-5 z-index-100'
                  }
                  onClick={hideProfileWindow}>
                  <span className={'material-symbols-outlined'}>close</span>
                </span>
                <UserProfile
                  onToggleDialog={hideProfileWindow}
                  selectedMenuItemName={
                    showUserProfile === 'user-support'
                      ? 'Support'
                      : 'Notifications'
                  }
                />
              </div>
            </ProfileDialogWindow>
          )}
        </>
      )}
    </>
  );
};

export default UserIcon;
