import React, { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { TeamStateFull } from '../types/state';
import { uuid } from '../types/common-helper';

const TeamContext = React.createContext<
  [
    initialState: TeamContextState,
    setState: React.Dispatch<React.SetStateAction<TeamContextState>>
  ]
>([{ isLoaded: false, isDirty: false }, () => {}]);

interface TeamStateProviderProps {
  gameCode: string;
  children: React.ReactNode;
}

const TeamStateProvider = ({ gameCode, ...props }: TeamStateProviderProps) => {
  const [state, setState] = useLocalStorage<TeamContextState>(
    `${gameCode}-team`,
    {
      gameCode: gameCode,
      teamState: BuildNewTeamState(gameCode),
      isLoaded: false,
      isDirty: false
    }
  );

  useEffect(() => {
    if (!state.isLoaded) {
      setState((state) => ({
        ...state,
        isLoaded: true,
        teamState: BuildNewTeamState(gameCode)
      }));
    }
  });

  return (
    <TeamContext.Provider value={[state, setState]}>
      {props.children}
    </TeamContext.Provider>
  );
};

export interface TeamContextState {
  gameCode?: string;
  teamState?: TeamStateFull;
  isLoaded?: boolean;
  isDirty: boolean;
}

export const BuildNewTeamState = (gameCode: string): TeamStateFull => {
  return {
    code: uuid(),
    name: '',
    avatarImage: '',
    status: 'waiting',
    score: 0,
    coordinates: {
      latitude: 0,
      longitude: 0
    },
    location: '',
    zones: [],
    areas: [],
    inventory: [],
    titles: [],
    timers: []
  };
};

export { TeamContext, TeamStateProvider };
