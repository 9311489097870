import { getHeight, getWidth } from 'ol/extent';
import { Style, Fill, Text, Stroke, Icon } from 'ol/style';
import {
  DefaultFontFamily,
  DefaultStrokeColor,
  DefaultTextcolor
} from '../constants/map';

export const ScaleImage = (imageUrl: string) => {
  const defaultResolution = 256; // resolution of default image task (256 x 256)
  const defaultScale = 0.25; // size of default scale
  const img = new Image();
  img.src = imageUrl;
  const imgResolution = Math.sqrt(img.naturalWidth * img.naturalHeight);

  // return default scale if img resolution lower than default image resolution
  if (imgResolution <= defaultResolution) return defaultScale;

  return defaultScale / (imgResolution / defaultResolution);
};

export const GetPointStyle = (imageUrl: string, taskName: string) => {
  let imageStyle = new Style({
    image: new Icon({
      anchor: [0.5, 0.4],
      opacity: 1,
      scale: ScaleImage(imageUrl),
      src: imageUrl
    }),
    fill: new Fill({ color: [0, 0, 1, 0.6] })
  });

  return [
    imageStyle,
    new Style({
      text: new Text({
        text: taskName,
        offsetY: -40,
        fill: new Fill({
          color: DefaultTextcolor
        }),
        stroke: new Stroke({
          color: DefaultStrokeColor,
          width: 10
        }),
        font: DefaultFontFamily // Set the font weight and size
      })
    })
  ];
};

export const calculateCenter = (geometry: any) => {
  let center1: any, coordinates, minRadius;
  const type = geometry.getType();
  if (type === 'Polygon') {
    let x = 0;
    let y = 0;
    let i = 0;
    coordinates = geometry.getCoordinates()[0].slice(1);
    coordinates.forEach(function (coordinate: any) {
      x += coordinate[0];
      y += coordinate[1];
      i++;
    });
    center1 = [x / i, y / i];
  }
  let sqDistances;
  if (coordinates) {
    sqDistances = coordinates.map(function (coordinate: any) {
      const dx = coordinate[0] - center1[0];
      const dy = coordinate[1] - center1[1];
      return dx * dx + dy * dy;
    });
    minRadius = Math.sqrt(Math.max.apply(Math, sqDistances)) / 3;
  } else {
    minRadius =
      Math.max(
        getWidth(geometry.getExtent()),
        getHeight(geometry.getExtent())
      ) / 3;
  }
  return {
    center: center1,
    coordinates: coordinates,
    minRadius: minRadius,
    sqDistances: sqDistances
  };
};
