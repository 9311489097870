import { Outlet } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/cg-icon-globe.svg';
import React from 'react';

/**
 * The layout for public unstructured pages. E.g. Privacy policy and TAC.
 * @returns
 */
export const ErrorsLayout = () => {
  return (
    <>
      <div className={'layout layout--errors'}>
        <div className={'layout__left'}>
          <Outlet />
        </div>
        <div className={'layout__right'}>
          <Logo className={'logo'} style={{ width: 100 }} />
        </div>
      </div>
    </>
  );
};

export default ErrorsLayout;
