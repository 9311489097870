import React, { useContext, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import Popup from '../popup-window';
import { PDFExport } from '@progress/kendo-react-pdf';
import { AssessmentExport } from '../../features/facilitator/assessment-export';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import useScreenSize from '../../hooks/use-screen-size';
import { Assessment } from '../../types/assessment';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';

interface AssessmentDialogProps {
  title: string;
  subTitle: string;
  showResult?: boolean;
  assessment?: Assessment;
}

const Dialog: React.FC<{
  logoUrl: string | undefined;
  title: string;
  subTitle: string;
  showDownloadButton: boolean;
  handleExport: () => void;
}> = ({ logoUrl, title, subTitle, showDownloadButton, handleExport }) => {
  const { playerState } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  return (
    <div
      className={
        'modal waiting-modal d-flex flex-column align-items-center justify-content-center'
      }>
      <div
        className={
          'd-flex flex-column align-items-center justify-content-center waiting-content'
        }>
        <img
          width={logoUrl ? 100 : 250}
          height="auto"
          src={logoUrl ? logoUrl : '/assessment/assessment-team-logo.png'}
          className={`position-relative ${
            logoUrl ? 'rounded-circle bg-white w-10' : ''
          } mb-5`}
          alt={logoUrl ? logoUrl : 'assessment-team-logo'}
        />

        <h1 className={'fw-bold text-primary'}>{title}</h1>
        <h3 className={'text-dark mt-3'}>{subTitle}</h3>
        {showDownloadButton && (
          <Button
            className={'mt-3'}
            themeColor={'success'}
            onClick={handleExport}>
            {generateTitleById(
              '95065f71-e1f5-4375-8663-89e95c331d95',
              playerState,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Download report'}
          </Button>
        )}
      </div>
    </div>
  );
};

const AssessmentDialog = ({
  title,
  subTitle,
  showResult,
  assessment
}: AssessmentDialogProps) => {
  const { playerState } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const [showExport, setShowExport] = useState<boolean>(false);
  const [exportClicked, setExportClicked] = useState<boolean>(false);
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const screenSize = useScreenSize();

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const toggleExportClicked = () => {
    setTimeout(
      () => {
        setExportClicked(!exportClicked);
      },
      exportClicked ? 500 : 0
    );
  };

  const handleExport = () => {
    if (screenSize.width <= 767) {
      toggleExportClicked();
      exportPDFWithComponent();
    } else {
      setShowExport(true);
    }
  };

  return (
    <>
      {!showResult && (
        <Dialog
          logoUrl={playerState ? playerState?.assessmentDocument?.logoUrl : ''}
          title={title}
          subTitle={subTitle}
          showDownloadButton={false}
          handleExport={handleExport}
        />
      )}

      {showResult &&
        assessment &&
        (assessment.settings?.hideFinalResultsFromParticipant ? (
          <Dialog
            logoUrl={
              playerState ? playerState?.assessmentDocument?.logoUrl : ''
            }
            title={title}
            subTitle={subTitle}
            showDownloadButton={
              !assessment.settings?.hideDownloadReportFromParticipant
            }
            handleExport={handleExport}
          />
        ) : (
          <div className={'p-4'}>
            <AssessmentExport
              assessment={assessment as any}
              isExportClicked={exportClicked}
            />
          </div>
        ))}

      {/* display pdf export in mobile screen with visually hidden */}
      {screenSize.width <= 767 &&
        showResult &&
        assessment &&
        !assessment.settings?.hideDownloadReportFromParticipant && (
          <div
            className={`box assessment-export d-flex flex-column gap-3 position-absolute`}
            style={{
              left: '9999px'
            }}>
            <PDFExport
              ref={pdfExportComponent}
              paperSize="A4"
              margin={40}
              scale={0.419}
              forcePageBreak={'.page-break'}
              fileName={`Report assessment ${new Date().toDateString()}`}>
              <>
                <AssessmentExport
                  assessment={assessment as any}
                  isExportClicked={exportClicked}
                />
              </>
            </PDFExport>
          </div>
        )}

      {showExport && assessment && (
        <Popup
          handleClose={() => setShowExport(false)}
          width={'800'}
          className={
            'box assessment-export d-flex flex-column position-relative'
          }>
          <div
            className={'position-absolute d-flex justify-content-center w-full'}
            style={{
              height: '64px',
              top: '56px',
              padding: '0 30px'
            }}>
            <div
              className={
                'd-flex justify-content-end align-items-center w-full'
              }>
              <Button
                themeColor={'success'}
                style={{
                  height: 'fit-content'
                }}
                onClick={exportPDFWithComponent}
                onMouseDown={toggleExportClicked}
                onMouseUp={toggleExportClicked}>
                {generateTitleById(
                  '95065f71-e1f5-4375-8663-89e95c331d95',
                  playerState,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'assessment'
                ) || 'Download report'}
              </Button>
            </div>
          </div>
          <PDFExport
            ref={pdfExportComponent}
            paperSize="A4"
            margin={40}
            scale={0.419}
            forcePageBreak={'.page-break'}
            fileName={`Report assessment ${new Date().toDateString()}`}>
            <>
              <AssessmentExport
                assessment={assessment as any}
                isExportClicked={exportClicked}
              />
            </>
          </PDFExport>
        </Popup>
      )}
    </>
  );
};

export default AssessmentDialog;
