import React, { useContext, useState } from 'react';
import { TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { PlayerContext } from '../../contexts/player';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { postAssementMeasurableAsync } from '../../services/assessments';
import { Measurable } from '../../types/state/assessment/assessment-players-answer-state';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { generateTitleById } from '../../utils/game-document/display-languages';
interface OutcomeProps {
  measurables?: Measurable[];
  showDetails?: boolean;
  isFacilitator?: boolean;
  children?: React.ReactNode;
}
export const AssessmentAddMeasurables = ({ ...props }: OutcomeProps) => {
  const [playerState] = useContext(PlayerContext);
  const { playerState: assessmentDocument } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const [measurable, setMeasurable] = useState<string>('');
  const onSubmitHandler = async () => {
    try {
      await postAssementMeasurableAsync(
        assessmentDocument?.assessmentCode,
        props?.isFacilitator
          ? '00000000-0000-0000-0000-000000000000'
          : playerState?.assessmentState?.code,
        measurable
      );
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setMeasurable('');
    }
  };

  return (
    <>
      <div className={'d-flex flex-column w-100'}>
        <div>
          <TextArea
            rows={5}
            className={'mr-1 flex-grow-1'}
            placeholder={`${
              generateTitleById(
                '19848afc-259e-49ec-bd1a-b5789356e999',
                assessmentDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'assessment'
              ) || 'Comment here'
            }...`}
            value={measurable}
            onChange={(e) => setMeasurable(e.target.value as string)}
          />
        </div>
        <div className={'d-flex justify-content-end mt-2'}>
          <Button themeColor={'primary'} onClick={onSubmitHandler}>
            {generateTitleById(
              '68fe4076-4770-4624-8b14-fda0e1797cda',
              assessmentDocument,
              displayLanguageContext.displayLanguageSelected.resources!,
              'assessment'
            ) || 'Add'}
          </Button>
        </div>
      </div>
      {props.showDetails && (
        <div className={'d-flex flex-column w-100 mt-2'}>
          {props?.measurables?.map((item, index) => (
            <div className="card mt-2" key={index}>
              <div className="card-body text-dark">
                <div className={'d-flex flex-wrap'}>
                  <div className={'d-flex w-75 flex-column'}>
                    <span>{item?.comment}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
