import React from 'react';
import { fromLonLat, toLonLat } from 'ol/proj';
import { boundingExtent, containsCoordinate } from 'ol/extent';

import { GameContext } from '../contexts/game';
import { MapContext } from '../components/map/map-context';

export const useGameHighlight = () => {
  const map = React.useContext(MapContext);
  const [game] = React.useContext(GameContext);

  const getPlayer = React.useCallback(
    (code: string) => {
      if (game.gameState) {
        const player = game.gameState.players.find(
          (player) => player.code === code
        );

        return player;
      }
    },
    [game.gameState]
  );

  const addHighlightClass = React.useCallback((playerCode: string) => {
    const playerName = document.getElementById(`playerName-${playerCode}`);
    if (playerName) {
      const playerContainer = playerName.parentElement?.parentElement;
      playerContainer?.classList.add('highlighted', 'blip');
      setTimeout(() => {
        playerContainer?.classList.remove('blip');
      }, 5000);
    }
  }, []);

  const removeHighlightClass = React.useCallback(() => {
    const highlighted = document.querySelectorAll(
      '.player-container.highlighted'
    );
    for (let i = 0; i < highlighted.length; i++) {
      highlighted[i].classList.remove('highlighted', 'blip');
    }
  }, []);

  const highlightPlayer = React.useCallback(
    (playerCode: string) => {
      if (map) {
        const player = getPlayer(playerCode);
        if (player) {
          const position = [
            player.coordinates?.longitude ?? 0,
            player.coordinates?.latitude ?? 0
          ];

          const view = map.getView();
          const currentCenter = toLonLat(view.getCenter()!);
          const currentZoom = view.getZoom();

          const midPoint = [
            (currentCenter[0] + position[0]) / 2,
            (currentCenter[1] + position[1]) / 2
          ];

          const extent = view.calculateExtent(map.getSize());
          const isPlayerInView = containsCoordinate(
            extent,
            fromLonLat(position)
          );

          view.animate(
            {
              ...(!isPlayerInView && {
                center: fromLonLat(midPoint),
                zoom: (currentZoom ?? 17) - 2,
                duration: 1000
              })
            },
            {
              center: fromLonLat(position),
              zoom: 17,
              duration: 1000
            }
          );

          removeHighlightClass();
          addHighlightClass(playerCode);
        }
      }
    },
    [map, getPlayer, removeHighlightClass, addHighlightClass]
  );

  const highlightTeam = React.useCallback(
    (playersCode: string[]) => {
      if (map) {
        const players = playersCode.map((code) => getPlayer(code));
        const playersPosition = players.map((player) =>
          fromLonLat([
            player?.coordinates?.longitude ?? 0,
            player?.coordinates?.latitude ?? 0
          ])
        );
        const extent = boundingExtent(playersPosition);

        const view = map.getView();
        view.fit(extent, {
          duration: 1000,
          maxZoom: 17,
          padding: [50, 50, 50, 50]
        });

        removeHighlightClass();
        playersCode.forEach((code) => addHighlightClass(code));
      }
    },
    [map, getPlayer, removeHighlightClass, addHighlightClass]
  );

  return { removeHighlightClass, highlightPlayer, highlightTeam };
};
