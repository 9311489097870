import { Col, Row } from 'react-bootstrap';
import { useContext } from 'react';
import { PlayerContext } from '../../contexts/player';
import { getResourceDescription } from '../../utils/assessment-state/resource';
import { AssessmentEntity } from '../../types/state/assessment/assessment';
import { generateTitleById } from '../../utils/game-document/display-languages';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from '../../contexts/assessment-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';
interface AssessmentPurposeStatementProps {
  assessment?: AssessmentEntity;
  textClass?: string;
}
export const AssessmentPurposeStatement = ({
  ...props
}: AssessmentPurposeStatementProps) => {
  const { playerState: assessmentDocument } = useContext(
    AssessmentDocumentContext
  ) as AssessmentDocumentContextProps;
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const [playerState] = useContext(PlayerContext);
  return (
    <>
      {props?.assessment?.settings?.showPurposeStatement && (
        <Row>
          <Col>
            <div className={'d-flex flex-column card p-3'}>
              <span className={'fw-bold text-dark'}>
                {generateTitleById(
                  'dbadbbfd-0dd7-4bfb-a7a2-daa93937ba95',
                  assessmentDocument,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'assessment'
                ) || 'Purpose statement'}
                :
              </span>
              <span className={props.textClass ?? ''}>
                {getResourceDescription(
                  props?.assessment?.descriptionResId!,
                  props?.assessment!,
                  playerState?.assessmentState?.languageId ?? ''
                )}
              </span>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
