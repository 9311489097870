import React, { useContext } from 'react';
import { RadioButton } from '@progress/kendo-react-inputs';
import { FeedbackDetailOptions } from '../../types/feedback-document';
import { GetFeedbackResourceValue } from '../../utils/feedback-document';
import { FeedbackDocumentContext } from '../../contexts/feedback-document';

type AnswerOptionProps = {
  group?: string;
  options?: FeedbackDetailOptions[];
  answerOptionSelected?: number;
  isCorrectAnswer?: boolean;
  taskId?: string;
  formId?: string;
  onChangeOptionIndex?: (index: number) => void;
};
const AnswerRadio = ({
  group,
  options,
  answerOptionSelected: answerOptionIndex,
  onChangeOptionIndex
}: AnswerOptionProps) => {
  const [state] = useContext(FeedbackDocumentContext);
  const onRadioButtonChange = (index: number) => {
    if (onChangeOptionIndex) {
      onChangeOptionIndex(index);
    }
  };

  return (
    <div id={'answer-radio'}>
      {options &&
        options.map((option: FeedbackDetailOptions, index: number) => {
          const labelOption = GetFeedbackResourceValue(
            state?.feedbackDocument!,
            option?.optionResId,
            state?.selectedLanguageId
          );
          return (
            <div
              className={`d-flex flex-row justify-content-center align-items-center card border border-2 w-100 my-2 border-dark`}
              key={index}>
              <div className={'card-body flex-grow-1'}>
                <RadioButton
                  id={`radio-option-${index}`}
                  name={group}
                  className={`border border-2 border-dark ${
                    index === answerOptionIndex && 'bg-success'
                  }`}
                  size={'large'}
                  checked={option.checked ?? false}
                  onChange={() => onRadioButtonChange(index)}
                  label={labelOption}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default AnswerRadio;
