import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { DrawerItem, DrawerItemProps } from '@progress/kendo-react-layout';

import { PlayerAvatar } from '../../../player-avatar';
import { OverlayContext } from '../../../../../contexts/overlay';

const Item: React.ComponentType<DrawerItemProps> = (props) => {
  const { visible, ...rest } = props;
  const [, setOverlay] = React.useContext(OverlayContext);

  const toggleOverlay = () => {
    setOverlay((prevState) => ({
      ...prevState,
      drawerMobileIsOpen: !prevState.drawerMobileIsOpen
    }));
  };

  const toggleProfileDialog = () =>
    setOverlay((prev) => ({
      ...prev,
      profileDialogIsOpen: !prev.profileDialogIsOpen
    }));

  return (
    <div className="px-3">
      {props.index === 0 ? (
        <div
          className={'d-flex justify-content-between drawer-mobile-profile'}
          style={{ height: '80px' }}>
          <div className={'d-flex align-items-center'}>
            <PlayerAvatar onClick={toggleProfileDialog} />
          </div>

          <div className={'d-flex align-items-center justify-content-center'}>
            <Button fillMode={'flat'} size={'large'} onClick={toggleOverlay}>
              <div className={'d-flex'}>
                <span className={'material-symbols-outlined'}>menu</span>
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <div onClick={toggleOverlay}>
          <DrawerItem {...props}>
            <div className={'d-flex align-items-center gap-2'}>
              <span
                className="material-symbols-outlined icon"
                style={{
                  fontSize: '26px'
                }}>
                {props.icon}
              </span>
              <span>{props.text}</span>
            </div>
          </DrawerItem>
        </div>
      )}
    </div>
  );
};

export default Item;
