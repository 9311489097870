import { useEffect, useState } from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

import ComboboxInput from '../forms/combobox-input';
import SelectionItem from '../../types/selection-item';

interface ArgumentProps {
  argumentList?: SelectionItem[];
  selectedList?: string;
  showQuantity?: boolean;
  onChangeArgument?: (selectedArguments?: string | string[]) => void;
  onChangeQuantity?: (quantity?: number) => void;
}

export const ArgumentList = ({
  argumentList,
  selectedList = '',
  showQuantity = false,
  onChangeArgument = () => {},
  onChangeQuantity = () => {}
}: ArgumentProps) => {
  const [selectedArgument, setSelectedArgument] = useState<SelectionItem>({});

  const onChangeArgumentHandler = (e: ComboBoxChangeEvent) => {
    onChangeArgument(e.target?.value?.id);
    setSelectedArgument(e.target?.value);
  };

  useEffect(() => {
    setSelectedArgument({});
  }, [argumentList]);
  return (
    <>
      {selectedList && (
        <ComboboxInput
          data={argumentList!}
          textField={'name'}
          dataItemKey={'id'}
          value={selectedArgument}
          className={'w-20 border border-dark'}
          onChange={(e) => onChangeArgumentHandler(e)}
        />
      )}
      {showQuantity && (
        <NumericTextBox
          width={100}
          placeholder={'00'}
          className={'border border-dark ml-2'}
          onChange={(e) => onChangeQuantity(e.target.value!)}
          min={0}
        />
      )}
    </>
  );
};
