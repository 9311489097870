import * as React from 'react';

import {
  Chart,
  ChartTooltip,
  ChartSeries,
  ChartSeriesItem,
  TooltipContext,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltipProps,
  ChartCategoryAxis,
  ChartCategoryAxisItem
} from '@progress/kendo-react-charts';
import { DataRangeChart } from '../../types/assessment';

interface RangeBarChart {
  data?: DataRangeChart[];
  maxTitle?: string;
  minTitle?: string;
  isExported?: boolean;
  maxValue?: number;
}

export const RangeBarChart = ({ ...props }: RangeBarChart) => {
  const [chartData, setChartData] = React.useState<
    (DataRangeChart & {
      series1: number;
      series2: number;
      series3: number;
      series4: number;
    })[]
  >([]);
  const getValue = (title: string, prop: 'avg' | 'min' | 'max') => {
    const selectedOutcome = props.data?.find(
      (outcome) => outcome.title === title
    );
    return selectedOutcome ? selectedOutcome[prop] : '';
  };

  const tooltipRender = (toolTipsProps: TooltipContext) => {
    return (
      <div>
        {props?.minTitle} :{' '}
        {getValue(toolTipsProps.point.category as string, 'min')}
        <br />
        {props?.maxTitle} :{' '}
        {getValue(toolTipsProps.point.category as string, 'max')}
        <br />
        Average : {getValue(toolTipsProps.point.category as string, 'avg')}
      </div>
    );
  };

  React.useEffect(() => {
    const emptySeries = { series1: 0, series2: 0, series3: 0, series4: 0 };
    let newData: typeof chartData = [
      ...(props.data?.map((data) => ({
        ...data,
        ...emptySeries
      })) ?? [])
    ];
    if (newData.length === 0)
      newData.push({
        id: '',
        title: '',
        min: 9,
        max: 9,
        avg: '0',
        ...emptySeries
      });

    if (newData.length > 0) {
      newData = newData.map((data) => {
        let series1 = data.min || 0;
        let series2 = 0;
        let series3 = 0.25;
        let series4 = 0;

        if (data.max! - data.min! <= 1) {
          series2 = (1 - series3) / 2;
          series4 = series2;
        } else {
          const range = data.max! - data.min!;
          series2 = (range - series3) / 2;
          series4 = series2;
        }

        return { ...data, series1, series2, series3, series4 };
      });
    }
    setChartData(newData);
  }, [props.data]);

  return (
    <Chart transitions={false} className={'h-100'}>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={chartData?.map((data) => data.title)}
          labels={{
            font: `normal ${
              props.isExported ? '20px' : '12px'
            } Arial, sans-serif`
          }}
        />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem
          {...(props.maxValue && { max: props.maxValue })}
          labels={{
            format: '{0}',
            font: `normal ${
              props.isExported ? '20px' : '12px'
            } Arial, sans-serif`
          }}
        />
      </ChartValueAxis>
      <ChartSeries>
        <ChartSeriesItem
          type="bar"
          stack={true}
          data={chartData.map((dataItem) => dataItem.series1)}
          color="transparent"
          border={{ width: 0 }}
        />
        <ChartSeriesItem
          type="bar"
          stack={true}
          data={chartData.map((dataItem) => dataItem.series2)}
          color="#70ad46"
          border={{ width: 0.5, color: '#70ad46' }}
        />
        <ChartSeriesItem
          type="bar"
          stack={true}
          data={chartData.map((dataItem) => dataItem.series3)}
          color="#66afe4"
          border={{ width: 0.5, color: '#66afe4' }}
        />
        <ChartSeriesItem
          type="bar"
          stack={true}
          data={chartData.map((dataItem) => dataItem.series4)}
          color="#70ad46"
          border={{ width: 0.5, color: '#70ad46' }}
        />
      </ChartSeries>
      <ChartTooltip render={tooltipRender as ChartTooltipProps['render']} />
    </Chart>
  );
};
