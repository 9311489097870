import { Col } from 'react-bootstrap';
import { Task, TaskContentAnswer } from '../../../types/entities/task';
import { TaskContentAnswerEntity } from '../../../types';
import { GetTaskContentById } from '../../../utils/game-document/assets/task-contents';
import { GameDocumentContext } from '../../../contexts/game-document';
import { useContext, useState } from 'react';
import { GetResourceValue } from '../../../utils/game-document/resources';
import { ManualScoring } from '../../../types/manual-scoring';
import React from 'react';
import Popup from '../../popup-window';
import { DetailPlayer } from '../detail-player';
import { roundingDecimalNumber } from '../../../utils/number';
import { GetTaskById } from '../../../utils/game-document/assets';

interface TaskCardProps {
  playerCode?: string;
  playerName?: string;
  taskContentAnswer?: TaskContentAnswer;
  index?: number;
  selectedTask?: Task;
  isEdit?: boolean;
  onSubmitScore?: (e: ManualScoring) => void;
}
export const TaskCard = ({ isEdit = true, ...props }: TaskCardProps) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [selectedAnswer, setSelectedAnswer] = useState<
    ManualScoring | undefined
  >();

  const onSelectEdit = (
    playerCode?: string,
    taskId?: string,
    formId?: string,
    answer?: string,
    isScored?: boolean,
    score?: number
  ) => {
    let answerSelected: ManualScoring = {
      playerName: props?.playerName,
      gameCode: gameDocument?.gameCode,
      playerCode: playerCode!,
      taskId: taskId,
      formId: formId,
      answer: answer,
      isScored: isScored,
      score: score
    };

    setSelectedAnswer(answerSelected);

    setShowDetail(true);
  };

  const getAnswerList = (
    taskContentId: string,
    formId: string,
    answers: TaskContentAnswerEntity
  ): React.ReactNode[] => {
    const answerList: React.ReactNode[] = [];

    if (!taskContentId) {
      return answerList;
    }

    let taskContent = GetTaskContentById(
      gameDocument!.gameDocument,
      taskContentId!
    );

    let form = taskContent?.forms?.find((x) => x.id === formId);

    if (form) {
      if (form.type === 'checkbox' || form.type === 'radio') {
        answers?.answers
          ?.filter((x) => x.value)
          ?.forEach((answer, index) => {
            const description = GetResourceValue(
              gameDocument?.gameDocument!,
              answer.answerResId,
              ''
            );
            answerList.push(
              <label key={index} className={'text-break'}>
                {description}
              </label>
            );
          });
      } else if (form.type === 'image') {
        answers?.answers?.forEach((answer, index) => {
          answerList.push(
            <div className={'d-flex align-items-center'}>
              <img
                src={answer.value}
                className={'img-fluid'}
                alt="Answer Image"
              />
            </div>
          );
        });
      } else if (form.type === 'file') {
        answers?.answers?.forEach((answer, index) => {
          answerList.push(
            <a href={answer?.value} target="_blank">
              Open
            </a>
          );
        });
      } else if (form.type === 'video') {
        answers?.answers?.forEach((answer, index) => {
          answerList.push(
            <video controls playsInline className={`video h-100 w-100`}>
              <source src={answer?.value} type={'video/mp4'} />
            </video>
          );
        });
      } else {
        answers?.answers?.forEach((answer, index) => {
          answerList.push(
            <label className={'text-break'}>{answer?.value}</label>
          );
        });
      }
    }
    return answerList;
  };

  const checkIsManualScoring = (taskContentId: string, formId: string) => {
    let taskContent = GetTaskContentById(
      gameDocument?.gameDocument,
      taskContentId!
    );
    let form = taskContent?.forms?.find((x) => x.id === formId);
    return form?.isManualScoring ? 'M' : 'A';
  };

  return (
    <Col md={4} className={'p-1'} key={props.index}>
      <div className={'d-flex flex-column'}>
        <span className={'fw-bold'}>{props?.playerName}</span>
        <div className={'card p-3'}>
          <div className={'mb-3'}>
            <span>
              Task :{' '}
              {GetResourceValue(
                gameDocument!.gameDocument!,
                GetTaskById(
                  gameDocument!.gameDocument!,
                  props!.selectedTask!.id!
                )!.titleResId!,
                ''
              )}
            </span>
          </div>
          <div className={'d-flex w-full justify-content-between'}>
            <div className={'d-flex flex-column'}>
              <small className={'text-muted'}>Score</small>
              <div className={'d-flex'}>
                <h1 className={'text-success mr-3'}>
                  {checkIsManualScoring(
                    props!.selectedTask!.taskContentId!,
                    props!.taskContentAnswer!.formId!
                  )}
                </h1>
                <h1 className={'text-dark'}>
                  {roundingDecimalNumber(
                    props!.taskContentAnswer!.score! ?? 0,
                    2
                  )}
                </h1>
              </div>
            </div>

            <div>
              <a
                href="#"
                className={'cursor-pointer'}
                onClick={() =>
                  onSelectEdit(
                    props!.playerCode,
                    props!.selectedTask!.id,
                    props!.taskContentAnswer!.formId!,
                    JSON.stringify(props?.taskContentAnswer),
                    checkIsManualScoring(
                      props!.selectedTask!.taskContentId!,
                      props!.taskContentAnswer!.formId!
                    ) === 'A'
                      ? true
                      : false || (props?.taskContentAnswer?.score ?? 0) > 0,
                    props?.taskContentAnswer?.score
                  )
                }>
                {isEdit ? 'Edit' : 'View'}
              </a>
            </div>
          </div>
          <div className={'d-flex w-full'}>
            <div className={'flex-grow-1'}>
              <div className={'d-flex flex-column'}>
                <small className={'text-muted mb-2'}>Answer</small>
                {props?.selectedTask &&
                  getAnswerList(
                    props!.selectedTask!.taskContentId!,
                    props!.taskContentAnswer!.formId!,
                    props!.taskContentAnswer!
                  ).map((item, index) => (
                    <React.Fragment key={index}>{item}</React.Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDetail && (
        <Popup
          handleClose={() => setShowDetail(false)}
          width={'600'}
          className={
            'box assessment-export d-flex flex-column position-relative'
          }>
          <DetailPlayer
            dataPlayer={selectedAnswer}
            onSubmitScore={(e) => {
              if (props.onSubmitScore) {
                props.onSubmitScore(e);
                setShowDetail(false);
              }
            }}></DetailPlayer>
        </Popup>
      )}
    </Col>
  );
};
