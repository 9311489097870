import React, { useCallback, useContext, useRef } from 'react';
import MessageChat from '../../components/chat/message-chat';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { GameDocumentContext } from '../../contexts/game-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { PlayerContext } from '../../contexts/player';
import {
  Chat,
  ChatMessageSendEvent,
  ChatMessageTemplateProps
} from '@progress/kendo-react-conversational-ui';
import { ChatState } from '../../types/state/websocket/chat-state';
import { PlayerState } from '../../types/state';
import { Avatar } from '@progress/kendo-react-layout';
import { MessageTemplate } from '../../components/chat/message-template';
import { Badge } from 'react-bootstrap';

interface ChatRoom {
  activeTab: string;
  messages: ChatState[];
  selectedPlayer: string;
  visibleButtonChat: boolean;
  playerList: ChatRoomPlayer[];
  onChangePlayer: (code: string) => Promise<void>;
  addNewMessage: (event: ChatMessageSendEvent) => Promise<void>;
}

export interface ChatRoomPlayer extends PlayerState {
  totalUnreadMessage: number;
}

export const ChatRoom = ({
  messages,
  activeTab,
  playerList,
  selectedPlayer,
  visibleButtonChat,
  addNewMessage,
  onChangePlayer
}: ChatRoom) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [gameDocumentstate] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const [playerState] = useContext(PlayerContext);

  const user = {
    id: playerState?.playerState?.code,
    avatarUrl: playerState?.playerState?.avatarImage
  };

  const renderMessageTemplate = useCallback(
    (props: ChatMessageTemplateProps) => (
      <MessageTemplate
        hideChatButton={true}
        currentUserCode={user.id!}
        {...props}
      />
    ),
    [user.id]
  );

  React.useEffect(() => {
    if (containerRef.current) {
      const chatContainer =
        containerRef.current.querySelector('.chat-container');
      if (chatContainer) {
        const height = containerRef.current.clientHeight;
        chatContainer.setAttribute('style', `height:${height}px`);
      }
    }
  }, [containerRef.current, containerRef.current?.clientHeight]);

  return (
    <div className="h-100 w-100 row m-0 p-0" ref={containerRef}>
      {activeTab === 'Direct' && (
        <div
          className="col-3 d-none d-md-block pl-0 pr-3"
          style={{
            height: containerRef?.current?.clientHeight,
            overflowY: 'auto'
          }}>
          <div className="list-group w-100">
            {playerList.map((item, index) => (
              <div
                key={index}
                className={`list-group-item cursor-pointer ${
                  selectedPlayer === item.code ? 'selected-list' : ''
                }`}
                onClick={() => {
                  onChangePlayer(item.code!);
                }}>
                <div className={'d-flex gap-2 align-items-center'}>
                  <Avatar type={'image'} themeColor={'light'}>
                    <img
                      src={item.avatarImage}
                      className={` rounded-circle`}
                      alt={'Player Avatar'}
                    />
                  </Avatar>
                  {item?.name}
                  {item.totalUnreadMessage != 0 && (
                    <div className="ml-5">
                      <Badge bg="primary">{item.totalUnreadMessage}</Badge>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div
        className={`col-12 ${activeTab === 'Direct' ? 'col-md-9' : 'col-md-12'} m-0 p-0 h-100`}>
        <Chat
          className={`chat-container ${!visibleButtonChat ? 'hide-action' : ''}`}
          user={user}
          messages={messages}
          onMessageSend={addNewMessage}
          placeholder={
            generateTitleById(
              '2373ccd3-e73e-403d-bd99-7083f1512a96',
              gameDocumentstate,
              displayLanguageContext.displayLanguageSelected.resources!,
              'game'
            ) || 'Type a message...'
          }
          message={MessageChat}
          messageTemplate={renderMessageTemplate}
        />
      </div>
    </div>
  );
};
