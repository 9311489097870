import { GameDocumentContext } from '../../../contexts/game-document';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { PlayerContextState } from '../../../contexts/player';
import { GetTaskById } from '../../../utils/game-document/assets';
import { GetForm } from '../../../utils/game-document/assets/task-contents';
import { Row } from 'react-bootstrap';
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridCellProps
} from '@progress/kendo-react-grid';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { GetResourceValue } from '../../../utils/game-document/resources';
import { capitalizeFirstLetter } from '../../../utils/string';
import { DataResult, State, process } from '@progress/kendo-data-query';
import {
  GridMode,
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../grid/grid-tool-bar';
import { TaskContentAnswerEntity } from '../../../types';
import { ManualScoring } from '../../../types/manual-scoring';
import Popup from '../../popup-window';
import { DetailPlayer } from '../detail-player';
import { roundingDecimalNumber } from '../../../utils/number';
import { PDFExport } from '@progress/kendo-react-pdf';
import AnswerColumn from './answer-column';

export interface TaskState {
  taskName?: string;
  question?: string;
  playerCode?: string;
  playerName?: string;
  questionType?: string;
  answer?: string;
  scoringType?: string;
  time?: string;
  score?: number;
  taskId?: string;
  taskContentId?: string;
  formId?: string;
  answers?: TaskContentAnswerEntity;
}

interface TaskListProps {
  playerStates?: PlayerContextState[];
  onViewChange?: (view: GridMode) => void;
  viewMode?: GridMode;
  onSubmitScore?: (e: ManualScoring) => void;
  onExport?: () => void;
}

export const TaskList = ({ playerStates, ...props }: TaskListProps) => {
  const initialDataState: State = {
    sort: [{ field: 'playerName', dir: 'asc' }],
    ...DefaultGridSettings.initialDataState
  };
  const [gameDocument] = useContext(GameDocumentContext);
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [dataResult, setDataResult] = useState<DataResult>();
  const [data, setData] = useState<TaskState[]>([]);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [selectedAnswer, setSelectedAnswer] = useState<
    ManualScoring | undefined
  >();
  const pdfExportComponent = React.useRef<PDFExport>(null);

  const generateTasks = () => {
    let tasks: TaskState[] = [];

    if (playerStates) {
      let data = playerStates?.map((playerState, playerStateIndex) => {
        try {
          return playerState?.playerState!.tasks?.map((task, taskIndex) => {
            return task?.taskContentFormAnswers?.map(
              (taskContentAnswer, index) => {
                return {
                  taskId: task?.id,
                  taskName: GetTaskById(gameDocument?.gameDocument!, task?.id!)
                    ?.name,
                  question: GetResourceValue(
                    gameDocument?.gameDocument!,
                    GetForm(
                      gameDocument?.gameDocument!,
                      GetTaskById(gameDocument?.gameDocument!, task.id!)
                        ?.taskContentId!,
                      taskContentAnswer?.formId!
                    )?.questionResId ?? '',
                    ''
                  ),
                  playerName: playerState?.playerState?.name,
                  playerCode: playerState?.playerState?.code,
                  questionType: capitalizeFirstLetter(
                    GetForm(
                      gameDocument?.gameDocument!,
                      GetTaskById(gameDocument?.gameDocument!, task.id!)
                        ?.taskContentId!,
                      taskContentAnswer?.formId!
                    )?.type ?? ''
                  ),
                  scoringType: capitalizeFirstLetter(
                    GetForm(
                      gameDocument?.gameDocument!,
                      GetTaskById(gameDocument?.gameDocument!, task.id!)
                        ?.taskContentId!,
                      taskContentAnswer?.formId!
                    )?.isManualScoring
                      ? 'M'
                      : 'A'
                  ),
                  formId: taskContentAnswer.formId,
                  score: taskContentAnswer.score,
                  taskContentId: GetTaskById(
                    gameDocument?.gameDocument!,
                    task.id!
                  )?.taskContentId!,
                  answers: taskContentAnswer
                };
              }
            );
          });
        } catch (error) {}
      });

      data?.forEach((dataTasks) => {
        if (dataTasks) {
          dataTasks?.forEach((item) => {
            item !== undefined && tasks.push(item as TaskState);
          });
        }
      });
      setData(tasks.flat());
    }
  };

  const ScoringTypeColoring = (props: GridCellProps) => {
    return (
      <td>
        <span className={'text-success'}>
          {props?.dataItem?.scoringType ?? '-'}
        </span>
      </td>
    );
  };

  const ScoreColumn = (props: GridCellProps) => {
    return (
      <td>
        <span>{roundingDecimalNumber(props?.dataItem?.score ?? 0, 2)}</span>
      </td>
    );
  };

  const CommandColumn = (props: GridCellProps) => {
    return (
      <td>
        <div
          className={'d-flex align-items-center justify-content-center w-full'}>
          <span
            onClick={() => onView(props)}
            className={'material-symbols-outlined cursor-pointer text-dark'}>
            visibility
          </span>
        </div>
      </td>
    );
  };

  const onView = (props: GridCellProps) => {
    let answerSelected: ManualScoring = {
      playerName: props?.dataItem?.playerName,
      gameCode: gameDocument?.gameCode,
      playerCode: props?.dataItem?.playerCode,
      taskId: props?.dataItem?.taskId,
      formId: props?.dataItem?.formId,
      answer: JSON.stringify(props?.dataItem?.answers),
      isScored:
        (props?.dataItem?.scoringType === 'A' ? true : false) ||
        (props?.dataItem?.score ?? 0) > 0,
      score: props?.dataItem?.score
    };
    setSelectedAnswer(answerSelected);

    setShowDetail(true);
  };

  useEffect(() => {
    generateTasks();
  }, [playerStates]);

  useEffect(() => {
    setDataResult(process(data, dataState));
  }, [data, dataState]);

  const QuestionType = (props: GridCellProps) => {
    return (
      <td>
        {props.dataItem?.questionType === 'Radio'
          ? 'Multiple Choice'
          : props.dataItem?.questionType}
      </td>
    );
  };

  return (
    <Row className={'d-flex w-100'}>
      <div
        className={
          'd-flex flex-column justify-items-center align-items-center pr-0'
        }>
        <div className={'mt-2 w-100'}>
          <GridToolBar
            viewMode={props.viewMode}
            searchPlaceholder={'Search'}
            columnsToSearch={[
              'playerName',
              'taskName',
              'question',
              'questionType',
              'score'
            ]}
            onGridModeChange={(e) => {
              if (props.onViewChange) {
                props.onViewChange(e.gridMode);
              }
            }}
            {...dataState}
            onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}
            handleExport={() => {
              if (props.onExport) {
                props.onExport();
              }
            }}
          />

          <Grid
            id={'grid-tasks'}
            className={'w-full'}
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            data={dataResult}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column field={'taskName'} title={'Task'} />
            <Column field={'playerName'} title={'Player'} />
            <Column title={'Question type'} cell={QuestionType} />
            <Column
              field={'scoringType'}
              cell={ScoringTypeColoring}
              title={'Type'}
            />
            <Column cell={AnswerColumn} title={'Answer'} />
            <Column cell={ScoreColumn} title={'Score'} />
            <Column width={'100px'} cell={CommandColumn} />
          </Grid>
        </div>
      </div>

      {showDetail && (
        <Popup
          handleClose={() => setShowDetail(false)}
          width={'600'}
          className={
            'box assessment-export d-flex flex-column position-relative'
          }>
          <DetailPlayer
            dataPlayer={selectedAnswer}
            onSubmitScore={(e) => {
              if (props.onSubmitScore) {
                props.onSubmitScore(e);
                setShowDetail(false);
              }
            }}></DetailPlayer>
        </Popup>
      )}
    </Row>
  );
};
