import { GameDocument } from '../../../types/game-document';

export const GetImageOverlay = (
  gameDocument: GameDocument,
  zoneId?: string
) => {
  if (zoneId) {
    return gameDocument.assets.maps?.find((map) => map.id === zoneId)
      ?.imageOverlay;
  }

  return gameDocument.assets.maps?.find((map) => map.name === 'world-map')
    ?.imageOverlay;
};
