import React from 'react';
import { Polygon } from 'ol/geom';
import { getDistance } from 'ol/sphere';
import { merge, cloneDeep } from 'lodash';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat, toLonLat } from 'ol/proj';

import {
  AlgorithmEventHandlerAsync,
  AlgorithmEventHandlerPlayer,
  executeControlStructureStepEventHandler
} from '../utils/game-engine/game';
import {
  AddExecutedStepAsync,
  IsStepExecuted,
  OpenTask,
  ShowTaskAsync
} from '../utils/game-engine/player';
import {
  AddTask,
  GetTaskById,
  TaskExistsAsync,
  UpdateTaskAsync
} from '../utils/game-engine/assets';
import {
  GetAreasByZoneId,
  GetAreasWorldMap,
  GetTasksByIds,
  GetTasksByZoneId
} from '../utils/game-document/assets';
import { Task } from '../types/entities/task';
import { GameContext } from '../contexts/game';
import { TeamContextState } from '../contexts/team';
import { postTeamStateAsync } from '../services/teams';
import { distributeTaskAsync } from '../services/players';
import { MapContext } from '../components/map/map-context';
import { useGameTaskPosition } from './use-game-task-position';
import { GameDocumentContext } from '../contexts/game-document';
import { UpdatePlayerStateAsync } from '../utils/game-engine/base';
import { GetResourceValue } from '../utils/game-document/resources';
import { PlayerContext, PlayerContextState } from '../contexts/player';
import { isContentHtmlEmpty } from '../utils/game-engine/html-content';
import { AreaEntity, TaskEntity } from '../types/game-document/entities';
import { AlgorithmControlStructure, AlgorithmStep } from '../types/algorithm';
import { MapIllustrationContext } from '../components/map/map-illustration-context';
import { Task as PlayerTask } from '../types/entities/task';

export const useGameTask = ({
  team,
  mapType,
  accuracy,
  isFacilitator,
  selectedZoneId,
  toastEventHandler
}: {
  mapType: string;
  selectedZoneId?: string;
  accuracy?: number;
  isFacilitator?: boolean;
  team?: TeamContextState;
  toastEventHandler?: (
    player: PlayerContextState,
    step: AlgorithmStep | AlgorithmControlStructure
  ) => void;
}) => {
  const map = React.useContext(MapContext);
  const mapIllustration = React.useContext(MapIllustrationContext);
  const [game] = React.useContext(GameContext);
  const [gameDocument] = React.useContext(GameDocumentContext);
  const [player, setPlayer] = React.useContext(PlayerContext);
  const {
    hasTaskOverlay,
    addTaskOverlay,
    addTaskDistance,
    getLineDistance,
    removeTaskOverlay,
    updateTaskOverlay,
    removeTaskDistance,
    addTaskPlayerListOverlay
  } = useGameTaskPosition({ mapType });
  const callbackCLickRef = React.useRef<
    ((event: MouseEvent | Event) => void) | null
  >(null);
  const mapContext = React.useMemo(
    () => (mapType === 'illustration' ? mapIllustration : map),
    [mapType, map, mapIllustration]
  );

  const isTaskAccessible = React.useCallback(
    (taskId: string) => {
      const ingameSetting = gameDocument.gameDocument?.settings.inGame;
      if (!ingameSetting?.tasksRequireProximity) {
        return true;
      }

      let taskPosition = mapContext.getOverlayById(taskId)?.getPosition() || [
        0, 0
      ];
      if (taskPosition) {
        taskPosition = toLonLat(taskPosition);
      }
      return checkIfTaskAccesible(
        taskPosition,
        ingameSetting?.setTaskProximity
      );
    },
    [gameDocument, mapContext, player.playerState]
  );

  const checkIfTaskAccesible = React.useCallback(
    (
      position: Coordinate | [number, number],
      setTaskProximity?: number | undefined
    ) => {
      const gameDoc = gameDocument.gameDocument;
      // implement can open task on area with disable proximity setting override
      if (gameDoc?.settings.inGame.tasksRequireProximity) {
        let isAccesible = false;
        let areas: AreaEntity[] = [];

        if (player.playerState?.location === 'world-map') {
          areas = GetAreasWorldMap(gameDoc);
        } else {
          areas = GetAreasByZoneId(gameDoc, player.playerState?.location || '');
        }

        areas
          .filter((area) => area.disableProximity)
          .forEach((area) => {
            const polygon = new Polygon(area.boundary.geometry.coordinates);
            const taskPoint = fromLonLat(position);
            if (polygon.intersectsCoordinate(taskPoint)) {
              isAccesible = true;
              return;
            }
          });

        return isAccesible;
      }
      // end of implement can open task on area with disable proximity setting override

      const playerLonLat: Coordinate = [
        player.playerState?.coordinates?.longitude || 0,
        player.playerState?.coordinates?.latitude || 0
      ];

      const distance = getDistance(position, playerLonLat);

      // if tasksRequireProximity is false default maxRange are 25 meters
      const maxRange: number = setTaskProximity ? setTaskProximity : 25;
      // maxAccuracy tolerance set to 60 meters
      const maxAccuracy: number = 60;

      return (
        Math.floor(distance) <= maxRange ||
        (accuracy! <= maxAccuracy && distance <= accuracy!)
      );
    },
    [
      accuracy,
      gameDocument.gameDocument,
      player.playerState?.location,
      player.playerState?.coordinates?.latitude,
      player.playerState?.coordinates?.longitude
    ]
  );

  const getMapTasks = () => {
    const gameDoc = gameDocument.gameDocument;
    if (gameDoc) {
      if (
        (selectedZoneId && selectedZoneId === 'world-map') ||
        player?.playerState?.location === 'world-map'
      ) {
        return GetTasksByIds(
          gameDoc,
          gameDoc?.rules?.worldMap?.tasks?.map((task) => task?.taskAssId)
        );
      } else {
        if (
          (selectedZoneId && selectedZoneId !== '') ||
          player?.playerState?.location !== ''
        ) {
          return GetTasksByZoneId(
            selectedZoneId && selectedZoneId !== ''
              ? selectedZoneId!
              : player?.playerState?.location!,
            gameDoc
          );
        }
      }
    }
  };

  const getTaskImage = (playerTaskStatus: string, task: TaskEntity) => {
    //display only visible task player or worldmap task
    let image =
      playerTaskStatus === undefined || playerTaskStatus !== 'completed'
        ? 'https://cdn.catalystglobal.games/resources/map-task.png'
        : 'https://cdn.catalystglobal.games/resources/map-task--complete.png';

    const taskImage = GetResourceValue(
      gameDocument?.gameDocument!,
      task?.imageResId!,
      player?.playerState?.language?.name! ?? ''
    );

    const taskCompleteImage = GetResourceValue(
      gameDocument?.gameDocument!,
      task?.completeImageResId!,
      player?.playerState?.language?.name! ?? ''
    );

    // if task status = completed, then task image no longer exist.
    if (playerTaskStatus !== 'completed') {
      image = taskImage !== '' ? taskImage : image;
    } else if (playerTaskStatus === 'completed') {
      image = taskCompleteImage !== '' ? taskCompleteImage : image;
    }

    return image;
  };

  const changeTaskIconToComplete = (taskId: string) => {
    const tasks = getMapTasks();
    if (tasks) {
      const task = tasks.find((task) => task.id === taskId);
      if (task) {
        const imgUrl = getTaskImage('completed', task);
        updateTaskOverlay({ id: taskId, icon: imgUrl });
      }
    }
  };

  const isVisibleMapTask = (mapTask: TaskEntity, playerTask: Task) => {
    let mapTasks: TaskEntity[] = getMapTasks() ?? [];

    //If tasks not belongs to the map, hide the task!
    if (!mapTasks?.find((x) => x?.id === mapTask?.id!)) {
      return false;
    }

    if (playerTask) {
      if (playerTask.status === 'completed' && mapTask.hideWhenComplete) {
        return false;
      }
      return playerTask.isVisible!;
    } else {
      return mapTask?.isVisible!;
    }
  };

  //remove all task overlay
  const resetAllTaskOverlay = () => {
    const taskIds = gameDocument?.gameDocument?.assets?.tasks?.map(
      (task) => task?.id
    );
    taskIds?.forEach((taskId) => {
      removeTaskOverlay(taskId);
      removeTaskOverlay(`player-list-${taskId}`);
    });
  };

  //#region all task set position here
  const populateTasks = () => {
    const worldTask = getMapTasks();
    if (worldTask) {
      worldTask.forEach((worldTask) => {
        if (gameDocument.gameDocument && worldTask.boundary) {
          const task = gameDocument.gameDocument.assets?.tasks?.find((x) => {
            return x.boundary?.id === worldTask?.boundary?.id;
          });
          const findStatusTask = player.playerState?.tasks?.find((t) => {
            return t.id === worldTask.id;
          });
          const imgUrl = getTaskImage(findStatusTask?.status!, worldTask);
          const title = GetResourceValue(
            gameDocument.gameDocument,
            worldTask?.titleResId || '',
            player?.playerState?.language.name ?? ''
          );

          if (isVisibleMapTask(task!, findStatusTask!)) {
            addTaskOverlay(
              worldTask.id,
              title,
              imgUrl,
              toLonLat(worldTask.boundary.geometry.coordinates),
              isFacilitator
            );
          } else {
            removeTaskOverlay(worldTask.id);
          }
        }
      });
    }
  };

  const executeAlgorithmSteps = async (
    steps: AlgorithmStep[] | AlgorithmControlStructure[],
    checkStepExecution: boolean
  ) => {
    for (const step of steps) {
      await executeAlgorithmEventHandler(step, checkStepExecution);
    }
  };

  const executeAlgorithmEventHandler = async (
    step: AlgorithmStep | AlgorithmControlStructure,
    checkStepExecution: boolean = true
  ) => {
    if (
      step.identifier === 'team' &&
      (player.playerState?.teamCode === '' ||
        player.playerState?.teamCode === undefined)
    )
      return;
    if (
      game.gameState?.status === 'Finished' ||
      game.gameState?.status === 'PostGame'
    )
      return;
    let executable: boolean = true;
    if (checkStepExecution) {
      if (IsStepExecuted(player, step.id)) {
        executable = false;
      }
    }

    if (executable) {
      setPlayer((prev) => {
        const responseStep = AddExecutedStepAsync(prev, step?.id);
        return UpdatePlayerStateAsync(prev, responseStep);
      });

      const stepControlStructure = step as AlgorithmControlStructure;
      if (stepControlStructure?.condition) {
        if (team) {
          const controlStructureResponse =
            await executeControlStructureStepEventHandler(
              stepControlStructure,
              player,
              team
            );
          for (const step of controlStructureResponse ?? []) {
            await executeStepEventHandler(step);
          }
        }
      } else {
        await executeStepEventHandler(step);
      }
    }
  };

  const executeStepEventHandler = async (
    step: AlgorithmStep | AlgorithmControlStructure
  ) => {
    if (step.identifier === 'player') {
      // ** Temporary solution for the race condition
      if (step.operation === 'distributeTask') {
        distributeTaskAsync(
          gameDocument?.gameCode!,
          player?.playerState?.code!,
          {
            stepId: step?.id,
            taskIds: step?.argumentAssetId as string[],
            quantity: step?.argumentQuantity
          }
        ).then((response) => {
          for (const taskId of response) {
            merge(player.playerState, ShowTaskAsync(player, taskId));
          }
          setPlayer(player);
        });
      } else {
        if (team !== undefined) {
          setPlayer((prev) => {
            const response = AlgorithmEventHandlerPlayer(
              gameDocument,
              prev,
              team,
              step
            );
            toastEventHandler && toastEventHandler(prev, step);
            return UpdatePlayerStateAsync(cloneDeep(prev), cloneDeep(response));
          });
        }
      }
    } else {
      if (player.playerState?.teamCode) {
        team !== undefined &&
          (await AlgorithmEventHandlerAsync(gameDocument, player, team, step));
        postTeamStateAsync(
          gameDocument?.gameCode!,
          player.playerState.teamCode
        );
      }
    }
  };

  const onOpenEventTaskAsync = async (task: TaskEntity) => {
    const { events: { onOpenTask: { steps = [] } = {} } = {} } = task;

    await executeAlgorithmSteps(steps, false);
  };

  const onCompleteEventTaskAsync = async (task: TaskEntity) => {
    setPlayer((prev) => {
      let selectedTask = GetTaskById(prev, task.id);

      if (selectedTask) {
        selectedTask.status = 'completed';
        let response = UpdateTaskAsync(prev, selectedTask?.id!, selectedTask!);
        return UpdatePlayerStateAsync(prev, response);
      }
      return prev;
    });

    const { events: { onCompleteTask: { steps = [] } = {} } = {} } = task;
    await executeAlgorithmSteps(steps, false);
  };

  const onProximityEventTaskAsync = async (task: TaskEntity) => {
    const { events: { onProximity: { steps = [] } = {} } = {} } = task;
    await executeAlgorithmSteps(steps, true);
  };

  const onCloseEventTaskAsync = async (task: TaskEntity) => {
    const { events: { onCloseTask: { steps = [] } = {} } = {} } = task;

    await executeAlgorithmSteps(steps, false);
  };

  const isContainPreMessage = (taskContentId: string) => {
    const taskContent = gameDocument?.gameDocument?.assets?.taskContents?.find(
      (x) => x.id === taskContentId
    );
    if (taskContent !== undefined) {
      if (taskContent.preMessageResId) {
        const premessageContent = GetResourceValue(
          gameDocument?.gameDocument!,
          taskContent.preMessageResId,
          player?.playerState?.language?.name!
        );
        return !isContentHtmlEmpty(premessageContent);
      }
    }

    return false;
  };

  const openTaskContent = React.useCallback(
    async (id: string, isOnClick?: boolean, isAccesible: boolean = true) => {
      const task = gameDocument.gameDocument?.assets?.tasks?.find(
        (x) => x.id === id
      );
      if (task) {
        if (task.taskContentId && task.taskContentId !== '') {
          //Get taskContent from gameDocument

          const ingameSetting = gameDocument.gameDocument?.settings.inGame;
          const isTaskContainPreMessage = isContainPreMessage(
            task.taskContentId
          );

          const handlePlayerUpdate = () => {
            setPlayer((prev) => {
              const response = OpenTask(prev, task.id, task.isVisible);
              return UpdatePlayerStateAsync(prev, response);
            });
          };

          if (ingameSetting?.tasksRequireProximity) {
            if (
              (!isAccesible && isTaskContainPreMessage && isOnClick) ||
              isAccesible
            ) {
              handlePlayerUpdate();
              return;
            }
          } else {
            handlePlayerUpdate();
          }
        } else {
          const isTaskExist = await TaskExistsAsync(player, task.id);
          if (!isTaskExist) {
            setPlayer((prev) => {
              const response = AddTask(prev, task);
              return UpdatePlayerStateAsync(prev, response);
            });
          }
          await onOpenEventTaskAsync(task);
          await onCompleteEventTaskAsync(task);
          await onCloseEventTaskAsync(task);
        }
      }
    },
    [gameDocument.gameDocument?.assets?.tasks, player, setPlayer]
  );

  const handleClickTask = React.useCallback(
    (e: Event) => {
      const gameDoc = gameDocument.gameDocument;
      if (gameDoc && (map || mapIllustration) && !isFacilitator) {
        const { tasksRequireProximity, setTaskProximity } =
          gameDoc?.settings.inGame;

        const overlayElement = e.target as HTMLElement;
        let taskContainer = overlayElement;
        while (
          taskContainer.parentElement &&
          !taskContainer.id.startsWith('taskContainer-')
        ) {
          taskContainer = taskContainer.parentElement;
        }

        const taskId = taskContainer.id.replace('taskContainer-', '');
        if (!taskId) return;

        let taskPosition = mapContext.getOverlayById(taskId)?.getPosition() || [
          0, 0
        ];
        if (taskPosition) {
          taskPosition = toLonLat(taskPosition);
        }

        const isAccesible = checkIfTaskAccesible(
          taskPosition,
          setTaskProximity
        );
        const task = GetTaskById(player, taskId);
        const taskStatus = task && task.status && task.status === 'completed';
        if (
          tasksRequireProximity !== undefined &&
          tasksRequireProximity &&
          !isAccesible &&
          !taskStatus
        ) {
          addTaskDistance(taskId, taskPosition || [0, 0]);
        } else {
          removeTaskDistance(taskId);
        }

        // add task expanded state on mobile
        if (!taskContainer.classList.contains('expanded')) {
          taskContainer.classList.add('expanded');
          taskContainer.classList.remove('initial');
          const detailTaskContainer = taskContainer.querySelector(
            '[id^="detailTaskContainer-"]'
          );
          detailTaskContainer?.classList.add('visible');
          detailTaskContainer?.classList.remove('hidden');
          return;
        }

        // task should in expanded state that can be open
        taskContainer.classList.contains('expanded') &&
          openTaskContent(taskId, true, isAccesible);
      }
    },
    [
      player,
      mapContext,
      isFacilitator,
      gameDocument.gameDocument,
      addTaskDistance,
      openTaskContent,
      removeTaskDistance,
      checkIfTaskAccesible
    ]
  );

  const handleCheckTaskProximity = (
    tasks: TaskEntity[],
    taskProximity: number | undefined
  ) => {
    tasks.forEach((task) => {
      let taskPosition = task.boundary?.geometry?.coordinates;

      if (taskPosition) {
        taskPosition = toLonLat(taskPosition);
        const isAccesible = checkIfTaskAccesible(taskPosition, taskProximity);

        // if player entered proximity radius, task will auto running task events
        if (isAccesible) {
          onProximityEventTaskAsync(task);
        }
      }
    });
  };

  const generateTaskProximity = React.useCallback(() => {
    if (
      player.playerState?.coordinates?.longitude !== undefined &&
      player.playerState?.coordinates?.latitude !== undefined &&
      gameDocument.gameDocument?.settings.inGame
    ) {
      const { setTaskProximity } = gameDocument.gameDocument.settings.inGame;

      if (player.playerState.location === 'world-map') {
        const taskIdsOnMap = gameDocument.gameDocument.rules.worldMap.tasks.map(
          (i) => i.taskAssId
        );
        const tasks = gameDocument.gameDocument.assets.tasks
          ?.filter((i) => taskIdsOnMap.some((taskId) => taskId === i.id))
          .filter((i) => i.events?.onProximity);

        if (tasks && tasks?.length) {
          handleCheckTaskProximity(tasks, setTaskProximity);
        }
      } else if (
        player.playerState.location &&
        player.playerState.location !== 'world-map'
      ) {
        const zoneId = gameDocument.gameDocument.rules.worldMap.zones.map(
          (i) => i.zoneAssId
        );
        const zones = gameDocument.gameDocument.assets.zones?.filter((i) =>
          zoneId.some((zoneId) => zoneId === i.id)
        );
        const selectedZone = zones?.find(
          (i) => i.id === player.playerState?.location
        );
        const taskIdsOnZone = selectedZone?.tasks?.map((i) => i.taskAssId);
        if (taskIdsOnZone) {
          const tasks = gameDocument.gameDocument.assets.tasks
            ?.filter((i) => taskIdsOnZone.some((taskId) => taskId === i.id))
            .filter((i) => i.events?.onProximity);

          if (tasks && tasks?.length) {
            if (tasks && tasks?.length) {
              handleCheckTaskProximity(tasks, setTaskProximity);
            }
          }
        }
      }
    }
  }, [
    player.playerState?.coordinates?.latitude,
    player.playerState?.coordinates?.longitude
  ]);

  React.useEffect(() => {
    callbackCLickRef.current = handleClickTask;
  }, [handleClickTask]);

  React.useEffect(() => {
    if (hasTaskOverlay) {
      const overlayStop = document.getElementsByClassName(
        'ol-overlaycontainer-stopevent'
      );

      if (overlayStop.length > 0) {
        const clickListener = (e: Event) => {
          if (callbackCLickRef.current) {
            callbackCLickRef.current(e);
          }
        };

        overlayStop[0].addEventListener('click', clickListener);

        return () => {
          if (overlayStop.length !== 0) {
            overlayStop[0].removeEventListener('click', clickListener);
          }
        };
      }
    }
  }, [hasTaskOverlay]);

  // task detector for updating task distance and line based on player movement
  React.useEffect(() => {
    if (
      player.playerState?.coordinates?.longitude !== undefined &&
      player.playerState?.coordinates?.latitude !== undefined &&
      gameDocument.gameDocument?.settings.inGame
    ) {
      const coordinates = [
        player.playerState?.coordinates?.longitude,
        player.playerState?.coordinates?.latitude
      ];
      const { setTaskProximity } = gameDocument.gameDocument.settings.inGame;

      const tasks = document.querySelectorAll('.task-container');
      tasks.forEach((task) => {
        const child = task.querySelector('[id^="taskDistance-"]');
        if (child && !child.classList.contains('d-none')) {
          const taskId = child.id.replace('taskDistance-', '');
          let taskPosition = mapContext
            .getOverlayById(taskId)
            ?.getPosition() || [0, 0];
          if (taskPosition) {
            taskPosition = toLonLat(taskPosition);
          }

          // update distance text in task
          const distances = getDistance(taskPosition, coordinates);
          updateTaskOverlay({ id: taskId, distance: Math.round(distances) });

          // update line following on player position
          const lineFeature = getLineDistance();
          if (lineFeature) {
            const linePosition = lineFeature.getGeometry()?.getCoordinates();
            if (linePosition) {
              lineFeature
                .getGeometry()
                ?.setCoordinates([linePosition[0], fromLonLat(coordinates)]);
            }
          }

          const isAccesible = checkIfTaskAccesible(
            taskPosition,
            setTaskProximity
          );

          // if player entered proximity radius, task will auto open
          openTaskContent(taskId, false, isAccesible);
        }
      });
    }
  }, [
    mapContext,
    player.playerState?.coordinates?.latitude,
    player.playerState?.coordinates?.longitude
  ]);

  // task proximity detector for updating task distance based on player movement
  React.useEffect(() => {
    generateTaskProximity();
  }, [
    player.playerState?.coordinates?.latitude,
    player.playerState?.coordinates?.longitude
  ]);

  // populate player list below task in illustration map
  React.useEffect(() => {
    if (
      !gameDocument.gameDocument?.settings.inGame.tasksRequireProximity &&
      mapType === 'illustration' &&
      (isFacilitator ||
        gameDocument.gameDocument?.settings.inGame
          .showOtherPlayerOrTeamOnMap) &&
      game.gameState?.players &&
      game.gameState.players.length > 0
    ) {
      const players = game.gameState.players;
      const taskCompletedIds: string[] = [];

      players.forEach((player) => {
        if (player.latestCompletedTaskId) {
          !taskCompletedIds.includes(player.latestCompletedTaskId) &&
            taskCompletedIds.push(player.latestCompletedTaskId);
        }
      });

      taskCompletedIds.forEach((id) => {
        const playerItems = players
          .filter((p) => p.code !== player.playerState?.code)
          .filter(
            (player) =>
              player.latestCompletedTaskId &&
              player.latestCompletedTaskId === id
          )
          .map((player) => {
            const teams = game.gameState?.teams;
            let team = '';
            if (player.teamCode) {
              team =
                teams?.find((team) => team.code === player.teamCode)?.name ||
                '';
            }

            return {
              id: player.code,
              avatarUrl: player.avatarImage,
              name: player.name,
              team
            };
          });
        addTaskPlayerListOverlay(id, playerItems);
      });
    }
  }, [
    mapType,
    isFacilitator,
    selectedZoneId,
    game.gameState?.teams,
    game.gameState?.players,
    player.playerState?.code,
    !gameDocument.gameDocument?.settings.inGame.tasksRequireProximity,
    gameDocument.gameDocument?.settings.inGame.showOtherPlayerOrTeamOnMap,
    addTaskPlayerListOverlay
  ]);

  return {
    getMapTasks,
    openTaskContent,
    addTaskOverlay,
    updateTaskOverlay,
    removeTaskDistance,
    checkIfTaskAccesible,
    populateTaskOverlay: populateTasks,
    changeTaskIconToComplete,
    resetAllTaskOverlay,
    isTaskAccessible
  };
};
