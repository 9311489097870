import React, { ComponentType } from 'react';

interface ToolbarProps {
  children?: React.ReactNode;
  title: string;
}

const PageToolbar: ComponentType<ToolbarProps> = (props: ToolbarProps) => {
  return (
    <div
      className={
        'page-toolbar d-flex justify-content-between align-items-center'
      }>
      <h2>{props.title}</h2>
      <div className={'d-flex align-items-center'}>{props.children}</div>
    </div>
  );
};

export default PageToolbar;
