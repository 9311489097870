export const AvailablePlayerColor = '#14BF8B';
export const AwayPlayerColor = '#FF5527';
export const OfflinePlayerColor = '#D22630';
export const PolygonLineColor = '#FF5527';
export const DefaultPolygonFillColor = 'rgba(255, 255, 255, 0.1)';
export const DefaultAccuracyColor = 'rgba(70, 152, 203, 0.2)';
export const DefaultAccuracyStrokeColor = 'rgba(70, 152, 203, 1)';
export const DefaultCurrentPlayerColor = 'rgb(70 152 203)';
export const DefaultAccuracyLineStroke = 1;
export const FiveMinuteInSeconds = 300;
export const TenMinuteInSeconds = 600;
export const DefaultZoomLevel = 19;
export const DefaultCircleStroke = 2;
export const DefaultCircleStrokeColor = '#ffffff';
export const DefaultLineStroke = 3;
export const DefaultRadius = 7;
export const DefaultTextcolor = 'black';
export const DefaultStrokeColor = 'white';
export const DefaultOffsetY = -15;
export const DefaultFontFamily = 'bold 12px Open Sans';
