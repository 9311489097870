import React, { useContext, useEffect, useState } from 'react';
import { DisplayLanguagePublished } from '../types/game-document/entities/display-languages';
import { GameDocumentContext } from './game-document';
import { GetDisplayLanguageJsonAsync } from '../utils/game-document/display-languages';
import { DisplayLangResources } from '../constants/display-languages';
import {
  AssessmentDocumentContext,
  AssessmentDocumentContextProps
} from './assessment-document';
import { PlayerContext } from './player';

interface initialDisplayLanguageState {
  displayLanguageOriginal: DisplayLanguagePublished;
  displayLanguageSelected: DisplayLanguagePublished;
  isLoaded: boolean;
}
const initialDisplayLanguage: initialDisplayLanguageState = {
  displayLanguageOriginal: {
    id: 0,
    languageCode: '',
    languageName: '',
    description: '',
    resources: DisplayLangResources,
    resourcePacks: []
  },
  displayLanguageSelected: {
    id: 0,
    languageCode: '',
    languageName: '',
    description: '',
    resources: DisplayLangResources,
    resourcePacks: []
  },
  isLoaded: false
};

const DisplayLanguageContext = React.createContext<
  [
    initialState: initialDisplayLanguageState,
    setState: React.Dispatch<React.SetStateAction<initialDisplayLanguageState>>
  ]
>([initialDisplayLanguage, () => {}]);

const DisplayLanguageProvider = (props: any) => {
  const [state, setState] = useState<initialDisplayLanguageState>(
    initialDisplayLanguage
  );
  const [gameDocument] = useContext(GameDocumentContext);
  const assessmentDocumentContext = useContext(AssessmentDocumentContext) as
    | AssessmentDocumentContextProps
    | undefined;
  const [playerStateContext] = useContext(PlayerContext);
  const currentPath = window.location.pathname;
  const assessmentDocument = assessmentDocumentContext?.playerState;

  const generateUrl = (): string => {
    let url;
    if (currentPath.includes('assessment')) {
      if (playerStateContext?.assessmentState?.displayLanguageUrl) {
        url = playerStateContext?.assessmentState?.displayLanguageUrl;
      } else if (assessmentDocument?.assessmentDocument?.displayLanguageUrl) {
        url = assessmentDocument?.assessmentDocument?.displayLanguageUrl;
      }
    } else {
      if (playerStateContext?.playerState?.displayLanguageUrl) {
        url = playerStateContext?.playerState?.displayLanguageUrl;
      } else if (gameDocument?.gameDocument?.overview?.displayLanguagesUrl) {
        url = gameDocument?.gameDocument!.overview!.displayLanguagesUrl;
      }
    }
    return url ?? '';
  };

  useEffect(() => {
    const getUrl = generateUrl();

    if (!state.isLoaded) {
      if (getUrl) {
        GetDisplayLanguageJsonAsync(getUrl).then((response) => {
          setState((prev) => ({
            displayLanguageOriginal: response,
            displayLanguageSelected: response,
            isLoaded: true
          }));
        });
      }
    } else {
      if (getUrl) {
        GetDisplayLanguageJsonAsync(getUrl).then((response) => {
          setState((prev) => ({
            ...prev,
            displayLanguageSelected: response,
            isLoaded: true
          }));
        });
      }
    }
  }, [
    gameDocument?.gameDocument?.overview?.displayLanguagesUrl,
    playerStateContext?.assessmentState?.displayLanguageUrl,
    playerStateContext?.playerState?.displayLanguageUrl
  ]);

  return (
    <DisplayLanguageContext.Provider value={[state, setState]}>
      {props.children}
    </DisplayLanguageContext.Provider>
  );
};

export { DisplayLanguageContext, DisplayLanguageProvider };
